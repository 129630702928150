import React, {useEffect, useState} from 'react';
import classes from "./OffersForm.module.scss"
import {InsuranceObjectInterface, RequestStatusEnum} from "../../common/commonTypes";
import FormBuilder, {TabFormInterface} from "../FormBuilder/FormBuilder";
import {renderToStaticMarkup} from "react-dom/server";
import {createOffer, getInsuranceObject} from "../../api/commonApi";
import {useAppSelector} from "../../store/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchInsuranceObjects} from "../../store/features/insuranceObjects";
import {useDispatch} from "react-redux";
import cn from "classnames";
import AddButton from "../../features/ui/AddButton/AddButtonButton";
import {fetchQuotes, resetPagination, setScrollIndex} from "../../store/features/quotesTable";
import _ from "lodash"
import {toast} from "react-toastify";
import {separateThousands} from "../../common/lib";


const physicalData: TabFormInterface = {
    tabs: [
        {
            key: "root",
            label: "Предложения",
            fields: [
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                group: {
                                    maxLength: 3,
                                    class: cn(classes.offerGroup),
                                    addButtonComponent: renderToStaticMarkup(<AddButton
                                        title={'Добавить Предложение'}/>),
                                    name: "offer",
                                    fields: [
                                        [
                                            {
                                                namedGroup: {
                                                    class: cn(classes.offerSubGroup),
                                                    name: "",
                                                    fields: [
                                                        {
                                                            type: "date",
                                                            key: "date",
                                                            label: "Дата",
                                                            required: false,
                                                            disabled: true,
                                                            value: (new Date()),
                                                            validate: (val: string) => true,
                                                        },
                                                        {
                                                            type: "number",
                                                            key: "insurance_award",
                                                            label: "Страховая премия",
                                                            required: true,
                                                            validate: (val: string) => true,
                                                            onRealChange: (form, v, idx) => {
                                                                if (!form || !form['offer'] || !form['offer'][idx]) return
                                                                if (form['offer'][idx]['tarif_input']) {
                                                                    // @ts-ignore
                                                                    window.setFormValue('offer[' + idx + '].tarif_input', false);
                                                                    // @ts-ignore
                                                                    window.setFormValue('offer[' + idx + '].insurance_award', v);
                                                                    return
                                                                }
                                                                if (v) {
                                                                    // TARIF=SP*100/SS
                                                                    // @ts-ignore
                                                                    let val = parseFloat(parseInt(v) * 100 / parseInt(form.insurance_sum)).toFixed(4)

                                                                    if (val) {
                                                                        // @ts-ignore
                                                                        window.setFormValue('offer[' + idx + '].tarif', val);
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        {
                                                            type: "string",
                                                            key: "tarif",
                                                            label: "Тариф (%)",
                                                            value: '0',
                                                            required: true,
                                                            validate: (val: string) => true,
                                                            onRealChange: (form, v, idx) => {
                                                                if (v.indexOf(".") === v.length - 1) {
                                                                    // @ts-ignore
                                                                    window.setFormValue('offer[' + idx + '].tarif', v);
                                                                    return
                                                                }
                                                                v = parseFloat(v)
                                                                // @ts-ignore
                                                                window.setFormValue('offer[' + idx + '].tarif', v);
                                                                // SP=SS*TARIF/100
                                                                // @ts-ignore
                                                                const val = parseInt(parseInt(form.insurance_sum) * parseFloat(v) / 100)
                                                                // @ts-ignore
                                                                window.setFormValue('offer[' + idx + '].tarif_input', true);
                                                                // @ts-ignore
                                                                window.setFormValue('offer[' + idx + '].insurance_award', val);
                                                            }
                                                        },
                                                        {
                                                            type: "string",
                                                            key: "comment",
                                                            label: "Комментарий",
                                                            required: false,
                                                            validate: (val: string) => true,
                                                        },
                                                        {
                                                            type: "string",
                                                            key: "account_needs",
                                                            label: "Для договора необходимо:",
                                                            required: false,
                                                            validate: (val: string) => true,
                                                        },
                                                        {
                                                            type: "checkbox",
                                                            key: "is_franchise_exist",
                                                            label: "Франшиза",
                                                            required: false,
                                                            validate: (val: string) => true,
                                                        },
                                                        {
                                                            namedGroup: {
                                                                class: cn(classes.offerSubGroup),
                                                                name: "franchise",
                                                                showArrIf: (form: any, idx: number): any => {
                                                                    return form && Object.keys(form) && form.offer && form.offer.hasOwnProperty(idx) && form.offer[idx].is_franchise_exist === true
                                                                },
                                                                fields: [
                                                                    {
                                                                        type: "number",
                                                                        key: "franchise_price",
                                                                        label: "Стоимость франшизы",
                                                                        value: 0,
                                                                        required: false,
                                                                        validate: (val: string) => true,
                                                                    },
                                                                    {
                                                                        type: "checkbox",
                                                                        key: "is_franchise_conditional",
                                                                        label: "Условная франшиза",
                                                                        value: false,
                                                                        tooltip: "Стоит галочка - условная, нет галочки - безусловная",
                                                                        required: false,
                                                                        validate: (val: string) => true,
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            },

                                            {
                                                type: "files",
                                                key: "files",
                                                label: "Загрузить документы",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                        ]
                                    ]
                                }
                            }
                        ]
                    }
                }
            ]

        },

    ],
}


interface InsuranceObjectFormProps {
    clientId: string,
    quoteId: string,
    offersCount: number,
    onClose?: () => void,
}

const validateValues = (vals: any) => {
    // эта валидация существует здесь, потому что в FormBuilder отсутсвует валидация форм в виде массивов
    let validationErrors: string[] = []
    vals.offer.forEach((v: any, i: number) => {
        const offerNumber = i + 1;
        // валидация тарифа
        const tarifStr = v.tarif;
        try {
            // @ts-ignore
            const tarifInt = parseInt(tarifStr)
            if (tarifInt > 100) {
                validationErrors.push("Предложение №" + offerNumber + ": 0 > Тариф < 100")
            }
        } catch (e) {
            validationErrors.push("Предложение №" + offerNumber + ": Некорректный тариф. Корректный тариф, например: 1.9999 или 99")
        }

        // валидация блока франшизы
        if (v.is_franchise_exist === true) {
            try {
                if (!v.franchise.hasOwnProperty("franchise_price") || v.franchise.franchise_price == 0) {
                    validationErrors.push("Предложение №" + offerNumber + ": Стоимость Франшизы для предложения не может быть равна 0")
                } else {
                    v.is_franchise_conditional = v.franchise.is_franchise_conditional ? v.franchise.is_franchise_conditional : false;
                    v.franchise_price = v.franchise.franchise_price;
                    delete v.franchise
                }
            } catch (e) {
                validationErrors.push("Предложение №" + offerNumber + ": Некорректно заполен блок Франшиза")
            }
        }
    })

    return validationErrors
}

// @ts-ignore
const OffersFormForm = (props: InsuranceObjectFormProps) => {

    const dispatch = useDispatch();

    useEffect(() => {
        //@ts-ignore
        physicalData.tabs[0].fields[0].namedGroup.fields[0].group.maxLength = 3 - props.offersCount;
    }, [])


    const {activeClientId} = useAppSelector((state) => state.clientsTable);
    const {activeId} = useAppSelector((state) => state.insuranceObjects);
    const [isSaving, setIsSaving] = useState(false);

    const [lazyTitle, setTitle] = useState("Создание предложений");

    const insuranceObjectActiveId = useAppSelector((state) => state.insuranceObjects.activeId);

    const [io, setIO] = useState<InsuranceObjectInterface | null>(null);

    useEffect(() => {
        getInsuranceObject(insuranceObjectActiveId).then((io) => {
            setIO(io.data)
            const insStr = JSON.stringify(io.data.insurance_sum);
            const result = {
                insurance_sum: JSON.stringify(io.data.insurance_sum),
            }
            // @ts-ignore
            setTitle("Создание предложений для страховой суммы " + separateThousands(insStr) + " руб.")
            setInitValues(result);
            setStatus(RequestStatusEnum.Succeeded);
        })
    }, [])

    const onSubmit = async (values: any) => {


        try {
            setIsSaving(true);
            const vals = _.cloneDeep(values);

            const validated = validateValues(vals);
            if (validated.length > 0) {
                validated.forEach((v: any) => toast.error(`${v}`));
                return
            }

            vals.offer.forEach((v: any) => {
                v.date = new Date();
                v.tarif = v.tarif.toString()
            })

            await createOffer(props.quoteId, vals.offer);

            await dispatch(fetchInsuranceObjects({clientId: activeClientId}));

            await dispatch(resetPagination());
            // @ts-ignore
            await dispatch(setScrollIndex(0));
            await dispatch(fetchQuotes({skip: 0, take: 40, activeInsuranceId: activeId}));
        } catch (e) {
            // TODO: handle error
        } finally {
            setIsSaving(false)
        }

        props.onClose && props.onClose();

    }

    const [initValues, setInitValues] = useState({});

    //const [status, setStatus] = useState(props.quoteId === "" ? RequestStatusEnum.Idle : RequestStatusEnum.Loading);
    const [status, setStatus] = useState(RequestStatusEnum.Idle);


    if (status === RequestStatusEnum.Loading) {
        return <div
            style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size={100}/>
        </div>
    } else {
        return (
            <div>
                <FormBuilder
                    onClose={props.onClose}
                    initialValues={initValues}
                    onSubmit={onSubmit}
                    data={physicalData}
                    useTab={false}
                    title={lazyTitle}
                    loading={isSaving}
                />
            </div>
        )
    }

}

export default OffersFormForm;
