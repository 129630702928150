import {ColourOption} from "../features/ui/StatusSelect/data";


export interface DealCreateInterface {
    files: Array<File>;
    account_file: Array<File>;
    contract_date: string;
    contract_file: Array<File>;
    contract_number: string;
    date: string;
    kv_v_rub: number;
    end_date: string;
    kv_v_perc: string;
    number: number;
    pokazatel_stat: string;
    quotation_id: string;
    sp: number;
    ss: number;
    start_date: string;
}

export interface DealCreateInterface2 {
    account_file_id: string;
    contract_date: string;
    contract_file_id: string;
    contract_number: string;
    date: string;
    kv_v_rub: number;
    end_date: string;
    kv_v_perc: string;
    number: number;
    pokazatel_stat: string;
    quotation_id: string;
    sp: number;
    ss: number;
    start_date: string;
    status_id: number;
}

export interface Apartment {
    area: number;
    built_year: number;
    created_at: string;
    inner_decoration_price: number;
    insurance_object_id: string;
    is_security_system: boolean;
    is_under_construction: boolean;
    property_type: string;
    region: string;
    residence_type: string;
    updated_at: string;
    wall_materials: string;
}

export interface CommercTmc {
    area_overlap_percent_video_control: number;
    building_insurance_price: number;
    built_year: number;
    computers_insurance_price: number;
    created_at: string;
    fire_resistance_system: string;
    furniture_inventory_insurance_price: number;
    insurance_object_id: string;
    is_24_hours_pult_automatic_fire_system: boolean;
    is_administrative_building_function: boolean;
    is_building: boolean;
    is_chop_security: boolean;
    is_computers: boolean;
    is_drencher_automatic_fire_system: boolean;
    is_electricity_heating_system: boolean;
    is_employees_security: boolean;
    is_furniture_inventory: boolean;
    is_gazoline_heating_system: boolean;
    is_items_resources: boolean;
    is_land_plot: boolean;
    is_living_building_function: boolean;
    is_mchs_pult_automatic_fire_system: boolean;
    is_mvd_security: boolean;
    is_not_wooden_overlap_building_materials: boolean;
    is_on_floor_organize_tmc: boolean;
    is_on_floor_without_rack_organize_tmc: boolean;
    is_on_pallet_organize_tmc: boolean;
    is_on_rack_above_55_organize_tmc: boolean;
    is_on_rack_below_55_organize_tmc: boolean;
    is_on_rack_combustible_material_organize_tmc: boolean;
    is_par_gaz_pena_automatic_fire_system: boolean;
    is_production_building_function: boolean;
    is_production_equipment: boolean;
    is_room_decor: boolean;
    is_room_in_building: boolean;
    is_sale_building_function: boolean;
    is_vnevedom_mvd_automatic_security: boolean;
    is_warehouse_building_function: boolean;
    is_windows: boolean;
    is_with_record_video_control: boolean;
    is_without_record_video_control: boolean;
    is_wooden_overlap_building_materials: boolean;
    items_resources_insurance_price: number;
    land_plot_insurance_price: number;
    nomen_group_tmc: string;
    other_building_function: string;
    other_connect_automatic_security: string;
    other_heating_system: string;
    other_name: string;
    other_name_insurance_price: number;
    production_equipment_insurance_price: number;
    room_decor_insurance_price: number;
    room_in_building_insurance_price: number;
    updated_at: string;
    windows_insurance_price: number;
}

export interface House {
    area: number;
    basement_type: string;
    built_year: number;
    communications: string;
    created_at: string;
    insurance_object_id: string;
    is_fire_resistance_system: boolean;
    is_security_system: boolean;
    is_under_construction: boolean;
    property_type: string;
    region: string;
    residence_type: string;
    updated_at: string;
    wall_materials: string;
}

export interface Vehicle {
    assemble_year: number;
    brand: string;
    created_at: string;
    distance_passed: number;
    driver_age: number;
    driver_experience_years: number;
    driver_fio: string;
    insurance_object_id: string;
    model: string;
    price: number;
    updated_at: string;
    usage_region: string;
}

export interface InsuranceObjectInterface {
    address: string;
    client_id: string;
    created_at: string;
    financing_type_id: number;
    id: string;
    insurance_sum: number;
    status_id: number;
    type_id: number;
    updated_at: string;
    apartment?: Apartment;
    commerc_tmc?: CommercTmc;
    quotations_count?: number;
    house?: House;
    vehicle?: Vehicle;
    files: Array<File>;
}


export interface ContactLegalInterface {
    id?: string,
    "comment": string,
    "email": string,
    "fio": string,
    "phone": Array<string>,
    "position": "string"
}

export interface RequisitesInterface {
    id?: string,
    "bank_name": string,
    "bik": string,
    "k_s": string,
    "r_s": string
}

export interface CreateOfferInterface {
    "comment": string,
    "date": Date | string,
    "insurance_award": number,
    "quotation_id": string,
    "tarif": number,
    "account_needs": string,
    files: Array<File>,
}


export interface UpdateOfferInterface {
    is_applied?: boolean,
    is_rejected?: boolean,
    quotation_status_id?: number
}

export interface UpdateIsPaidDealsPaymentScheduleInterface {
    is_paid?: boolean,
}

export interface UpdateDealInterface {
    status_id?: number,
}

export interface FileResponse {
    id: string,
    name: string,
}

export interface OfferResponseInterface {
    "comment": string,
    "date": Date | string,
    "insurance_award": number,
    is_applied?: boolean,
    "quotation_id": string,
    "tarif": number,
    "account_needs": string,
    is_franchise_exist: boolean,
    franchise_price?: number,
    is_franchise_conditional?: boolean,
    files: Array<FileResponse>,
}

export interface CreateLegalInterface {
    "client": {
        "contacts": Array<ContactLegalInterface>,
        "requisites": Array<RequisitesInterface>
    },
    "comment": string,
    "inn": string,
    "name": string,
    "opf_type_id": 1,
    "site": string
}

export interface CreatePhysicalInterface {
    "client": {
        "contacts": Array<ContactLegalInterface>,
        "requisites": Array<RequisitesInterface>
    },
    "passport_series": string,
    "passport_number": string,
    "fio": string,
    "registration_address": string,
    "issue_date": string,
    "issuer": string,
    "dep_code": string,
    "birthdate": string,
    "birthplace": string,
    "created_at": string,
    "updated_at": string
}


export interface ClientLegalInterface {
    id: number,
    type: {
        id: number,
        name: string
    },
    name: string,
    inn: string,
    opf_type: {
        id: number,
        name: string,
        created_at: string,
        updated_at: string
    },
    site: string,
    //object_statuses: Array<number>
}

export interface ClientPhysicalInterface {
    id: number,
    type: {
        id: number,
        name: string
    },
    opf_type: {
        created_at: string | null,
        updated_at: string | null
    },
    passport_series: string,
    passport_number: string,
    fio: string,
    registration_address: string,
    issue_date: string,
    issuer: string,
    dep_code: string,
    birthdate: string,
    birthplace: string,
    //object_statuses: Array<number>
}


export enum InsuranceObjectStatusesEnum {
    NEW = 1,
    SEND_IN_INSURANCE_COMPANY = 2,
    WORKING_IN_INSURANCE_COMPANY = 3,
    QUOTES_PROPOSED = 4,
    DEAL_REQUESTED = 5,
    INSURED = 6,
    DECLINED_BY_INSURANCE_COMPANY = 7
}

export enum RoleIdsEnum {
    ADMIN = 1,
    KM = 2,
    SSK = 3,
    SUPERVISOR_KM = 4,
    SUPERVISOR_SSK = 5,
}



export enum ClintTypesEnum {
    LEGAL = 1,
    PHYSICAL = 2,
}

export interface CommonClientFieldsInterface {
    "id": string,
    "type_id": ClintTypesEnum,
    "is_requisite_exists": boolean,
    "is_contact_exists": boolean,
    "is_objects_in_process": boolean,
    "km_company_name": string,
    "created_at": string,
    "updated_at": string,
    "name": string,
    "identity": string,
    "comment": string,
}

export interface LegalClientItemResponseInterface extends CommonClientFieldsInterface{
    legal_client: {
        "opf_type_id": number,
        "site": string,
    }
};


export interface PhysicalClientItemResponse extends CommonClientFieldsInterface {
    physical_client: {
        "registration_address": string,
        "issue_date": string,
        "issuer": string,
        "dep_code": string,
        "birthdate": string,
        "birthplace": string
    },
}


export interface ClientsResponse {
    count_all: number,
    limit: number,
    ptoken: string,
    data: Array<LegalClientItemResponseInterface | PhysicalClientItemResponse>
}


export interface DealsResponse {
    count_all: number,
    limit: number,
    ptoken: string,
    data: Array<DealInterface>
}

export interface DealFullInterface {
    installments_type_id: OptionInterface;
    id: string;
    status_id: number;
    quotation_id: string;
    date: Date;
    number: number;
    km_id: string;
    ssk_id: string;
    pokazatel_stat: string;
    client_id: string;
    insurance_object_id: string;
    ss: number;
    sp: number;
    kv_v_perc: string;
    kv_v_rub: number;
    contract_date: Date;
    contract_number: string;
    start_date: Date;
    end_date: Date;
    contract_file_id: string;
    account_file_id: string;
    created_at: Date;
    updated_at: Date;
}

export interface DealInterface {
    id: string;
    status_id: number;
    quotation_id: string;
    date: Date;
    number: number;
    client_name: string;
    type_id: number;
    client_opf_type_id: number;
    insurance_company_name: string;
    insurance_object_type_id: number;
    ss: number;
    sp: number;
    km_name: string;
    contract_date: Date;
    end_date: Date;
    pay_date: Date;
    contract_number: string;
    contract_file_id: string;
    account_file_id: string;
    created_at: Date;
    updated_at: Date;
    kv_v_rub: number;
    object_address: string;
    ssk_name: string;
    bank_name: string;
    deputy_str: string;
}

export interface CompanyInterface {
    id: string;
    ras_account: string;
    bik: string;
    korr_account: string;
    bank_name: string;
    name: string;
    opf_type_id: number;
    legal_address: string;
    post_address: string;
    ogrn: string;
    okpo: string;
    okato: string;
    inn: string;
    kpp: string;
    phone: string;
    site: string;
    type_id: number;
    created_at: string;
    updated_at: string;
}


export interface UserInterface {
    id: string;
    email: string;
    name: string;
    role_id: number;
    updated_at: string;
    created_at: string;
}

export interface KMInterface {
    user: UserInterface;
    company: CompanyInterface;
    created_at: string;
    updated_at: string;
}

export interface SSKInterface {
    user: UserInterface;
    company: CompanyInterface;
    created_at: string;
    updated_at: string;
}


export interface MessageResponse {
    "id": string,
    "action_id": number,
    "text": string,
    "chat_id": string,
    "sender_id": string,
    "created_at": string
}


export interface MessagesListResponse {
    "total": number,
    "has_next": false,
    "has_prev": false,
    result: Array<MessageResponse>
}

export interface QuoteInterface {
    "quotation_id": string,
    "quotation_offer_id": string,
    "chat_id": string,
    "quotation_status_id": number,
    "quotation_offer_status_id": null | number,
    "insurance_object_id": string,
    "insurance_company_name": string,
    "tarif": number,
    "insurance_award": number,
    "account_needs": string,
    "comment": string,
    "date": string,
    "created_at": string,
    "updated_at": string,
    is_applied: boolean,
    is_rejected: boolean,
}

export interface QuotesResponse {
    count_all: number,
    limit: number,
    ptoken: string,
    data: Array<QuoteInterface>
}





export function isClientLegalInterface(arg: LegalClientItemResponseInterface | PhysicalClientItemResponse): boolean {
    return arg && arg.hasOwnProperty("legal_client");
}

export function isClientPhysicalInterface(arg: LegalClientItemResponseInterface | PhysicalClientItemResponse): boolean {
    return arg && arg.hasOwnProperty("physical_client");
}

export enum RequestStatusEnum {
    Idle,
    Loading,
    Succeeded,
    Failed
}

export const OpfTypeName: { [key: number]: string } = {
    "10000": "Организационно-правовые формы юридических лиц, являющихся коммерческими корпоративными организациями",
    "11000": "Хозяйственные товарищества",
    "11051": "Полные товарищества",
    "11064": "Товарищества на вере (коммандитные товарищества)",
    "12000": "Хозяйственные общества",
    "12200": "Акционерные общества",
    "12247": "Публичные акционерные общества",
    "12267": "Непубличные акционерные общества",
    "12300": "Общества с ограниченной ответственностью",
    "13000": "Хозяйственные партнерства",
    "14000": "Производственные кооперативы (артели)",
    "14100": "Сельскохозяйственные производственные кооперативы",
    "14153": "Сельскохозяйственные артели (колхозы)",
    "14154": "Рыболовецкие артели (колхозы)",
    "14155": "Кооперативные хозяйства (коопхозы)",
    "14200": "Производственные кооперативы (кроме сельскохозяйственных производственных кооперативов)",
    "15300": "Крестьянские (фермерские) хозяйства",
    "19000": "Прочие юридические лица, являющиеся коммерческими организациями",
    "20000": "Организационно-правовые формы юридических лиц, являющихся некоммерческими корпоративными организациями",
    "20100": "Потребительские кооперативы",
    "20101": "Гаражные и гаражно-строительные кооперативы",
    "20102": "Жилищные или жилищно-строительные кооперативы",
    "20103": "Жилищные накопительные кооперативы",
    "20104": "Кредитные потребительские кооперативы",
    "20105": "Кредитные потребительские кооперативы граждан",
    "20106": "Кредитные кооперативы второго уровня",
    "20107": "Потребительские общества",
    "20108": "Общества взаимного страхования",
    "20109": "Сельскохозяйственные потребительские перерабатывающие кооперативы",
    "20110": "Сельскохозяйственные потребительские сбытовые (торговые) кооперативы",
    "20111": "Сельскохозяйственные потребительские обслуживающие кооперативы",
    "20112": "Сельскохозяйственные потребительские снабженческие кооперативы",
    "20113": "Сельскохозяйственные потребительские садоводческие кооперативы",
    "20114": "Сельскохозяйственные потребительские огороднические кооперативы",
    "20115": "Сельскохозяйственные потребительские животноводческие кооперативы",
    "20120": "Садоводческие, огороднические или дачные потребительские кооперативы",
    "20121": "Фонды проката",
    "20200": "Общественные организации",
    "20201": "Политические партии",
    "20202": "Профсоюзные организации",
    "20210": "Общественные движения",
    "20211": "Органы общественной самодеятельности",
    "20217": "Территориальные общественные самоуправления",
    "20600": "Ассоциации (союзы)",
    "20601": "Ассоциации (союзы) экономического взаимодействия субъектов Российской Федерации",
    "20603": "Советы муниципальных образований субъектов Российской Федерации",
    "20604": "Союзы (ассоциации) кредитных кооперативов",
    "20605": "Союзы (ассоциации) кооперативов",
    "20606": "Союзы (ассоциации) общественных объединений",
    "20607": "Союзы (ассоциации) общин малочисленных народов",
    "20608": "Союзы потребительских обществ",
    "20609": "Адвокатские палаты",
    "20610": "Нотариальные палаты",
    "20611": "Торгово-промышленные палаты",
    "20612": "Объединения работодателей",
    "20613": "Объединения фермерских хозяйств",
    "20614": "Некоммерческие партнерства",
    "20615": "Адвокатские бюро",
    "20616": "Коллегии адвокатов",
    "20617": "Садоводческие, огороднические или дачные некоммерческие партнерства",
    "20618": "Ассоциации (союзы) садоводческих, огороднических и дачных некоммерческих объединений",
    "20619": "Саморегулируемые организации",
    "20620": "Объединения (ассоциации и союзы) благотворительных организаций",
    "20700": "Товарищества собственников недвижимости",
    "20701": "Садоводческие, огороднические или дачные некоммерческие товарищества",
    "20716": "Товарищества собственников жилья",
    "30000": "Организационно-правовые формы организаций, созданных без прав юридического лица",
    "30001": "Представительства юридических лиц",
    "30002": "Филиалы юридических лиц",
    "30003": "Обособленные подразделения юридических лиц",
    "30004": "Структурные подразделения обособленных подразделений юридических лиц",
    "30005": "Паевые инвестиционные фонды",
    "30006": "Простые товарищества",
    "30008": "Районные суды, городские суды, межрайонные суды (районные суды)",
    "40000": "Организационно-правовые формы международных организаций, осуществляющих деятельность на территории российской федерации",
    "40001": "Межправительственные международные организации",
    "40002": "Неправительственные международные организации",
    "50000": "Организационно-правовые формы для деятельности граждан (физических лиц)",
    "50100": "Организационно-правовые формы для коммерческой деятельности граждан",
    "50101": "Главы крестьянских (фермерских) хозяйств",
    "50102": "Индивидуальные предприниматели",
    "50200": "Организационно-правовые формы для деятельности граждан, не отнесенной к предпринимательству",
    "50201": "Адвокаты, учредившие адвокатский кабинет",
    "50202": "Нотариусы, занимающиеся частной практикой",
    "60000": "Организационно-правовые формы юридических лиц, являющихся коммерческими унитарными организациями",
    "65000": "Унитарные предприятия",
    "65100": "Унитарные предприятия, основанные на праве оперативного управления (казенные предприятия)",
    "65141": "Федеральные казенные предприятия",
    "65142": "Казенные предприятия субъектов Российской Федерации",
    "65143": "Муниципальные казенные предприятия",
    "65200": "Унитарные предприятия, основанные на праве хозяйственного ведения",
    "65241": "Федеральные государственные унитарные предприятия",
    "65242": "Государственные унитарные предприятия субъектов Российской Федерации",
    "65243": "Муниципальные унитарные предприятия",
    "70000": "Организационно-правовые формы юридических лиц, являющихся некоммерческими унитарными организациями",
    "70400": "Фонды",
    "70401": "Благотворительные фонды",
    "70402": "Негосударственные пенсионные фонды",
    "70403": "Общественные фонды",
    "70404": "Экологические фонды",
    "71400": "Автономные некоммерческие организации",
    "71500": "Религиозные организации",
    "71600": "Публично-правовые компании",
    "71601": "Государственные корпорации",
    "71602": "Государственные компании",
    "71610": "Отделения иностранных некоммерческих неправительственных организаций",
    "75000": "Учреждения",
    "75100": "Учреждения, созданные Российской Федерацией",
    "75101": "Федеральные государственные автономные учреждения",
    "75103": "Федеральные государственные бюджетные учреждения",
    "75104": "Федеральные государственные казенные учреждения",
    "75200": "Учреждения, созданные субъектом Российской Федерации",
    "75201": "Государственные автономные учреждения субъектов Российской Федерации",
    "75203": "Государственные бюджетные учреждения субъектов Российской Федерации",
    "75204": "Государственные казенные учреждения субъектов Российской Федерации",
    "75300": "Государственные академии наук",
    "75400": "Учреждения, созданные муниципальным образованием (муниципальные учреждения)",
    "75401": "Муниципальные автономные учреждения",
    "75403": "Муниципальные бюджетные учреждения",
    "75404": "Муниципальные казенные учреждения",
    "75500": "Частные учреждения",
    "75502": "Благотворительные учреждения",
    "75505": "Общественные учреждения",
};

export interface OptionInterface {
    value: any,
    label: string,
}

export const operatorsOptions: Array<OptionInterface> = [
    {
        "value": "gt",
        "label": ">"
    },
    {
        "value": "lt",
        "label": "<"
    },
    {
        "value": "gte",
        "label": ">="
    },
    {
        "value": "lte",
        "label": "<="
    },
    {
        "value": "eq",
        "label": "="
    },
]

export const OpfTypeNameOptions: Array<OptionInterface> = [
    {
        "value": 10000,
        "label": "Организационно-правовые формы юридических лиц, являющихся коммерческими корпоративными организациями"
    },
    {
        "value": 11000,
        "label": "Хозяйственные товарищества"
    },
    {
        "value": 11051,
        "label": "Полные товарищества"
    },
    {
        "value": 11064,
        "label": "Товарищества на вере (коммандитные товарищества)"
    },
    {
        "value": 12000,
        "label": "Хозяйственные общества"
    },
    {
        "value": 12200,
        "label": "Акционерные общества"
    },
    {
        "value": 12247,
        "label": "Публичные акционерные общества"
    },
    {
        "value": 12267,
        "label": "Непубличные акционерные общества"
    },
    {
        "value": 12300,
        "label": "Общества с ограниченной ответственностью"
    },
    {
        "value": 13000,
        "label": "Хозяйственные партнерства"
    },
    {
        "value": 14000,
        "label": "Производственные кооперативы (артели)"
    },
    {
        "value": 14100,
        "label": "Сельскохозяйственные производственные кооперативы"
    },
    {
        "value": 14153,
        "label": "Сельскохозяйственные артели (колхозы)"
    },
    {
        "value": 14154,
        "label": "Рыболовецкие артели (колхозы)"
    },
    {
        "value": 14155,
        "label": "Кооперативные хозяйства (коопхозы)"
    },
    {
        "value": 14200,
        "label": "Производственные кооперативы (кроме сельскохозяйственных производственных кооперативов)"
    },
    {
        "value": 15300,
        "label": "Крестьянские (фермерские) хозяйства"
    },
    {
        "value": 19000,
        "label": "Прочие юридические лица, являющиеся коммерческими организациями"
    },
    {
        "value": 20000,
        "label": "Организационно-правовые формы юридических лиц, являющихся некоммерческими корпоративными организациями"
    },
    {
        "value": 20100,
        "label": "Потребительские кооперативы"
    },
    {
        "value": 20101,
        "label": "Гаражные и гаражно-строительные кооперативы"
    },
    {
        "value": 20102,
        "label": "Жилищные или жилищно-строительные кооперативы"
    },
    {
        "value": 20103,
        "label": "Жилищные накопительные кооперативы"
    },
    {
        "value": 20104,
        "label": "Кредитные потребительские кооперативы"
    },
    {
        "value": 20105,
        "label": "Кредитные потребительские кооперативы граждан"
    },
    {
        "value": 20106,
        "label": "Кредитные кооперативы второго уровня"
    },
    {
        "value": 20107,
        "label": "Потребительские общества"
    },
    {
        "value": 20108,
        "label": "Общества взаимного страхования"
    },
    {
        "value": 20109,
        "label": "Сельскохозяйственные потребительские перерабатывающие кооперативы"
    },
    {
        "value": 20110,
        "label": "Сельскохозяйственные потребительские сбытовые (торговые) кооперативы"
    },
    {
        "value": 20111,
        "label": "Сельскохозяйственные потребительские обслуживающие кооперативы"
    },
    {
        "value": 20112,
        "label": "Сельскохозяйственные потребительские снабженческие кооперативы"
    },
    {
        "value": 20113,
        "label": "Сельскохозяйственные потребительские садоводческие кооперативы"
    },
    {
        "value": 20114,
        "label": "Сельскохозяйственные потребительские огороднические кооперативы"
    },
    {
        "value": 20115,
        "label": "Сельскохозяйственные потребительские животноводческие кооперативы"
    },
    {
        "value": 20120,
        "label": "Садоводческие, огороднические или дачные потребительские кооперативы"
    },
    {
        "value": 20121,
        "label": "Фонды проката"
    },
    {
        "value": 20200,
        "label": "Общественные организации"
    },
    {
        "value": 20201,
        "label": "Политические партии"
    },
    {
        "value": 20202,
        "label": "Профсоюзные организации"
    },
    {
        "value": 20210,
        "label": "Общественные движения"
    },
    {
        "value": 20211,
        "label": "Органы общественной самодеятельности"
    },
    {
        "value": 20217,
        "label": "Территориальные общественные самоуправления"
    },
    {
        "value": 20600,
        "label": "Ассоциации (союзы)"
    },
    {
        "value": 20601,
        "label": "Ассоциации (союзы) экономического взаимодействия субъектов Российской Федерации"
    },
    {
        "value": 20603,
        "label": "Советы муниципальных образований субъектов Российской Федерации"
    },
    {
        "value": 20604,
        "label": "Союзы (ассоциации) кредитных кооперативов"
    },
    {
        "value": 20605,
        "label": "Союзы (ассоциации) кооперативов"
    },
    {
        "value": 20606,
        "label": "Союзы (ассоциации) общественных объединений"
    },
    {
        "value": 20607,
        "label": "Союзы (ассоциации) общин малочисленных народов"
    },
    {
        "value": 20608,
        "label": "Союзы потребительских обществ"
    },
    {
        "value": 20609,
        "label": "Адвокатские палаты"
    },
    {
        "value": 20610,
        "label": "Нотариальные палаты"
    },
    {
        "value": 20611,
        "label": "Торгово-промышленные палаты"
    },
    {
        "value": 20612,
        "label": "Объединения работодателей"
    },
    {
        "value": 20613,
        "label": "Объединения фермерских хозяйств"
    },
    {
        "value": 20614,
        "label": "Некоммерческие партнерства"
    },
    {
        "value": 20615,
        "label": "Адвокатские бюро"
    },
    {
        "value": 20616,
        "label": "Коллегии адвокатов"
    },
    {
        "value": 20617,
        "label": "Садоводческие, огороднические или дачные некоммерческие партнерства"
    },
    {
        "value": 20618,
        "label": "Ассоциации (союзы) садоводческих, огороднических и дачных некоммерческих объединений"
    },
    {
        "value": 20619,
        "label": "Саморегулируемые организации"
    },
    {
        "value": 20620,
        "label": "Объединения (ассоциации и союзы) благотворительных организаций"
    },
    {
        "value": 20700,
        "label": "Товарищества собственников недвижимости"
    },
    {
        "value": 20701,
        "label": "Садоводческие, огороднические или дачные некоммерческие товарищества"
    },
    {
        "value": 20716,
        "label": "Товарищества собственников жилья"
    },
    {
        "value": 30000,
        "label": "Организационно-правовые формы организаций, созданных без прав юридического лица"
    },
    {
        "value": 30001,
        "label": "Представительства юридических лиц"
    },
    {
        "value": 30002,
        "label": "Филиалы юридических лиц"
    },
    {
        "value": 30003,
        "label": "Обособленные подразделения юридических лиц"
    },
    {
        "value": 30004,
        "label": "Структурные подразделения обособленных подразделений юридических лиц"
    },
    {
        "value": 30005,
        "label": "Паевые инвестиционные фонды"
    },
    {
        "value": 30006,
        "label": "Простые товарищества"
    },
    {
        "value": 30008,
        "label": "Районные суды, городские суды, межрайонные суды (районные суды)"
    },
    {
        "value": 40000,
        "label": "Организационно-правовые формы международных организаций, осуществляющих деятельность на территории российской федерации"
    },
    {
        "value": 40001,
        "label": "Межправительственные международные организации"
    },
    {
        "value": 40002,
        "label": "Неправительственные международные организации"
    },
    {
        "value": 50000,
        "label": "Организационно-правовые формы для деятельности граждан (физических лиц)"
    },
    {
        "value": 50100,
        "label": "Организационно-правовые формы для коммерческой деятельности граждан"
    },
    {
        "value": 50101,
        "label": "Главы крестьянских (фермерских) хозяйств"
    },
    {
        "value": 50102,
        "label": "Индивидуальные предприниматели"
    },
    {
        "value": 50200,
        "label": "Организационно-правовые формы для деятельности граждан, не отнесенной к предпринимательству"
    },
    {
        "value": 50201,
        "label": "Адвокаты, учредившие адвокатский кабинет"
    },
    {
        "value": 50202,
        "label": "Нотариусы, занимающиеся частной практикой"
    },
    {
        "value": 60000,
        "label": "Организационно-правовые формы юридических лиц, являющихся коммерческими унитарными организациями"
    },
    {
        "value": 65000,
        "label": "Унитарные предприятия"
    },
    {
        "value": 65100,
        "label": "Унитарные предприятия, основанные на праве оперативного управления (казенные предприятия)"
    },
    {
        "value": 65141,
        "label": "Федеральные казенные предприятия"
    },
    {
        "value": 65142,
        "label": "Казенные предприятия субъектов Российской Федерации"
    },
    {
        "value": 65143,
        "label": "Муниципальные казенные предприятия"
    },
    {
        "value": 65200,
        "label": "Унитарные предприятия, основанные на праве хозяйственного ведения"
    },
    {
        "value": 65241,
        "label": "Федеральные государственные унитарные предприятия"
    },
    {
        "value": 65242,
        "label": "Государственные унитарные предприятия субъектов Российской Федерации"
    },
    {
        "value": 65243,
        "label": "Муниципальные унитарные предприятия"
    },
    {
        "value": 70000,
        "label": "Организационно-правовые формы юридических лиц, являющихся некоммерческими унитарными организациями"
    },
    {
        "value": 70400,
        "label": "Фонды"
    },
    {
        "value": 70401,
        "label": "Благотворительные фонды"
    },
    {
        "value": 70402,
        "label": "Негосударственные пенсионные фонды"
    },
    {
        "value": 70403,
        "label": "Общественные фонды"
    },
    {
        "value": 70404,
        "label": "Экологические фонды"
    },
    {
        "value": 71400,
        "label": "Автономные некоммерческие организации"
    },
    {
        "value": 71500,
        "label": "Религиозные организации"
    },
    {
        "value": 71600,
        "label": "Публично-правовые компании"
    },
    {
        "value": 71601,
        "label": "Государственные корпорации"
    },
    {
        "value": 71602,
        "label": "Государственные компании"
    },
    {
        "value": 71610,
        "label": "Отделения иностранных некоммерческих неправительственных организаций"
    },
    {
        "value": 75000,
        "label": "Учреждения"
    },
    {
        "value": 75100,
        "label": "Учреждения, созданные Российской Федерацией"
    },
    {
        "value": 75101,
        "label": "Федеральные государственные автономные учреждения"
    },
    {
        "value": 75103,
        "label": "Федеральные государственные бюджетные учреждения"
    },
    {
        "value": 75104,
        "label": "Федеральные государственные казенные учреждения"
    },
    {
        "value": 75200,
        "label": "Учреждения, созданные субъектом Российской Федерации"
    },
    {
        "value": 75201,
        "label": "Государственные автономные учреждения субъектов Российской Федерации"
    },
    {
        "value": 75203,
        "label": "Государственные бюджетные учреждения субъектов Российской Федерации"
    },
    {
        "value": 75204,
        "label": "Государственные казенные учреждения субъектов Российской Федерации"
    },
    {
        "value": 75300,
        "label": "Государственные академии наук"
    },
    {
        "value": 75400,
        "label": "Учреждения, созданные муниципальным образованием (муниципальные учреждения)"
    },
    {
        "value": 75401,
        "label": "Муниципальные автономные учреждения"
    },
    {
        "value": 75403,
        "label": "Муниципальные бюджетные учреждения"
    },
    {
        "value": 75404,
        "label": "Муниципальные казенные учреждения"
    },
    {
        "value": 75500,
        "label": "Частные учреждения"
    },
    {
        "value": 75502,
        "label": "Благотворительные учреждения"
    },
    {
        "value": 75505,
        "label": "Общественные учреждения"
    },
]

export enum InsuranceObjectTypesEnum {
    APARTMENT      = 1,
    HOUSE          = 2,
    VEHICLE        = 3,
    COMMERCIAL_TMC = 4,
    OTHER_TRANSPORT = 5,
}

export const InsuranceObjectTypeNameOptions: Array<OptionInterface> = [
    { value: InsuranceObjectTypesEnum.APARTMENT, label: "Квартира"},
    { value: InsuranceObjectTypesEnum.HOUSE, label: "Дом"},
    { value: InsuranceObjectTypesEnum.VEHICLE, label: "Автотранспортные средства"},
    { value: InsuranceObjectTypesEnum.OTHER_TRANSPORT, label: "Прочие транспортные средства"},
    { value: InsuranceObjectTypesEnum.COMMERCIAL_TMC, label: "Недвижимость, Оборудование, ТМЦ"},
]


export const InsuranceObjectWallMaterialsNameOptions: Array<OptionInterface> = [
    { value: "Кирпич", label: "Кирпич"},
    { value: "Монолит", label: "Монолит"},
    { value: "Блочный", label: "Блочный"},
    { value: "Панельный", label: "Панельный"},
    { value: "Деревянный", label: "Деревянный"},
    { value: "Иное", label: "Иное"},
]

export const InsuranceObjectBasementTypeNameOptions: Array<OptionInterface> = [
    { value: "Ленточный", label: "Ленточный"},
    { value: "Столбы", label: "Столбы"},
    { value: "Ж/бетон", label: "Ж/бетон"},
    { value: "Свайный", label: "Свайный"},
    { value: "Кирпич", label: "Кирпич"},
    { value: "Иное", label: "Иное"},
]

export const InsuranceObjectResidenceTypeNameOptions: Array<OptionInterface> = [
    { value: "Постоянный", label: "Постоянный"},
    { value: "Временный", label: "Временный"}
]

export const InsuranceObjectPropertyTypeNameOptions: Array<OptionInterface> = [
    { value: "В собственности", label: "В собственности"},
    { value: "Не в собственности", label: "Не в собственности"}
]

export const InsuranceObjectFireResistanceSystemNameOptions: Array<OptionInterface> = [
    { value: "Нет", label: "Нет"},
    { value: "Огнетушитель", label: "Огнетушитель"},
    { value: "Пожарная сигнализация", label: "Пожарная сигнализация"},
    { value: "Система автоматического пожаротушения", label: "Система автоматического пожаротушения"}
]


export enum InsuranceObjectFinanceTypesEnum {
    CREDIT      = 1,
    GUARANTEE         = 2,
    LETTER_OF_CREDIT        = 3,
    MORTGAGE = 4,
    CAR_LOAN = 5,
    LEASE = 6,
}


export const InsuranceObjectFinanceTypeNameOptions: Array<OptionInterface> = [
    { value: InsuranceObjectFinanceTypesEnum.CREDIT, label: "Кредит"},
    { value: InsuranceObjectFinanceTypesEnum.GUARANTEE, label: "Гарантия"},
    { value: InsuranceObjectFinanceTypesEnum.LETTER_OF_CREDIT, label: "Аккредитив"},
    { value: InsuranceObjectFinanceTypesEnum.MORTGAGE, label: "Ипотека"},
    { value: InsuranceObjectFinanceTypesEnum.CAR_LOAN, label: "Автокредит"},
    { value: InsuranceObjectFinanceTypesEnum.LEASE, label: "Лизинг"},
]




export enum QuotationStatusesEnum {
    NEW = 1,
    OPEN = 2,
    APPLY = 3,
    DEAL_OFFERED = 4,
    GOT_BILL = 5,
    SUCCESS = 6,
    REJECTED = 999,
}

export const QuotationStatusesNameOptions: Array<OptionInterface> = [
    { value: QuotationStatusesEnum.NEW, label: "Новая"},
    { value: QuotationStatusesEnum.OPEN, label: "Открыта"},
    { value: QuotationStatusesEnum.APPLY, label: "Применили"},
    { value: QuotationStatusesEnum.DEAL_OFFERED, label: "Предложена сделка"},
    { value: QuotationStatusesEnum.GOT_BILL, label: "Получен счет на оплату"},
    { value: QuotationStatusesEnum.SUCCESS, label: "Успех"},
    { value: QuotationStatusesEnum.REJECTED, label: "Отклонили"},
]

export enum DealStatusesEnum {
    NEW = 1,
    OPEN = 2,
    DONE = 3,
}

export const quotesStatusOptions: ColourOption[] = [
    { value: '1', label: 'Новая', color: '#3DD598',},
    { value: '2', label: 'Открыта', color: '#109CF1',},
    { value: '3', label: 'Применили', color: '#8F8FD4',},
    { value: '999', label: 'Отклонили', color: '#707683',},
    { value: '4', label: 'Предложена сделка', color: '#FF974A',},
    { value: '5', label: 'Получен счет на оплату', color: '#323C47',},
    { value: '6', label: 'Успех', color: '#ec6be2',},
];

export const clientStatusOptions: ColourOption[] = [
    { value: 'true', label: 'В работе', color: '#FF974A',},
    { value: 'false', label: 'Не в работе', color: '#323C47',},
];



export interface LoginInterface {
    email: string,
    password: string,
}

export interface SignInResponseInterface {
    access_token: string,
    refresh_token: string,
}


export interface SortOptionInterface {
    value: {
        name: string,
        order: "desc" | "asc"
    };
    label: string;
}

export const ClientSortFieldToName = {
    is_objects_in_process: "Статус",
    name: "Фио/Наим",
    identity: "Инн/Пас.",
    opf_type_id: "Тип",
    comment: "Комм.",
    created_at: "Дата",
}


export const clientSortOptions: SortOptionInterface[] = [
    { value: { name: 'is_objects_in_process', order: "asc" }, label: 'Статус: c начала не в работе' },
    { value: { name: 'is_objects_in_process', order: "desc" }, label: 'Статус: c начала в работе' },
    { value: { name: 'name', order: "asc" }, label: 'Фио/Наименование: А-Я' },
    { value: { name: 'name', order: "desc" }, label: 'Фио/Наименование Я-А' },
    { value: { name: 'identity', order: "asc" }, label: 'ИНН/Паспорт А-Я' },
    { value: { name: 'identity', order: "desc" }, label: 'ИНН/Паспорт Я-А' },
    { value: { name: 'opf_type_id', order: "asc" }, label: 'Тип А-Я' },
    { value: { name: 'opf_type_id', order: "desc" }, label: 'Тип Я-А' },
    { value: { name: 'comment', order: "asc" }, label: 'Комментарий А-Я' },
    { value: { name: 'comment', order: "desc" }, label: 'Комментарий Я-А' },
    { value: { name: 'created_at', order: "asc" }, label: 'Дата: c начала новые' },
    { value: { name: 'created_at', order: "desc" }, label: 'Дата: c начала старые' },
];


export const dealSortOptions: SortOptionInterface[] = [


    { value: { name: 'date', order: "asc" }, label: 'Дата: c начала новые' },
    { value: { name: 'date', order: "desc" }, label: 'Дата: c начала старые' },

    { value: { name: 'number', order: "asc" }, label: 'Номер: А-Я' },
    { value: { name: 'number', order: "desc" }, label: 'Номер: Я-А' },

    { value: { name: 'client_opf_type_id', order: "asc" }, label: 'ОПФ: А-Я' },
    { value: { name: 'client_opf_type_id', order: "desc" }, label: 'ОПФ: Я-А' },

    { value: { name: 'insurance_company_name', order: "asc" }, label: 'Страховая: А-Я' },
    { value: { name: 'insurance_company_name', order: "desc" }, label: 'Страховая: Я-А' },

    { value: { name: 'insurance_object_type_id', order: "asc" }, label: 'Риск: А-Я' },
    { value: { name: 'insurance_object_type_id', order: "desc" }, label: 'Риск Я-А' },

    { value: { name: 'client_name', order: "asc" }, label: 'Клиент А-Я' },
    { value: { name: 'client_name', order: "desc" }, label: 'Клиент Я-А' },

    { value: { name: 'ss', order: "asc" }, label: 'СС А-Я' },
    { value: { name: 'ss', order: "desc" }, label: 'СС Я-А' },

    { value: { name: 'sp', order: "asc" }, label: 'СП А-Я' },
    { value: { name: 'sp', order: "desc" }, label: 'СП Я-А' },

    { value: { name: 'km_name', order: "asc" }, label: 'Продавец А-Я' },
    { value: { name: 'km_name', order: "desc" }, label: 'Продавец Я-А' },

    { value: { name: 'end_date', order: "asc" }, label: 'Дата окончания: c начала новые' },
    { value: { name: 'end_date', order: "desc" }, label: 'Дата окончания: c начала старые' },

    { value: { name: 'pay_date', order: "asc" }, label: 'Дата оплаты: c начала новые' },
    { value: { name: 'pay_date', order: "desc" }, label: 'Дата оплаты: c начала старые' },

    { value: { name: 'created_at', order: "asc" }, label: 'Дата: c начала новые' },
    { value: { name: 'created_at', order: "desc" }, label: 'Дата: c начала старые' },
];


export const QuotationSortFieldToName = {
    quotation_status_id: "Статус",
    insurance_company_name: "Комп.",
    tarif: "Тариф",
    insurance_award: "Прем.",
    comment: "Комм.",
    created_at: "Дата",
}


export const quotationSortOptions: SortOptionInterface[] = [
    { value: { name: 'quotation_status_id', order: "asc" }, label: 'Статус: от новой к успеху' },
    { value: { name: 'quotation_status_id', order: "desc" }, label: 'Статус: от успеха к новой' },
    { value: { name: 'insurance_company_name', order: "asc" }, label: 'Наим. Компании: А-Я' },
    { value: { name: 'insurance_company_name', order: "desc" }, label: 'Наим. Компании: Я-А' },
    { value: { name: 'tarif', order: "asc" }, label: 'Тариф: от большего к меньшему' },
    { value: { name: 'tarif', order: "desc" }, label: 'Тариф: от меньшего к большему' },
    { value: { name: 'insurance_award', order: "asc" }, label: 'Премия: от большего к меньшему' },
    { value: { name: 'insurance_award', order: "desc" }, label: 'Премия: от меньшего к большему' },
    { value: { name: 'comment', order: "asc" }, label: 'Комментарий Я-А' },
    { value: { name: 'comment', order: "desc" }, label: 'Комментарий А-Я' },
    { value: { name: 'created_at', order: "asc" }, label: 'Дата: c начала новые' },
    { value: { name: 'created_at', order: "desc" }, label: 'Дата: c начала старые' },
];

export const DealInstallmentsTypeNameOptions: Array<OptionInterface> = [
    { value: 1, label: "1 платеж"},
    { value: 2, label: "2 платежа"},
    { value: 3, label: "3 платежа"},
    { value: 4, label: "4 платежа"},
]

export const PokazatelStatistikiNameOptions: Array<OptionInterface> = [
    { value: 1, label: "юридических лиц (прочее)" },
    { value: 2, label: "пенсионное страхование" },
    { value: 3, label: "всего от несчастных случаев и болезней" },
    { value: 4, label: "пациентов, участвующих в клинических исследованиях лекарственного препарата для медицинского применения	из него	" },
    { value: 5, label: "работников налоговых органов		" },
    { value: 6, label: "медицинское страхование" },
    { value: 7, label: "средств наземного транспорта (кроме средств железнодорожного транспорта)" },
    { value: 8, label: "средств железнодорожного транспорта" },
    { value: 9, label: "средств воздушного транспорта" },
    { value: 10, label: "средств водного транспорта " },
    { value: 11, label: "грузов" },
    { value: 12, label: "граждан (прочее)" },
    { value: 13, label: "владельцев авто- транспорт- ных средств" },
    { value: 14, label: "из него страхование, осуществляемое в рамках международной системы страхования «Зеленая карта»	" },
    { value: 15, label: "владельцев средств железнодорожного транспорта" },
    { value: 16, label: "владельцев средств воздушного транспорта" },
    { value: 17, label: "владельцев средств водного транспорта" },
    { value: 18, label: "организаций, эксплуатирующих опасные объекты" },
    { value: 19, label: "за причинение вреда вследствие недостатков товаров, работ, услуг" },
    { value: 20, label: "за причинение вреда третьим лицам" },
    { value: 21, label: "за неисполнение или ненадлежащее исполнение обязательств по договору" },
    { value: 22, label: "страхование предпринимательских рисков" },
    { value: 23, label: "страхование финансовых рисков" },
    { value: 24, label: "государственное страхование жизни и здоровья военнослужащих и приравненных к ним в обязательном государственном страховании лиц	личное страхование" },
    { value: 25, label: "иные виды обязательного личного страхования, предусмотренные федеральными законами" },
    { value: 26, label: "владельцев транспортных средств	имущественное страхование" },
    { value: 27, label: "владельца опасного объекта за причинение вреда в результате аварии на опасном объекте" },
    { value: 28, label: "перевозчика за причинение вреда жизни, здоровью, имуществу пассажиров	" },
    { value: 29, label: "иные виды обязательного имущественного страхования, предусмотренные федеральными законами" },
]

export interface DaDataCarResponse {
    suggestions: Array<DaDataCarData>
}

export interface DaDataCarData {
    value: string;
    unrestricted_value: string;
}

export interface DaDataAddressResponse {
    suggestions: Array<DaDataAddressData>
}

export interface DaDataAddressData {
    value: string;
    unrestricted_value: string;
}

export interface DaDataResponse {
    suggestions: Array<DaDataData>
}

export interface DaDataData {
    value: string;
    data: DaDataUL;
}

export interface DaDataUL {
    kpp: string;
    opf: DaDataULOPF;
    inn: string;
    ogrn: string;
    okpo: string;
    okato: string;
    oktmo: string;
    okogu: string;
    okfs: string;
    address: DaDataULAddress;
    name: DaDataULName;
}

export interface DaDataULName {
    full_with_opf: string;
    short_with_opf: string;
    latin: string;
    full: string;
    short: string;
}

export interface DaDataULOPF {
    type: string;
    code: string;
    full: string;
    short: string;
}

export interface DaDataULAddress {
    value: string;
}

export interface ChooseFormData {
    inn?: string;
    ogrn?: string;
    passport_series?: string;
    passport_number?: string;
}
