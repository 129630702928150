import React, {FormEvent, memo, useCallback, useEffect, useRef, useState} from 'react';
import classes from "./Chat.module.scss";
import SplitPaneWrapper from "../SplitPaneWrapper/SplitPaneWrapper";
import Message from "./Message/Message";
import {createMessage} from "../../api/chatApi";
import {useAppSelector} from "../../store/hooks";
import {fetchMessages, fetchMessagesPolling, limit, clearChatData} from "../../store/features/chat";
import {useDispatch} from "react-redux";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import SendIcon from '@mui/icons-material/Send';
import {AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List, ScrollParams} from "react-virtualized";
import {RequestStatusEnum, RoleIdsEnum} from "../../common/commonTypes";
import usePrevious from "../../common/hooks/usePrevious";
import CircularProgress from "@mui/material/CircularProgress";
import {useInterval} from "../../hooks/useInterval";
import cn from "classnames"
import _ from "lodash";

interface ChatProps {
    height: number
}


export default function Chat(props: ChatProps) {

    const chatId = useAppSelector((state) => state.quotesTable.chatId)
    const {data, total, status, has_next, lastLoadedCount} = useAppSelector((state) => state.chat)
    const userId = useAppSelector((state) => state.user.id)
    const userEmail = useAppSelector((state) => state.user.email)
    const userName = useAppSelector((state) => state.user.name)
    const userRoleID = useAppSelector((state) => state.user.role_id)
    const [scrollToIndex, setScrollToIndex] = useState({ index: 0 });


    const dispatch = useDispatch()

    useInterval(async () => {
        if(!chatId) { return  }
        const action = await dispatch(fetchMessagesPolling({chatId: chatId, force: true, successCallBack: (result, fullData) => {}}));
        // @ts-ignore
        //setScrollToIndex(res.payload.data.data.result.length - 1)

        // @ts-ignore
        const t1 = action.payload.prevData.slice(action.payload.prevData.length - limit, action.payload.prevData.length).map( t => t.id);
        // @ts-ignore
        const t2 = action.payload.data.data.result.map( t => t.id);

        if(t1.length === t2.length && _.difference(t1, t2).length === 0) { return; }

        // @ts-ignore
        setScrollToIndex({index: action.payload.data.data.result.length - 1})
        // @ts-ignore
        //console.log('res', action.payload.data.data.result.length)
    }, 1000, () => {
        return !!document.querySelector(`.chat-row-${data.length - 1}`)
    } );

    /* useInterval( () => {
         console.log(document.querySelector(`.chat-row-${data.length - 1}`))
     }, 1000)*/

    //const use

    const [isFirstLoading, isFirstLoadingSet] = useState(true);

    useEffect(() => {


        if(!chatId) { return }
        dispatch(fetchMessages({chatId: chatId, successCallBack: (result, fullData) => {
                setScrollToIndex({index: result.length - 1});
            }, }));

    }, [chatId])





    /*useEffect(() => {
        console.log('chatId', chatId)
    }, [chatId])*/


    const sendMsg = async (e: FormEvent<HTMLFormElement>) => {
        setMsgInProcess(true);

        try {
            e.preventDefault();
            await createMessage(chatId, msg);
            await dispatch(fetchMessages({chatId: chatId, force: true, successCallBack: (result) => {
                    setScrollToIndex({ index: result.length - 1 });
                }}));
            setMsg("");
        } catch (e) {
        } finally {
            setMsgInProcess(false)
        }

    }

    // @ts-ignore
    const onEnterPress = async(e) => {
        if(e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            await sendMsg(e)
        }
    }

    const [msg, setMsg] = useState("");
    const [msgInProcess, setMsgInProcess] = useState(false);

    const infiniteLoaderRef = useRef(null);

    const loadMoreRowsHandler = useCallback(({startIndex, stopIndex, from = "loadmore"}: { startIndex: number, stopIndex: number, from?: string }) => {
        return new Promise(async () => {})
    }, []);

    const scrollRef = useRef<ScrollParams>({
        clientHeight: 0,
        clientWidth: 0,
        scrollHeight: 0,
        scrollLeft: 0,
        scrollTop: 0,
        scrollWidth: 0,
    })




    const hasNextRef = useRef(false);
    const chatIdRef = useRef("");
    const statusRef = useRef<RequestStatusEnum>(RequestStatusEnum.Succeeded);


    useEffect(() => {
        statusRef.current = status;
    }, [status])

    useEffect(() => {
        hasNextRef.current = has_next;
    }, [has_next])


    useEffect(() => {
        chatIdRef.current = chatId;
    }, [chatId])




    const scrollHandler = useCallback( async (scrollParams: ScrollParams) => {

        //console.log('scroll');
        if(!chatIdRef.current) { return  }
        if(scrollParams.scrollTop < 50 && hasNextRef.current && statusRef.current === RequestStatusEnum.Succeeded) {
            scrollRef.current = scrollParams;
            await dispatch(fetchMessages({chatId: chatIdRef.current, successCallBack: () => {}}));
        }

    }, [])


    const [scrollTop, setScrollTop] = useState<number | undefined>(undefined);


    /*useEffect(() => {
        dispatch(clearChatData());
    }, [props.height])*/


    return (
        <SplitPaneWrapper tableName={"Чат"}>
            <div className={cn('chat-list', classes.root)} style={{height: `${props.height}px`}}>


                {
                    status === RequestStatusEnum.Loading
                    && <CircularProgress size={20} className={classes.loader} />
                }


                {
                    // @ts-ignore
                    (total !== 0 && chatId !== "") && <RenderMessages infiniteLoaderRef={infiniteLoaderRef}
                                                                      loadMoreRowsHandler={loadMoreRowsHandler}
                                                                      data={data}
                                                                      height={props.height}
                                                                      userId={userId}
                                                                      userName={userName}
                                                                      userRoleID={userRoleID}
                                                                      scrollHandler={scrollHandler}
                                                                      scrollToIndex={scrollToIndex}
                    />
                }

                <div className={classes.toolBar}>
                    <form onSubmit={sendMsg} className={classes.form}>
                        <TextareaAutosize
                            className={classes.textarea}
                            maxRows={5}
                            value={msg}
                            onChange={(e) => setMsg(e.target.value)}
                            onKeyDown={onEnterPress}
                        />

                        <button type="submit" className={classes.submit}>
                            <SendIcon className={classes.sendIcon}/>
                        </button>

                    </form>
                </div>
            </div>
        </SplitPaneWrapper>
    );
}

const RenderMessages = memo((props: any) => {

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        keyMapper: index => props.data[index] && props.data[index].id
    });

    const rowRenderer = useCallback(({index, key, parent, style}) => {
        if(props.data[index] === undefined) {
            return <div></div>
        }
        return <CellMeasurer
            key={key}
            cache={cache}
            columnIndex={0}
            rowIndex={index}
            parent={parent}
        >
            <div style={{...style, transform: 'scale(-1, 1)'}} className={`chat-row-${index}`}>
                {
                    props.userId === props.data[index].sender_id
                        ? <Message is_me={true} from={props.userName.charAt(0)} message={props.data[index].text} created_at={props.data[index].created_at}/>
                        : <Message from={props.userRoleID === RoleIdsEnum.SSK ? "KM" : "ССК"} message={props.data[index].text} created_at={props.data[index].created_at}/>
                }
            </div>
        </CellMeasurer>
    }, [props.data])

    const prevData = usePrevious(props.data)

    useEffect(() => {
        cache.clearAll();
        // @ts-ignore
        listRef.current.recomputeRowHeights();
        // @ts-ignore
        listRef.current.forceUpdateGrid();
    }, [props.data])

    const reInit = () => {
        //console.log('hello!111')
        setTimeout(() => {
            //console.log('hello!111')
            cache.clearAll();
            // @ts-ignore
            listRef.current.forceUpdateGrid();
            // @ts-ignore
            listRef.current.recomputeRowHeights();
            // @ts-ignore
            listRef.current.recomputeGridSize();
            // @ts-ignore
            setTimeout(() => listRef.current.scrollToRow(props.data.length - 1), 100)
        }, 1000)
    }

    const listRef = useRef<List | null>(null);

       useEffect(() => {
           //debounce(reInit(), 500)
       }, [props.height])

    useEffect(() => {
        // @ts-ignore
        if(!listRef.current || !listRef.current.scrollToRow) { return }

        try {
            // @ts-ignore
            setTimeout(() => listRef.current && listRef.current.scrollToRow(props.scrollToIndex.index), 50)
        } catch (e) {}
    }, [props.scrollToIndex])


    return <InfiniteLoader
        ref={props.infiniteLoaderRef}
        minimumBatchSize={41}
        isRowLoaded={({index}) => {
            return !!props.data[index];
        }}
        loadMoreRows={props.loadMoreRowsHandler}
        rowCount={props.data.length + 1}
    >
        {({onRowsRendered, registerChild}) => (
            <AutoSizer>
                {({width, height}) => (
                    <List
                        onScroll={props.scrollHandler}
                        //scrollToIndex={scrollIndex}
                        className={classes.list}
                        // @ts-ignore
                        width={360}
                        deferredMeasurementCache={cache}
                        scrollToIndex={props.scrollToIndex.index}
                        // - height info and - height table header ( - 65)
                        height={props.height - 160}
                        onRowsRendered={onRowsRendered}
                        ref={ element => {
                            registerChild(element);
                            // @ts-ignore
                            listRef.current = element;
                        }}
                        rowCount={props.data.length}
                        rowHeight={cache.rowHeight}
                        rowRenderer={rowRenderer}
                    />
                )}
            </AutoSizer>
        )}
    </InfiniteLoader>
})