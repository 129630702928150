import React, {useEffect, useState} from 'react';
import {
    ChooseFormData,
    LoginInterface,
    OpfTypeNameOptions,
    OptionInterface,
    RequestStatusEnum, RoleIdsEnum
} from "../../common/commonTypes";
import { useAppSelector, useAppDispatch } from '../../store/hooks'


import FormBuilder from "../FormBuilder/FormBuilder";
import {TabFormInterface} from "../FormBuilder/FormBuilder";
import AddButton from "../../features/ui/AddButton/AddButtonButton";
import { renderToStaticMarkup } from 'react-dom/server'
import classes from './ClientForm.module.scss'
import {
    createLegalClient,
    createPhysicalClient, deleteClientContact,
    deleteClientRequisites,
    updateLegalClient,
    updatePhysicalClient
} from "../../api/commonApi";
import { fetchClients, resetPagination, setScrollIndex, setLastAddedClient } from '../../store/features/clientsTable'
import {fetchPhysicalClient} from "../../store/features/currentClient";
import _ from "lodash";
import cn from "classnames";

const data: TabFormInterface = {
    tabs: [
        {
            key: "root",
            class: classes.mainGroup,
            label: "Основная информация",
            fields: [
                {
                    type: "string",
                    key: "issuer",
                    label: "Паспорт выдан",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "passport_series",
                    label: "Серия паспорта",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "passport_number",
                    label: "Номер паспорта",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "date",
                    key: "issue_date",
                    format: "dd.MM.yyyy",
                    label: "Дата выдачи паспорта",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "dep_code",
                    label: "Код подразделения",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "fio",
                    label: "ФИО",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "registration_address",
                    label: "Адрес регистрации",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "date",
                    key: "birthdate",
                    format: "dd.MM.yyyy",
                    label: "Дата рождения",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "birthplace",
                    label: "Место рождения",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    namedGroup: {
                        name: "client",
                        fields: [
                            {
                                type: "string",
                                key: "comment",
                                label: "Комментарий",
                                required: false,
                                validate: (val: string) => true,
                            },
                        ]
                    }
                }
            ]

        },

        {
            key: "requisites",
            label: "Реквизиты",
            fields: [

                {
                    namedGroup: {
                        name: "client",
                        fields: [
                            {
                                group: {
                                    class: cn(classes.requisitesGroup),
                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить Реквизиты'} />),
                                    name: "requisites",
                                    onDelete: async (id, values) => {
                                        if(values.client.requisites[id] === undefined) { return; }

                                        if(!values.client.requisites[id].id) {
                                            values.client.requisites.splice(id, 1);
                                            return;
                                        }

                                        if(!values.client.id) { return; }

                                        await deleteClientRequisites(values.client.id, values.client.requisites[id].id);
                                        values.client.requisites.splice(id, 1);
                                    },
                                    fields: [
                                        [
                                            {
                                                type: "hidden",
                                                key: "id",
                                                label: "",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "bank_name",
                                                label: "Название банка",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "bik",
                                                label: "БИК",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "k_s",
                                                label: "к/с",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                options: OpfTypeNameOptions,
                                                key: "r_s",
                                                label: "р/с",
                                                required: false,
                                                validate: (val: string) => true,
                                            }
                                        ]
                                    ]
                                }
                            }
                        ]
                    }
                }
            ]

        },

        {
            key: "clients",
            label: "Контакты",
            fields: [
                {
                    namedGroup: {
                        name: "client",
                        fields: [
                            {
                                group: {
                                    class: classes.contactGroup,
                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить контакт'} />),
                                    onDelete: async (id, values) => {
                                        if(values.client.contacts[id] === undefined) { return; }

                                        if(!values.client.contacts[id].id) {
                                            values.client.contacts.splice(id, 1);
                                            return;
                                        }

                                        if(!values.client.id) { return; }

                                        await deleteClientContact(values.client.id, values.client.contacts[id].id);
                                        values.client.contacts.splice(id, 1);
                                    },
                                    name: 'contacts',
                                    fields: [
                                        [
                                            {
                                                type: "hidden",
                                                key: "id",
                                                label: "ID",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "email",
                                                label: "E-mail",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "fio",
                                                label: "ФИО",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                class: classes.position,
                                                options: OpfTypeNameOptions,
                                                key: "position",
                                                label: "Должность",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                group: {
                                                    name: 'phone',
                                                    class: classes.phoneGroup,
                                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить телефон'} />),
                                                    fields: [
                                                        [
                                                            {
                                                                type: "string",
                                                                key: "",
                                                                label: "Телефон",
                                                                required: false,
                                                                validate: (val: string) => true,
                                                            }
                                                        ]
                                                    ]
                                                }
                                            },
                                        ]
                                    ]
                                }
                            }
                        ]
                    }
                }
            ]

        }

    ],
};



const ClientPhysicalForm = (props: { onClose?: () => void, clientId: string, chooseForm?: ChooseFormData}) => {

    const { physicalClient } = useAppSelector((state) => state.editClient)


    useEffect( () => {
        if(props.clientId === "") {
            return
        } else {
            dispatch(fetchPhysicalClient({clientId: props.clientId}));
        }
    }, []);

    useEffect(() => {
        //console.log('physicalClient', physicalClient)
        if(physicalClient === null) {
            const defValues = {}

            setInitValues(defValues);
            return
        } else {
            const client = JSON.parse(JSON.stringify(physicalClient));
            const newForm: TabFormInterface = _.cloneDeep(data);
            console.log(client)

            //@ts-ignore
            client.opf_type_id = OpfTypeNameOptions.filter(ot => ot.value == physicalClient.opf_type_id)[0];

            //console.log('client', client)

            setInitValues(client);


            if(physicalClient.client.requisites) {
                // @ts-ignore
                //console.log('physicalClient.client.requisites.length', newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields)
                for(let i = 0; i < physicalClient.client.requisites.length - 1; i++) {
                    // @ts-ignore
                    //console.log('newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields[0]', newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields[0])
                    // @ts-ignore
                    newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields.push(newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields[0])
                }
            }

            //console.log('newForm', newForm)



            if(physicalClient.client.contacts) {
                // @ts-ignore
                const contactField = newForm.tabs[2].fields[0].namedGroup.fields[0].group.fields[0];
                // @ts-ignore
                newForm.tabs[2].fields[0].namedGroup.fields[0].group.fields = [];
                for(let i = 0; i < physicalClient.client.contacts.length; i++) {
                    const newContactField = _.cloneDeep(contactField);
                    //console.log('newContactField', newContactField[3].group.fields)
                    if(physicalClient.client.contacts && physicalClient.client.contacts[i].phone && physicalClient.client.contacts[i].phone.length > 1) {
                        for (let x = 0; x < physicalClient.client.contacts[i].phone.length - 1; x ++) {
                            //console.log('newContactField[3].group', newContactField);
                            newContactField[4].group.fields.push(newContactField[4].group.fields[0])
                        }
                    }
                    // @ts-ignore
                    newForm.tabs[2].fields[0].namedGroup.fields[0].group.fields.push(newContactField)
                }
            }


            //console.log('newForm', newForm)
            // @ts-ignore
            setForm(newForm);
        }
    }, [physicalClient])

    const [form, setForm] = useState(null);
    const [initValues, setInitValues] = useState({});


    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    function initTable() {
        dispatch(resetPagination());
        // @ts-ignore
        dispatch(setScrollIndex(0));
        dispatch(fetchClients({skip: 0, take: 40}));
    }


    const submitHandler = async (data: any) => {
        setLoading(true);
        //issue_date //birthdate
        try {
            const values = JSON.parse(JSON.stringify(data));
            if(values.hasOwnProperty("issue_date") && values.issue_date) {
                values.issue_date = values.issue_date.split('T')[0];
            }
            if(values.hasOwnProperty("birthdate") && values.birthdate) {
                values.birthdate = values.birthdate.split('T')[0];
            }

            if(props.clientId) {
                updatePhysicalClient(props.clientId, values)
                dispatch(setLastAddedClient({ id: props.clientId, date: (new Date()) }))
            } else {
                const id = await createPhysicalClient(values);
                dispatch(setLastAddedClient({ id: id, date: (new Date()) }))
            }
            initTable();
        } catch (e) {
            return;
        } finally {
            setLoading(false);
        }

        if(props.onClose) { props.onClose() }

    }

    const { role_id } = useAppSelector((state) => state.user);


    return (
        <div>
            {
                (form !== null || props.clientId === "")
                    ? <FormBuilder
                        initialValues={initValues}
                        onClose={props.onClose}
                        onSubmit={submitHandler}
                        data={form ?? data}
                        useTab={true}
                        loading={loading}
                        disableAll={role_id === RoleIdsEnum.SSK}
                        title={props.clientId ? (
                            role_id === RoleIdsEnum.KM
                                ? 'Редактирование физического лица'
                                : 'Просмотр физического лица'
                        ) : 'Создание нового физического лица'}
                    />
                    : null
            }
        </div>
    )
}

export default ClientPhysicalForm;
