import * as React from "react";

const ReadIconSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
        <path d="M2 8.75L4.66667 11L10 5"  strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 8.75L8.66667 11L14 5"  strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default ReadIconSvg;
