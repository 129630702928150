import 'react-toastify/dist/ReactToastify.css';
import {Field, Form} from 'react-final-form'
import React, {useState} from 'react';
import {ValidationErrors} from "final-form";
import classes from "./FormBuilder.module.scss"
import TextField from '@mui/material/TextField';
import {OptionInterface} from "../../common/commonTypes";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Button, IconButton, Tooltip} from "@mui/material";
import cn from "classnames";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import {FileIcon} from 'react-file-icon';
import DownloadIcon from '@mui/icons-material/Download';
import {toast, ToastContainer} from 'react-toastify';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import InputMask from "react-input-mask";
import SingleSelect from "../../features/ui/SingleSelect/SingleSelect";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {Autocomplete} from "@mui/lab";

let originalFilesCount = 0;

const maximumSize = 16 * 1024 * 1024 //MegaBytes


const FilesField = ({ name, ...props }: { name: string, [key: string]: any, label?: string, onDeleteFile?: (id: string, name: string) => Promise<void> | void, onDownloadFile?: (id: string) => Promise<void> | void, disableAll?: boolean }) => {
    let [filesArr, setFilesArr] = React.useState([]);
    const [deleteFileActiveId, setDeleteFileActiveId] = useState("");
    const [downloadFileActiveId, setDownloadFileActiveId] = useState("");


    return (
        <Field name={name}>
            {({input: {value, onChange, ...input}, meta}) => {
                //console.log('---val---', value ?? [])
                // @ts-ignore
                if (Array.isArray(value)) {
                    // @ts-ignore
                    setFilesArr(value);
                }
                //setFilesArr(value ?? []);
                let files: Array<any> = [];
                const handleChange = (event: any) => {
                    // @ts-ignore
                    const target = event.target;

                    //target.srcElement.value = "";
                    //console.log('srcElement', event)

                    files = getFiles(target.files);
                    const approvedFiles = [];
                    const notApprovedFiles = [];

                    for (const file of files) {
                        if (file.size <= maximumSize) {
                            approvedFiles.push(file)
                        } else {
                            notApprovedFiles.push(file)
                        }
                    }


                    let newFiles: Array<File> = [];

                    if (props.multiple) {
                        newFiles = [...filesArr, ...approvedFiles];
                    } else {
                        newFiles = [...approvedFiles]
                    }

                    // @ts-ignore
                    setFilesArr(newFiles);

                    // filesArr
                    originalFilesCount = newFiles.length;
                    onChange(newFiles); // instead of the default target.value

                    for (let f of notApprovedFiles) {
                        toast.error(`${f.name} превышает 5мб`,);
                    }

                    // @ts-ignore
                    event.target.value = "";
                };
                const getFiles = (files: any) => {
                    return Array.from(files).filter((item, key) => key < 5);
                };

                const handleDelete = async (e: any) => {
                    if (value[e].hasOwnProperty('id')) {
                        setDeleteFileActiveId(value[e].id);
                        if (props.onDeleteFile !== undefined) {
                            // @ts-ignore
                            await props.onDeleteFile(value[e].id, name);
                        }
                    }
                    value.splice(e, 1);
                    let temp = [...value];
                    // @ts-ignore
                    setFilesArr(temp);
                    setDeleteFileActiveId("");
                };

                const handleDownload = async (e: any) => {
                    if (value[e].hasOwnProperty('id')) {
                        setDownloadFileActiveId(value[e].id);
                        if (props.onDownloadFile !== undefined) {
                            // @ts-ignore
                            await props.onDownloadFile(value[e].id);
                        }
                        setDownloadFileActiveId("")
                    }
                };

                // React.useEffect(() => {
                //   console.log("filesArr", filesArr);
                // }, [filesArr]);

                files = getFiles(value);
                return (
                    <div>
                        <Button
                            variant="outlined"
                            component="label"
                            style={{
                                width: '100%',
                                marginTop: '30px',
                                marginBottom: '10px',
                                fontWeight: 700,
                                background: 'yellow',
                                color: 'green'
                            }}
                            disabled={props.disableAll}
                            className={cn({[classes.fileError]: (meta.error && (props.submitted))})}
                        >{props.label ? props.label : 'Загрузить документы'}
                            <input {...input} type="file" onChange={handleChange} {...props} hidden/>
                        </Button>
                        {value && value[0] && (
                            <div>
                                <div className="files">
                                    {filesArr.map((file: File, key) => (
                                        <div key={key} className={classes.fileContainer}>
                                            <div style={{display: 'flex', alignItems: 'center', gridGap: '10px',}}>
                                                <div style={{width: '30px'}}>
                                                    {
                                                        file.name &&
                                                        <FileIcon extension={file.name.split(".").pop()}/>
                                                    }
                                                </div>
                                                {file.name}
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                                // @ts-ignore
                                                 className={cn({[classes.groupAddBtnDisabled]: file.hasOwnProperty("id") && file.id === deleteFileActiveId})}>
                                                {
                                                    file.hasOwnProperty("id") && <div onClick={(e) => {
                                                        handleDownload(key);
                                                    }} style={{marginRight: "10px", marginTop: '2px', cursor: "pointer"}}>
                                                        {
                                                            // @ts-ignore
                                                            file.hasOwnProperty("id") && file.id === downloadFileActiveId
                                                                ? <CircularProgress style={{color: "#3DD598",}} size={18}/>
                                                                : <DownloadIcon style={{color: "#3DD598"}}/>
                                                        }
                                                    </div>
                                                }
                                                <div
                                                    onClick={(e) => {
                                                        handleDelete(key);
                                                    }}
                                                    className={cn({
                                                        // @ts-ignore
                                                        [classes.groupAddBtnDisabled]: (file.hasOwnProperty("id") && file.id === downloadFileActiveId) || props.disableAll,
                                                    })}
                                                >
                                                    {
                                                        // @ts-ignore
                                                        file.hasOwnProperty("id") && file.id === deleteFileActiveId
                                                            ? <CircularProgress style={{color: "#FC5A5A",}} size={18}/>
                                                            : <DeleteIcon className={classes.deleteFileIcon}/>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }}
        </Field>
    );
};


interface LoginFormErrorsInterface {
    email?: string,
    password?: string,
}


export type RequiredFuncType = (form: any) => boolean | string;
export type DisabledFuncType = (form: any) => boolean;

export interface FieldInfo {
    type: "string_w_mask" | "string" | "number" | "checkbox" | "select" | "file" | "date" | "hidden" | "files" | "autocomplete",
    key: string,
    mask?: string,
    value?: any,
    class?: string,
    format?: string,
    disabled?: boolean | DisabledFuncType,
    realtime?: boolean,
    onChange?: (val: any, values: any) => void,
    onRealChange?: (form: any, val: any, idx: any) => void,
    label: string,
    tooltip?: string | undefined,
    required?: boolean | RequiredFuncType | undefined;
    validate: (val: any) => boolean;
    showIf?: (form: any) => boolean;
    options?: Array<OptionInterface>;
}


export interface GroupInfo {
    group: {
        name: string,
        maxLength?: number,
        class?: string,
        defaultCount?: number,
        addButtonComponent?: any,
        onDelete?: (props: any, values: any,) => Promise<any> | void,
        fields: Array<Array<FieldInfo | GroupInfo | NamedGroupInfo | ComponentInfo>>
    }
}

export interface ComponentInfo {
    component: any,
    showIf?: (form: any) => boolean,
}


export interface NamedGroupInfo {
    namedGroup: {
        name: string,
        label?: string,
        showIf?: (form: any) => boolean,
        showArrIf?: (form: any, idx: any) => boolean,
        fields: Array<FieldInfo | GroupInfo | NamedGroupInfo | ComponentInfo>,
        class?: any,
    }
}


export interface TabInfo {
    key: string,
    label: string,
    class?: string,
    fields: Array<FieldInfo | GroupInfo | NamedGroupInfo | ComponentInfo>
}

export interface TabFormInterface {
    tabs: Array<TabInfo>
}


interface FormBuilderProps {
    useTab?: boolean,
    data: TabFormInterface,
    title?: string,
    onSubmit?: (values: any) => void,
    onDeleteFile?: (id: string, name: string) => Promise<void> | void,
    onDownloadFile?: (id: string) => Promise<void> | void,
    onExportPDF?: () => Promise<void> | void,
    onClose?: () => void,
    loading?: boolean,
    initialValues?: any,
    disableAll?: boolean,
}

const NumberThousandFormat = React.forwardRef(function NumberThousandFormat(nfprops, ref) {
    // @ts-ignore
    const {onChange, ...other} = nfprops;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange(values)
            }}
            thousandSeparator={" "}
            isNumericString
        />
    );
});

NumberThousandFormat.propTypes = {
    // @ts-ignore
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const YearFormat = React.forwardRef(function YearFormat(nfprops, ref) {
    // @ts-ignore
    const {onChange, ...other} = nfprops;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange(values)
            }}
            isNumericString
        />
    );
});

YearFormat.propTypes = {
    // @ts-ignore
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


// @ts-ignore
const FormBuilder = (props: FormBuilderProps) => {


    const [data, setData] = useState(props.data);

    const getGroupIndexes = (tabIndex: number, fields: Array<FieldInfo | GroupInfo | NamedGroupInfo | ComponentInfo>) => {

    }


    const deleteGroupHandler = async (path: Array<string | number>, name: string, id: number, values: any, onDelete?: (props: any, values: any,) => Promise<any> | void, tabName?: string) => {
        if (tabName == undefined) {
            if (!props.useTab) {
                tabName = "root";
                path.splice(0, 1);
            }
        }


        const tabIndex = props.data.tabs.findIndex(t => t.key == tabName);

        const groupIndexes: Array<number> = [];

        let fields = props.data.tabs[tabIndex].fields;

        const newData = _.cloneDeep(data);
        let newFields = newData.tabs[tabIndex].fields;

        let newValues: any = values;

        //console.log('path', path)
        path.forEach((p, i) => {
            if (typeof p == "string") {

                let isGroup = false
                // @ts-ignore
                const gIdx = fields.findIndex(f => f.hasOwnProperty("group") && f.group.name == p);

                isGroup = gIdx > -1;

                if (isGroup) {
                    // @ts-ignore
                    fields = fields[gIdx].group.fields;
                    if (newFields[gIdx]) {
                        // @ts-ignore
                        if (newValues[newFields[gIdx].group.name]) {
                            // @ts-ignore
                            newValues = newValues[newFields[gIdx].group.name];
                        }
                        // @ts-ignore
                        newFields = newFields[gIdx].group.fields;
                    } else {
                        throw Error();
                    }
                } else {
                    // @ts-ignore
                    const gnIdx = fields.findIndex(f => f.hasOwnProperty("namedGroup") && f.namedGroup.name == p);
                    // @ts-ignore
                    if (fields[gnIdx].namedGroup.name) {
                        // @ts-ignore
                        if (newValues[fields[gnIdx].namedGroup.name]) {
                            // @ts-ignore
                            newValues = newValues[fields[gnIdx].namedGroup.name];
                        }
                    }
                    // @ts-ignore
                    fields = fields[gnIdx].namedGroup.fields;
                    // @ts-ignore
                    newFields = newFields[gnIdx].namedGroup.fields;
                    //console.log('fieldsfieldsfields', fields, p)
                }
            }

            if (typeof p == "number") {
                ;
                // @ts-ignore
                fields = fields[0];
                if (i == path.length - 1) {
                    return
                }
                // @ts-ignore
                if (newValues[p]) {
                    newValues = newValues[p];
                }
                // @ts-ignore
                newFields = newFields[p];
            }
        })

        // если определяешь onDelete для группы - сам удаляй в нем
        if (typeof onDelete == "function") {
            try {
                // @ts-ignore
                await onDelete(id, values);
            } catch (e) {
                //console.log(e)
                //alert('Не удалось удалить запись')
            }
        } else {
            newValues.splice(id, 1);
        }
        newFields.splice(id, 1);
        setData(_.cloneDeep(newData));

    }
    const addGroupHandler = (path: Array<string | number>, name: string, tabName?: string) => {
        if (tabName === undefined) {
            if (!props.useTab) {
                tabName = "root";
                path.splice(0, 1);
            }
        }


        const tabIndex = props.data.tabs.findIndex(t => t.key == tabName);


        const groupIndexes: Array<number> = [];

        let fields = props.data.tabs[tabIndex].fields;

        const newData = _.cloneDeep(data);
        let newFields = newData.tabs[tabIndex].fields;


        //console.log('path', path)
        path.forEach((p, i) => {
            if (typeof p == "string") {

                let isGroup = false
                // @ts-ignore
                const gIdx = fields.findIndex(f => f.hasOwnProperty("group") && f.group.name == p);
                isGroup = gIdx > -1;

                if (isGroup) {
                    // @ts-ignore
                    fields = fields[gIdx].group.fields;
                    if (newFields[gIdx]) {
                        // @ts-ignore
                        newFields = newFields[gIdx].group.fields;
                    } else {
                        throw Error();
                    }
                } else {
                    // @ts-ignore
                    const gnIdx = fields.findIndex(f => f.hasOwnProperty("namedGroup") && f.namedGroup.name == p);
                    // @ts-ignore
                    fields = fields[gnIdx].namedGroup.fields;
                    // @ts-ignore
                    newFields = newFields[gnIdx].namedGroup.fields;
                    //console.log('fieldsfieldsfields', fields, p)
                }
            }


            if (typeof p == "number") {
                // @ts-ignore
                fields = fields[0];
                if (i == path.length - 1) {
                    return
                }
                // @ts-ignore
                newFields = newFields[p];
            }
        })

        // @ts-ignore
        newFields.push(fields);
        setData(newData);

    }

    const getFullPath = (path: Array<string | number>, splitDots: boolean = true): Array<string | number> => {
        const fullPath: Array<string | number> = [];
        path.forEach((fp) => {
            if (fp === undefined) {
                return;
            }
            if (typeof fp == "number") {
                fullPath.push(fp);
            } else {
                try {
                    if (fp.length == 0) {
                        return
                    }
                } catch (e) {
                    //alert(fp)
                }
                if (splitDots) {
                    fp.split(".").forEach((fp2) => {
                        fullPath.push(fp2)
                    })
                } else {
                    fullPath.push(fp)
                }
            }

        });

        if (fullPath.length == 0) {
            return [];
        }

        if (fullPath[0] == "root") {
            fullPath.shift();
        }

        return fullPath;
    }

    const RenderField = (props: { fieldOrGroup: FieldInfo | GroupInfo | NamedGroupInfo | ComponentInfo, path: Array<string | number>, isGroup?: boolean, idx?: number, values: any, submitted: boolean, tabName?: string, onDeleteFile?: (id: string, name: string) => Promise<void> | void, onDownloadFile?: (id: string) => Promise<void> | void, disableAll?: boolean }): JSX.Element | null => {

        if (props.fieldOrGroup.hasOwnProperty("group")) {

            // @ts-ignore
            const group: GroupInfo = props.fieldOrGroup;


            const path = [...props.path, group.group.name, props.idx ? props.idx : 0];

            //console.log('group.group', group.group.fields.length)
            return <div>
                <div
                    className={cn(classes.groupAddBtn, {[classes.groupAddBtnDisabled]: group.group.fields.length === group.group.maxLength})}>
                    {
                        group.group.addButtonComponent != undefined
                            ? <div
                                style={{width: 'fit-content'}}
                                onClick={() => addGroupHandler(path, group.group.name, props.tabName)}
                                dangerouslySetInnerHTML={{
                                    __html: group.group.addButtonComponent
                                }}></div>
                            :
                            <button onClick={() => addGroupHandler(path, group.group.name, props.tabName)}>add</button>

                    }
                </div>
                <div style={{display: "flex", flexDirection: "column-reverse",}}>
                    {
                        /*group.group.defaultCount !== undefined
                            ? Array.from(Array(group.group.defaultCount).keys()).map((itm, id) => <RenderFields fields={group.group.fields[0]} path={[...props.path, group.group.name, id]} isGroup={true} idx={id} values={props.values} class={group.group.class} submited={props.submitted} tabName={props.tabName} />)
                            : group.group.fields.map(((fields, id) =>
                                    <RenderFields fields={fields} path={[...props.path, group.group.name, id]} isGroup={true} idx={id} values={props.values} class={group.group.class} submited={props.submitted} tabName={props.tabName} />
                            ))*/

                        group.group.fields.map(((fields, id) =>
                                <div style={{display: "flex", flexDirection: "column-reverse", alignItems: "end"}}>
                                    {<div className={classes.deleteBtn}
                                          onClick={() => deleteGroupHandler(path, group.group.name, id, props.values, group.group.onDelete, props.tabName)}>
                                        <DeleteIcon/>
                                        <div>
                                            Удалить
                                        </div>
                                    </div>}
                                    {
                                        group.group.name == "offer" && "Предложение №" + (id + 1)
                                    }
                                    <RenderFields fields={fields} path={[...props.path, group.group.name, id]}
                                                  isGroup={true} idx={id} values={props.values}
                                                  class={group.group.class} submited={props.submitted}
                                                  tabName={props.tabName} disableAll={props.disableAll}
                                                  onDownloadFile={props.onDownloadFile}
                                                  onDeleteFile={props.onDeleteFile}
                                    />
                                </div>
                        ))
                    }
                </div>
            </div>;
        }

        if (props.fieldOrGroup.hasOwnProperty("namedGroup")) {

            // @ts-ignore
            const group: NamedGroupInfo = props.fieldOrGroup;

            if (group.namedGroup.showIf != undefined && !group.namedGroup.showIf(props.values)) {
                return null;
            }

            if (group.namedGroup.showArrIf != undefined && !group.namedGroup.showArrIf(props.values, props.idx ? props.idx : 0)) {
                return null;
            }


            const path = [...props.path, group.namedGroup.name];
            return <div className={cn({[group.namedGroup.class]: group.namedGroup.class})}>
                {group.namedGroup.fields.map(((field, id) =>
                        <RenderField fieldOrGroup={field} path={path} idx={props.idx} tabName={props.tabName}
                                     onDownloadFile={props.onDownloadFile}
                                     onDeleteFile={props.onDeleteFile}
                                     isGroup={false} values={props.values} submitted={props.submitted}
                                     disableAll={props.disableAll}/>
                ))}
            </div>;
        }


        if (props.fieldOrGroup.hasOwnProperty("component")) {
            // @ts-ignore
            if (props.fieldOrGroup.component.showIf != undefined && !props.fieldOrGroup.component.showIf(props.values)) {
                return null
            }
            return <div
                dangerouslySetInnerHTML={{
                    // @ts-ignore
                    __html: props.fieldOrGroup.component.component ?? ''
                }}></div>
        }

        // @ts-ignore
        const field: FieldInfo = props.fieldOrGroup;
        if (field.showIf != undefined && !field.showIf(props.values)) {
            return null;
        }

        if (!props.values[field.key]) {
            props.values[field.key] = field.value
        }

        const fullPath: Array<string | number> = getFullPath([...props.path, field.key]);

        if (fullPath.length == 0) {
            return <div></div>;
        }

        let fName = ''

        fullPath.forEach((p) => {
            if (typeof p == "string") {
                fName = `${fName}${p}.`
            } else if (typeof p == "number") {
                // @ts-ignore
                fName = `${fName.rtrim(".")}[${p}].`
            }
        });

        // @ts-ignore
        fName = fName.rtrim(".")


        if (field.type == "date") {
            return <Field name={fName}>
                {({
                      input: {name, onChange, value, ...restInput},
                      meta,
                      ...rest
                  }
                ) => (
                    <DesktopDatePicker
                        // @ts-ignore
                        disabled={props.disableAll || field.disabled}
                        value={field.value ? field.value : ((value === "" && !props.submitted) ? null : value)}
                        onChange={date => onChange(date)}
                        {...rest}
                        inputFormat={field.format ?? 'dd.MM.yyyy'}
                        renderInput={(params) => {
                            if (params.inputProps && params.inputProps.hasOwnProperty("placeholder")) {
                                params.inputProps.placeholder = field.label;
                            }
                            return <TextField {...params} />
                        }
                        }
                    />
                )}
            </Field>
        }


        if (field.type === "files") {
            return <FilesField
                multiple
                name={fName}
                label={field.label}
                onDeleteFile={props.onDeleteFile}
                onDownloadFile={props.onDownloadFile}
                disableAll={props.disableAll}
                submitted={props.submitted}
            />

        }

        if (field.type === "file") {

            return <FilesField
                label={field.label}
                name={fName}
                onDeleteFile={props.onDeleteFile}
                onDownloadFile={props.onDownloadFile}
                disableAll={props.disableAll}
                submitted={props.submitted}
            />

        }

        if (field.type == "checkbox") {
            return (
                <div>
                    {field.tooltip && (
                        <Tooltip title={field.tooltip ? field.tooltip : ""} followCursor>
                            <IconButton>
                                <InfoIcon/>
                            </IconButton>
                        </Tooltip>
                    )}
                    <Field name={fName} type="checkbox">
                        {({input, meta}) => (
                            <div
                                className={cn({[classes.checkboxWrapperError]: (meta.error && (props.submitted))})}>
                                <input
                                    {...input} id={fName} type="checkbox"
                                    checked={input.checked}
                                    onChange={(e) => {
                                        //console.log('field.onChange', field.onChange)
                                        if (field.onChange !== undefined) {
                                            field.onChange(e, props.values)
                                        }

                                        input.onChange(e)
                                    }}
                                    // @ts-ignore
                                    disabled={props.disableAll || field.disabled}
                                />
                                <label htmlFor={fName}
                                       className={cn({[classes.groupAddBtnDisabled]: props.disableAll || field.disabled})}>{field.label}</label>
                                {meta.error && (props.submitted) && (
                                    <span className={classes.checkBoxError}>{'- ' + meta.error}</span>
                                )}
                            </div>
                        )
                        }
                    </Field>
                </div>
            )
        }

        if (field.type == "hidden") {
            return (<Field name={fName}>
                {({
                      input: {name, onChange, value, ...restInput},
                      meta,
                      ...rest
                  }
                ) => (
                    <input {...rest}
                           hidden={true}
                           name={name}
                           type={field.type}
                           onChange={onChange}
                           value={value}
                           className={classes.input}
                    />
                )}
            </Field>);
        }

        if (field.type == "number") {
            return (<Field name={fName}>
                {({
                      input: {name, onChange, value, ...restInput},
                      meta,
                      ...rest
                  }
                ) => (
                    <div className={cn({[field.class ?? '']: field.class})}>
                        <TextField
                            {...rest}
                            name={name}
                            // @ts-ignore
                            disabled={props.disableAll || (typeof field.disabled === "function" ? field.disabled(props.values) : field.disabled)}
                            error={meta.error && (props.submitted)}
                            onChange={(values) => {

                                let e = {
                                    target: {
                                        // @ts-ignore
                                        value: values.floatValue
                                    }
                                }

                                onChange(e)

                                if (field.onRealChange !== undefined) {
                                    field.onRealChange(props.values, e.target.value, props.idx);
                                }
                                if (field.onChange !== undefined) {
                                    field.onChange(props.values, e);
                                }
                            }}
                            InputProps={{
                                // @ts-ignore
                                inputComponent: field.format == "year" ? YearFormat : NumberThousandFormat,
                            }}
                            value={value ? value : field.value}
                            className={classes.input}
                            label={field.label + (meta.error && (props.submitted) ? `  - ${meta.error}` : '')}
                            variant="standard"/>
                    </div>
                )}
            </Field>);
        }

        if (field.type == "autocomplete") {
            return (<Field name={fName}>
                {({
                      input: {name, onChange, value, ...restInput},
                      meta,
                      ...rest
                  }
                ) => (
                    <div className={cn({[field.class ?? '']: field.class})}>
                        <Autocomplete
                            freeSolo
                            // @ts-ignore
                            options={props.values.options && props.values.options.hasOwnProperty(field.key) && props.values.options[field.key] ? props.values.options[field.key] : []}
                            value={value ? value : field.value}
                            filterOptions={(x) => x}
                            onChange={(event, newValue) => {
                                let nv;
                                if (typeof newValue === 'string') {
                                    nv = newValue
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    nv = newValue.inputValue
                                } else if (newValue && newValue.value) {
                                    nv = newValue.value
                                } else {
                                    nv = newValue
                                }
                                // @ts-ignore
                                window.setFormValue(name, nv);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                    {...rest}
                                    name={name}
                                    type={field.type}
                                    // @ts-ignore
                                    disabled={props.disableAll || (typeof field.disabled === "function" ? field.disabled(props.values) : field.disabled)}
                                    error={meta.error && (props.submitted)}
                                    onChange={(e) => {
                                        onChange(e)

                                        if (field.onChange !== undefined) {
                                            field.onChange(props.values, e.target.value);
                                        }
                                    }}
                                    value={value ? value : field.value}
                                    className={classes.input}
                                    label={field.label + (meta.error && (props.submitted) ? `  - ${meta.error}` : '')}
                                    variant="standard"
                                />
                            )}
                        />
                    </div>
                )}
            </Field>);
        }

        if (field.type == "string_w_mask") {
            return (<Field name={fName}>
                {({
                      input: {name, onChange, value, ...restInput},
                      meta,
                      ...rest
                  }
                ) => (
                    <div className={cn({[field.class ?? '']: field.class})}>
                        <InputMask
                            mask={field.mask ?? ""}
                            // @ts-ignore
                            disabled={props.disableAll || (typeof field.disabled === "function" ? field.disabled(props.values) : field.disabled)}
                            value={value ? value : field.value}
                            onChange={(e: any) => {
                                if (field.type === "number") {
                                    try {
                                        onChange(parseInt(e.target.value));
                                    } catch (e) {
                                        onChange("")
                                    }
                                } else {
                                    onChange(e)
                                }
                                if (field.onRealChange !== undefined) {
                                    field.onRealChange(props.values, e.target.value, props.idx);
                                }
                                if (field.onChange !== undefined) {
                                    field.onChange(props.values, e);
                                }
                            }}
                        >
                            <TextField
                                {...rest}
                                name={name}
                                type={field.type}
                                error={meta.error && (props.submitted)}
                                inputProps={{...restInput, min: 0}}
                                className={classes.input}
                                label={field.label + (meta.error && (props.submitted) ? `  - ${meta.error}` : '')}
                                variant="standard"/>
                        </InputMask>
                    </div>
                )}
            </Field>);
        }

        if (field.type == "string") {
            return (<Field name={fName}>
                {({
                      input: {name, onChange, value, ...restInput},
                      meta,
                      ...rest
                  }
                ) => (
                    <div className={cn({[field.class ?? '']: field.class})}>
                        <TextField
                            {...rest}
                            name={name}
                            type={field.type}
                            // @ts-ignore
                            disabled={props.disableAll || (typeof field.disabled === "function" ? field.disabled(props.values) : field.disabled)}
                            error={meta.error && (props.submitted)}
                            inputProps={{...restInput, min: 0}}
                            onChange={(e) => {
                                if (field.type === "number") {
                                    try {
                                        onChange(parseInt(e.target.value));
                                    } catch (e) {
                                        onChange("")
                                    }
                                } else {
                                    onChange(e)
                                }
                                if (field.onRealChange !== undefined) {
                                    field.onRealChange(props.values, e.target.value, props.idx);
                                }
                                if (field.onChange !== undefined) {
                                    field.onChange(props.values, e);
                                }
                            }}
                            value={value ? value : field.value}
                            className={classes.input}
                            label={field.label + (meta.error && (props.submitted) ? `  - ${meta.error}` : '')}
                            variant="standard"/>
                    </div>
                )}
            </Field>);
        }

        if (field.type == "select") {
            return (<Field name={fName}>
                {({input, onChange, meta, ...rest}
                ) => (
                    <div className={cn({[field.class ?? '']: field.class, ["selectError"]: meta.error && (props.submitted)})}>
                        <SingleSelect
                            // @ts-ignore
                            rffInput={{...input, onChange: (e) => { input.onChange(e); field.onChange && field.onChange(e)  } }}
                            rffRest={{...rest}}
                            placeholder={(meta.error && (props.submitted)) ? `${field.label} - обязательно` : field.label}
                            options={field.options}
                            value={field.value}
                            height={40}
                            defaultDropdownIndicator={true}
                            disabled={!!field.disabled || props.disableAll}
                        />
                    </div>
                )}
            </Field>);
        } else {
            return (<div>hello</div>);
        }

    }


    function RenderFields(props: { fields: Array<FieldInfo | GroupInfo | NamedGroupInfo | ComponentInfo>, tabName?: string, path: Array<string | number>, isGroup?: boolean, idx?: number, values: any, class?: string, submited: boolean, onDeleteFile?: (id: string, name: string) => Promise<void> | void, onDownloadFile?: (id: string) => Promise<void> | void, disableAll?: boolean }): JSX.Element {
        return <div className={cn(classes.tabPanel, {[props.class ?? '']: props.class})}>{
            props.fields.map((f, i) => <RenderField fieldOrGroup={f} path={[...props.path,]} idx={props.idx}
                                                    isGroup={props.isGroup} values={props.values}
                                                    disableAll={props.disableAll}
                                                    submitted={props.submited} tabName={props.tabName}
                                                    onDeleteFile={props.onDeleteFile}
                                                    onDownloadFile={props.onDownloadFile}/>)
        }</div>
    }


    const recursiveValidation = (fields: Array<FieldInfo | GroupInfo | NamedGroupInfo | ComponentInfo>, path: Array<string | number>, values: any, errors: any = {}, groupKey: string = "") => {
        fields.forEach((f) => {
            if (f.hasOwnProperty("namedGroup")) {
                // @ts-ignore
                const namedGroup: NamedGroupInfo = f;
                if (namedGroup.namedGroup.showIf == undefined || namedGroup.namedGroup.showIf(values)) {
                    //console.log('namedGroup', f, path);
                    recursiveValidation(namedGroup.namedGroup.fields, [...path, namedGroup.namedGroup.name], values, errors, groupKey)
                } else {
                    //console.log('namedGroup false', values);
                    // console.log('namedGroup false', path, namedGroup.namedGroup.showIf == undefined, namedGroup.namedGroup.showIf != undefined, namedGroup.namedGroup.showIf, namedGroup.namedGroup.showIf(values), values);
                }
            } else if (f.hasOwnProperty("group")) {
                // @ts-ignore
                const groupInfo: GroupInfo = f;
                if (!groupInfo.group.hasOwnProperty("showIf")) {
                    groupInfo.group.fields.forEach((f, i) => {
                        recursiveValidation(f, [...path, groupInfo.group.name, i], values, errors, groupInfo.group.name)
                    })
                }
            } else {
                // @ts-ignore
                const field: FieldInfo = f;
                let error = errors;
                let val = values;
                const fullPath = getFullPath([...path, field.key], false);
                //console.log('fp', fullPath)
                fullPath.forEach((p, idx) => {
                    if (p === "") {
                        return
                    }

                    if (typeof p == "string" && p.split('.').length > 1) {
                        const pathDotSplit = p.split('.');
                        pathDotSplit.forEach((pdt) => {

                            if (val && val.hasOwnProperty(pdt)) {
                                val = val[pdt];
                            } else {
                                val = null;
                            }

                            /*if(idx == fullPath.length - 1) {
                                if(typeof field.required == "boolean" && field.required && !val) {
                                    error[pdt.toString()] = "required";
                                }

                                if(typeof field.required == "function" && field.required(values) && !val) {
                                    error[pdt.toString()] = "function required";
                                }
                                return;
                            }*/

                            if (error.hasOwnProperty(pdt)) {
                                //console.log("has", error, pdt)
                                error = error[pdt.toString()];
                                return;
                            } else {
                                //console.log("has", pdt)
                                error[pdt.toString()] = {}
                                error = error[pdt.toString()];
                            }
                        });
                    } else {
                        if (val && val.hasOwnProperty(p)) {
                            val = val[p.toString()];
                        } else {
                            val = null;
                        }

                        if (idx == fullPath.length - 1) {
                            if (field.showIf !== undefined && !field.showIf(values)) {
                                return;
                            }

                            if (typeof field.required == "boolean" && field.required && (!val || (Array.isArray(val) && !val.length))) {
                                error[p.toString()] = "Обязательно";
                            }

                            if (typeof field.required == "function") {
                                const res = field.required(values);
                                if (typeof res == "string") {
                                    error[p.toString()] = res;
                                } else if (!val && res) {
                                    error[p.toString()] = "Обязательно";
                                }
                            }
                            return;
                        }
                        if (Array.isArray(error)) {
                            if (typeof p == "number") {
                                if (error[p] == undefined) {
                                    error[p] = {};
                                }
                                error = error[p];
                            }
                        } else if (error.hasOwnProperty(p)) {
                            error = error[p.toString()];
                            return;
                        } else {
                            if (p === groupKey) {
                                //console.log('p == groupKey', p == groupKey, p, typeof groupKey, groupKey === "")
                                if (error[p.toString()] == undefined) {
                                    error[p.toString()] = [];
                                    error = error[p.toString()];
                                } else {
                                    //alert('hello')
                                }
                                //console.log('groupKey', error)
                            } else {
                                error[p.toString()] = {}
                                error = error[p.toString()];
                            }
                        }
                    }
                })
                //console.log('field', field, path);
            }
        })

        //console.log('errors', errors)

        return errors;

    }

    const validate = async (values: any): Promise<ValidationErrors> => {

        //console.log({ offer: [ { comment: "test" } ] })
        // return { offer: [ { comment: "test" } ] }
        if (!props.useTab) {
            //console.log(data.tabs[0].fields, values);
            const res = recursiveValidation(data.tabs[0].fields, [], values);
            //console.log('all errors', res)
            return res;
        } else {
            let errors: any = {}
            data.tabs.forEach(
                (t, i) => {
                    const tabErrors = recursiveValidation(t.fields, [], values);

                    Object.keys(tabErrors).forEach((te: string) => {
                        if (errors.hasOwnProperty(te)) {
                            errors[te] = {...errors[te], ...tabErrors[te]}
                        } else {
                            errors[te] = tabErrors[te]
                        }
                    })
                    //console.log('tab', i, recursiveValidation(t.fields, [t.key], values))
                    //errors = {...errors, ...recursiveValidation(t.fields, [t.key], values)}
                }
            );
            //console.log('all errors', errors)
            return errors;

        }

        return {};
    }

    const [tabValue, setValue] = React.useState('root');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const [submited, setSubmited] = React.useState(false);


    const onSubmit = async (data: any) => {
        setSubmited(true);
        //console.log(data)
        if (props.onSubmit != undefined) {
            props.onSubmit(data);
        }
    }


    // @ts-ignore
    return (
        <div className={classes.root} id={"formbuilder"}>
            <ToastContainer limit={10}/>
            <div className={classes.header}>
                <div className={classes.title}>
                    {props.title}
                </div>

                <CloseIcon onClick={props.onClose} style={{cursor: "pointer"}}/>

            </div>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, {changeValue}) => {
                        changeValue(state, field, () => value)
                    }
                }}
                initialValues={props.initialValues}
                render={({handleSubmit, values, form},) => (
                    <form onSubmit={handleSubmit} style={{padding: '0px', height: "100%"}}>
                        {
                            // @ts-ignore
                            window.setFormValue = form.mutators.setValue
                        }

                        {
                            !props.useTab
                                ? <div className={classes.noTabFormRoot}>
                                    <RenderFields fields={data.tabs[0].fields} path={[data.tabs[0].key]} values={values}
                                                  submited={submited} onDeleteFile={props.onDeleteFile}
                                                  onDownloadFile={props.onDownloadFile} disableAll={props.disableAll}/>
                                </div>
                                : <TabContext value={tabValue}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        {
                                            data.tabs.map(d => <Tab label={d.label} value={d.key}/>)
                                        }

                                    </TabList>

                                    <Tabs value={tabValue} className={classes.tabsContent}>
                                        {
                                            data.tabs.map(
                                                d =>
                                                    <TabPanel value={d.key} className={classes.tabPanel}>
                                                        <RenderFields fields={d.fields} path={[]} values={values}
                                                                      onDownloadFile={props.onDownloadFile}
                                                                      onDeleteFile={props.onDeleteFile}
                                                                      class={d.class} submited={submited} tabName={d.key}
                                                                      disableAll={props.disableAll}/>
                                                    </TabPanel>
                                            )
                                        }
                                    </Tabs>
                                </TabContext>
                        }

                        <div
                            className={cn(classes.submitBtnContainer, {[classes.submitBtnContainerDisabled]: props.loading})}>
                            {
                                props.onExportPDF &&
                                // @ts-ignore
                                <Button onClick={() => props.onExportPDF()} className={classes.submit}
                                        variant="contained"
                                        type="button">Экспорт в PDF</Button>
                            }
                            {
                                !props.disableAll && <Button disabled={props.loading} onClick={() => setSubmited(true)}
                                                             className={classes.submit} variant="contained"
                                                             type="submit">
                                    {
                                        props.loading ? <CircularProgress style={{color: "white",}} size={30}/>
                                            : "Сохранить"
                                    }
                                </Button>
                            }
                        </div>

                    </form>
                )}
            />
        </div>
    )
}

export default FormBuilder;

