import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import BasePageWrapper from "./features/BasePageWrapper/BasePageWrapper";
import {
    BrowserRouter,
} from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './store/store';
import classes from "./styles/main.module.scss"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


require("./styles/main.css");

const container = document.getElementById('root');

// @ts-ignore Not added types for alpha react 18
const root = ReactDOM.createRoot(container);

root.render(
    <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className={classes.root}></div>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
        </LocalizationProvider>
    </Provider>
);