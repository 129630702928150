import { Form, Field } from 'react-final-form'
import React, {useEffect, useState} from 'react';
import {ValidationErrors} from "final-form";
import classes from "./LoginForm.module.scss"
import TextField from '@mui/material/TextField';
import { LoginInterface } from "../../common/commonTypes";
import cn from "classnames"


import { Typography,
    Paper,
    Link,
    InputLabel,
    Grid,
    Button,
    Input,
    TextareaAutosize,
    CssBaseline,
    MenuItem, } from "@mui/material";
import {nonEmptyString} from "../../common/lib";
import {signInRequest} from "../../api/commonApi";
import axios, {AxiosError} from "axios";
import CircularProgress from "@mui/material/CircularProgress";


interface LoginFormErrorsInterface {
    email?: string,
    password?: string,
}



const validate = (values: LoginInterface): ValidationErrors | Promise<ValidationErrors> => {
    const errors: LoginFormErrorsInterface = {};

    if (values.email == undefined || values.email.length == 0) errors.email = 'Введите логин';
    if (values.password == undefined || values.password.length == 0) errors.password = 'Введите логин';

    //console.log('errors', errors)
    return errors;
}



// @ts-ignore
const LoginForm = () => {

    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: any) => {
        setLoading(true);

        const response = await signInRequest(data);

        if(!response) { return; }

        if (response.status !== 200) {
            if (response.status === 404) {
                setRequestError("Пользователь с таким логином не найден")
            } else if (response.status === 403) {
                setRequestError("Не верный пароль")
            } else {
                setRequestError("Произошла ошибка")
            }
            setLoading(false);
            return
        }

        localStorage.setItem("access_token", response.headers['access-token']);
        localStorage.setItem("refresh_token", response.headers['refresh-token']);
        localStorage.setItem('user', JSON.stringify(response.data));
        location.href = "/" + window.location.search;

        setLoading(false);
    }

    return <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.root}>
                <Field name="email">
                    {(  { input: { name, onChange, value, ...restInput },
                            meta,
                            ...rest}
                    ) => (
                        <div>
                            <TextField
                                {...rest}
                                name={name}
                                helperText={meta.touched ? meta.error : undefined}
                                error={meta.error && meta.touched}
                                inputProps={restInput}
                                onChange={onChange}
                                value={value}
                                className={classes.input}
                                label="E-mail"
                                variant="standard" />
                        </div>
                    )}
                </Field>

                <Field name="password">
                    {(  { input: { name, onChange, value, ...restInput },
                            meta,
                            ...rest}
                    ) => (
                        <div>
                            <TextField
                                {...rest}
                                name={name}
                                helperText={meta.touched ? meta.error : undefined}
                                error={meta.error && meta.touched}
                                inputProps={restInput}
                                onChange={onChange}
                                value={value}
                                className={classes.input}
                                label="Пароль"
                                type="password"
                                variant="standard" />
                        </div>
                    )}
                </Field>
                {
                    requestError && <div className={classes.error}>
                        { requestError }
                    </div>
                }
                <Button className={cn(classes.submit, {[classes.submitError]: !!requestError})} variant="contained" type="submit">
                    {
                        !loading ? 'Войти'
                        : <CircularProgress size={30} style={{color: "white", marginRight: '5px'}} />
                    }
                </Button>

            </form>
        )}
    />
}

export default LoginForm;
