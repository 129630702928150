import {Box, Button, Divider, Grid, LinearProgress} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {getAllCompanies, getAllKMs, getAllSSKs, getKMByID, getSSKByID} from "../../api/commonApi";
import {CompanyInterface, KMInterface, RoleIdsEnum, SSKInterface} from "../../common/commonTypes";
import UserCard from "./UserCard";
import {AddUserModal} from "./AddUserModal";
import {AddCompanyModal} from "./AddCompanyModal";
import {UpdateUserModal} from "./EditUserModal";

export default function AdminPage(props: any) {
    const [isOpenAddUser, setIsOpenAddUser] = useState(false);
    const handleOpenAddUserModal = () => setIsOpenAddUser(true);
    const handleCloseAddUserModal = () => {
        setIsOpenAddUser(false)
        fetchAllUsers()
    };

    const [isOpenUpdateUser, setIsOpenUpdateUser] = useState(false);
    const handleOpenUpdateUserModal = () => setIsOpenUpdateUser(true);
    const handleCloseUpdateUserModal = () => {
        setIsOpenUpdateUser(false)
        fetchAllUsers()
    };

    const [isOpenAddCompany, setIsOpenAddCompany] = useState(false);
    const handleOpenAddCompanyModal = () => setIsOpenAddCompany(true);
    const handleCloseAddCompanyModal = () => {
        setIsOpenAddCompany(false)
        fetchAllCompanies()
    };

    const [loading, setLoading] = useState(true);
    const [more, setMore] = useState(true);

    const [updatingUser, setUpdatingUser] = useState<KMInterface | SSKInterface>();
    const [kms, setKMs] = useState<KMInterface[]>([]);
    const [ssks, setSSKs] = useState<SSKInterface[]>([]);
    const [companies, setCompanies] = useState<Record<string, CompanyInterface>>({})

    const fetchAllUsers = useCallback(() => {
        setLoading(true)
        getAllKMs(100).then(r => {
            setLoading(true)
            setKMs(r)
        }).finally(() => {
            setLoading(false)
        })
        getAllSSKs(100).then(r => {
            setLoading(true)
            setSSKs(r)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const fetchAllCompanies = useCallback(() => {
        setLoading(true)
        getAllCompanies(5000).then(r => {
            let res: Record<string, CompanyInterface> = {}
            for (const c of r) {
                res[c.id] = c
            }
            setCompanies(res)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        fetchAllUsers()
        fetchAllCompanies()
    }, [])

    const handleEditUser = async (userID: string, roleID: number): Promise<void> => {
        let kmOrSSK: KMInterface | SSKInterface;
        switch (roleID) {
            case RoleIdsEnum.KM:
            case RoleIdsEnum.SUPERVISOR_KM:
                kmOrSSK = await getKMByID(userID)
                break;
            case RoleIdsEnum.SSK:
            case RoleIdsEnum.SUPERVISOR_SSK:
                kmOrSSK = await getSSKByID(userID)
                break;
            default:
                return
        }
        setUpdatingUser(kmOrSSK);
        handleOpenUpdateUserModal();
    }

    const handleDeleteUser = () => {

    }

    return (
        <Box>
            <h1>Администрирование системы AIMA</h1>

            {/* users */}
            <Box sx={{textAlign: 'center'}}>
                <h2>
                    Пользователи
                </h2>
                <Box>
                    <Button variant="outlined" onClick={handleOpenAddUserModal}>Создать пользователя</Button>
                    <Button variant="outlined" onClick={handleOpenAddCompanyModal}>Создать компанию</Button>
                </Box>
                <Grid container spacing={3} pt={3}>
                    {
                        loading &&
                        <Box sx={{width: '100%'}}>
                            <LinearProgress/>
                        </Box>
                    }
                    {
                        !loading && kms.length > 0 &&
                        kms.map((km) => (
                            <Grid item xs={"auto"}>
                                <UserCard user={km.user} company={km.company}
                                          onEditUser={handleEditUser}
                                          onDeleteUser={handleDeleteUser}/>
                            </Grid>
                        ))
                    }
                </Grid>
                <Divider sx={{pt: 3}}/>
                <Grid container spacing={3} pt={3}>
                    {
                        !loading && ssks.length > 0 &&
                        ssks.map((ssk) => (
                            <Grid item xs={"auto"}>
                                <UserCard user={ssk.user} company={ssk.company}
                                          onEditUser={handleEditUser}
                                          onDeleteUser={handleDeleteUser}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>

            <AddUserModal companies={companies} isOpen={isOpenAddUser} onClose={handleCloseAddUserModal}/>
            {
                updatingUser &&
                <UpdateUserModal kmOrSSK={updatingUser} companies={companies} isOpen={isOpenUpdateUser} onClose={handleCloseUpdateUserModal}/>
            }
            <AddCompanyModal isOpen={isOpenAddCompany} onClose={handleCloseAddCompanyModal}/>
        </Box>
    )
}