import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import classes from "./Modal.module.scss";
import TextField from '@mui/material/TextField';
import cn from "classnames"
import {
    checkINN,
    // @ts-ignore
} from 'ru-validation-codes'
import {getFromKontur} from "../../api/commonApi";
import {useModal} from "../../hooks/useModal";
import {ChooseFormData, DaDataData} from "../../common/commonTypes";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from "@mui/icons-material/Close";



// проверка серии паспорта
function isPassSerValid(val: string) {
    if (val.match(/^\d{4}$/)) {
        return true;
    }
    else { return false; }
}

function isPassNumValid(val: string) {
    if (val.match(/^\d{6}$/)) {
        return true;
    }
    else { return false; }
}

function ChooseOrganization (props: { onClose?: () => void, ref?: any, data: Array<DaDataData>, onChooseItem: (idx: number) => void }) {
    return <div className={classes.chooseOrgModal}>
        <CloseIcon className={classes.closeIcon} onClick={props.onClose} />
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <div style={{fontSize: "18px", fontWeight: "bold"}}>Выберите организацию</div>
            <div>Найдено несколько совпадений</div>
        </div>

        <div className={classes.orgList}>
            {
                props.data.map((kr, idx) => {
                    return (
                        <div className={classes.orgItem} onClick={() => props.onChooseItem(idx)}>
                            <div>
                                <span style={{fontWeight: "bold"}}>ОГРН:</span> { kr.data.ogrn }
                            </div>
                            {
                                kr.value && <div>
                                    <span style={{fontWeight: "bold"}}>Наименование:</span> { kr.value }
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    </div>
}


export default function CreateNewClientChoose (props: { onClose?: () => void, onSubmit: (value: string, formData: ChooseFormData, konturResp?: DaDataData) => void, ref?: any }) {

    const modalContentRef = useRef(null);
    const { toggle: toggleModal, Modal, visible: modalVisible } = useModal(modalContentRef);

    const [konturLoading, setKonturLoading] = useState(false);

    const onSubmit = async () => {

        if(state === "legal") {
            if(inn.length < 10) {
                setError("ИНН должен быть 10 или 12");
                return;
            } if(inn.length === 11) {
                setError("ИНН должен быть 10 или 12");
                return;
            }  else if(inn.length <= 12 && !checkINN(inn)) {
                setError("Не верный ИНН");
                return;
            } else if(inn.length > 13) {
                setError("ИНН должен быть 10 или 12");
                return;
            }

            try {
                setKonturLoading(true);
                const resp = await getFromKontur(inn);
                if(!Array.isArray(resp.data.suggestions) || !resp.data.suggestions.length) {
                    props.onSubmit(state, { inn: inn }, undefined);
                } else if (resp.data.suggestions.length === 1) {
                    props.onSubmit(state, { inn: inn }, resp.data.suggestions[0]);
                } else {
                    // @ts-ignore
                    setKonturResp(resp.data.suggestions);
                }
                toggleModal();
            } catch (e) {
                if(inn.length === 10 || inn.length === 12) {
                    props.onSubmit(state, { inn: inn }, undefined);
                }
            } finally {
                setKonturLoading(false);
            }
        } else {
            if(!isPassSerValid(passportSeries) || !isPassNumValid(passportNumber)) {
                setError('Не верная серия или номер паспорта')
            } else {
                props.onSubmit(state, { passport_series: passportSeries, passport_number: passportNumber }, undefined)
            }
        }




    }

    const [state, setState] = useState("legal");
    const [inn, setInn] = useState("");
    const [passportSeries, setPassportSeries] = useState("");
    const [passportNumber, setPassportNumber] = useState("");
    const [error, setError] = useState("");
    const [konturResp, setKonturResp] = useState([]);


    const onChooseItem = (idx: number) => {
        toggleModal();
        //props.onClose && props.onClose();
        if(state === "legal") {
            if(inn.length === 10 || inn.length === 12) {
                props.onSubmit(state, { inn: inn }, konturResp[idx]);
            }
        }
    }

    return (
        <div className={classes.root}>

            <Modal>
                <ChooseOrganization ref={modalContentRef} data={konturResp} onChooseItem={onChooseItem} onClose={toggleModal} />
            </Modal>

            {
                !modalVisible && <div className={classes.chooseRoot}>
                    <div className={classes.chooseHeader}>
                        Добавление нового клиента
                    </div>
                    <div className={classes.chooseContent}>
                        <div className={classes.chooseBtnContainer}>
                            <div className={cn(classes.chooseBtn, {[classes.chooseBtnActive]: state == "legal"})} onClick={() => setState("legal")}>
                                Юридическое лицо
                            </div>
                            <div className={cn(classes.chooseBtn, {[classes.chooseBtnActive]: state == "physical"})} onClick={() => setState("physical")}>
                                Физическое лицо
                            </div>
                        </div>
                        <div className={classes.chooseInnText}>
                            { state == "legal" ?  'Для продолжения введите ИНН' : 'Для продолжения введите серию и номер паспорта' }
                        </div>

                        {
                            state == "legal"
                                ? <TextField className={classes.chooseInnField} placeholder={ 'ИНН'} variant="standard" onChange={(e) => setInn(e.target.value)} error={!!error} />
                                : <div style={{display: "flex", gridGap: "20px"}}>
                                    <TextField value={passportSeries} className={classes.chooseInnField} type="number" placeholder={'Серия паспорта'} variant="standard" onChange={(e) => setPassportSeries(e.target.value)} />
                                    <TextField value={passportNumber} className={classes.chooseInnField} type="number" placeholder={'Номер паспорта'} variant="standard" onChange={(e) => setPassportNumber(e.target.value)} />
                                </div>

                        }

                        <div className={classes.error}>
                            { error && error}
                        </div>


                        <div className={classes.chooseSubmitContainer}>
                            <div className={classes.chooseCancel} onClick={props.onClose}>
                                Отмена
                            </div>
                            <div className={cn(classes.chooseSubmit, {[classes.chooseSubmitDisabled]: konturLoading})} onClick={() => onSubmit()}>
                                {
                                    konturLoading ? <CircularProgress size={25} style={{color: 'white'}} />
                                    : 'Далее'
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}