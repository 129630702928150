import React, {InputHTMLAttributes, MouseEventHandler, useEffect, useState} from 'react';
import classes from "./AddButton.module.scss";
import CancelIcon from '@mui/icons-material/Cancel';
import cn from "classnames"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface ClearButtonProps {
    title: string;
}

export default function AddButton(props: ClearButtonProps) {

    return (
        <div className={classes.root}
        >
            { props.title }
            <AddCircleOutlineIcon style={{ height: '20px', width: "20px", marginLeft: "3px", color: '#3DD598' }} />
        </div>
    );
}