import React, {useEffect, useState} from 'react';
import classes from "./EditOfferForm.module.scss"
import {QuotationStatusesEnum, RequestStatusEnum} from "../../common/commonTypes";
import FormBuilder, {TabFormInterface} from "../FormBuilder/FormBuilder";
import {deleteOfferFile, downloadFileById, getInsuranceObject, getOffer, updateOffer} from "../../api/commonApi";
import {useAppSelector} from "../../store/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchInsuranceObjects} from "../../store/features/insuranceObjects";
import {useDispatch} from "react-redux";
import cn from "classnames";
import {fetchQuotes, resetPagination, setScrollIndex} from "../../store/features/quotesTable";
import {separateThousands} from "../../common/lib";

const onSubmit = async (data: any) => {
    //console.log(data)
}

const Delimeter = (props: { title: string, className?: any }) => {
    return <div className={props.className}>{props.title}</div>
}


const physicalData: TabFormInterface = {
    tabs: [

        {
            key: "root",
            label: "Предложения",
            fields: [
                {
                    namedGroup: {
                        class: cn(classes.offerSubGroup),
                        name: "",
                        fields: [
                            {
                                type: "date",
                                key: "date",
                                label: "Дата",
                                required: true,
                                disabled: true,
                                validate: (val: string) => true,
                            },
                            {
                                type: "number",
                                key: "insurance_award",
                                label: "Страховая премия",
                                required: true,
                                validate: (val: string) => true,
                                onRealChange: (form, v, idx) => {
                                    if (v) {
                                        // TARIF=SP*100/SS
                                        // @ts-ignore
                                        let val = parseFloat(parseInt(v) * 100 / parseInt(form.insurance_sum)).toFixed(4)

                                        if (val) {
                                            // @ts-ignore
                                            window.setFormValue('tarif', val);
                                        }
                                    }
                                }
                            },
                            {
                                type: "string",
                                key: "tarif",
                                label: "Тариф (%)",
                                required: (form) => {
                                    try {
                                        const t = parseInt(form.tarif)
                                        if (t < 0 || t > 100) {
                                            return "0 > Тариф < 100"
                                        }
                                        return true
                                    } catch (e) {
                                        return "Некорректный тариф, корретный, например: 0.1000 или 91.0000"
                                    }
                                }, validate: (val: string) => true,
                                onRealChange: (form, v, idx) => {
                                    v = parseFloat(v)
                                    // SP=SS*TARIF/100
                                    // @ts-ignore
                                    const val = parseInt(parseInt(form.insurance_sum) * parseFloat(v) / 100)

                                    // @ts-ignore
                                    window.setFormValue('insurance_award', val);
                                    // @ts-ignore
                                    window.setFormValue('tarif', v);
                                }
                            },
                            {
                                type: "string",
                                key: "comment",
                                label: "Комментарий",
                                required: false,
                                validate: (val: string) => true,
                            },
                            {
                                type: "string",
                                key: "account_needs",
                                label: "Для договора необходимо:",
                                required: false,
                                validate: (val: string) => true,
                            },
                            {
                                type: "checkbox",
                                key: "is_franchise_exist",
                                label: "Франшиза",
                                required: false,
                                validate: (val: string) => true,
                            },
                            {
                                type: "number",
                                key: "franchise_price",
                                label: "Стоимость франшизы",
                                value: 0,
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: any): any => {
                                    return form && Object.keys(form).length > 0 && form.hasOwnProperty("is_franchise_exist") && form.is_franchise_exist === true
                                },
                            },
                            {
                                type: "checkbox",
                                key: "is_franchise_conditional",
                                label: "Условная франшиза",
                                value: false,
                                tooltip: "Стоит галочка - условная, нет галочки - безусловная",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: any): any => {
                                    return form && Object.keys(form).length > 0 && form.hasOwnProperty("is_franchise_exist") && form.is_franchise_exist === true
                                },
                            }
                        ]
                    }
                },

                {
                    type: "files",
                    key: "files",
                    label: "Добавить файлы",
                    required: false,
                    validate: (val: string) => true,
                },
            ]

        },

    ],
}


interface InsuranceObjectFormProps {
    offerId: string,
    quoteId: string,
    quotationStatusId: string,
    onClose?: () => void,
}


// @ts-ignore
const EditOfferForm = (props: InsuranceObjectFormProps) => {

    const dispatch = useDispatch();

    const {activeClientId} = useAppSelector((state) => state.clientsTable);
    const {activeId} = useAppSelector((state) => state.insuranceObjects);
    const insuranceObjectActiveId = useAppSelector((state) => state.insuranceObjects.activeId);

    const [initVals, setInitVals] = useState({});

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [lazyTitle, setTitle] = useState("Редактирование предложения");

    useEffect(() => {
        setLoading(true);

        //console.log('quotationStatusId', props.quotationStatusId.toString() === QuotationStatusesEnum.APPLY.toString())
        getOffer(props.offerId)
            .then((offer) => {
                getInsuranceObject(insuranceObjectActiveId).then((io) => {
                    // @ts-ignore
                    let obj = _.cloneDeep(offer.data)
                    const insStr = JSON.stringify(io.data.insurance_sum);
                    obj.insurance_sum = io.data.insurance_sum
                    setInitVals(obj);
                    // @ts-ignore
                    setTitle("Редактирование предложения для страховой суммы " + separateThousands(insStr) + " руб.")
                })
            })
            .finally(() => setLoading(false));
    }, [])

    const onSubmit = async (values: any) => {
        setSaving(true);
        const vals = {...values};

        delete vals.chat;

        await updateOffer(props.offerId, vals);

        await dispatch(fetchInsuranceObjects({clientId: activeClientId}));

        await dispatch(resetPagination());
        // @ts-ignore
        await dispatch(setScrollIndex(0));
        await dispatch(fetchQuotes({skip: 0, take: 40, activeInsuranceId: activeId}));

        setSaving(false);
        props.onClose && props.onClose();

    }

    const [initValues, setInitValues] = useState({});

    //const [status, setStatus] = useState(props.quoteId === "" ? RequestStatusEnum.Idle : RequestStatusEnum.Loading);
    const [status, setStatus] = useState(RequestStatusEnum.Idle);


    const onDeleteFile = async (fileId: string) => {
        await deleteOfferFile(props.offerId, fileId);
    }


    if (loading) {
        return <div
            style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size={100}/>
        </div>
    } else {
        return (
            <div>
                <FormBuilder
                    onClose={props.onClose}
                    onDeleteFile={onDeleteFile}
                    initialValues={initVals}
                    onSubmit={onSubmit}
                    data={physicalData}
                    useTab={false}
                    disableAll={props.quotationStatusId.toString() === QuotationStatusesEnum.APPLY.toString()}
                    loading={saving}
                    title={lazyTitle}
                    onDownloadFile={async (id: string) => {
                        await downloadFileById(id)
                    }}
                />
            </div>
        )
    }

}

export default EditOfferForm;
