import React from 'react';
import classes from "./HeaderLink.module.scss";

import {Link, LinkProps} from "react-router-dom"

interface HeaderLinkProps extends LinkProps{
    icon?: any,
}

export default function HeaderLink(props: HeaderLinkProps) {

    return (
        <Link {...props} className={classes.root} >
            <div className={classes.icon}> { props.icon } </div>
            <div>
                { props.children }
            </div>
        </Link>
    );
}