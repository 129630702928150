import React, {useEffect, useState} from 'react';
import classes from "./SplitPaneWrapper.module.scss";
import PlusIconSvg from "../icons/PlusIconSvg";
import cn from "classnames"
import {forEach} from "lodash";

interface SplitPaneWrapperProps {
    tableName?: string;
    onTableBtnClick?: void;
    children?: any;
    onAddClick?: () => void,
    addButtonActive?: boolean,
    showFooter?: boolean,
    downloadPdf?: () => void,
    tableSum?: any;
}

export default function SplitPaneWrapper({...props}: SplitPaneWrapperProps) {

    const addActive = props.addButtonActive == undefined || props.addButtonActive;

    function renderObj(obj: any) {

        const arr = [];
        for (let [k, v] of Object.entries(obj)) {
            arr.push( <div>{k + ": " + v + "    "}</div>);
        }
        return (
            <div className={classes.tableName} style={{display: "flex"}}>
                <div style={{marginRight: "15px"}}>{arr.slice(0, 2)}</div>
                <div>{arr.slice(2)}</div>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.tableBar}>
                <div className={classes.tableName} >{props.tableName}</div>
                {
                    props.onAddClick ?
                        <div className={cn(classes.tableBtn, {[classes.tableBtnDisabled]: !addActive})} onClick={props.onAddClick} >
                            <PlusIconSvg width={16} height={16} />
                            Добавить
                        </div>
                        : null
                }
                {
                    props.tableSum ?  renderObj(props.tableSum) : null
                }
                {
                    props.downloadPdf ?
                        <div className={cn(classes.tableBtn, {[classes.tableBtnDisabled]: !addActive})} onClick={props.downloadPdf}>
                            PDF
                        </div>
                        : null
                }
            </div>

                { props.children }



            {
                (props.showFooter === undefined || props.showFooter)
                && <div className={classes.footer}></div>
            }
        </div>
    );
}