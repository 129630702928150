import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import ClientForm from "../../forms/ClientForm/ClientForm";
import classes from "./Modal.module.scss";
import {ChooseFormData, DaDataData} from "../../common/commonTypes";




export default function CreateClientModal (props: { onClose?: () => void, ref?: any, clientId: string, chooseForm?: ChooseFormData, kontur?: DaDataData }) {

    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <ClientForm onClose={props.onClose} clientId={props.clientId} chooseForm={props.chooseForm} kontur={props.kontur} />
            </div>
        </div>
    );
}