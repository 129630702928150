import React, {ChangeEvent, Component, Fragment} from 'react';

import Select, {StylesConfig} from 'react-select';
import {ColourOption} from '../StatusSelect/data';
import {OptionInterface} from "../../../common/commonTypes";


interface State {
    isClearable: boolean;
    isDisabled: boolean;
    isLoading: boolean;
    isRtl: boolean;
    isSearchable: boolean;
}


type FormatOptionLabel = (label: any, data: any) => any;

interface SingleSelectProps {
    height?: number,
    width?: number,
    placeholder?: string,
    options?: Array<any>,
    onChange?: any;
    value?: any,
    formatOptionLabel?: FormatOptionLabel,
    menuStyles?: any,
    rffRest?: any,
    rffInput?: any,
    isClearable?: boolean,
    isOperatorSelect?: boolean,
    defaultDropdownIndicator?: boolean,
    disabled?: boolean,
}


export default function SingleSelect(props: SingleSelectProps) {

    const colourStyles: StylesConfig<ColourOption> = {
        control: (styles) => ({ ...styles,
            backgroundColor: 'white',
            width: props.width ? props.width : "100%",
            height: props.height ? props.height : 25,
            border: "1px solid #C6D9FF",
            borderRadius: props.isOperatorSelect ? "5px 0px 0px 5px" : styles.borderRadius,
            minHeight: 25,
            fontSize: props.isOperatorSelect ? 14 : 12,
        }),
        indicatorSeparator: (styles) => ({ ...styles, height: props.height ? "100%" : '60%', marginTop: props.height ? "0px" : '4.5px', display: props.isOperatorSelect ? 'none' : styles.display}),
        clearIndicator: (styles) => ({ ...styles,
            padding: "0 1px",
            width: 13,
            height: 13,
            background: "#FC5A5A",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "4px",
            color: "white",
            cursor: "pointer"
        }),
        dropdownIndicator: !props.defaultDropdownIndicator ? (styles) => ({ ...styles, padding: "0 1px", width: props.isOperatorSelect ? 17 : (props.height ? styles.width : 24)}) : (styles) => ({ ...styles,}),
        indicatorsContainer: (styles) => ({ ...styles, height: props.height ? "100%" : 24, padding: 0}),
        valueContainer: (styles) => ({ ...styles, top: props.height ? styles.top : '-4px', left: props.height ? styles.left : '-5px'}),
        menu: (styles) => ({ ...styles, ...props.menuStyles, zIndex: 10000}),
        input: (styles) => ({...styles, width: props.isOperatorSelect ? 20 : styles.width }),
    };

    return (
        <Fragment>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={props.disabled}
                isLoading={false}
                isClearable={props.isClearable === undefined ? true : props.isClearable}
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
                options={props.options ? props.options : []}
                styles={colourStyles}
                formatOptionLabel={props.formatOptionLabel ? props.formatOptionLabel : undefined}
                {...props.rffInput}
                {...props.rffRest}
            />

        </Fragment>
    );
}
