import React, {InputHTMLAttributes, useEffect, useState} from 'react';
import classes from "./Input.module.scss";
import CancelIcon from '@mui/icons-material/Cancel';
import cn from "classnames"

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
    className?: string,
    withSelect?: boolean,
    showClear?: boolean,
}

export default function Input(props: InputProps) {

    return (
        <div className={cn(classes.root, props.className)}
             style={{
                 width: typeof props.width == "number" ? `${(props.width)}px` : "40px",
                 minWidth: typeof props.width == "number" ? `${(props.width)}px` : "40px"
             }}
        >
            <input {...props}
                   type="text" className={cn(classes.input, {[classes.inputClearOff]: (!props.value && !props.showClear), [classes.withSelect]: (props.withSelect && !props.value), [classes.withSelectActive]: (props.withSelect && (props.value || props.showClear))})}
            />
            {
                (props.value || props.showClear) ? <div className={classes.clear} onClick={function () {
                    if(props.onChange != undefined) {
                        // @ts-ignore
                        props.onChange({target: { value: ""}});
                    }
                }}>
                    <CancelIcon style={{ height: '70%', color: '#FC5A5A' }} />
                </div>
                    : null
            }
        </div>
    );
}