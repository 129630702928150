import * as React from "react";

const PlusIconSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.99999 1.99984C4.68628 1.99984 1.99999 4.68613 1.99999 7.99984C1.99999 11.3135 4.68628 13.9998 7.99999 13.9998C11.3137 13.9998 14 11.3135 14 7.99984C14 4.68613 11.3137 1.99984 7.99999 1.99984ZM0.666656 7.99984C0.666656 3.94975 3.9499 0.666504 7.99999 0.666504C12.0501 0.666504 15.3333 3.94975 15.3333 7.99984C15.3333 12.0499 12.0501 15.3332 7.99999 15.3332C3.9499 15.3332 0.666656 12.0499 0.666656 7.99984Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.00001 4.6665C8.3682 4.6665 8.66668 4.96498 8.66668 5.33317V10.6665C8.66668 11.0347 8.3682 11.3332 8.00001 11.3332C7.63182 11.3332 7.33334 11.0347 7.33334 10.6665V5.33317C7.33334 4.96498 7.63182 4.6665 8.00001 4.6665Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.66666 8.00016C4.66666 7.63197 4.96513 7.3335 5.33332 7.3335H10.6667C11.0348 7.3335 11.3333 7.63197 11.3333 8.00016C11.3333 8.36835 11.0348 8.66683 10.6667 8.66683H5.33332C4.96513 8.66683 4.66666 8.36835 4.66666 8.00016Z" fill="white"/>
    </svg>
);

export default PlusIconSvg;
