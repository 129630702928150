import Axios, {AxiosError} from "axios";
import {
    ClientsResponse, CompanyInterface,
    ContactLegalInterface,
    CreateLegalInterface,
    CreateOfferInterface,
    CreatePhysicalInterface, DaDataAddressResponse, DaDataCarResponse, DaDataResponse,
    DealCreateInterface, DealFullInterface, DealsResponse,
    InsuranceObjectInterface, KMInterface,
    LoginInterface,
    QuotesResponse,
    RequisitesInterface,
    SignInResponseInterface, SSKInterface, UpdateDealInterface, UpdateIsPaidDealsPaymentScheduleInterface,
    UpdateOfferInterface,
    UserInterface
} from "../common/commonTypes";
import {downloadFile, formatDateForBackend} from "../common/lib";
import {CreateKMDTO, CreateSSKDTO} from "../features/admin/AddUserModal";
import {CreateCompanyDTO} from "../features/admin/AddCompanyModal";
import {UpdateUserDTO} from "../features/admin/EditUserModal";

const token = localStorage.getItem("access_token") || null;
if (token) Axios.defaults.headers.common.Authorization = `Bearer ${token}`;

export const baseUrl = process.env.NODE_ENV === "development"
    ? "http://localhost:10005/api"
    : "https://aima-backend.aima.biz/api";

export const api = Axios.create({
    baseURL: baseUrl,
    validateStatus: function (status) {
        return true;
    },
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
});

export async function getAllCompanies(limit: number): Promise<CompanyInterface[]> {
    const response = await api.get(`/companies?limit=${limit}`);
    if (response.status !== 200) {
        throw Error(JSON.stringify(response))
    }
    return response.data
}

export async function updateUser(userID: string, payload: UpdateUserDTO) {
    const response = await api.patch(`/users/${userID}`, payload);
    if (response.status !== 204) {
        throw Error(JSON.stringify(response))
    }
}

export async function createCompany(payload: CreateCompanyDTO) {
    const response = await api.post(`/companies`, payload);
    if (response.status !== 201) {
        throw Error(JSON.stringify(response))
    }
}

export async function createKM(payload: CreateKMDTO) {
    const response = await api.post(`/kms`, payload);
    if (response.status !== 201) {
        throw Error(JSON.stringify(response))
    }
}

export async function createSSK(payload: CreateSSKDTO) {
    const response = await api.post(`/ssks`, payload);
    if (response.status !== 201) {
        throw Error(JSON.stringify(response))
    }
}

export async function getKMByID(id: string): Promise<KMInterface> {
    const response = await api.get(`/kms/${id}`);
    if (response.status !== 200) {
        throw Error(JSON.stringify(response))
    }
    return response.data
}

export async function getSSKByID(id: string): Promise<SSKInterface> {
    const response = await api.get(`/ssks/${id}`);
    if (response.status !== 200) {
        throw Error(JSON.stringify(response))
    }
    return response.data
}


export async function getAllKMs(limit: number): Promise<KMInterface[]> {
    const response = await api.get(`/kms?limit=${limit}`);
    if (response.status !== 200) {
        throw Error(JSON.stringify(response))
    }
    return response.data
}

export async function getAllSSKs(limit: number): Promise<SSKInterface[]> {
    const response = await api.get(`/ssks?limit=${limit}`);
    if (response.status !== 200) {
        throw Error(JSON.stringify(response))
    }
    return response.data
}

export async function signInRequest(data: LoginInterface) {
    return await api.post<UserInterface>("/auth", data);
}

export async function updateLegalClient(id: string, data: CreateLegalInterface) {

    let requests: any = [];

    if (data.client && data.client.hasOwnProperty("comment") && data.client) {
        // @ts-ignore
        requests.push(api.patch(`/clients/${id}`, {comment: data.client.comment}));
    }

    if (data.client && data.client.hasOwnProperty('contacts') && data.client.contacts.length) {
        //@ts-ignore
        requests = data.client.contacts.filter(c => c.hasOwnProperty('id') && !!c.id).map(c => updateClientContacts(id, c.id, c));
        requests = [...requests, ...data.client.contacts.filter(c => !c.hasOwnProperty('id') || !c.id).map(c => createClientContacts(id, c))];
    }

    if (data.client && data.client.hasOwnProperty('requisites')) {
        //@ts-ignore
        requests = [...requests, ...data.client.requisites.filter(c => c.hasOwnProperty('id') && !!c.id).map(c => updateClientRequisites(id, c.id, c))]
        requests = [...requests, ...data.client.requisites.filter(c => !c.hasOwnProperty('id') || !c.id).map(c => createClientRequisites(id, c))];
    }


    await Promise.all([
        api.patch<SignInResponseInterface>(`/legal-clients/${id}`, data), //@ts-ignore
        ...requests,
    ])
}


export async function updatePhysicalClient(id: string, data: CreatePhysicalInterface) {

    let requests: any = [];

    if (data.client && data.client.hasOwnProperty("comment") && data.client) {
        // @ts-ignore
        requests.push(api.patch(`/clients/${id}`, {comment: data.client.comment}));
    }


    if (data.client && data.client.hasOwnProperty('contacts') && data.client.contacts.length) {
        //@ts-ignore
        requests = data.client.contacts.filter(c => c.hasOwnProperty('id') && !!c.id).map(c => updateClientContacts(id, c.id, c));
        requests = [...requests, ...data.client.contacts.filter(c => !c.hasOwnProperty('id') || !c.id).map(c => createClientContacts(id, c))];
    }

    if (data.client && data.client.hasOwnProperty('requisites')) {
        //@ts-ignore
        requests = [...requests, ...data.client.requisites.filter(c => c.hasOwnProperty('id') && !!c.id).map(c => updateClientRequisites(id, c.id, c))]
        requests = [...requests, ...data.client.requisites.filter(c => !c.hasOwnProperty('id') || !c.id).map(c => createClientRequisites(id, c))];
    }


    await Promise.all([
        api.patch<SignInResponseInterface>(`/physical-clients/${id}`, data), //@ts-ignore
        ...requests,
    ])
}


export async function getInsuranceObject(insuranceObjectsId: string) {
    return await api.get(`/insurance-objects/${insuranceObjectsId}`);
}

export async function updateInsuranceObject(insuranceObjectsId: string, data: InsuranceObjectInterface) {
    const existedIOFiles = await api.get(`/insurance-objects/${insuranceObjectsId}/files`);

    const response = await api.patch(`/insurance-objects/${insuranceObjectsId}`, data);

    let originalFilesIDS = []
    for (const ioF of existedIOFiles.data) {
        originalFilesIDS.push(ioF.id)
    }


    let changedFiles = data.files.filter(f => f.hasOwnProperty('id'));

    let changedFileIDS = []
    for (const ioF of changedFiles) {
        // @ts-ignore
        changedFileIDS.push(ioF.id)
    }

    for (const ioFID of originalFilesIDS) {
        if (!changedFileIDS.includes(ioFID)) {
            await api.delete(`/insurance-objects/${insuranceObjectsId}/files/${ioFID}`);
        }
    }

    let files = data.files;
    if (files === undefined || files.length === 0) {
        console.debug("no files were updated")
        return response
    }

    files = files.filter(f => !f.hasOwnProperty('id'));

    if (files === undefined || files.length === 0) {
        console.debug("no files were updated")
        return response
    }

    //console.log('files', files);
    const formData = new FormData();
    for (let file of files) {
        formData.append("m_files", file);
    }


    const config = {headers: {'Content-Type': 'multipart/form-data'}};
    const responseFile = await api.post(`/files`, formData, config);
    const ids = responseFile.data.split(',');
    for (let fileId of ids) {
        const responseIOFile = await api.post(`/insurance-objects/${insuranceObjectsId}/files`, {
            file_id: fileId,
            insurance_object_id: insuranceObjectsId,
        });
    }
    return response
}

export async function updateInsuranceObjectStatus(insuranceObjectsId: string, data: { status_id: number }) {
    return await api.patch(`/insurance-objects/${insuranceObjectsId}`, data);
}

export async function getInsuranceObjects(clientId: string) {
    return await api.get(`/clients/${clientId}/insurance-objects?limit=10000`);
}


export async function deleteClientContact(clientId: string, contactId: string) {
    return await api.delete(`/clients/${clientId}/contacts/${contactId}`);
}

export async function deleteClientRequisites(clientId: string, requisitesId: string) {
    return await api.delete(`/clients/${clientId}/requisites/${requisitesId}`);
}

export async function addInsuranceObjectFile(ioID: string, fileID: string) {
    await api.post(`/insurance-objects/${ioID}/files`, {
        file_id: fileID,
        insurance_object_id: ioID
    })
}

export async function createInsuranceObject(clientId: string, data: InsuranceObjectInterface) {
    const response = await api.post(`/clients/${clientId}/insurance-objects`, {...data, client_id: clientId});
    if (!response.headers.location) {
        console.error("no location header")
        return response
    }

    const sp = response.headers.location.split('/');
    const ioID = sp[sp.length - 1];

    const files = data.files;
    if (files === undefined || files.length === 0) {
        console.debug("no files to upload")
        return response
    }

    //console.log('files', files);
    const formData = new FormData();
    for (let file of files) {
        formData.append("m_files", file);
    }


    const config = {headers: {'Content-Type': 'multipart/form-data'}};
    const responseFile = await api.post(`/files`, formData, config);
    const ids = responseFile.data.split(',');
    for (let fileId of ids) {
        const responseIOFile = await api.post(`/insurance-objects/${ioID}/files`, {
            file_id: fileId,
            insurance_object_id: ioID,
        });
    }
    return response
}


export async function getQuotation(quoteId: string) {
    return await api.get(`/quotations/${quoteId}`);
}

export async function getOffer(offerId: string) {
    return await api.get(`/offers/${offerId}`);
}


export async function deleteOfferFile(offerId: string, fileId: string) {
    await api.delete(`/offers/${offerId}/files/${fileId}`);
}

export async function deleteDealFile(dealId: string, fileId: string) {
    await api.delete(`/deals/${dealId}/files/${fileId}`);
}

export async function getFromKontur(inn: string) {
    return await api.get<DaDataResponse>(`/kontur/ul?inn=${inn}`);
}

export async function findDaDataAddress(text: string) {
    return await api.get<DaDataAddressResponse>(`/dadata/address?text=${encodeURIComponent(text)}`);
}

export async function findDaDataCar(text: string) {
    return await api.get<DaDataCarResponse>(`/dadata/car?text=${encodeURIComponent(text)}`);
}

export async function getDealsPaymentSchedule(dealId: string) {
    return await api.get(`/deals/${dealId}/payments`);
}

export async function updateIsPaidStatusDealsPaymentSchedule(dealId: string, paymentId: string,  data: UpdateIsPaidDealsPaymentScheduleInterface) {
    const response = await api.patch(`/deals/${dealId}/payments/${paymentId}`, data);
}

export async function getDealsFile(dealId: string) {
    return await api.get(`/deals/${dealId}/files`);
}


export async function getFileById(fileId: string) {
    return await api.get(`/files/${fileId}`,);
}

export async function deleteFileById(fileId: string) {
    return await api.delete(`/files/${fileId}`,);
}


export async function downloadFileById(fileId: string) {
    const response = await api.get(`/download/${fileId}`, {responseType: 'blob'});

    await downloadFile(response);
    //console.log('resp file',  response.data);
}

export async function updateOfferStatus(offerId: string, data: UpdateOfferInterface) {
    const response = await api.patch(`/offers/${offerId}`, data);
}

export async function updateDealStatus(offerId: string, data: UpdateDealInterface) {
    const response = await api.patch(`/deals/${offerId}`, data);
}


export async function safeFiles(files: Array<File>) {
    const formData = new FormData();
    for (let file of files) {
        formData.append("m_files", file);
    }


    const config = {headers: {'Content-Type': 'multipart/form-data'}};
    const responseFile = await api.post(`/files`, formData, config);
    return responseFile.data.split(',');
}

export async function createDeal(quotationId: string, data: DealCreateInterface) {
    data.contract_date = formatDateForBackend(data.contract_date);
    data.start_date = formatDateForBackend(data.start_date);
    data.end_date = formatDateForBackend(data.end_date);
    data.date = formatDateForBackend((new Date()));

    let files: Array<File> = [];

    if (data.files) {
        files = [...data.files]
    }

    // @ts-ignore
    delete data.files;

    // @ts-ignore
    data.status_id = 1;
    // @ts-ignore
    data.number = 1;


    if (data.contract_file && data.contract_file.length) {
        const contractFileResp = await safeFiles(data.contract_file);
        // @ts-ignore
        delete data.contract_file
        // @ts-ignore
        data.contract_file_id = contractFileResp[0];
    }

    if (data.account_file && data.account_file.length) {
        const accountFileResp = await safeFiles(data.account_file);
        // @ts-ignore
        delete data.account_file;
        // @ts-ignore
        data.account_file_id = accountFileResp[0];
    }

    const response = await api.post(`/deals`, {...data, quotation_id: quotationId});
    if (!response.headers.location) {
        return
    }

    const sp = response.headers.location.split('/');
    const dealId = sp[sp.length - 1];

    if (!files || files.length === 0) {
        return
    }

    const ids = await safeFiles(files);

    for (let fileId of ids) {
        const responseOfferFile = await api.post(`/deals/${dealId}/files`, {
            file_id: fileId,
            deal_id: dealId,
        });
    }
}


const clearFieldsArray = [
    "id",
    "status_id",
    "quotation_id",
    "date",
    "client_name",
    "type_id",
    "client_opf_type_id",
    "insurance_company_name",
    "insurance_object_type_id",
    "km_name",
    "pay_date",
    "contract_number",
    "contract_file_id",
    "account_file_id",
    "created_at",
    "updated_at",
    //"files",
    //"contract_file",
]

export async function updateDeal(dealId: string, data: DealCreateInterface, prevData: DealCreateInterface | null) {

    data.contract_date = formatDateForBackend(data.contract_date);
    data.start_date = formatDateForBackend(data.start_date);
    data.end_date = formatDateForBackend(data.end_date);

    const files = [...data.files].filter(f => !f.hasOwnProperty('id'));


    for (let k of clearFieldsArray) {
        if (data.hasOwnProperty(k)) {
            // @ts-ignore
            delete data[k]
        }
    }


    if (data.contract_file && data.contract_file.length && !data.contract_file[0].hasOwnProperty("id")) {
        //console.log('data.contract_file', data.contract_file)
        const contractFileResp = await safeFiles(data.contract_file);
        // @ts-ignore
        delete data.contract_file
        // @ts-ignore
        data.contract_file_id = contractFileResp[0];
    } else {
        // @ts-ignore
        delete data.contract_file
    }


    if (data.account_file && data.account_file.length && !data.account_file[0].hasOwnProperty("id")) {
        const accountFileResp = await safeFiles(data.account_file);
        // @ts-ignore
        delete data.account_file;
        // @ts-ignore
        data.account_file_id = accountFileResp[0];
    } else if (data.account_file && data.account_file.length === 0) {
        // @ts-ignore
        data.account_file_id = "";
        // @ts-ignore
        delete data.account_file;
    }

    const response = await api.patch(`/deals/${dealId}`, {...data});



    // @ts-ignore
    //console.log('test', data.account_file_id === "" && prevData.account_file_id, data.account_file_id, prevData.account_file_id )
    // @ts-ignore
    if (data.account_file_id === "" && prevData.account_file_id) {
        // @ts-ignore
        data.account_file_id = "";
        try {
            // @ts-ignore
            deleteFileById(prevData.account_file_id)
        } catch (e) {

        }
    }


    if (files.length === 0) {
        return
    }
    const ids = await safeFiles(files);

    for (let fileId of ids) {
        const responseOfferFile = await api.post(`/deals/${dealId}/files`, {
            file_id: fileId,
            deal_id: dealId,
        });
    }
}


export async function getDealTableRequest(queryString: string) {
    return api.get<DealsResponse>(`/deals${queryString}`,);
}


export async function getDealById(id: string) {
    return api.get<DealFullInterface>(`/deals/${id}`,);
}

export async function chatMessageNotify(chatID: string, text: string) {
    return api.get(`/notify/chat/${chatID}?message=${text}`)
}


export async function updateOffer(offerId: string, data: CreateOfferInterface) {
    data.date = formatDateForBackend(data.date);
    const response = await api.patch(`/offers/${offerId}`, {...data});
    let files = data.files;
    if (files === undefined || files.length === 0) {
        return
    }
    files = files.filter(f => !f.hasOwnProperty('id'));

    if (files === undefined || files.length === 0) {
        return;
    }

    const formData = new FormData();
    for (let file of files) {
        formData.append("m_files", file);
    }


    const config = {headers: {'Content-Type': 'multipart/form-data'}};
    const responseFile = await api.post(`/files`, formData, config);
    const ids = responseFile.data.split(',');
    for (let fileId of ids) {
        const responseOfferFile = await api.post(`/offers/${offerId}/files`, {
            file_id: fileId,
            offer_id: offerId,
        });
    }

}

export async function createOffer(quotationId: string, dataList: Array<CreateOfferInterface>) {
    for (let data of dataList) {
        data.date = formatDateForBackend(data.date);
        const response = await api.post(`/quotations/${quotationId}/offers`, {...data, quotation_id: quotationId});
        if (!response.headers.location) {
            // console.error("no location header")
            // console.debug('response', response);
            return
        }

        const sp = response.headers.location.split('/');
        const offerId = sp[sp.length - 1];

        const files = data.files;
        if (files === undefined || files.length === 0) {
            // console.debug('data', data);
            continue;
        }

        const formData = new FormData();
        for (let file of files) {
            formData.append("m_files", file);
        }


        const config = {headers: {'Content-Type': 'multipart/form-data'}};
        const responseFile = await api.post(`/files`, formData, config);
        const ids = responseFile.data.split(',');
        for (let fileId of ids) {
            const responseOfferFile = await api.post(`/offers/${offerId}/files`, {
                file_id: fileId,
                offer_id: offerId,
            });
        }
    }
}


export async function createClientRequisites(clientId: string, data: RequisitesInterface) {
    await api.post(`/clients/${clientId}/requisites`, {...data, client_id: clientId});
}

export async function updateClientRequisites(clientId: string, id: string, data: RequisitesInterface) {
    await api.patch<SignInResponseInterface>(`/clients/${clientId}/requisites/${id}`, data);
}

export async function createClientContacts(clientId: string, data: ContactLegalInterface) {
    await api.post<SignInResponseInterface>(`/clients/${clientId}/contacts`, {...data, client_id: clientId});
}

export async function updateClientContacts(clientId: string, id: string, data: ContactLegalInterface) {
    await api.patch<SignInResponseInterface>(`/clients/${clientId}/contacts/${id}`, data);
}

export async function createLegalClient(data: CreateLegalInterface) {
    let r = {
        id: "",
        err: null
    }
    const response = await api.post("/legal-clients", data);

    if (response.status == 201) {
        const sp = response.headers.location.split('/');
        r.id = sp[sp.length - 1]
    } else {
        r.err = response.data;
    }

    return r
}


export async function getLegalClient(id: string) {
    const response = await api.get<CreateLegalInterface>(`/legal-clients/${id}`);
    return response.data;
}

export async function getPhysicalClient(id: string) {
    const response = await api.get<CreatePhysicalInterface>(`/physical-clients/${id}`);
    return response.data;
}


export async function createPhysicalClient(data: CreateLegalInterface) {

    const response = await api.post<SignInResponseInterface>("/physical-clients", data);

    if (response.headers.location) {
        const sp = response.headers.location.split('/');
        return sp[sp.length - 1];
    } else {
        return "";
    }
}

export async function refreshTokenRequest() {
    const refreshToken = localStorage.getItem("refresh_token");

    const response = await api.put<SignInResponseInterface>("/auth", {}, {
        headers: {
            'Refresh-Token': refreshToken ? refreshToken : "",
        }
    });
    const jData = response.data;

    localStorage.setItem("access_token", jData.access_token);
    localStorage.setItem("refresh_token", jData.refresh_token);

}


export async function getClientsTableRequest(queryString: string) {
    return api.get<ClientsResponse>(`/clients${queryString}`,);
}

export async function getQuotesTableRequest(queryString: string, activeInsuranceId: string) {
    return api.get<QuotesResponse>(`/insurance-objects/${activeInsuranceId}/quotations${queryString}`,);
}

api.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error: AxiosError) {
        const status = error.response?.status || 500;
        if (status == 401) {
            try {
                await refreshTokenRequest();
                const token = localStorage.getItem("access_token") || null;
                if (error.config.headers != undefined) {
                    error.config.headers.Authorization = token ? `Bearer ${token}` : ''
                }
                return Axios.request(error.config)
            } catch (e) {
                localStorage.setItem("user", "");
                localStorage.setItem("access_token", "");
                localStorage.setItem("refresh_token", "");
                location.href = "/login" + window.location.search;
            }
        }

        return Promise.reject(error);
    }
);


api.interceptors.request.use(function (config) {
    const token = localStorage.getItem("access_token") || null;

    if (config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
});
