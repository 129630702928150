import React, {useEffect, useState} from 'react';
import classes from "./Purchases.module.scss";
import SplitPaneWrapper from "../SplitPaneWrapper/SplitPaneWrapper";
import InsuranceObjectItem from "../PurchaseObject/InsuranceObjectItem";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    InsuranceObjectInterface,
    InsuranceObjectStatusesEnum,
    RequestStatusEnum,
    RoleIdsEnum
} from "../../common/commonTypes";
import {
    fetchInsuranceObjects,
    fetchInsuranceObjectsAuto,
    setActiveId,
    setData,
    setFilterIOId
} from "../../store/features/insuranceObjects";
import {useInterval} from "../../hooks/useInterval";
import {reInit} from "../../store/features/quotesTable";
import {addInsuranceObjectFile, createInsuranceObject, getInsuranceObject} from "../../api/commonApi";
import _, {isUndefined} from "lodash";
import {toast, ToastContainer} from "react-toastify";


interface PurchasesProps {
    height: number,
    addOnClick?: () => void,
    onEdit?: (id: string) => void,
}


export default function InsuranceObjectList(props: PurchasesProps) {

    const activeClientId = useAppSelector((state) => state.clientsTable.activeClientId);
    const {data, blockAutoFetching, status, filters} = useAppSelector((state) => state.insuranceObjects,);
    //let status = RequestStatusEnum.Succeeded;

    const [clientChanged, setClientChanged] = useState(false);
    const [searchAddress, setSearchAddress] = useState("");

    useEffect(() => {
        setClientChanged(true);
    }, [activeClientId])

    useEffect(() => {
        if (status === RequestStatusEnum.Succeeded) {
            setClientChanged(false);
        }
    }, [status])


    const showAllHandler = () => {
        //dispatch(clearFilters());
        dispatch(setFilterIOId(""));
        dispatch(setActiveId(""));
        fetchInsurances(true)
        dispatch(setData([]));
        dispatch(reInit());
        //setActiveId, setData
        //clearFilters();
    }


    const dispatch = useAppDispatch();

    const fetchInsurances = (isAutoFetching?: boolean) => {
        if (activeClientId === "") {
            return
        }
        if (isAutoFetching) {
            dispatch(fetchInsuranceObjects({clientId: activeClientId}))
        } else {
            dispatch(fetchInsuranceObjectsAuto({clientId: activeClientId}))
        }
    }

    useEffect(() => {
            fetchInsurances(true);
        },
        [activeClientId])

    //useInterval(() => fetchInsurances(), 5000, () => tableScrollTop < 15 && !blockAutoFetching)
    useInterval(() => fetchInsurances(), 5000, () => !blockAutoFetching)

    const Content = () => {
        if (status == RequestStatusEnum.Idle) {
            return <div className={classes.chooseClient}>
                Выберите клиента в таблице слева
            </div>
        }
        if (status == RequestStatusEnum.Loading && clientChanged) {
            return <div className={classes.loadingContainer}>
                <CircularProgress size={30}/>
            </div>
        }
        if (data.length === 0) {
            return <div className={classes.chooseClient}>
                Для этого клиента ещё не создали объекты страхования
            </div>
        } else {
            //console.log('data', data)
            return <div>
                {
                    data.filter((d: InsuranceObjectInterface) => filters.id.length === 0 || filters.id === d.id)
                        .filter((d: InsuranceObjectInterface) => d.address.includes(searchAddress))
                        .map((io: InsuranceObjectInterface, key: number) => <InsuranceObjectItem insuranceObject={io}
                                                                                                 onCopy={copyIO}
                                                                                                 onEdit={props.onEdit}/>)
                }

                {
                    filters.id.length !== 0 &&
                    <div className={classes.showAll} onClick={showAllHandler}>
                        Показать всё...
                    </div>
                }
            </div>

        }
    }

    const {role_id} = useAppSelector((state) => state.user);

    const copyIO = async (id: string) => {
        const ioResp = await getInsuranceObject(id)
        const io = ioResp.data
        io.status_id = InsuranceObjectStatusesEnum.NEW.valueOf()
        const ioFiles = _.cloneDeep(io.files)
        delete io.files
        const response = await createInsuranceObject(activeClientId, io);
        if (isUndefined(response)) {
            toast.error("Не удалось создать копию объекта")
            return
        }
        const sp = response.headers.location.split('/');
        const ioID = sp[sp.length - 1];

        for (const f of ioFiles) {
            await addInsuranceObjectFile(ioID, f.id)
        }
        toast.success("Копия объекта успешно создана!")
        dispatch(setActiveId(ioID));
        fetchInsurances(true);
    }

    return (
        <SplitPaneWrapper tableName={'Объекты страхования'}
                          addButtonActive={activeClientId !== ""}
                          onAddClick={[RoleIdsEnum.KM, RoleIdsEnum.SUPERVISOR_KM].includes(role_id) ? props.addOnClick : undefined}
        >
            <div className={classes.inputWrapper}>
                <TextField className={classes.searchInput} placeholder={'Поиск по адресу...'} variant="filled"
                           onChange={(e) => setSearchAddress(e.target.value)}/>
            </div>
            <div className={classes.root} style={{height: `${props.height - 103}px`}}>
                <Content/>
                <ToastContainer />
            </div>
        </SplitPaneWrapper>
    );
}
