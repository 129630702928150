import React, {useEffect, useRef, useState} from 'react';
import {setActiveId, setData, setFilterIOId} from '../../store/features/insuranceObjects'
import {reInit} from '../../store/features/quotesTable'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {
    ClientSortFieldToName,
    dealSortOptions,
    InsuranceObjectTypeNameOptions,
    operatorsOptions,
    OpfTypeName,
    OpfTypeNameOptions,
    OptionInterface,
    QuotationStatusesNameOptions,
    RequestStatusEnum,
    RoleIdsEnum,
    SortOptionInterface,
} from "../../common/commonTypes";
import {formatDateForBackend, formatDateForClient, separateThousands, sumOfFields} from "../../common/lib";

import {ScrollParams} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {ClientTypesEnum} from "../../common/catalogs"; // only needs to be imported once
import classes from "./DealsTable.module.scss"
import SingleSelect from "../ui/SingleSelect/SingleSelect"
import cn from "classnames"
import SplitPaneWrapper from "../SplitPaneWrapper/SplitPaneWrapper";
import Input from "../ui/Input/Input"
import ClearButton from "../ui/ClearButton/ClearButton";
import SortIcon from '@mui/icons-material/Sort';
import {useInterval} from "../../hooks/useInterval";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import {
    clearData,
    clearFilters,
    clearSort,
    fetchDeals,
    resetPagination,
    setActiveDeal,
    setFilterAdress,
    setFilterBank,
    setFilterClientName,
    setFilterClientOpfTypeId,
    setFilterContractNumber,
    setFilterDate,
    setFilterDeputy,
    setFilterEndDate,
    setFilterInsuranceCompanyName,
    setFilterInsuranceObjectTypeId,
    setFilterKvVRub,
    setFilterNumber,
    setFilterPayDate,
    setFilterQuotationStatusID,
    setFilterSP,
    setFilterSS,
    setFilterSsk,
    setOperatorKvVRub,
    setOperatorSP,
    setOperatorSS,
    setScrollIndex,
    setSort
} from "../../store/features/dealsTable";
import {downloadFileById, getDealsFile} from "../../api/commonApi";
//@ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Stack, Tooltip} from "@mui/material";

interface DealTableProps {
    onDealEdit: (dealId: string) => void;
    onDealFilesShow: (dealId: string, dealAccountNumber: string, dealFiles: [], dealClientName: string) => void;
    onDealShowPaymentSchedule: (dealId: string) => void;
}


export default function DealsTable(props: DealTableProps) {

    const {
        status,
        data,
        filters,
        operators,
        sorts,
        allDataLoaded,
        scrollIndex,
        activeDealId,
        lastAddedClientDate,
        blockAutoFetching
    } = useAppSelector((state) => state.dealsTable);
    let ssSum = sumOfFields(data.data, 'ss');
    let spSum = sumOfFields(data.data, 'sp');
    let kvVRubSum = sumOfFields(data.data, 'kv_v_rub');
    let dealsSum = data.data.length;
    const {role_id} = useAppSelector((state) => state.user);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    const [activeMenuId, setActiveMenuId] = useState("");
    const [activeMenuType, setActiveMenuType] = useState(0);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string, type: ClientTypesEnum) => {
        setActiveMenuId(id);
        setActiveMenuType(type);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const formatOpf = (opf: string) => {
        if (opf === undefined) {
            return "Неизв."
        }
        return opf.split(" ").filter(s => s.length > 2).map(s => s[0].toUpperCase()).join("");
    }

    const onDownloadFile = async (fileId: string) => {
        setDownloadFileActiveId(fileId);
        try {
            await downloadFileById(fileId);
        } finally {
            setDownloadFileActiveId("");
        }
    }

    const [downloadFileActiveId, setDownloadFileActiveId] = useState("");

    const showAllHandler = () => {
        dispatch(setActiveId(""));
        dispatch(setFilterIOId(""));
        dispatch(setActiveDeal(""));
        dispatch(clearFilters());
        dispatch(setData([]));
        dispatch(reInit());
        dispatch(resetPagination());
        // @ts-ignore
        dispatch(setScrollIndex(0));
        loadMoreRows({startIndex: 0, stopIndex: 40, from: "init"});
        //setActiveId, setData
        //clearFilters();
    }

    // @ts-ignore
    function rowRenderer(key, index) {
        const items = data.data;
        //console.log(items[index].installments_type_id)
        if (items[index] == undefined) {
            if (filters.id.length) {
                return <div onClick={showAllHandler} className={classes.showAll}>Показать всё...</div>
            } else {
                return <div></div>
            }
        }

        let type = '';

        let date = null;

        if (lastAddedClientDate != null) {
            date = new Date(lastAddedClientDate);
            date.setSeconds(lastAddedClientDate.getSeconds() + 10);
        }


        let highlightNew = false;
        try {
            let date = new Date((new Date(items[index].created_at)).toUTCString());
            date.setSeconds(date.getSeconds() + 10);
            highlightNew = (new Date()) < date;
        } catch (e) {
        }

        const highlightActive = items[index].id === activeDealId;


        let highlightNewInSSK = false;
        if (role_id === RoleIdsEnum.SSK) {
            let lastUpdateDate = (new Date(items[index].updated_at));
            lastUpdateDate.setSeconds(lastUpdateDate.getSeconds() + 10);
            highlightNewInSSK = (new Date()) < lastUpdateDate;
        }

        return (
            <div key={key}
                 onClick={() => dispatch(setActiveDeal(items[index].id))}
                 className={cn(classes.tableTr, items[index].number % 2 == 0 ? classes.rowEven : classes.rowOdd, {
                     [classes.rowActive]: highlightActive,
                     [classes.rowAdded]: ((highlightActive && highlightNew) || highlightNewInSSK)
                 })}
            >
                <div className={classes.tableTh}>
                    <div
                        // @ts-ignore
                        onClick={(event => handleClick(event, items[index].id, items[index].type_id))}>
                        <MoreVertIcon className={classes.menuIcon}/>
                    </div>
                </div>
                <div className={classes.tableTh}>
                    <div>
                        {items[index].number}
                    </div>
                </div>
                <div className={classes.tableTd}>
                    <div>
                        {
                            items[index].account_file_id && items[index].account_file_id != "" &&
                            <span onClick={() => onDownloadFile(items[index].account_file_id)}>Скачать счет</span>
                        }
                    </div>
                    <div>
                        {
                            items[index].contract_file_id && items[index].contract_file_id != "" &&
                            <span onClick={() => onDownloadFile(items[index].contract_file_id)}>Скачать договор</span>
                        }
                    </div>
                    <div>
                        {
                            <span
                                onClick={() => showFilesHandler(items[index].id, items[index].client_name, items[index].contract_number)}>Показать файлы</span>
                        }
                    </div>
                </div>
                <div className={classes.tableTd}>
                    <div>
                        <Tooltip title={items[index].client_name}>
                            <div className={cn(classes.overflowEllipsis)}>{items[index].client_name}</div>
                        </Tooltip>
                    </div>
                    <div className={cn()}>
                        {formatOpf(OpfTypeName[items[index].client_opf_type_id])}
                    </div>
                </div>
                <div className={classes.tableTd}>
                    <div className={cn(classes.insuranceObjectTypeId)}>
                        {InsuranceObjectTypeNameOptions.filter(opf => opf.value === items[index].insurance_object_type_id)[0].label}
                    </div>
                    <div className={cn(classes.clientName)}>
                        {items[index].object_address}
                        <div className={classes.clientNameLabel}> {items[index].object_address} </div>
                    </div>
                </div>
                <div className={classes.tableTd}>
                    <div className={cn(classes.ss)}>
                        {separateThousands(items[index].ss)}
                    </div>

                    <div className={cn(classes.ss)}>
                        {separateThousands(items[index].sp)}
                    </div>
                </div>
                <div className={classes.tableTd}>
                    {separateThousands(items[index].kv_v_rub)}
                </div>
                <div className={classes.tableTd}>
                    <div>
                        <Tooltip title={items[index].insurance_company_name}>
                            <div className={cn(classes.overflowEllipsis)}>{items[index].insurance_company_name}</div>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title={items[index].bank_name}>
                            <div className={cn(classes.overflowEllipsis)}>{items[index].bank_name}</div>
                        </Tooltip>
                    </div>
                </div>
                <div className={classes.tableTd}>
                    <div className={cn(classes.date)}>
                        {items[index].contract_number}
                    </div>
                    <div className={cn(classes.date)}>
                        {formatDateForClient(items[index].date)}
                    </div>
                </div>
                <div className={classes.tableTd}>
                    <div className={cn(classes.date)}>
                        {formatDateForClient(items[index].pay_date)}
                    </div>
                    <div className={cn(classes.date)}>
                        {formatDateForClient(items[index].end_date)}
                    </div>
                </div>
                <div className={classes.tableTd}>
                    <div>
                        <Tooltip title={items[index].deputy_str}>
                            <div className={cn(classes.overflowEllipsis)}>{items[index].deputy_str}</div>
                        </Tooltip>
                    </div>
                    <div className={cn(classes.date)}>
                        {
                            // @ts-ignore
                            QuotationStatusesNameOptions.filter(qsn => qsn.value === items[index].quotation_status_id)[0].label
                        }
                    </div>
                </div>
                <div className={classes.tableTd}></div>
            </div>
        );
    }


    const dispatch = useAppDispatch();


    function loadClients({startIndex, stopIndex}: { startIndex: number, stopIndex: number }) {
        //console.log('status != RequestStatusEnum.Succeeded || allDataLoaded', status != RequestStatusEnum.Succeeded || allDataLoaded)
        if (status != RequestStatusEnum.Succeeded) {
            return;
        }
        return dispatch(fetchDeals({skip: startIndex, take: stopIndex}));
    }

    const loadMoreRowsTmp = ({
                                 startIndex,
                                 stopIndex,
                                 from = "loadmore"
                             }: { startIndex: number, stopIndex: number, from?: string }) => {
        //console.log('allDataLoaded', allDataLoaded)
        //if(allDataLoaded) { return; }
        loadClients({startIndex: startIndex, stopIndex: stopIndex});

    }

    const loadMoreRows = ({
                              startIndex,
                              stopIndex,
                              from = "loadmore"
                          }: { startIndex: number, stopIndex: number, from?: string }) => {
        return new Promise(async () => {
            if (from == 'loadmore') {
                if (data.data.length < 40) {
                    return;
                }
            }
            try {
                loadMoreRowsTmp({startIndex: startIndex, stopIndex: stopIndex, from: from});
            } catch (e) {

            }
        })
    };


    const loadMoreRowsHandler = ({
                                     startIndex,
                                     stopIndex,
                                     from = "loadmore"
                                 }: { startIndex: number, stopIndex: number, from?: string }) => {
        return new Promise(async () => {
            if (allDataLoaded) {
                return
            }
            setTimeout(
                () => loadMoreRows({startIndex: startIndex, stopIndex: stopIndex}),
            )
        })
    };


    useEffect(() => {
        setTimeout(() => {
            if (!filters.id) {
                loadMoreRows({startIndex: 0, stopIndex: 40, from: "init"});
            }
        }, 500)

        return () => {
            dispatch(resetPagination());
            // @ts-ignore
            dispatch(setScrollIndex(0));
            dispatch(clearData());

        }
    }, []);

    function initTable() {
        dispatch(resetPagination());
        // @ts-ignore
        dispatch(setScrollIndex(0));
        loadMoreRows({startIndex: 0, stopIndex: 40, from: 'initTable'});
    }

    const [tableScrollTop, setTableScrollTop] = useState(0);

    function scrollHandler(scrollParams: ScrollParams) {
        dispatch(setScrollIndex(undefined));
        setTableScrollTop(scrollParams.scrollTop);
    }

    useInterval(() => initTable(), 5000, () => tableScrollTop < 15 && !blockAutoFetching)

    const infiniteLoaderRef = useRef(null);

    function handleChangeSort(value: SortOptionInterface) {
        if (value == null) {
            dispatch(clearSort());
        } else {
            dispatch(setSort({name: value.value.name, order: value.value.order}));
        }
        initTable();
    }

    function clearFiltersHandler() {
        if (filters.id.length !== 0) {
            showAllHandler();
        } else {
            dispatch(clearSort());
            dispatch(clearFilters());
            initTable();
        }
    }

    function formatSortOptionLabel(label: SortOptionInterface, data: any) {
        if (data.context === "menu") {
            return label.label;
        }

        if (ClientSortFieldToName.hasOwnProperty(label.value.name)) {
            // @ts-ignore
            const displayLabel = ClientSortFieldToName[label.value.name]
            return <div className={classes.sortValue}>
                {displayLabel}
                <SortIcon className={cn({[classes.sortMirrorVertical]: label.value.order == "asc"})}/>
            </div>
        }

        return label.label;
    }

    const editClientHandler = () => {
        //activeMenuId
        setAnchorEl(null);
        props.onDealEdit(activeMenuId);
    }

    const showPaymentScheduleHandler = () => {
        //activeMenuId
        setAnchorEl(null);
        props.onDealShowPaymentSchedule(activeMenuId);
        //getDealsPaymentSchedule(dealId).then((data) => {
        //    console.log(data);

        //})
    }

    const showFilesHandler = (dealId: string, accountNumber: string, clientName: string) => {
        setAnchorEl(null);

        // @ts-ignore
        getDealsFile(dealId).then((data) => {
            // @ts-ignore
            props.onDealFilesShow(dealId, clientName, data.data, accountNumber);
        });
    }

    const ref = useRef(null);
    const [tableSize, tableSizeSet] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        function handleResize() {
            if (ref.current === null) {
                return
            }
            tableSizeSet({
                // @ts-ignore
                width: (ref.current.clientWidth === ref.current.scrollWidth || ref.current.clientWidth > 1100) ? ref.current.clientWidth : ref.current.scrollWidth,
                // @ts-ignore
                height: 0,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    function handleChangeNumber(event: any) {
        dispatch(setFilterNumber(event.target.value));
        initTable();
    }

    function handleChangeClientName(event: any) {
        dispatch(setFilterClientName(event.target.value));
        initTable();
    }

    function handleChangeAdress(event: any) {
        dispatch(setFilterAdress(event.target.value));
        initTable();
    }

    function handleChangeSS(event: any) {
        dispatch(setFilterSS(event.target.value));
        initTable();
    }

    function handleChangeSSOperator(event: any) {
        dispatch(setOperatorSS(event.value));
        if (filters.ss.length !== 0) {
            initTable();
        }
    }

    function handleChangeSPOperator(event: any) {
        dispatch(setOperatorSP(event.value));
        if (filters.sp.length !== 0) {
            initTable();
        }
    }

    function handleChangeKvVRubOperator(event: any) {
        dispatch(setOperatorKvVRub(event.value));
        if (filters.kv_v_rub.length !== 0) {
            initTable();
        }
    }

    function handleChangeDeputy(event: any) {
        dispatch(setFilterDeputy(event.target.value));
        initTable();
    }

    function handleChangeSP(event: any) {
        dispatch(setFilterSP(event.target.value));
        initTable();
    }

    function handleChangeSsk(event: any) {
        dispatch(setFilterSsk(event.target.value));
        initTable();
    }

    function handleChangeKvVRub(event: any) {
        dispatch(setFilterKvVRub(event.target.value));
        initTable();
    }

    function handleChangeBank(event: any) {
        dispatch(setFilterBank(event.target.value));
        initTable();
    }

    function handleChangeClientOpfType(event: OptionInterface) {
        dispatch(setFilterClientOpfTypeId(event != null ? event.value : 0));
        initTable();
    }

    function handleChangeInsuranceObjectTypeId(event: OptionInterface) {
        dispatch(setFilterInsuranceObjectTypeId(event != null ? event.value : 0));
        initTable();
    }

    function handleChangeQuotationStatusID(event: OptionInterface) {
        dispatch(setFilterQuotationStatusID(event != null ? event.value : 0));
        initTable();
    }

    function handleChangeInsuranceCompanyName(event: any) {
        dispatch(setFilterInsuranceCompanyName(event.target.value));
        initTable();
    }

    function handleChangeContractNumber(event: any) {
        dispatch(setFilterContractNumber(event.target.value));
        initTable();
    }

    function handleChangeDealDate(dates: any) {
        const [start, end] = dates;

        let v = "";
        if (start && end) {
            v = formatDateForBackend(start) + "," + formatDateForBackend(end)
        } else if (!start && !end) {

        } else {
            return
        }

        dispatch(setFilterDate(v));
        initTable();
    }


    function handleChangeEndDate(dates: any) {
        const [start, end] = dates;

        let v = "";
        if (start && end) {
            v = formatDateForBackend(start) + "," + formatDateForBackend(end)
        } else if (!start && !end) {

        } else {
            return
        }

        dispatch(setFilterEndDate(v));
        initTable();
    }

    const [payDateRange, setPayDateRange] = useState([null, null]);
    const [startPayDate, endPayDate] = payDateRange;
    const [dealDateRange, setDealDateRange] = useState([null, null]);
    const [endDateRange, setEndDateRange] = useState([null, null]);
    const [startDealDate, endDealDate] = dealDateRange;
    const [startEndDate, endEndDate] = endDateRange;

    function handleChangePayDate(dates: any) {
        const [start, end] = dates;

        let v = "";
        if (start && end) {
            v = formatDateForBackend(start) + "," + formatDateForBackend(end)
        } else if (!start && !end) {

        } else {
            return
        }

        dispatch(setFilterPayDate(v));
        initTable();
    }

    const downloadPdfHandler = () => {
        // @ts-ignore
        html2canvas(ref.current, {scrollY: -window.scrollY}).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
            });
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('AIMA_deals.pdf');
        });
    }


    // @ts-ignore
    return (
        <SplitPaneWrapper tableName={"Сделки"}
                          tableSum={{'СС': ssSum, 'СП': spSum, 'КВ': kvVRubSum, 'Количество сделок': dealsSum}}
                          downloadPdf={downloadPdfHandler}>
            <div className={classes.root} ref={ref}>
                <div className={classes.table}>
                    <div className="tableColgroup">
                        <div className={cn(classes.tableCol, classes.col1)}></div>
                        <div className={cn(classes.tableCol, classes.col2)}></div>
                        <div className={cn(classes.tableCol, classes.col3)}></div>
                        <div className={cn(classes.tableCol, classes.col4)}></div>
                        <div className={cn(classes.tableCol, classes.col5)}></div>
                        <div className={cn(classes.tableCol, classes.col6)}></div>
                        <div className={cn(classes.tableCol, classes.col7)}></div>
                        <div className={cn(classes.tableCol, classes.col8)}></div>
                        <div className={cn(classes.tableCol, classes.col9)}></div>
                        <div className={cn(classes.tableCol, classes.col10)}></div>
                        <div className={cn(classes.tableCol, classes.col11)}></div>
                        <div className={cn(classes.tableCol, classes.col12)}></div>
                    </div>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {
                            role_id == RoleIdsEnum.SSK &&
                            <MenuItem onClick={editClientHandler}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>{'Редактировать'}</ListItemText>
                            </MenuItem>
                        }
                        {
                            <MenuItem onClick={showPaymentScheduleHandler}>
                                <ListItemIcon>
                                    <VisibilityIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>{'График платежей'}</ListItemText>
                            </MenuItem>
                        }
                    </Menu>
                    <div className={classes.tableThead}>
                        <div className={classes.tableTr}>
                            <div className={classes.tableTh}></div>
                            <div className={classes.tableTh}>#</div>
                            <div className={classes.tableTh}>
                                <Input placeholder={"Файлы"} width={140} type={'number'} disabled={true}/>
                            </div>
                            <div className={classes.tableTh}>
                                <Input placeholder={"Имя клиента"} width={160} onChange={handleChangeClientName}
                                       value={filters.client_name} type={'number'}/>
                                <SingleSelect
                                    width={160}
                                    placeholder={'ОПФ'}
                                    options={OpfTypeNameOptions}
                                    onChange={handleChangeClientOpfType}
                                    value={filters.client_opf_type_id ? OpfTypeNameOptions.filter((o) => o.value == filters.client_opf_type_id)[0] : null}
                                />
                            </div>
                            <div className={classes.tableTh}>
                                <SingleSelect
                                    width={135}
                                    placeholder={'Риск'}
                                    options={InsuranceObjectTypeNameOptions}
                                    onChange={handleChangeInsuranceObjectTypeId}
                                    value={filters.insurance_object_type_id ? InsuranceObjectTypeNameOptions.filter((o) => o.value == filters.insurance_object_type_id)[0] : null}
                                />
                                <Input width={135} placeholder={'Адрес'} onChange={handleChangeAdress}
                                       value={filters.object_address}/>
                            </div>
                            <div className={classes.tableTh}>
                                <Stack direction={'row'}>
                                    <SingleSelect
                                        isClearable={false}
                                        width={45}
                                        placeholder={'='}
                                        options={operatorsOptions}
                                        isOperatorSelect={true}
                                        onChange={handleChangeSSOperator}
                                        value={operators.ss ? operatorsOptions.filter((o) => o.value == operators.ss)[0] : null}
                                    />
                                    <Input withSelect={true} placeholder={"СС"} width={100} onChange={handleChangeSS}
                                           value={filters.ss}/>
                                </Stack>
                                <Stack direction={'row'}>
                                    <SingleSelect
                                        isClearable={false}
                                        width={45}
                                        placeholder={'='}
                                        options={operatorsOptions}
                                        isOperatorSelect={true}
                                        onChange={handleChangeSPOperator}
                                        value={operators.sp ? operatorsOptions.filter((o) => o.value == operators.sp)[0] : null}
                                    />
                                    <Input withSelect={true} placeholder={"СП"} width={100} onChange={handleChangeSP}
                                           value={filters.sp}/>
                                </Stack>
                            </div>
                            <div className={classes.tableTh}>
                                <Stack direction={'row'}>
                                    <SingleSelect
                                        isClearable={false}
                                        width={45}
                                        placeholder={'='}
                                        options={operatorsOptions}
                                        isOperatorSelect={true}
                                        onChange={handleChangeKvVRubOperator}
                                        value={operators.kv_v_rub ? operatorsOptions.filter((o) => o.value == operators.kv_v_rub)[0] : null}
                                    />
                                    <Input width={100} withSelect={true} placeholder={"КВ"}
                                           onChange={handleChangeKvVRub} value={filters.kv_v_rub}/>
                                </Stack>
                            </div>
                            <div className={classes.tableTh}>
                                <Input width={125} placeholder={"Страховая компания"}
                                       onChange={handleChangeInsuranceCompanyName}
                                       value={filters.insurance_company_name} type={'number'}/>
                                <Input width={125} placeholder={'Заказчик'} onChange={handleChangeBank}
                                       value={filters.bank_name}/>
                            </div>
                            <div className={classes.tableTh}>
                                <Input width={130} placeholder={"Номер договора"} onChange={handleChangeContractNumber}
                                       value={filters.contract_number}/>
                                <div className={"deal-data-picker"}>
                                    <DatePicker
                                        placeholderText="Дата договора"
                                        // @ts-ignore
                                        dateFormat="dd.MM.yyyy"
                                        // @ts-ignore
                                        onChange={(update) => {
                                            setDealDateRange(update);
                                            handleChangeDealDate(update);
                                        }}
                                        selected={startDealDate}
                                        startDate={startDealDate}
                                        endDate={endDealDate}
                                        selectsRange={true}
                                        isClearable={true}
                                        withPortal
                                    />
                                </div>
                            </div>
                            <div className={classes.tableTh}>
                                <div className={"deal-data-picker"}>
                                    <DatePicker
                                        // @ts-ignore
                                        dateFormat="dd.MM.yyyy"
                                        // @ts-ignore
                                        onChange={(update) => {
                                            setPayDateRange(update);
                                            handleChangePayDate(update);
                                        }}
                                        placeholderText="Дата оплаты"
                                        selected={startPayDate}
                                        startDate={startPayDate}
                                        endDate={endPayDate}
                                        selectsRange={true}
                                        isClearable={true}
                                        withPortal
                                    />
                                </div>
                                <div className={"deal-data-picker"}>
                                    <DatePicker
                                        placeholderText="Дата окончания"
                                        // @ts-ignore
                                        dateFormat="dd.MM.yyyy"
                                        // @ts-ignore
                                        onChange={(update) => {
                                            setEndDateRange(update);
                                            handleChangeEndDate(update);
                                        }}
                                        selected={startEndDate}
                                        startDate={startEndDate}
                                        endDate={endEndDate}
                                        selectsRange={true}
                                        isClearable={true}
                                        withPortal
                                    />
                                </div>
                            </div>
                            <div className={classes.tableTh}>
                                <div>
                                    <Input withSelect={true} placeholder={"Заемщик"} width={100}
                                           onChange={handleChangeDeputy} value={filters.deputy}/>
                                </div>
                                <div>
                                    <SingleSelect
                                        width={160}
                                        placeholder={'Статус Котировки'}
                                        options={QuotationStatusesNameOptions}
                                        onChange={handleChangeQuotationStatusID}
                                        value={filters.quotation_status_id ? QuotationStatusesNameOptions.filter((o) => o.value == filters.quotation_status_id)[0] : null}
                                    />
                                </div>
                            </div>
                            <div className={classes.tableTh}>
                                <Stack direction={'row'}>
                                    <SingleSelect
                                        width={140}
                                        formatOptionLabel={formatSortOptionLabel}
                                        placeholder={'Сортировка'}
                                        options={dealSortOptions}
                                        onChange={handleChangeSort}
                                        menuStyles={{width: '250px', left: "-110px"}}
                                        value={Object.keys(sorts).length ? dealSortOptions.filter((cs) => cs.value.name == Object.keys(sorts)[0] && cs.value.order == sorts[Object.keys(sorts)[0]])[0] : null}
                                    />
                                    <ClearButton width={25} onClick={clearFiltersHandler}/>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <div className={classes.tableTbody}>
                        {
                            data.data.map((deal, index) => {
                                return rowRenderer(deal.id, index)
                            })
                        }
                    </div>
                </div>
            </div>
        </SplitPaneWrapper>
    );
}