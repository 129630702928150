import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    ClientLegalInterface,
    ClientPhysicalInterface,
    ClientsResponse,
    RequestStatusEnum,
} from "../../common/commonTypes";
import {getClientsTableRequest} from "../../api/commonApi";



interface ClientTableFilterInterface {
    name: string;
    km_company_name: string;
    identity: string;
    opf_type_id:  number;
    created_at:  string;
    comment:  string;
    account_needs: string,
    is_objects_in_process: string,
    id: string,
}

interface SortTableFilterInterface {
    [key: string]: string | null;
}

interface ClientsTableState {
    data: ClientsResponse,
    status: RequestStatusEnum,
    filters: ClientTableFilterInterface,
    sorts: SortTableFilterInterface,
    resetPagination: boolean,
    allDataLoaded: boolean,
    lastQuery: string,
    scrollIndex: number | undefined,
    activeClientId: string,
    lastAddedClientDate: Date | null,
    blockAutoFetching: boolean,
}

// Define the initial state using that type
const initialState: ClientsTableState = {
    data: {
        count_all: 0,
        limit: 0,
        ptoken: "",
        data: []
    },
    status: RequestStatusEnum.Succeeded,
    filters: {
        name: "",
        km_company_name: "",
        id: "",
        identity: "",
        opf_type_id: 0,
        created_at: "",
        comment: "",
        account_needs: "",
        is_objects_in_process: "",
    },
    sorts: {
    },
    resetPagination: false,
    allDataLoaded: false,
    lastQuery: "",
    scrollIndex: undefined,
    activeClientId: "",
    lastAddedClientDate: null,
    blockAutoFetching: false
};

export const fetchClients = createAsyncThunk('clientsTable/initFetch', async ({skip, take}: {skip: number, take: number}, { getState }) => {

    // @ts-ignore
    const state: ClientsTableState = getState().clientsTable;

    let queryString = `?limit=${40}`;

    Object.keys(state.filters).forEach((fk) => {
        // @ts-ignore
        const value = state.filters[fk];
        if(value) {
            queryString = `${queryString}&${fk}=${value}`;
        }
    });

    for (let fk in state.sorts) {
        // @ts-ignore
        const value = state.sorts[fk];

        if(value) {
            queryString = `${queryString}&sort_by=${fk}&sort_order=${value}`;
            break;
        }

    }

    const qString = queryString;

    if(!state.resetPagination && state.data.ptoken.length != 0) {
        queryString = `${queryString}&ptoken=${state.data.ptoken}`
    }


    const result = await getClientsTableRequest(queryString);

    return { skip: skip, data: result.data, lastQuery: queryString};
    
    //await timeout(2000);
    
    //console.log('skip, take', skip, take)
    //return { skip: skip, items: generateRows(take - skip, skip)};
})


export const clientsTableSlice = createSlice({
    name: 'clientsTable',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setBlockAutoFetching: (state, { payload }: {payload: boolean}) => {
            state.blockAutoFetching = payload;
        },
        setFilterId: (state, { payload }: {payload: string}) => {
            state.filters.id = payload;
        },
        setFilterName: (state, { payload }: {payload: string}) => {
            state.filters.name = payload;
        },
        setFilterKMCompanyName: (state, { payload }: {payload: string}) => {
            state.filters.km_company_name = payload;
        },
        setScrollIndex: (state, { payload }: { payload: number | undefined}) => {
            state.scrollIndex = payload;
        },
        clearData: (state) => {
            state.data.data = [];
        },
        setFilterIdentity: (state, { payload }: {payload: string}) => {
            state.filters.identity = payload;
        },
        setFilterOpfType: (state, { payload }: {payload: number}) => {
            state.filters.opf_type_id = payload;
        },
        setFilterCreatedDate: (state, { payload }: {payload: string}) => {
            state.filters.created_at = payload;
        },

        setLastAddedClient: (state, { payload }: { payload: {id: string, date: Date}}) => {
            state.activeClientId = payload.id;
            state.lastAddedClientDate = payload.date;
        },

        setActiveClient: (state, { payload }: { payload: string }) => {
            state.lastAddedClientDate = null;
            state.activeClientId = payload;
        },

        setFilterComment: (state, { payload }: {payload: string}) => {
            state.filters.comment = payload;
        },

        setFilterStatus: (state, { payload }: {payload: string}) => {
            state.filters.is_objects_in_process = payload;
        },

        clearFilters: (state) => {
            state.filters = {
                name: "",
                km_company_name: "",
                id: "",
                identity: "",
                opf_type_id: 0,
                created_at: "",
                comment: "",
                account_needs: "",
                is_objects_in_process: ""
            };

            state.sorts = {};

            state.resetPagination = true;
            state.allDataLoaded = false;
        },
        resetPagination: (state) => {
            state.allDataLoaded = false;
            state.resetPagination = true;
            state.data.ptoken = "";
        },
        setSort: (state, { payload }: {payload: { name: string, order: string }}) => {
            Object.keys(state.sorts).forEach((ks) => {
                state.sorts[ks] = null;
            })
            state.sorts[payload.name] = payload.order;
            state.resetPagination = true;
            state.allDataLoaded = false;
        },
        clearSort: (state) => {
            Object.keys(state.sorts).forEach((ks) => {
                state.sorts[ks] = null;
            })
            state.resetPagination = true;
            state.allDataLoaded = false;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchClients.pending, (state, action) => {
                state.status = RequestStatusEnum.Loading;
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                //console.log('action.payload', action.payload)
                state.status = RequestStatusEnum.Succeeded;

                if (!action.payload.data.data) return;

                //console.log('action.payload.data.data.length', action.payload.data.data.length)
                if(action.payload.data.data.length < 40) {
                    //console.log('state.data.ptoken', state.data.ptoken)
                    state.allDataLoaded = true;
                    if(!state.data.ptoken) {
                        state.data = action.payload.data;
                    } else {
                        state.data = { ...action.payload.data, data: [...state.data.data, ...action.payload.data.data] };
                    }
                    return;
                }
                if(state.resetPagination) {
                    state.data = action.payload.data;
                } else {
                    state.data = { ...action.payload.data, data: [...state.data.data, ...action.payload.data.data] };
                }

                state.resetPagination = false;
                state.allDataLoaded = false;
                //state.items = action.payload.items;
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.status = RequestStatusEnum.Failed;
            })
    }

})

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}




export const {
    setFilterStatus,
    setFilterName,
    setFilterKMCompanyName,
    setFilterId,
    setFilterIdentity,
    setFilterOpfType,
    setFilterComment,
    setFilterCreatedDate,
    clearFilters,
    setSort,
    clearSort,
    resetPagination,
    setScrollIndex,
    clearData,
    setLastAddedClient,
    setActiveClient,
    setBlockAutoFetching,
} = clientsTableSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default clientsTableSlice.reducer