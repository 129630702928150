import * as React from 'react';
import {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {createCompany, createKM, createSSK, getAllCompanies} from "../../../api/commonApi";
import {CompanyInterface, OpfTypeName, RoleIdsEnum} from "../../../common/commonTypes";
import {Field, Form} from 'react-final-form'
import {LoadingButton} from "@mui/lab";

const COMPANY_TYPES: Record<number, string> = {
    1: "Банк",
    2: "Страховая"
}

export interface CreateCompanyDTO {
    ras_account: string;
    bik: string;
    korr_account: string;
    bank_name: string;
    name: string;
    opf_type_id: number;
    legal_address: string;
    post_address: string;
    ogrn: string;
    okpo: string;
    okato: string;
    inn: string;
    kpp: string;
    phone: string;
    site: string;
    type_id: number;
}

interface AddCompanyModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const AddCompanyModal = (props: AddCompanyModalProps) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: CreateCompanyDTO) => {
        if (data.name == "") {
            alert("введите имя")
            return
        }
        if (!data.type_id) {
            alert("выберите тип банка")
            return
        }
        if (!data.opf_type_id) {
            alert("выберите тип ОПФ")
            return
        }
        setLoading(true)

        createCompany(data).then(() => {
            alert("компания создана")
            props.onClose()
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isOpen}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isOpen}>
                    <Box sx={{
                        position: "absolute",
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "30%",
                        height: "30%",
                        overflow: "scroll",
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Typography variant="h6" component="h2">
                            Добавить компанию
                        </Typography>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                type_id: 1,
                                name: "",
                                bank_name: "",
                                ras_account: "",
                                opf_type_id: 12300,
                                inn: "",
                                bik: "",
                                korr_account: "",
                                legal_address: "",
                                post_address: "",
                                ogrn: "",
                                okpo: "",
                                okato: "",
                                kpp: "",
                                phone: "",
                                site: "",
                            }}
                            render={({handleSubmit, form, submitting, pristine, values}) => (
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <label>Тип компании</label>
                                        <Field name="type_id" component="select">
                                            {
                                                Object.keys(COMPANY_TYPES).map(Number).map((ur) => (
                                                    <option value={ur}>{COMPANY_TYPES[ur]}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <label>ОПФ</label>
                                        <Field name="opf_type_id" component="select">
                                            {
                                                Object.keys(OpfTypeName).map(Number).map((ur) => (
                                                    <option value={ur}>{OpfTypeName[ur]}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <label>Название</label>
                                        <Field
                                            name="name"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>ИНН (необязательно)</label>
                                        <Field
                                            name="inn"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>Расчетный счет (необязательно)</label>
                                        <Field
                                            name="ras_account"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>БИК (необязательно)</label>
                                        <Field
                                            name="bik"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>Корр. счет (необязательно)</label>
                                        <Field
                                            name="korr_account"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>Юр. адрес (необязательно)</label>
                                        <Field
                                            name="legal_address"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>Почтовый адрес (необязательно)</label>
                                        <Field
                                            name="post_address"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>ОГРН (необязательно)</label>
                                        <Field
                                            name="ogrn"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>ОКПО (необязательно)</label>
                                        <Field
                                            name="okpo"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>ОКАТО (необязательно)</label>
                                        <Field
                                            name="okato"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>КПП (необязательно)</label>
                                        <Field
                                            name="kpp"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>Телефон (необязательно)</label>
                                        <Field
                                            name="phone"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>Сайт (необязательно)</label>
                                        <Field
                                            name="phone"
                                            component="input"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <label>Название банка (необязательно)</label>
                                        <Field
                                            name="bank_name"
                                            component="input"
                                            type="text"
                                            placeholder="Email"
                                        />
                                    </div>
                                    <LoadingButton loading={loading} type={"submit"} variant="outlined" sx={{mt: 3}}>
                                        Сохранить
                                    </LoadingButton>
                                </form>
                            )}
                        />
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}