import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    ClientLegalInterface,
    ClientPhysicalInterface,
    ClientsResponse, DealsResponse, QuotationStatusesEnum,
    RequestStatusEnum,
} from "../../common/commonTypes";
import { getDealTableRequest } from "../../api/commonApi";



interface DealTableFilterInterface {
    id: string;
    status_id: number;
    date: string;
    number:  number;
    client_opf_type_id:  number;
    insurance_company_name:  string;
    insurance_object_type_id:  number;
    quotation_status_id:  number;
    client_name:  string;
    ss: string,
    deputy: string,
    sp: string,
    km_name: string,
    contract_date: string,
    end_date: string,
    pay_date: string,
    contract_number: string,
    created_at: string,
    updated_at: string,
    ssk_name: string,
    kv_v_rub: string,
    bank_name: string,
    object_address: string,
}

interface SortTableFilterInterface {
    [key: string]: string | null;
}

interface QuotesTableOperatorsInterface {
    ss: string;
    sp: string;
    kv_v_rub: string;
}

interface DealsTableState {
    data: DealsResponse,
    status: RequestStatusEnum,
    filters: DealTableFilterInterface,
    operators: QuotesTableOperatorsInterface,
    sorts: SortTableFilterInterface,
    resetPagination: boolean,
    allDataLoaded: boolean,
    lastQuery: string,
    scrollIndex: number | undefined,
    activeDealId: string,
    lastAddedClientDate: Date | null,
    blockAutoFetching: boolean,
}

// Define the initial state using that type
const initialState: DealsTableState = {
    data: {
        count_all: 0,
        limit: 0,
        ptoken: "",
        data: []
    },
    status: RequestStatusEnum.Succeeded,
    filters: {
        id: "",
        status_id: 0,
        date: "",
        number:  0,
        client_opf_type_id:  0,
        insurance_company_name:  "",
        insurance_object_type_id:  0,
        quotation_status_id:  0,
        client_name: "",
        ss: "",
        deputy: "",
        sp: "",
        km_name: "",
        contract_date: "",
        end_date: "",
        pay_date: "",
        contract_number: "",
        created_at: "",
        updated_at: "",
        ssk_name: "",
        kv_v_rub: "",
        bank_name: "",
        object_address: "",
    },
    operators: {
        ss: "eq",
        sp: "eq",
        kv_v_rub: "eq",
    },
    sorts: {
    },
    resetPagination: false,
    allDataLoaded: false,
    lastQuery: "",
    scrollIndex: undefined,
    activeDealId: "",
    lastAddedClientDate: null,
    blockAutoFetching: false
};

export const fetchDeals = createAsyncThunk('dealsTable/initFetch', async ({skip, take}: {skip: number, take: number}, { getState }) => {

    // @ts-ignore
    const state: DealsTableState = getState().dealsTable;

    // TODO load more handle
    let queryString = `?limit=${10000}`;

    Object.keys(state.filters).forEach((fk) => {
        // @ts-ignore
        const value = state.filters[fk];
        if(value) {
            // queryString = `${queryString}&${fk}=${value}`;
            // @ts-ignore
            const operator = state.operators[fk];
            if(operator) {
                queryString = `${queryString}&${fk}=${operator}:${value}`;
            } else {
                queryString = `${queryString}&${fk}=${value}`;
            }
        }
    });

    for (let fk in state.sorts) {
        // @ts-ignore
        const value = state.sorts[fk];

        if(value) {
            queryString = `${queryString}&sort_by=${fk}&sort_order=${value}`;
            break;
        }

    }

    const qString = queryString;

    if(!state.resetPagination && state.data.ptoken.length != 0) {
        queryString = `${queryString}&ptoken=${state.data.ptoken}`
    }


    const result = await getDealTableRequest(queryString);

    return { skip: skip, data: result.data, lastQuery: queryString};
    
    //await timeout(2000);
    
    //console.log('skip, take', skip, take)
    //return { skip: skip, items: generateRows(take - skip, skip)};
})


export const dealsTableSlice = createSlice({
    name: 'dealsTable',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setBlockAutoFetching: (state, { payload }: {payload: boolean}) => {
            state.blockAutoFetching = payload;
        },
        setActiveDeal: (state, { payload }: { payload: string }) => {
            state.activeDealId = payload;
        },
        setScrollIndex: (state, { payload }: { payload: number | undefined}) => {
            state.scrollIndex = payload;
        },
        clearFilters: (state) => {
            state.filters = {
                id: "",
                status_id: 0,
                date: "",
                number:  0,
                client_opf_type_id:  0,
                insurance_company_name:  "",
                insurance_object_type_id:  0,
                quotation_status_id:  0,
                client_name:  "",
                deputy: "",
                ss: "",
                sp: "",
                km_name: "",
                contract_date: "",
                end_date: "",
                pay_date: "",
                contract_number: "",
                created_at: "",
                updated_at: "",
                ssk_name: "",
                kv_v_rub: "",
                bank_name: "",
                object_address: "",
            };

            state.operators = {
                ss: "eq",
                sp: "eq",
                kv_v_rub: "eq",
            };


            state.sorts = {};

            state.resetPagination = true;
            state.allDataLoaded = false;
        },
        resetPagination: (state) => {
            state.allDataLoaded = false;
            state.resetPagination = true;
            state.data.ptoken = "";
        },
        clearData: (state) => {
            state.data.data = [];
        },
        setSort: (state, { payload }: {payload: { name: string, order: string }}) => {
            Object.keys(state.sorts).forEach((ks) => {
                state.sorts[ks] = null;
            })
            state.sorts[payload.name] = payload.order;
            state.resetPagination = true;
            state.allDataLoaded = false;
        },
        clearSort: (state) => {
            Object.keys(state.sorts).forEach((ks) => {
                state.sorts[ks] = null;
            })
            state.resetPagination = true;
            state.allDataLoaded = false;
        },

        setFilterId: (state, { payload }: {payload: string}) => {
            state.filters.id = payload;
        },

        setFilterStatusId: (state, { payload }: {payload: number}) => {
            state.filters.status_id = payload;
        },

        setFilterDate: (state, { payload }: {payload: string}) => {
            state.filters.date = payload;
        },

        setFilterNumber: (state, { payload }: {payload: number}) => {
            state.filters.number = payload;
        },


        setFilterClientOpfTypeId: (state, { payload }: {payload: number}) => {
            state.filters.client_opf_type_id = payload;
        },

        setFilterClientName: (state, { payload }: {payload: string}) => {
            state.filters.client_name = payload;
        },

        setFilterInsuranceCompanyName: (state, { payload }: {payload: string}) => {
            state.filters.insurance_company_name = payload;
        },

        setFilterInsuranceObjectTypeId: (state, { payload }: {payload: number}) => {
                    state.filters.insurance_object_type_id = payload;
        },

        setFilterQuotationStatusID: (state, { payload }: {payload: number}) => {
            // @ts-ignore
            state.filters.quotation_status_id = payload;
        },

        setFilterAdress: (state, { payload }: {payload: string}) => {
            state.filters.object_address = payload;
        },

        setFilterBank: (state, { payload }: {payload: string}) => {
            state.filters.bank_name = payload;
        },

        setFilterSS: (state, { payload }: {payload: string}) => {
            state.filters.ss = payload;
        },

        setFilterDeputy: (state, { payload }: {payload: string}) => {
            state.filters.deputy = payload;
        },

        setFilterSP: (state, { payload }: {payload: string}) => {
            state.filters.sp = payload;
        },

        setFilterSsk: (state, { payload }: {payload: string}) => {
            state.filters.ssk_name = payload;
        },

        setOperatorSS: (state, { payload }: {payload: string}) => {
            state.operators.ss = payload;
        },

        setOperatorSP: (state, { payload }: {payload: string}) => {
            state.operators.sp = payload;
        },

        setOperatorKvVRub: (state, { payload }: {payload: string}) => {
            state.operators.kv_v_rub = payload;
        },

        setFilterKvVRub: (state, { payload }: {payload: string}) => {
            state.filters.kv_v_rub = payload;
        },

        setFilterSellerName: (state, { payload }: {payload: string}) => {
            state.filters.km_name = payload;
        },

        setFilterContractDate: (state, { payload }: {payload: string}) => {
            state.filters.contract_date = payload;
        },

        setFilterEndDate: (state, { payload }: {payload: string}) => {
            state.filters.end_date = payload;
        },

        setFilterPayDate: (state, { payload }: {payload: string}) => {
            state.filters.pay_date = payload;
        },

        setFilterContractNumber: (state, { payload }: {payload: string}) => {
            state.filters.contract_number = payload;
        },

        setFilterCreatedAt: (state, { payload }: {payload: string}) => {
            state.filters.created_at = payload;
        },

        setFilterUpdatedAt: (state, { payload }: {payload: string}) => {
            state.filters.updated_at = payload;
        },

    },
    extraReducers(builder) {
        builder
            .addCase(fetchDeals.pending, (state, action) => {
                state.status = RequestStatusEnum.Loading;
            })
            .addCase(fetchDeals.fulfilled, (state, action) => {
                //console.log('action.payload', action.payload)
                state.status = RequestStatusEnum.Succeeded;

                //console.log('action.payload.data.data.length', action.payload.data.data.length)
                if(action.payload.data.data.length < 40) {
                    //console.log('state.data.ptoken', state.data.ptoken)
                    state.allDataLoaded = true;
                    if(!state.data.ptoken) {
                        state.data = action.payload.data;
                    } else {
                        state.data = { ...action.payload.data, data: [...state.data.data, ...action.payload.data.data] };
                    }
                    return;
                }
                if(state.resetPagination) {
                    state.data = action.payload.data;
                } else {
                    state.data = { ...action.payload.data, data: [...state.data.data, ...action.payload.data.data] };
                }

                state.resetPagination = false;
                state.allDataLoaded = false;
                //state.items = action.payload.items;
            })
            .addCase(fetchDeals.rejected, (state, action) => {
                state.status = RequestStatusEnum.Failed;
            })
    }

})

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}




export const {
    clearFilters,
    clearSort,
    resetPagination,
    setActiveDeal,
    setSort,
    setScrollIndex,
    setFilterNumber,
    setFilterClientOpfTypeId,
    setFilterContractDate,
    setFilterContractNumber,
    setFilterCreatedAt,
    setFilterDate,
    setFilterEndDate,
    setFilterId,
    setFilterInsuranceCompanyName,
    setFilterPayDate,
    setFilterSellerName,
    setFilterSP,
    setFilterDeputy,
    setFilterSS,
    setOperatorSS,
    setOperatorSP,
    setOperatorKvVRub,
    setFilterStatusId,
    setFilterUpdatedAt,
    setBlockAutoFetching,
    setFilterClientName,
    setFilterInsuranceObjectTypeId,
    setFilterQuotationStatusID,
    clearData,
    setFilterAdress,
    setFilterKvVRub,
    setFilterBank,
    setFilterSsk,
} = dealsTableSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default dealsTableSlice.reducer