import React, {useEffect, useState} from 'react';
import classes from "./EditDealForm.module.scss"
import {
    DealCreateInterface,
    DealInstallmentsTypeNameOptions,
    InsuranceObjectWallMaterialsNameOptions,
    PokazatelStatistikiNameOptions,
} from "../../common/commonTypes";
import FormBuilder, {TabFormInterface} from "../FormBuilder/FormBuilder";
import {
    createDeal,
    deleteDealFile,
    downloadFileById, getDealById,
    getDealsFile,
    getFileById, getInsuranceObject,
    updateDeal
} from "../../api/commonApi";
import {useAppSelector} from "../../store/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchInsuranceObjects} from "../../store/features/insuranceObjects";
import {useDispatch} from "react-redux";
import cn from "classnames";
import {fetchQuotes, resetPagination, setScrollIndex} from "../../store/features/quotesTable";
import {formatDateForClient, separateThousands} from "../../common/lib";
import {toast} from "react-toastify";
import {getOffer} from "../../api/commonApi";

const physicalData: TabFormInterface = {
    tabs: [

        {
            key: "root",
            label: "Предложения",
            fields: [
                {
                    namedGroup: {
                        class: cn(classes.dealSubGroup),
                        name: "",
                        fields: [
                            {
                                type: "date",
                                key: "date",
                                label: "Дата",
                                required: false,
                                disabled: true,
                                value: (new Date()),
                                validate: (val: string) => true,
                            },
                            {
                                type: "select",
                                key: "pokazatel_stat",
                                label: "Показатель статистики",
                                options: PokazatelStatistikiNameOptions,
                                required: true,
                                disabled: false,
                                validate: (val: string) => true,
                            },
                            {
                                type: "number",
                                key: "ss",
                                label: "Страховая сумма",
                                disabled: true,
                                required: true,
                                validate: (val: string) => true,
                            },
                            {
                                type: "number",
                                key: "sp",
                                label: "Страховая премия",
                                required: true,
                                onRealChange: (form, v, idx) => {
                                    // @ts-ignore
                                    const val = parseInt(parseFloat(form.kv_v_perc) * parseInt(v) / 100);
                                    // @ts-ignore
                                    window.setFormValue("kv_v_rub", val);
                                },
                                validate: (val: string) => true,
                            },
                            {
                                type: "string",
                                key: "kv_v_perc",
                                label: "КВ в %",
                                value: "20",
                                required: (form) => {
                                    try {
                                        const t = parseInt(form.kv_v_perc)
                                        if (t <= 0 || t > 100) {
                                            return "0 >= КВ в % < 100"
                                        }
                                        return true
                                    } catch (e) {
                                        return "Некорректное значение"
                                    }
                                },
                                validate: (val: string) => true,
                                onRealChange: (form, v, idx) => {
                                    if (v == 0) {
                                        // @ts-ignore
                                        window.setFormValue("kv_v_rub", 0);
                                        return
                                    }
                                    try {
                                        const val = parseFloat(parseFloat(v).toFixed(3));
                                        // @ts-ignore
                                        const value = parseInt(parseInt(form.sp) * parseFloat(v) / 100);
                                        // @ts-ignore
                                        window.setFormValue('kv_v_perc', val.toString());

                                        // @ts-ignore
                                        window.setFormValue("kv_v_rub", value);
                                    } catch (e) {
                                        console.error(e)
                                    }
                                }
                            },

                            {
                                type: "number",
                                key: "kv_v_rub",
                                label: "КВ в руб",
                                required: true,
                                validate: (val: string) => true,
                            },

                            {
                                type: "date",
                                key: "contract_date",
                                label: "Дата договора",
                                required: true,
                                validate: (val: string) => true,
                            },

                            {
                                type: "string",
                                key: "contract_number",
                                label: "№ договора",
                                required: true,
                                validate: (val: string) => true,
                            },

                            {
                                type: "date",
                                key: "start_date",
                                label: "Срок начала",
                                required: (form) => {
                                    try {
                                        if (form.start_date > form.end_date) {
                                            toast.error("Дата начала не может быть позже даты окончания договора");
                                            return false
                                        }
                                        return true
                                    } catch (e) {
                                        toast.error("Дата начала не может быть позже даты окончания договора");
                                        return false
                                    }
                                },
                                validate: (val: string) => true,
                            },

                            {
                                type: "date",
                                key: "end_date",
                                label: "Срок окончания",
                                required: true,
                                validate: (val: string) => true,
                            },

                            {
                                type: "select",
                                key: "installments_type_id",
                                label: "Рассрочка",
                                options: DealInstallmentsTypeNameOptions,
                                required: true,
                                validate: (val: string) => true,
                            },

                            // {
                            //     type: "file",
                            //     key: "contract_file",
                            //     label: "Загрузить Договор",
                            //     required: true,
                            //     validate: (val: string) => true,
                            // },

                            // {
                            //     type: "file",
                            //     key: "account_file",
                            //     label: "Загрузить Счёт",
                            //     required: false,
                            //     validate: (val: string) => true,
                            // },
                        ]
                    }
                },
                {
                    type: "file",
                    key: "contract_file",
                    label: "Загрузить Договор",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "file",
                    key: "account_file",
                    label: "Загрузить Счёт",
                    required: false,
                    validate: (val: string) => true,
                },

                {
                    type: "files",
                    key: "files",
                    label: "Дополнительные файлы",
                    required: false,
                    validate: (val: string) => true,
                },
            ]

        },

    ],
}


interface InsuranceObjectFormProps {
    dealId: string,
    quotationId: string,
    onClose?: () => void,
    isEdit: boolean,
    offerId: string,
}


// @ts-ignore
const EditDealForm = (props: InsuranceObjectFormProps) => {

    const dispatch = useDispatch();

    const {activeClientId} = useAppSelector((state) => state.clientsTable);
    const {activeId} = useAppSelector((state) => state.insuranceObjects);
    const insuranceObjectActiveId = useAppSelector((state) => state.insuranceObjects.activeId);

    const [initVals, setInitVals] = useState({});

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);


    const [prevData, setPrevData] = useState<DealCreateInterface | null>(null);

    const loadingFunc = async () => {
        //console.log('props.isEdit', props.isEdit)
        if(props.dealId) {
            /*const resp = await getDealTableRequest(`?quotation_id=${props.quotationId}&limit=1`);
            const data = resp.data.data[0];*/

            const data = (await getDealById(props.dealId)).data;
            // @ts-ignore
            setPrevData(data);

            //console.log('data', data)

            // @ts-ignore
            data.date = formatDateForClient(data.date)

            // @ts-ignore
            data.files = (await getDealsFile(data.id)).data;

            try {
                if (data.account_file_id) {
                    // @ts-ignore
                    data.account_file = [(await getFileById(data.account_file_id)).data]
                }

                //console.log('data.contract_file_id', data.contract_file_id)

                if (data.contract_file_id) {
                    // @ts-ignore
                    data.contract_file = [(await getFileById(data.contract_file_id)).data]
                }
            } catch (e) {
                toast.error("Проблема с доступом к файлам. Обратитесь к администратору.")
            }

            data.installments_type_id = DealInstallmentsTypeNameOptions.filter(io => io.value === data.installments_type_id)[0];
            // @ts-ignore
            data.pokazatel_stat = PokazatelStatistikiNameOptions.filter(io => io.label === data.pokazatel_stat)[0];

            setInitVals(data);
        } else {
            // @ts-ignore
            const data = {
                installments_type_id: DealInstallmentsTypeNameOptions.filter(io => io.value === 1)[0],
                pokazatel_stat: PokazatelStatistikiNameOptions.filter(io => io.value === 1)[0],
            }
            if(props.offerId) {
                let offerById = (await getOffer(props.offerId)).data;
                let insuranceAward = offerById.insurance_award;
                // @ts-ignore
                data['sp'] = insuranceAward
            }
            getInsuranceObject(insuranceObjectActiveId).then((io) => {
                const insStr = io.data.insurance_sum;
                // @ts-ignore
                data['ss'] = parseInt(insStr)
                setInitVals(data);
            })
        }
    }

    useEffect(() => {
            setLoading(false);
            loadingFunc()
        },
        []
    )

    const validateValues = (vals: any) => {
        // эта валидация существует здесь, потому что в FormBuilder отсутсвует валидация форм в виде массивов
        let validationErrors: string[] = []
        vals.offer.forEach((v: any, i: number) => {
            const offerNumber = i + 1;
            // валидация тарифа
            const tarifStr = v.tarif;
            try {
                // @ts-ignore
                const tarifInt = parseInt(tarifStr)
                if (tarifInt > 100) {
                    validationErrors.push("Предложение №" + offerNumber +": 0 > Тариф < 100")
                }
            } catch (e) {
                validationErrors.push("Предложение №" + offerNumber +": Некорректный тариф. Корректный тариф, например: 1.9999 или 99")
            }

            // валидация блока франшизы
            if (v.is_franchise_exist === true) {
                try {
                    if (!v.franchise.hasOwnProperty("franchise_price") || v.franchise.franchise_price == 0) {
                        validationErrors.push("Предложение №" + offerNumber +": Стоимость Франшизы для предложения не может быть равна 0")
                    } else {
                        v.is_franchise_conditional = v.franchise.is_franchise_conditional ? v.franchise.is_franchise_conditional : false;
                        v.franchise_price = v.franchise.franchise_price;
                        delete v.franchise
                    }
                } catch (e) {
                    validationErrors.push("Предложение №" + offerNumber +": Некорректно заполен блок Франшиза")
                }
            }
        })

        return validationErrors
    }

    const onSubmit = async (values: any) => {
        //setSaving(true);
        const vals = {...values};
        vals.installments_type_id = vals.installments_type_id.value;
        vals.pokazatel_stat = vals.pokazatel_stat.label;

        if (vals.kv_v_perc) {
            vals.kv_v_perc = vals.kv_v_perc.toString()
        }

        if(props.dealId) {
            //console.log('vals', vals);
            await updateDeal(props.dealId, vals, prevData);
        } else {
            await createDeal(props.quotationId, vals);
            await dispatch(fetchInsuranceObjects({clientId: activeClientId}));

            await dispatch(resetPagination());
            // @ts-ignore
            await dispatch(setScrollIndex(0));
            await dispatch(fetchQuotes({skip: 0, take: 40, activeInsuranceId: activeId}));
        }


        setSaving(false);
        props.onClose && props.onClose();

    }


    const onDeleteFile = async (fileId: string, name: string) => {
        if(['account_file', 'contract_file'].includes(name)) {
            return
        } else if(name === "files") {

            // TODO: it`s incorrect behavior
            if(props.dealId === "") { return }
            await deleteDealFile(props.dealId, fileId);
        }
    }


    if (loading) {
        return <div
            style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size={100}/>
        </div>
    } else {
        return (
            <div>
                <FormBuilder
                    onClose={props.onClose}
                    onDeleteFile={onDeleteFile}
                    initialValues={initVals}
                    onSubmit={onSubmit}
                    data={physicalData}
                    useTab={false}
                    disableAll={false}
                    loading={saving}
                    title={props.dealId ? 'Редактирование сделки' : 'Создание сделки'}
                    onDownloadFile={async (id: string) => {
                        //console.log('hello')
                        await downloadFileById(id)
                    }}
                />
            </div>
        )
    }

}

export default EditDealForm;
