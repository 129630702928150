import React, {useEffect, useState} from 'react';
import classes from "./InsuranceObjectForm.module.scss"
import {
    InsuranceObjectBasementTypeNameOptions,
    InsuranceObjectFinanceTypeNameOptions,
    InsuranceObjectPropertyTypeNameOptions,
    InsuranceObjectResidenceTypeNameOptions,
    InsuranceObjectTypeNameOptions,
    InsuranceObjectTypesEnum,
    InsuranceObjectWallMaterialsNameOptions,
    RequestStatusEnum,
    RoleIdsEnum
} from "../../common/commonTypes";
import FormBuilder, {ComponentInfo, FieldInfo, NamedGroupInfo, TabFormInterface} from "../FormBuilder/FormBuilder";
import {renderToStaticMarkup} from "react-dom/server";
import {
    createInsuranceObject,
    downloadFileById,
    findDaDataAddress,
    findDaDataCar,
    getFromKontur,
    getInsuranceObject,
    updateInsuranceObject
} from "../../api/commonApi";
import {useAppSelector} from "../../store/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {
    fetchInsuranceObjects,
    setActiveId,
    setFilterIOId,
    setLastAddedDate
} from "../../store/features/insuranceObjects";
import {useDispatch} from "react-redux";
import _, {isUndefined} from "lodash";
import {setFilterQuotationId} from "../../store/features/quotesTable";
// @ts-ignore
import html2pdf from "html2pdf.js"
import cn from "classnames";
import AddButton from "../../features/ui/AddButton/AddButtonButton";
import {toast} from "react-toastify";

const Delimeter = (props: { title: string, className?: any, style?: any }) => {
    return <div className={props.className} style={props.style}>{props.title}</div>
}


const pricesFields = [
    "other_name_insurance_price",
    "windows_insurance_price",
    "furniture_inventory_insurance_price",
    "production_equipment_insurance_price",
    "room_in_building_insurance_price",
    "land_plot_insurance_price",
    "items_resources_insurance_price",
    "computers_insurance_price",
    "room_decor_insurance_price",
    "building_insurance_price"
]

const securityGroupRequired = (form: any): boolean => {
    if (form.commerc_tmc === undefined) {
        return false;
    }

    return !(form.commerc_tmc.is_chop_security || form.commerc_tmc.is_employees_security || form.commerc_tmc.is_mvd_security)
}

const apartmentFields: Array<FieldInfo | ComponentInfo> = [
    {
        type: "string",
        key: "cadastral_number",
        label: "Кадастровый номер",
        required: false,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
    },
    {
        type: "number",
        key: "constructive_price",
        label: "Конструктив (Страховая сумма) *",
        onChange: (form, e) => updateAppartmentSum(form, e, "constructive_price"),
        required: true,
        validate: (val: string) => true,
    },
    {
        type: "number",
        key: "inner_decoration_price",
        label: "Внутренняя отделка (Страховая сумма)",
        onChange: (form, e) => updateAppartmentSum(form, e, "inner_decoration_price"),
        required: false,
        validate: (val: string) => true,
    },
    {
        type: "number",
        key: "area",
        label: "Площадь м2",
        required: false,
        validate: (val: string) => true,
    },
    {
        type: "number",
        format: "year",
        key: "built_year",
        label: "Год постройки",
        required: (form) => {
            if (form.apartment == undefined) {
                return false;
            } else if (form.apartment.built_year == undefined || form.apartment.built_year == "") {
                return false;
            } else if (form.apartment.built_year < 1501 || form.apartment.built_year > new Date().getFullYear()) {
                return "1500 < Год постройки < " + new Date().getFullYear()
            } else {
                return false
            }
        },
        validate: (val: string) => true,
    },
    {
        type: "checkbox",
        key: "is_security_system",
        label: "Наличие охранных систем",
        required: false,
        validate: (val: string) => true,

    },
    {
        type: "checkbox",
        key: "is_under_construction",
        label: "Проводятся ремонтные работы",
        required: false,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
    },
    {
        type: "string",
        key: "property_type",
        label: "Тип Собственности",
        required: false,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
    },
    {
        type: "string",
        key: "residence_type",
        label: "Вид проживания",
        required: false,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
    },
    {
        type: "autocomplete",
        key: "wall_materials",
        label: "Материалы стен",
        required: false,
        options: InsuranceObjectWallMaterialsNameOptions,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
        onChange: (form, v) => {
            try {
                // @ts-ignore
                window.setFormValue('options.wall_materials', InsuranceObjectWallMaterialsNameOptions);
            } catch (e) {
            }
        }
    },
];

const houseFields: Array<FieldInfo> = [
    {
        type: "string",
        key: "cadastral_number",
        label: "Кадастровый номер",
        required: false,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
    },
    {
        type: "number",
        key: "constructive_price",
        label: "Конструктив (Страховая сумма) *",
        onChange: (form, e) => updateHouseSum(form, e, "constructive_price"),
        required: true,
        validate: (val: string) => true,
    },
    {
        type: "number",
        key: "inner_decoration_price",
        label: "Внутренняя отделка (Страховая сумма)",
        onChange: (form, e) => updateHouseSum(form, e, "inner_decoration_price"),
        required: false,
        validate: (val: string) => true,
    },
    {
        type: "number",
        key: "area",
        label: "Площадь",
        required: false,
        validate: (val: string) => true,
    },
    {
        type: "number",
        format: "year",
        key: "built_year",
        label: "Год постройки",
        required: (form) => {
            if (form.house == undefined) {
                return false;
            } else if (form.house.built_year == undefined || form.house.built_year == "") {
                return false;
            } else if (form.house.built_year < 1501 || form.house.built_year > new Date().getFullYear()) {
                return "1500 < Год постройки < " + new Date().getFullYear()
            } else {
                return false
            }
        }, validate: (val: string) => true,
    },
    {
        type: "checkbox",
        key: "is_security_system",
        label: "Наличие охранных систем",
        required: false,
        validate: (val: string) => true,

    },
    {
        type: "checkbox",
        key: "is_under_construction",
        label: "Проводятся ремонтные работы",
        required: false,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
    },
    {
        type: "autocomplete",
        key: "property_type",
        label: "Тип Собственности",
        required: false,
        options: InsuranceObjectPropertyTypeNameOptions,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
        onChange: (form, v) => {
            // @ts-ignore
            window.setFormValue('options.property_type', InsuranceObjectPropertyTypeNameOptions);
        }
    },
    {
        type: "autocomplete",
        key: "residence_type",
        label: "Вид проживания",
        required: false,
        options: InsuranceObjectResidenceTypeNameOptions,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
        onChange: (form, v) => {
            // @ts-ignore
            window.setFormValue('options.residence_type', InsuranceObjectResidenceTypeNameOptions);
        }
    },
    {
        type: "autocomplete",
        key: "wall_materials",
        label: "Материалы стен, перекрытий, кровли",
        required: false,
        options: InsuranceObjectWallMaterialsNameOptions,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
        onChange: (form, v) => {
            try {
                // @ts-ignore
                window.setFormValue('options.wall_materials', InsuranceObjectWallMaterialsNameOptions);
            } catch (e) {
            }
        }
    },
    {
        type: "autocomplete",
        key: "basement_type",
        label: "Тип фундамента",
        required: false,
        options: InsuranceObjectBasementTypeNameOptions,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
        onChange: (form, v) => {
            try {
                // @ts-ignore
                window.setFormValue('options.basement_type', InsuranceObjectBasementTypeNameOptions);
            } catch (e) {
            }
        }
    },
    {
        type: "string",
        key: "communications",
        label: "Коммуникации",
        required: false,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
    },
    {
        type: "checkbox",
        key: "is_fire_resistance_system",
        label: "Наличие Систем пожаротушения",
        required: false,
        validate: (val: string) => true,
        showIf: (form: TabFormInterface) => true,
    },
];

const vehicleFields: Array<FieldInfo | ComponentInfo | NamedGroupInfo> = [
    {
        namedGroup: {
            name: "",
            class: classes.apartmentGroup,
            fields: [
                {
                    type: "checkbox",
                    key: "is_multiple",
                    label: "Несколько ТС",
                    required: false,
                    validate: (val: string) => true
                },
                {
                    type: "autocomplete",
                    key: "brand",
                    label: "Марка *",
                    required: true,
                    validate: (val: string) => true,
                    onChange: (form, v) => {
                        try {
                            findDaDataCar(v).then((data) => {
                                // @ts-ignore
                                const opts = []
                                data.data.suggestions.forEach((addr) => {
                                    opts.push({
                                        "value": addr.value,
                                        "label": addr.value
                                    })
                                })
                                // @ts-ignore
                                window.setFormValue('options.brand', opts);
                            })
                        } catch (e) {
                        }
                    }
                },
                {
                    type: "string",
                    key: "model",
                    label: "Модель *",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "vin_number",
                    label: "VIN номер",
                    validate: (val: string) => true,
                    required: form => {
                        if (!form || !form.vehicle) return false;
                        return !form.vehicle.is_multiple
                    },
                    disabled: (form) => {
                        if (!form || ! form.vehicle) return false;
                        return form.vehicle.is_multiple;
                    }
                },
                {
                    type: "number",
                    format: "year",
                    key: "assemble_year",
                    label: "Год выпуска",
                    required: (form) => {
                        if (form.vehicle == undefined) {
                            return false;
                        } else if (form.vehicle.assemble_year == undefined || form.vehicle.assemble_year == "") {
                            return false;
                        } else if (form.vehicle.assemble_year < 1501 || form.vehicle.assemble_year > new Date().getFullYear()) {
                            return "1500 < Год выпуска < " + new Date().getFullYear()
                        } else {
                            return false
                        }
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "number",
                    format: "year",
                    key: "count",
                    label: "Количество единиц",
                    required: false,
                    validate: (val: string) => true,
                    showIf: (form) => {
                        if (!form || ! form.vehicle) return false;
                        return form.vehicle.is_multiple;
                    }
                },
                {
                    type: "number",
                    key: "all_insurance_sum",
                    label: "Общая страховая сумма",
                    required: false,
                    validate: (val: string) => true,
                    showIf: (form) => {
                        if (!form || ! form.vehicle) return false;
                        return form.vehicle.is_multiple;
                    }
                },
                {
                    type: "number",
                    key: "price",
                    label: "Стоимость",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "number",
                    key: "distance_passed",
                    label: "Пробег",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },
    {
        component: {
            component: renderToStaticMarkup(<Delimeter title={"Информация о водителе и регионе"}
                                                       className={classes.delim}/>),
        },
    },
    {
        namedGroup: {
            name: "",
            class: classes.apartmentGroup,
            fields: [
                {
                    type: "string",
                    key: "driver_fio",
                    label: "Водитель ФИО",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "usage_region",
                    label: "Регион использования",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "number",
                    key: "driver_age",
                    label: "Возраст Водителя",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "number",
                    key: "driver_experience_years",
                    label: "Стаж вождения",
                    required: (form) => {
                        if (form.vehicle == undefined) {
                            return false;
                        } else if (form.vehicle.driver_experience_years == undefined) {
                            return false;
                        } else if (form.vehicle.driver_age && form.vehicle.driver_experience_years > form.vehicle.driver_age) {
                            return "Стаж должен быть меньше, чем возраст"
                        } else if (form.vehicle.driver_age && ((form.vehicle.driver_age - form.vehicle.driver_experience_years) < 18)) {
                            return "При введенном возрасте, максимальный стаж: " + (form.vehicle.driver_age - 18).toString()
                        } else {
                            return false
                        }
                    },
                    validate: (val: string) => true,
                },
            ]
        }
    }
];

const otherTransportFields: Array<FieldInfo | ComponentInfo | NamedGroupInfo> = [
    {
        namedGroup: {
            name: "",
            class: classes.apartmentGroup,
            fields: [
                {
                    type: "checkbox",
                    key: "is_aviation",
                    label: "Авиация",
                    onChange: evt => {
                        // @ts-ignore
                        window.setFormValue('other_transport.is_railroad', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_ship', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_agri_machinery', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_other', false)
                    },
                    required: (form) => {
                        if (form.other_transport === undefined) {
                            return false;
                        }

                        return !form.other_transport.is_railroad && !form.other_transport.is_ship
                            && !form.other_transport.is_agri_machinery && !form.other_transport.is_other;
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_railroad",
                    label: "Железнодорожный транспорт",
                    onChange: evt => {
                        // @ts-ignore
                        window.setFormValue('other_transport.is_aviation', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_ship', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_agri_machinery', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_other', false)
                    },
                    required: (form) => {
                        if (form.other_transport === undefined) {
                            return false;
                        }

                        return !form.other_transport.is_aviation && !form.other_transport.is_ship
                            && !form.other_transport.is_agri_machinery && !form.other_transport.is_other;
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_ship",
                    label: "Суда",
                    onChange: evt => {
                        // @ts-ignore
                        window.setFormValue('other_transport.is_railroad', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_aviation', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_agri_machinery', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_other', false)
                    },
                    required: (form) => {
                        if (form.other_transport === undefined) {
                            return false;
                        }

                        return !form.other_transport.is_railroad && !form.other_transport.is_aviation
                            && !form.other_transport.is_agri_machinery && !form.other_transport.is_other;
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_agri_machinery",
                    label: "Сельхоз и спецтехника",
                    onChange: evt => {
                        // @ts-ignore
                        window.setFormValue('other_transport.is_railroad', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_ship', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_aviation', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_other', false)
                    },
                    required: (form) => {
                        if (form.other_transport === undefined) {
                            return false;
                        }

                        return !form.other_transport.is_railroad && !form.other_transport.is_ship
                            && !form.other_transport.is_aviation && !form.other_transport.is_other;
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_other",
                    label: "Иное",
                    onChange: evt => {
                        // @ts-ignore
                        window.setFormValue('other_transport.is_railroad', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_ship', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_agri_machinery', false)
                        // @ts-ignore
                        window.setFormValue('other_transport.is_aviation', false)
                    },
                    required: (form) => {
                        if (form.other_transport === undefined) {
                            return false;
                        }

                        return !form.other_transport.is_railroad && !form.other_transport.is_ship
                            && !form.other_transport.is_agri_machinery && !form.other_transport.is_aviation;
                    },
                    validate: (val: string) => true,
                },
            ]
        }
    },
    {
        component: {
            component: renderToStaticMarkup(<Delimeter title={"Информация о транспорте"}
                                                       className={classes.delim}/>),
        },
    },
    {
        namedGroup: {
            name: "aviation",
            class: classes.apartmentGroup,
            showIf: (form: any) => {
                if (form.other_transport == undefined) {
                    return false;
                }
                return form.other_transport.is_aviation
            },
            fields: [
                {
                    type: "string",
                    key: "model",
                    label: "Тип воздушного судна (марка, модель)",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "number",
                    format: "year",
                    key: "built_year",
                    label: "Год выпуска",
                    required: form => {
                        if (form == undefined) {
                            return false;
                        }
                        if (form.other_transport == undefined) {
                            return false;
                        }
                        if (!form.other_transport.is_aviation) {
                            return false
                        }
                        if (form.other_transport.aviation == undefined) {
                            return false
                        }
                        if (form.other_transport.aviation.built_year == undefined || form.other_transport.aviation.built_year == "") {
                            return false;
                        } else if (form.other_transport.aviation.built_year < 1901 || form.other_transport.aviation.built_year > new Date().getFullYear()) {
                            return "1900 < Год постройки < " + new Date().getFullYear()
                        } else {
                            return false
                        }
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "use_case",
                    label: "Цель использования",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "geo",
                    label: "Географические районы, эксплуатации воздушного судна",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "number",
                    key: "max_flight_weight",
                    label: "Максимальный взлетный вес воздушного судна (в тоннах)",
                    required: form => {
                        if (form == undefined) {
                            return false;
                        }
                        if (form.other_transport == undefined) {
                            return false;
                        }
                        if (!form.other_transport.is_aviation) {
                            return false
                        }
                        if (form.other_transport.aviation == undefined) {
                            return false
                        }
                        if (form.other_transport.aviation.max_flight_weight == undefined) {
                            return false;
                        } else if (form.other_transport.aviation.max_flight_weight < 0) {
                            return "Не может быть отрицательных значений"
                        } else {
                            return false
                        }
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "number",
                    key: "max_capacity",
                    label: "Максимальная грузоподъемность воздушного судна (в тоннах)",
                    required: form => {
                        if (form == undefined) {
                            return false;
                        }
                        if (form.other_transport == undefined) {
                            return false;
                        }
                        if (!form.other_transport.is_aviation) {
                            return false
                        }
                        if (form.other_transport.aviation == undefined) {
                            return false
                        }
                        if (form.other_transport.aviation.max_capacity == undefined) {
                            return false;
                        } else if (form.other_transport.aviation.max_capacity < 0) {
                            return "Не может быть отрицательных значений"
                        } else {
                            return false
                        }
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "number",
                    key: "max_passengers",
                    label: "Пассажировместимость воздушного судна (количество мест)",
                    required: form => {
                        if (form == undefined) {
                            return false;
                        }
                        if (form.other_transport == undefined) {
                            return false;
                        }
                        if (!form.other_transport.is_aviation) {
                            return false
                        }
                        if (!form.other_transport.aviation) {
                            return false
                        }
                        if (form.other_transport.aviation.max_passengers == undefined) {
                            return false;
                        } else if (form.other_transport.aviation.max_passengers < 0) {
                            return "Не может быть отрицательных значений"
                        } else {
                            return false
                        }
                    },
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "user",
                    label: "Эксплуатант воздушного судна",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "airport",
                    label: "Аэропорт базирования",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "loss_3_year",
                    label: "Информация по убыткам за последние 3 года",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "additional_insurance_conditions",
                    label: "Дополнительные условия страхования ",
                    required: false,
                    validate: (val: string) => true,
                }
            ]
        }
    },
    {
        namedGroup: {
            name: "railroad",
            showIf: (form: any) => {
                if (form.other_transport == undefined) {
                    return false;
                }
                return form.other_transport.is_railroad
            },
            fields: [
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                group: {
                                    maxLength: 5,
                                    name: "transports",
                                    class: cn(classes.offerGroup),
                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить транспорт'}/>),
                                    onDelete: async (id, values) => {
                                        if (values === undefined) {
                                            return;
                                        }
                                        if (values.other_transport === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.railroad === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.railroad.transports === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.railroad.transports[id] === undefined) {
                                            return;
                                        }

                                        values.other_transport.railroad.transports.splice(id, 1);
                                        updateAllOtherTransportSum(values, "railroad", "transports")
                                    },
                                    fields: [
                                        [
                                            {
                                                type: "string",
                                                key: "name",
                                                label: "Наименование вагона/марка",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                key: "count",
                                                label: "Количество",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                format: "year",
                                                key: "assemble_year",
                                                label: "Год выпуска",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                key: "insurance_sum",
                                                label: "Страховая сумма *",
                                                onRealChange: (form, v, idx) => updateOtherTransportSum(form, v, idx, "railroad", "transports"),
                                                required: true,
                                                validate: (val: string) => true,
                                            }
                                        ]
                                    ]
                                }
                            },
                            {
                                type: "string",
                                key: "additional_information",
                                label: "Дополнительная информация",
                                required: false,
                                validate: (val: string) => true,
                            },
                            {
                                type: "string",
                                key: "loss_3_year",
                                label: "Информация по убыткам за последние 3 года",
                                required: false,
                                validate: (val: string) => true,
                            }
                        ]
                    }
                }
            ]
        }
    },
    {
        namedGroup: {
            name: "ship",
            class: classes.apartmentGroup,
            showIf: (form: any) => {
                if (form.other_transport == undefined) {
                    return false;
                }
                return form.other_transport.is_ship
            },
            fields: [
                {
                    type: "string",
                    key: "name",
                    label: "Тип судна, модель, название",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "flag_register_place",
                    label: "Флаг (если есть) и место приписки",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "year_built_place",
                    label: "Год и место постройки",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "size",
                    label: "Размеры (длина, ширина, осадка)",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "body_material",
                    label: "Материал корпуса",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "engines",
                    label: "Двигатели (количество, модель, мощность, стационарный или навесной, серийный номер)",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "use_case",
                    label: "Цель эксплуатации",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "place_period_idle",
                    label: "Место и период отстоя (хранения)",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "additional_insurance_conditions",
                    label: "Дополнительные условия страхования ",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "ledovii_class",
                    label: "Ледовый Класс",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "cin_ship_identity",
                    label: "CIN Идентификационный номер судна",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "loss_3_year",
                    label: "Информация по убыткам за последние 3 года",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    component: {
                        component: renderToStaticMarkup(<Delimeter title={"Не забудьте загрузить Судовой билет!"}
                                                                   className={cn(classes.info, classes.offerGroup)}/>),
                    },
                },
            ]
        }
    },
    {
        namedGroup: {
            name: "agri_machinery",
            showIf: (form: any) => {
                if (form.other_transport == undefined) {
                    return false;
                }
                return form.other_transport.is_agri_machinery
            },
            fields: [
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                group: {
                                    maxLength: 5,
                                    name: "machineries",
                                    class: cn(classes.offerGroup),
                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить технику'}/>),
                                    onDelete: async (id, values) => {
                                        if (values === undefined) {
                                            return;
                                        }
                                        if (values.other_transport === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.agri_machinery === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.agri_machinery.machineries === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.agri_machinery.machineries[id] === undefined) {
                                            return;
                                        }

                                        values.other_transport.agri_machinery.machineries.splice(id, 1);
                                        updateAllOtherTransportSum(values, "agri_machinery", "machineries")
                                    },
                                    fields: [
                                        [
                                            {
                                                type: "string",
                                                key: "name",
                                                label: "Марка/модель *",
                                                required: true,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                key: "count",
                                                label: "Количество",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "factory_number",
                                                label: "Заводской номер",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                format: "year",
                                                key: "assemble_year",
                                                label: "Год выпуска",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                key: "insurance_sum",
                                                label: "Страховая сумма *",
                                                onRealChange: (form, v, idx) => updateOtherTransportSum(form, v, idx, "agri_machinery", "machineries"),
                                                required: true,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "activity_type",
                                                label: "Вид деятельности или отрасли, где используются техника",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                        ]
                                    ]
                                }
                            },
                            {
                                type: "string",
                                key: "additional_information",
                                label: "Дополнительная информация",
                                required: false,
                                validate: (val: string) => true,
                            }
                        ]
                    }
                }
            ]
        }
    },
    {
        namedGroup: {
            name: "other",
            showIf: (form: any) => {
                if (form.other_transport == undefined) {
                    return false;
                }
                return form.other_transport.is_other
            },
            fields: [
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                group: {
                                    maxLength: 5,
                                    name: "others",
                                    class: cn(classes.offerGroup),
                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить'}/>),
                                    onDelete: async (id, values) => {
                                        if (values === undefined) {
                                            return;
                                        }
                                        if (values.other_transport === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.other === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.other.others === undefined) {
                                            return;
                                        }
                                        if (values.other_transport.other.others[id] === undefined) {
                                            return;
                                        }

                                        values.other_transport.other.others.splice(id, 1);
                                        updateAllOtherTransportSum(values, "other", "others")
                                    },
                                    fields: [
                                        [
                                            {
                                                type: "string",
                                                key: "name",
                                                label: "Марка/модель *",
                                                required: true,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                key: "count",
                                                label: "Количество",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                format: "year",
                                                key: "assemble_year",
                                                label: "Год выпуска",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "number",
                                                key: "insurance_sum",
                                                label: "Страховая сумма *",
                                                onRealChange: (form, v, idx) => updateOtherTransportSum(form, v, idx, "other", "others"),
                                                required: true,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "activity_type",
                                                label: "Вид деятельности или отрасли, где используются техника",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                        ]
                                    ]
                                }
                            },
                            {
                                type: "string",
                                key: "additional_information",
                                label: "Дополнительная информация",
                                required: false,
                                validate: (val: string) => true,
                            }
                        ]
                    }
                }
            ]
        }
    }
]

const showWhenChooseTmc = (form: any) => {
    if (form.commerc_tmc == undefined) {
        return false;
    }

    let show = false;

    for (let tmc of tmcFieldsArray) {
        if (!!form.commerc_tmc[tmc]) {
            show = true;
            break
        }
    }

    //console.log('show', show)

    return show;
}

const commercialSelectedTMCFieldsObjectInfo: Array<FieldInfo | NamedGroupInfo | ComponentInfo> = [
    {
        component: {
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return !!form.commerc_tmc.is_items_resources
            },
            component: renderToStaticMarkup(<Delimeter title={"Организация хранения  ТМЦ"} className={classes.delim}/>),
        },
    },
    {
        namedGroup: {
            name: "",
            class: classes.apartmentGroup,
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return !!form.commerc_tmc.is_items_resources
            },
            fields: [
                {
                    type: "string",
                    key: "nomen_group_tmc",
                    label: "Номенклатурная группа ТМЦ",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_on_rack_below_55_organize_tmc",
                    label: "На стеллажах высотою до 5,5 м",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_on_rack_above_55_organize_tmc",
                    label: "На стеллажах высотою более 5,5 м",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_on_pallet_organize_tmc",
                    label: "На поддонах",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_on_floor_organize_tmc",
                    label: "На голом полу",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_on_floor_without_rack_organize_tmc",
                    label: "На полу - без стеллажное хранение",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_on_rack_combustible_material_organize_tmc",
                    label: "На стеллажах из горючего материала",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },
    {
        component: {
            showIf: showWhenChooseTmc,
            component: renderToStaticMarkup(<Delimeter title={"Характеристики здания"} className={classes.delim}/>),
        },
    },
    {
        namedGroup: {
            name: "",
            class: classes.builtYear,
            fields: [
                {
                    type: "number",
                    format: "year",
                    key: "built_year",
                    label: "Год постройки",
                    required: form => {
                        if (form == undefined) {
                            return false;
                        }
                        if (form.commerc_tmc == undefined) {
                            return false;
                        }
                        if (!form.commerc_tmc.is_building) {
                            return false
                        }
                        if (form.commerc_tmc.built_year == undefined || form.commerc_tmc.built_year == "") {
                            return false;
                        } else if (form.commerc_tmc.built_year < 1501 || form.commerc_tmc.built_year > new Date().getFullYear()) {
                            return "1500 < Год постройки < " + new Date().getFullYear()
                        } else {
                            return false
                        }
                    },
                    validate: (val: string) => true,
                },
            ]
        }
    },
    {
        namedGroup: {
            name: "",
            class: classes.apartmentGroup,
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return showWhenChooseTmc(form) && !form.commerc_tmc.is_land_plot && !form.commerc_tmc.is_windows
            },
            fields: [
                {
                    type: "checkbox",
                    key: "is_not_wooden_overlap_building_materials",
                    label: "Не деревянные перекрытия",
                    onChange: evt => {
                        // @ts-ignore
                        // @ts-ignore
                        window.setFormValue('commerc_tmc.is_wooden_overlap_building_materials', !evt.target.checked)
                    },
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_wooden_overlap_building_materials",
                    label: "Деревянные перекрытия",
                    onChange: evt => {
                        // @ts-ignore
                        window.setFormValue('commerc_tmc.is_not_wooden_overlap_building_materials', !evt.target.checked)
                    },
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },
    {
        component: {
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return showWhenChooseTmc(form) && !form.commerc_tmc.is_land_plot
            },
            component: renderToStaticMarkup(<Delimeter title={"Функциональное назначение здания"}
                                                       className={classes.delim}/>),
        },
    },

    {
        namedGroup: {
            name: "",
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return !form.commerc_tmc.is_land_plot
            },
            class: classes.apartmentGroup,
            fields: [
                {
                    type: "checkbox",
                    key: "is_production_building_function",
                    label: "Производственное",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_administrative_building_function",
                    label: "Административное",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_sale_building_function",
                    label: "Торговое",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_warehouse_building_function",
                    label: "Складское",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_living_building_function",
                    label: "Жилое",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "other_building_function",
                    label: "Иное",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },

    {
        component: {
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return showWhenChooseTmc(form) && !form.commerc_tmc.is_land_plot && !form.commerc_tmc.is_windows
            },
            component: renderToStaticMarkup(<Delimeter title={"Система отопления"} className={classes.delim}/>),
        },
    },
    {
        namedGroup: {
            name: "",
            class: classes.apartmentGroup,
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return !form.commerc_tmc.is_land_plot && !form.commerc_tmc.is_windows
            },
            fields: [
                {
                    type: "checkbox",
                    key: "is_gazoline_heating_system",
                    label: "Газ",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_electricity_heating_system",
                    label: "Электричество",
                    /*showIf: form => {
                        form.commerc_tmc.is_land_plot || form.commerc_tmc.is_windows
                        return !!form.commerc_tmc.is_building
                    },*/
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "other_heating_system",
                    label: "Иное",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },
    {
        component: {
            showIf: showWhenChooseTmc,
            component: renderToStaticMarkup(<Delimeter title={"Система пожаротушения"} className={classes.delim}/>),
        },
    },
    {
        namedGroup: {
            name: "",
            fields: [
                {
                    type: "string",
                    key: "fire_resistance_system",
                    class: classes.fire_resistance_system,
                    showIf: (form: any) => {
                        if (form.commerc_tmc == undefined) {
                            return false;
                        }
                        return !form.commerc_tmc.is_land_plot
                    },
                    label: "Первичные средства пожаротушения (огнетушители, пожарные краны, гидранты)",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },

    {
        namedGroup: {

            name: "",
            class: classes.groupCheckBox,
            fields: [
                {
                    type: "checkbox",
                    key: "is_auto_fire_resistance_fire_system",
                    label: "Автоматическая пожарная сигнализация",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },
    {
        namedGroup: {
            name: "",
            class: classes.apartmentGroup,
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return form.commerc_tmc.is_auto_fire_resistance_fire_system
            },
            fields: [
                {
                    type: "checkbox",
                    key: "is_mchs_pult_automatic_fire_system",
                    label: "С выходом на пульт МЧС",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_24_hours_pult_automatic_fire_system",
                    label: "С выходом на круглосуточный пульт",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_drencher_automatic_fire_system",
                    label: "Дренчер / Спринклер",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_par_gaz_pena_automatic_fire_system",
                    label: "Пар / Газ / Пена / Иное",
                    required: false,
                    validate: (val: string) => true,
                },
                /*   {
                       type: "checkbox",
                       key: "",
                       label: "Система пожаротушения",
                       required: false,
                       validate: (val: string) => true,
                   },*/
            ]
        }
    },
    {
        namedGroup: {

            name: "",
            class: classes.groupCheckBox,
            fields: [
                {
                    type: "checkbox",
                    key: "is_security",
                    label: "Присутствует охрана на объекте",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },
    {
        component: {
            showIf: (form: any) => showWhenChooseTmc(form) && form.commerc_tmc.is_security,
            component: renderToStaticMarkup(<Delimeter title={"Охрана"} className={classes.delim}
                                                       style={{marginTop: '0px'}}/>),
        },
    },
    {
        namedGroup: {

            name: "",
            class: classes.apartmentGroup,
            showIf: (form: any) => showWhenChooseTmc(form) && form.commerc_tmc.is_security,
            fields: [
                {
                    type: "checkbox",
                    key: "is_chop_security",
                    label: "ЧОП",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_employees_security",
                    label: "Сотрудники страхователя",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "checkbox",
                    key: "is_mvd_security",
                    label: "МВД",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },

    {
        component: {
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return showWhenChooseTmc(form) && !form.commerc_tmc.is_land_plot && form.commerc_tmc.is_security
            },
            component: renderToStaticMarkup(<Delimeter title={"Видеоконтроль"}
                                                       className={classes.delim}/>),
        },
    },
    {
        namedGroup: {

            name: "",
            class: classes.apartmentGroup,
            showIf: form => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return showWhenChooseTmc(form) && !form.commerc_tmc.is_land_plot && form.commerc_tmc.is_security
            },
            fields: [
                {
                    type: "checkbox",
                    key: "is_with_record_video_control",
                    label: "С записью",
                    onChange: evt => {
                        // @ts-ignore
                        window.setFormValue('commerc_tmc.is_without_record_video_control', !evt.target.checked)
                    },
                    required: false,
                    validate: (form: any) => true
                },
                {
                    type: "checkbox",
                    key: "is_without_record_video_control",
                    label: "Без записи",
                    onChange: evt => {
                        // @ts-ignore
                        window.setFormValue('commerc_tmc.is_with_record_video_control', !evt.target.checked)
                    },
                    required: false,
                    validate: (form: any) => true
                },
                {
                    type: "number",
                    key: "area_overlap_percent_video_control",
                    label: "Площадь покрытия (%)",
                    validate: (val: string) => true,
                    required: (form) => {
                        if (form.commerc_tmc == undefined) {
                            return false;
                        } else if (form.commerc_tmc.area_overlap_percent_video_control == undefined) {
                            return false;
                        } else if (form.commerc_tmc.area_overlap_percent_video_control > 100) {
                            return "< 100"
                        } else {
                            return true
                        }
                    },
                },
            ]
        }
    },

    {
        namedGroup: {
            name: "",
            class: classes.groupCheckBox,
            fields: [
                {
                    type: "checkbox",
                    key: "is_alarm_button_exists_automatic_security",
                    showIf: form => {
                        if (form.commerc_tmc == undefined) {
                            return false;
                        }
                        return showWhenChooseTmc(form) && !form.commerc_tmc.is_land_plot
                    },
                    label: "Тревожная кнопка",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },

    {
        component: {
            showIf: (form: any) => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return showWhenChooseTmc(form) && !form.commerc_tmc.is_land_plot && form.commerc_tmc.is_alarm_button_exists_automatic_security
            },
            component: renderToStaticMarkup(<Delimeter title={"Автоматическая охранная система"}
                                                       className={classes.delim} style={{marginTop: '0px'}}/>),
        },
    },

    {
        namedGroup: {

            name: "",
            class: classes.apartmentGroup,
            showIf: form => {
                if (form.commerc_tmc == undefined) {
                    return false;
                }
                return showWhenChooseTmc(form) && !form.commerc_tmc.is_land_plot && form.commerc_tmc.is_alarm_button_exists_automatic_security
            },
            fields: [
                {
                    type: "checkbox",
                    key: "is_vnevedom_mvd_automatic_security",
                    label: "На пульт вневедомственной охраны МВД",
                    required: false,
                    validate: (form: any) => {
                        return !!form.commerc_tmc.is_vnevedom_mvd_automatic_security || !!form.commerc_tmc.other_connect_automatic_security;
                    },
                },
                {
                    type: "string",
                    key: "other_connect_automatic_security",
                    label: "Иное подключение",
                    required: false,
                    validate: (val: string) => true,
                },
            ]
        }
    },
];

const tmcFieldsArray = ["is_building", "is_room_decor", "is_computers", "is_items_resources",
    "is_land_plot", "is_room_in_building", "is_production_equipment",
    "is_furniture_inventory", "is_windows"
];


const tmcFieldValidator = (form: any) => {
    if (form.commerc_tmc === undefined) {
        return true;
    }

    for (let tmc of tmcFieldsArray) {
        if (form.commerc_tmc.hasOwnProperty(tmc) && !!form.commerc_tmc[tmc]) {
            return false;
        }
    }

    return !(form.commerc_tmc.is_building)
        && !form.commerc_tmc.is_room_decor
        && !form.commerc_tmc.is_computers
        && !form.commerc_tmc.is_items_resources
        && !form.commerc_tmc.is_land_plot
        && !form.commerc_tmc.is_room_in_building
        && !form.commerc_tmc.is_production_equipment
        && !form.commerc_tmc.is_furniture_inventory
        && !form.commerc_tmc.is_windows
}

const updateSum = (form: any, e: any, key: string) => {

    if (form.commerc_tmc == undefined) {
        return false;
    }

    let res = 0;

    for (let pf of pricesFields) {
        if (form.commerc_tmc[pf] === undefined && pf !== key) {
            continue
        }

        try {
            if (pf === key) {
                //console.log('k', "key", e.target.value)
                if (!e.target.value) {
                    continue
                }
                res += parseFloat(e.target.value);
            } else {
                res += parseFloat(form.commerc_tmc[pf]);
            }
        } catch (e) {
        }

    }

    // @ts-ignore
    window.setFormValue('insurance_sum', res);

}


const appartmentsPriceFields = [
    "constructive_price",
    "inner_decoration_price",
]


const updateOtherTransportSum = (form: any, v: any, idx: number, otType: string, key: string) => {
    if (form.other_transport == undefined) {
        // @ts-ignore
        window.setFormValue('insurance_sum', parseFloat(v));
        // @ts-ignore
        return false;
    }

    if (form.other_transport[otType] == undefined) {
        // @ts-ignore
        window.setFormValue('insurance_sum', parseFloat(v));
        // @ts-ignore
        return false;
    }

    if (form.other_transport[otType][key] == undefined) {
        // @ts-ignore
        window.setFormValue('insurance_sum', parseFloat(v));
        // @ts-ignore
        return false;
    }

    let res = parseFloat(v);

    for (let [i, val] of form.other_transport[otType][key].entries()) {
        if (val == undefined) continue
        if (i == idx) continue
        res += parseFloat(val.insurance_sum)
    }

    // @ts-ignore
    window.setFormValue('insurance_sum', res);

}

const updateAllOtherTransportSum = (form: any, otType: string, key: string) => {
    if (form.other_transport == undefined) {
        // @ts-ignore
        return false;
    }

    if (form.other_transport[otType] == undefined) {
        // @ts-ignore
        return false;
    }

    let res = 0;

    for (let [i, val] of form.other_transport[otType][key].entries()) {
        if (val === undefined) continue
        res += parseFloat(val.insurance_sum)
    }

    // @ts-ignore
    window.setFormValue('insurance_sum', res);

}

const updateAppartmentSum = (form: any, e: any, key: string) => {

    //console.log('e.target.value', e.target.value)

    if (form.apartment == undefined) {
        // @ts-ignore
        window.setFormValue('insurance_sum', parseFloat(e.target.value));
        // @ts-ignore
        return false;
    }

    let res = 0;

    for (let pf of appartmentsPriceFields) {
        if (form.apartment[pf] === undefined && pf !== key) {
            continue
        }

        try {
            if (pf === key) {
                if (!e.target.value) {
                    continue
                }
                //console.log('1', parseFloat(e.target.value))
                res += parseFloat(e.target.value);
            } else {
                //console.log('2', parseFloat(e.target.value))
                const num = parseFloat(form.apartment[pf]);
                if (!Number.isNaN(num)) {
                    res += parseFloat(form.apartment[pf]);
                }
            }
        } catch (e) {
        }

    }

    // @ts-ignore
    window.setFormValue('insurance_sum', res);

}

const updateHouseSum = (form: any, e: any, key: string) => {

    if (form.house == undefined) {
        // @ts-ignore
        window.setFormValue('insurance_sum', parseFloat(e.target.value));
        // @ts-ignore
        return false;
    }

    let res = 0;

    for (let pf of appartmentsPriceFields) {
        if (form.house[pf] === undefined && pf !== key) {
            continue
        }

        try {
            if (pf === key) {
                if (!e.target.value) {
                    continue
                }
                //console.log('1', parseFloat(e.target.value))
                res += parseFloat(e.target.value);
            } else {
                //console.log('2', parseFloat(e.target.value))
                const num = parseFloat(form.house[pf]);
                if (!Number.isNaN(num)) {
                    res += parseFloat(form.house[pf]);
                }
            }
        } catch (e) {
        }

    }

    // @ts-ignore
    window.setFormValue('insurance_sum', res);

}


const commercialTMCFieldsObjectInfo: Array<FieldInfo | NamedGroupInfo | ComponentInfo> = [
    {
        namedGroup: {
            class: classes.apartmentGroupFirst,
            name: "",
            fields: [
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_building",
                                label: "Здание, сооружение",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.building_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "building_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "building_insurance_price",
                                label: "Стоимость “Здание, сооружение”",
                                onChange: (form, e) => updateSum(form, e, "building_insurance_price"),
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_building
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_room_decor",
                                label: "Отделка помещений",
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.room_decor_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "room_decor_insurance_price")
                                    }
                                },
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                            },
                            {
                                type: "number",
                                key: "room_decor_insurance_price",
                                label: "Стоимость “Отделка помещений”",
                                onChange: (form, e) => updateSum(form, e, "room_decor_insurance_price"),
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_room_decor
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_computers",
                                label: "Компьютеры, оргтехника",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.computers_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "computers_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "computers_insurance_price",
                                onChange: (form, e) => updateSum(form, e, "computers_insurance_price"),
                                label: "Стоимость “Компьютеры, оргтехника”",
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_computers
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_items_resources",
                                label: "Товарно-материальные ценности (запасы, сырье, прочее)",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.items_resources_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "items_resources_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "items_resources_insurance_price",
                                onChange: (form, e) => updateSum(form, e, "items_resources_insurance_price"),
                                label: "Стоимость “Товарно-материальные ценности (запасы, сырье, прочее)”",
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_items_resources
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_land_plot",
                                label: "Земельный участок",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.land_plot_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "land_plot_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "land_plot_insurance_price",
                                label: "Стоимость “Земельный участок”",
                                onChange: (form, e) => updateSum(form, e, "land_plot_insurance_price"),
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_land_plot
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_room_in_building",
                                label: "Помещение в здании",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.room_in_building_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "room_in_building_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "room_in_building_insurance_price",
                                label: "Стоимость “Помещение в здании”",
                                onChange: (form, e) => updateSum(form, e, "room_in_building_insurance_price"),
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_room_in_building
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_production_equipment",
                                label: "Оборудование производственное, торговое",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.production_equipment_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "production_equipment_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "production_equipment_insurance_price",
                                label: "Стоимость “Оборудование производственное, торговое”",
                                onChange: (form, e) => updateSum(form, e, "production_equipment_insurance_price"),
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_production_equipment
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_furniture_inventory",
                                label: "Мебель, инвертарь, бытовая техника",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.furniture_inventory_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "furniture_inventory_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "furniture_inventory_insurance_price",
                                label: "Стоимость “Мебель, инвертарь, бытовая техника”",
                                onChange: (form, e) => updateSum(form, e, "furniture_inventory_insurance_price"),
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_furniture_inventory
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "checkbox",
                                key: "is_windows",
                                label: "Стекла(окна, витрины, рекламные вывески)",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (evt, form) => {
                                    if (!evt.target.checked) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.windows_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "windows_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "windows_insurance_price",
                                label: "Стоимость “Стекла(окна, витрины, рекламные вывески)”",
                                onChange: (form, e) => updateSum(form, e, "windows_insurance_price"),
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.is_windows
                                },
                            },
                        ],
                    }
                },
                {
                    namedGroup: {
                        name: "",
                        fields: [
                            {
                                type: "string",
                                key: "other_name",
                                label: "Иное, введите название",
                                required: false,
                                validate: (val: string) => true,
                                showIf: (form: TabFormInterface) => true,
                                onChange: (form, evt) => {
                                    if (!evt.target.value) {
                                        // @ts-ignore
                                        window.setFormValue('commerc_tmc.other_name_insurance_price', 0)
                                        updateSum(form, {target: {value: 0}}, "other_name_insurance_price")
                                    }
                                },
                            },
                            {
                                type: "number",
                                key: "other_name_insurance_price",
                                onChange: (form, e) => updateSum(form, e, "other_name_insurance_price"),
                                label: "Стоимость “Иное”",
                                required: false,
                                validate: (val: string) => true,
                                showIf: form => {
                                    if (form.commerc_tmc == undefined) {
                                        return false;
                                    }
                                    return !!form.commerc_tmc.other_name
                                },
                            },
                        ],
                    }
                },
            ]
        }
    },
    {
        namedGroup: {
            name: "",
            showIf: showWhenChooseTmc,
            fields: commercialSelectedTMCFieldsObjectInfo,
        }
    },
];


interface InsuranceObjectFormProps {
    clientId: string,
    insuranceObjectId: string,
    onClose?: () => void,
    header: string,
}

// @ts-ignore
const InsuranceObjectForm = (props: InsuranceObjectFormProps) => {

    const dispatch = useDispatch();

    const {activeClientId} = useAppSelector((state) => state.clientsTable);

    const onSubmit = async (values: any) => {
        const vals = {...values};
        vals.type_id = vals.type_id.value;
        vals.financing_type_id = vals.financing_type_id.value;

        if (!props.insuranceObjectId) {
            vals.status_id = 1;
        } else {
            if (vals.status_id !== undefined) {
                delete vals.status_id;
            }
            if (vals.client_id !== undefined) {
                delete vals.client_id;
            }
        }

        if (vals.type_id === InsuranceObjectTypesEnum.APARTMENT) {
            if (vals.hasOwnProperty("house")) {
                delete vals.house;
            }
            if (vals.hasOwnProperty("vehicle")) {
                delete vals.vehicle;
            }
            if (vals.hasOwnProperty("commerc_tmc")) {
                delete vals.commerc_tmc;
            }
            if (vals.hasOwnProperty("other_transport")) {
                delete vals.other_transport;
            }
        } else if (vals.type_id === InsuranceObjectTypesEnum.HOUSE) {
            if (vals.hasOwnProperty("apartment")) {
                delete vals.apartment;
            }
            if (vals.hasOwnProperty("vehicle")) {
                delete vals.vehicle;
            }
            if (vals.hasOwnProperty("commerc_tmc")) {
                delete vals.commerc_tmc;
            }
            if (vals.hasOwnProperty("other_transport")) {
                delete vals.other_transport;
            }
        } else if (vals.type_id === InsuranceObjectTypesEnum.VEHICLE) {
            vals.address = " "
            if (vals.hasOwnProperty("apartment")) {
                delete vals.apartment;
            }
            if (vals.hasOwnProperty("house")) {
                delete vals.house;
            }
            if (vals.hasOwnProperty("commerc_tmc")) {
                delete vals.commerc_tmc;
            }
            if (vals.hasOwnProperty("other_transport")) {
                delete vals.other_transport;
            }
        } else if (vals.type_id === InsuranceObjectTypesEnum.COMMERCIAL_TMC) {
            if (vals.hasOwnProperty("apartment")) {
                delete vals.apartment;
            }
            if (vals.hasOwnProperty("house")) {
                delete vals.house;
            }
            if (vals.hasOwnProperty("vehicle")) {
                delete vals.vehicle;
            }
        } else if (vals.type_id === InsuranceObjectTypesEnum.OTHER_TRANSPORT) {
            if (vals.hasOwnProperty("apartment")) {
                delete vals.apartment;
            }
            if (vals.hasOwnProperty("house")) {
                delete vals.house;
            }
            if (vals.hasOwnProperty("vehicle")) {
                delete vals.vehicle;
            }
            if (vals.hasOwnProperty("commerc_tmc")) {
                delete vals.commerc_tmc;
            }

            if (!vals.other_transport) {
                toast.error("Выберите тип транспорта")
                return
            }

            // эта валидация существует здесь, потому что в FormBuilder отсутсвует валидация форм в виде массивов
            let validationErrors: string[] = []
            let targetArr = []

            if (!vals.other_transport.is_agri_machinery && !vals.other_transport.is_aviation && !vals.other_transport.is_other && !vals.other_transport.is_railroad && !vals.other_transport.is_ship) {
                validationErrors.push("Выберите тип транспорта")
            }

            if (vals.other_transport.is_agri_machinery) {
                delete vals.other_transport.aviation
                delete vals.other_transport.other
                delete vals.other_transport.railroad
                delete vals.other_transport.ship
                targetArr = vals.other_transport.agri_machinery.machineries
            } else if (vals.other_transport.is_aviation) {
                delete vals.other_transport.agri_machinery
                delete vals.other_transport.other
                delete vals.other_transport.railroad
                delete vals.other_transport.ship
            } else if (vals.other_transport.is_other) {
                delete vals.other_transport.agri_machinery
                delete vals.other_transport.aviation
                delete vals.other_transport.railroad
                delete vals.other_transport.ship
                targetArr = vals.other_transport.other.others
            } else if (vals.other_transport.is_railroad) {
                delete vals.other_transport.agri_machinery
                delete vals.other_transport.aviation
                delete vals.other_transport.other
                delete vals.other_transport.ship
                targetArr = vals.other_transport.railroad.transports
            } else if (vals.other_transport.is_ship) {
                delete vals.other_transport.agri_machinery
                delete vals.other_transport.aviation
                delete vals.other_transport.other
                delete vals.other_transport.railroad
            }


            targetArr.forEach((v: any, i: number) => {
                if ((v.assemble_year !== "" || v.assemble_year !== undefined) && v.assemble_year < 1901 || v.assemble_year > new Date().getFullYear()) {
                    validationErrors.push("1900 < Год выпуска < " + new Date().getFullYear())
                }
            })

            // @ts-ignore
            if (validationErrors.length > 0) {
                // @ts-ignore
                validationErrors.forEach((v: any) => toast.error(`${v}`));
                return
            }
        }

        setLoading(true);
        try {
            if (props.insuranceObjectId) {
                await updateInsuranceObject(props.insuranceObjectId, vals);
            } else {
                const response = await createInsuranceObject(activeClientId, vals);
                if (isUndefined(response)) {
                    console.error("create insurance object: no response")
                    setLoading(false);
                    return
                }

                if (response.headers.location) {
                    const sp = response.headers.location.split('/');
                    dispatch(setActiveId(sp[sp.length - 1]))
                }
                dispatch(setFilterIOId(""));
                dispatch(setFilterQuotationId(""));
            }

            await dispatch(fetchInsuranceObjects({clientId: activeClientId}));

            await dispatch(setLastAddedDate(new Date()));
        } catch (e) {
            // TODO: handle error
        } finally {
            setLoading(false);
        }

        props.onClose && props.onClose();

    }

    const [adOpt, setAdopt] = useState([]);

    const [initValues, setInitValues] = useState({});
    const [loading, setLoading] = useState(false);

    const [status, setStatus] = useState(props.insuranceObjectId === "" ? RequestStatusEnum.Idle : RequestStatusEnum.Loading);

    const [form, setForm] = useState(null);

    useEffect(() => {
        if (!props.insuranceObjectId || props.insuranceObjectId === "") {
            const def = {
                financing_type_id: InsuranceObjectFinanceTypeNameOptions.filter(io => io.value === 1)[0]
            }
            // @ts-ignore
            setInitValues(def);
            return
        } else {
            setStatus(RequestStatusEnum.Loading)
            getInsuranceObject(props.insuranceObjectId)
                .then((res) => {
                    const result = res.data;
                    result.type_id = InsuranceObjectTypeNameOptions.filter(io => io.value === result.type_id)[0];
                    result.financing_type_id = InsuranceObjectFinanceTypeNameOptions.filter(io => io.value === result.financing_type_id)[0];

                    const newForm: TabFormInterface = _.cloneDeep(physicalData);

                    if (result.other_transport) {
                        result.other_transport.is_agri_machinery = !!result.other_transport.agri_machinery
                        result.other_transport.is_railroad = !!result.other_transport.railroad
                        result.other_transport.is_ship = !!result.other_transport.ship
                        result.other_transport.is_aviation = !!result.other_transport.aviation
                        result.other_transport.is_other = !!result.other_transport.other

                        if (result.other_transport.is_railroad) {
                            // @ts-ignore
                            //physicalData.tabs[0].fields[6].namedGroup.fields[3].namedGroup.fields[0].namedGroup.fields[0].group.maxLength = 5 - result.other_transport.railroad.transports.length;

                            // @ts-ignore
                            for (let i = 0; i < result.other_transport.railroad.transports.length - 1; i++) {
                                // @ts-ignore
                                newForm.tabs[0].fields[6].namedGroup.fields[3].namedGroup.fields[0].namedGroup.fields[0].group.fields.push(newForm.tabs[0].fields[6].namedGroup.fields[3].namedGroup.fields[0].namedGroup.fields[0].group.fields[0])
                            }
                        } else if (result.other_transport.is_agri_machinery) {
                            // @ts-ignore
                            //physicalData.tabs[0].fields[6].namedGroup.fields[5].namedGroup.fields[0].namedGroup.fields[0].group.maxLength = 5 - result.other_transport.agri_machinery.machineries.length;

                            // @ts-ignore
                            for (let i = 0; i < result.other_transport.agri_machinery.machineries.length - 1; i++) {
                                // @ts-ignore
                                newForm.tabs[0].fields[6].namedGroup.fields[5].namedGroup.fields[0].namedGroup.fields[0].group.fields.push(newForm.tabs[0].fields[6].namedGroup.fields[5].namedGroup.fields[0].namedGroup.fields[0].group.fields[0])
                            }
                        } else if (result.other_transport.is_other) {
                            // @ts-ignore
                            //physicalData.tabs[0].fields[6].namedGroup.fields[6].namedGroup.fields[0].namedGroup.fields[0].group.maxLength = 5 - result.other_transport.other.others.length;

                            // @ts-ignore
                            for (let i = 0; i < result.other_transport.other.others.length - 1; i++) {
                                // @ts-ignore
                                newForm.tabs[0].fields[6].namedGroup.fields[6].namedGroup.fields[0].namedGroup.fields[0].group.fields.push(newForm.tabs[0].fields[6].namedGroup.fields[6].namedGroup.fields[0].namedGroup.fields[0].group.fields[0])
                            }
                        }
                    }

                    // @ts-ignore
                    setForm(newForm);

                    if (result.deputies) {
                        // @ts-ignore
                        for (let i = 0; i < result.deputies.length - 1; i++) {
                            // @ts-ignore
                            newForm.tabs[0].fields[8].group.fields.push(newForm.tabs[0].fields[8].group.fields[0])
                        }
                    }

                    setInitValues(result);
                    setStatus(RequestStatusEnum.Succeeded)
                })
        }
    }, [])

    const physicalData: TabFormInterface = {
        tabs: [
            {
                key: "root",
                label: "Основная информация",
                fields: [
                    {
                        component: {
                            component: renderToStaticMarkup(<Delimeter title={"Тип объекта"}
                                                                       className={classes.delim}/>)
                        },
                    },
                    {
                        namedGroup: {
                            name: "",
                            fields: [
                                {
                                    type: "select",
                                    key: "type_id",
                                    label: "Тип *",
                                    class: classes.mb2,
                                    options: InsuranceObjectTypeNameOptions,
                                    onChange: (form) => {
                                        // @ts-ignore
                                        window.setFormValue('insurance_sum', 0);

                                        // @ts-ignore
                                        window.setFormValue('apartment.inner_decoration_price', 0);
                                        // @ts-ignore
                                        window.setFormValue('apartment.constructive_price', 0);


                                        // @ts-ignore
                                        window.setFormValue('house.inner_decoration_price', 0);
                                        // @ts-ignore
                                        window.setFormValue('house.constructive_price', 0);

                                        for (let pf of pricesFields) {
                                            // @ts-ignore
                                            window.setFormValue(`commerc_tmc.${pf}`, 0);
                                        }

                                        for (let pf of tmcFieldsArray) {
                                            // @ts-ignore
                                            window.setFormValue(`commerc_tmc.${pf}`, false);
                                        }
                                    },
                                    required: true,
                                    validate: (val: string) => true,
                                    showIf: (form: any) => true,
                                },
                                {
                                    type: "select",
                                    key: "financing_type_id",
                                    label: "Тип финансирования *",
                                    class: classes.mb2,
                                    options: InsuranceObjectFinanceTypeNameOptions,
                                    required: form => true,
                                    validate: (val: string) => true,
                                    showIf: (form: any) => true,
                                },
                                {
                                    type: "autocomplete",
                                    key: "address",
                                    label: "Адрес",
                                    class: classes.mb2,
                                    required: false,
                                    validate: (val: string) => true,
                                    showIf: (form: any) => form.type_id && form.type_id.value !== InsuranceObjectTypesEnum.VEHICLE && form.type_id.value !== InsuranceObjectTypesEnum.OTHER_TRANSPORT,
                                    onChange: (form, v) => {
                                        try {
                                            // ищем адрес с каждого 5 символа
                                            if (v.length % 5 != 0) {
                                                return
                                            }

                                            findDaDataAddress(v).then((data) => {
                                                // @ts-ignore
                                                const opts = []
                                                data.data.suggestions.forEach((addr) => {
                                                    opts.push({
                                                        "value": addr.value,
                                                        "label": addr.value
                                                    })
                                                })
                                                // @ts-ignore
                                                window.setFormValue('options.address', opts);
                                            })
                                        } catch (e) {
                                        }
                                    }
                                },
                                {
                                    type: "number",
                                    key: "insurance_sum",
                                    label: "Страховая сумма *",
                                    class: classes.mb2,
                                    disabled: (form) => {
                                        if (form.type_id == undefined || form.type_id.value == undefined) {
                                            return true;
                                        }
                                        let toDisable = false
                                        if (form.type_id.value === InsuranceObjectTypesEnum.OTHER_TRANSPORT) {
                                            if (form && form.other_transport && (form.other_transport.is_railroad || form.other_transport.is_agri_machinery || form.other_transport.is_other)) {
                                                toDisable = true
                                            }
                                        } else if ([InsuranceObjectTypesEnum.COMMERCIAL_TMC,
                                            InsuranceObjectTypesEnum.APARTMENT,
                                            InsuranceObjectTypesEnum.HOUSE].includes(form.type_id.value)) {
                                            toDisable = true
                                        }

                                        return toDisable
                                    },
                                    required: true,
                                    validate: (val: string) => true,
                                    showIf: (form: any) => true,
                                },
                            ]
                        },
                    },
                    {
                        component: {
                            component: renderToStaticMarkup(<Delimeter title={"Информация по объекту"}
                                                                       className={classes.delim}/>),
                            showIf: (form: any) => {
                                return form.type_id !== undefined;
                            },
                        },
                    },
                    {
                        namedGroup: {
                            class: classes.apartmentGroup,
                            showIf: form => {
                                if (form.type_id == undefined || form.type_id.value == undefined) {
                                    return false;
                                }
                                return form.type_id.value == InsuranceObjectTypesEnum.APARTMENT
                            },
                            name: "apartment",
                            fields: apartmentFields,
                        },
                    },
                    {
                        namedGroup: {
                            class: classes.apartmentGroup,
                            showIf: form => {
                                if (form.type_id == undefined || form.type_id.value == undefined) {
                                    return false;
                                }
                                return form.type_id.value == InsuranceObjectTypesEnum.HOUSE
                            },
                            name: "house",
                            fields: houseFields,
                        },
                    },
                    {
                        namedGroup: {
                            showIf: form => {
                                if (form.type_id == undefined || form.type_id.value == undefined) {
                                    return false;
                                }
                                return form.type_id.value == InsuranceObjectTypesEnum.VEHICLE
                            },
                            name: "vehicle",
                            fields: vehicleFields,
                        },
                    },
                    {
                        namedGroup: {
                            showIf: form => {
                                if (form.type_id == undefined || form.type_id.value == undefined) {
                                    return false;
                                }
                                return form.type_id.value == InsuranceObjectTypesEnum.OTHER_TRANSPORT
                            },
                            name: "other_transport",
                            fields: otherTransportFields,
                        },
                    },
                    {
                        namedGroup: {
                            showIf: form => {
                                if (form.type_id == undefined || form.type_id.value == undefined) {
                                    return false;
                                }
                                return form.type_id.value == InsuranceObjectTypesEnum.COMMERCIAL_TMC
                            },
                            name: "commerc_tmc",
                            fields: [
                                {
                                    type: "string",
                                    key: "cadastral_number",
                                    label: "Кадастровый номер *",
                                    required: true,
                                    validate: (val: string) => true,
                                    showIf: (form: TabFormInterface) => true,
                                },
                                {
                                    component: {
                                        component: renderToStaticMarkup(<Delimeter
                                            title={"Объекты подлежащие страхованию"}
                                            className={classes.delim}/>),
                                    },
                                },
                                ...commercialTMCFieldsObjectInfo
                            ],
                        },
                    },
                    {
                        group: {
                            maxLength: 5,
                            name: "deputies",
                            class: cn(classes.deputyGroup),
                            addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить заемщика'}/>),
                            onDelete: async (id, values) => {
                                values.deputies.splice(id, 1);
                            },
                            fields: [
                                [
                                    {
                                        type: "number",
                                        format: "year",
                                        key: "inn",
                                        label: "ИНН",
                                        required: false,
                                        validate: (val: string) => true,
                                        onRealChange: (form, val, idx) => {
                                            try {
                                                getFromKontur(val).then((resp) => {
                                                    // @ts-ignore
                                                    const opts = []
                                                    resp.data.suggestions.forEach((addr) => {
                                                        opts.push({
                                                            "value": addr.value,
                                                            "label": addr.value
                                                        })
                                                    })
                                                    // @ts-ignore
                                                    window.setFormValue('options.name', opts);
                                                })
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }
                                    },
                                    {
                                        type: "autocomplete",
                                        key: "name",
                                        label: "Наименование",
                                        required: false,
                                        validate: (val: string) => true,
                                    }
                                ]
                            ]
                        }
                    },
                    {
                        type: "files",
                        key: "files",
                        label: "Загрузить документы",
                        required: false,
                        validate: (val: string) => true,
                    },
                ]

            },

        ],
    }

    function onExportPDF() {
        let el = document.getElementById('formbuilder');
        // @ts-ignore
        el.style.height = "100%";
        // @ts-ignore
        const currentPosition = el.scrollTop;
        // @ts-ignore
        const w = el.offsetWidth;
        // @ts-ignore
        const h = el.offsetHeight;

        // @ts-ignore
        const options = {
            dpi: 300, // Set to 300 DPI
            scale: 3, // Adjusts your resolution
            // @ts-ignore
            filename: initValues['address'],
            jsPDF: {unit: 'px', format: [w, h], orientation: 'landscape'},
        }
        html2pdf().from(el).set(options).save();

        setTimeout(function () {
            // @ts-ignore
            el.style.height = "80vh";
            // @ts-ignore
            el.scrollTop = currentPosition;
        }, 1000)
    }

    if (props.insuranceObjectId) {
        // @ts-ignore
        physicalData.tabs[0].fields[1].namedGroup.fields[0].disabled = true;
    }

    const {role_id} = useAppSelector((state) => state.user);

    if (status === RequestStatusEnum.Loading) {
        return <div
            style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size={100}/>
        </div>
    } else {
        return (
            <div id={"pdf"}>
                <FormBuilder
                    disableAll={role_id === RoleIdsEnum.SSK}
                    onClose={props.onClose}
                    initialValues={initValues}
                    onSubmit={onSubmit}
                    loading={loading}
                    data={form ?? physicalData}
                    useTab={false}
                    title={props.header}
                    onExportPDF={onExportPDF}
                    onDownloadFile={async (id: string) => {
                        await downloadFileById(id)
                    }}
                />
            </div>
        )
    }

}

export default InsuranceObjectForm;
