import React, { useEffect, useRef } from 'react';

export function useInterval(callback: any, delay: number, condition?: () => boolean) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    if(callback != undefined) {
      savedCallback.current = callback;
    }
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      //console.log('condition', condition());
      if(savedCallback.current != undefined && (condition == undefined || condition())) {
        // @ts-ignore
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, condition]);
}