import React, {useEffect, useRef, useState} from 'react';
import { setFilterStatus, setFilterKMCompanyName, setFilterName, setFilterIdentity,
    setFilterOpfType, setFilterComment, setFilterCreatedDate,
    clearFilters,  fetchClients, setSort, clearSort, setActiveClient,
    resetPagination, setScrollIndex, clearData } from '../../store/features/clientsTable'
import { setActiveId, setData, setFilterIOId } from '../../store/features/insuranceObjects'
import { reInit } from '../../store/features/quotesTable'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import {
    isClientLegalInterface,
    isClientPhysicalInterface,
    RequestStatusEnum,
    OpfTypeName,
    OpfTypeNameOptions,
    OptionInterface,
    clientStatusOptions,
    clientSortOptions,
    SortOptionInterface,
    ClientSortFieldToName, ClintTypesEnum, RoleIdsEnum
} from "../../common/commonTypes";
import {debounce, formatDateForBackend, formatDateForClient} from "../../common/lib";

import {InfiniteLoader, List, AutoSizer, ScrollParams} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {ClientTypesEnum} from "../../common/catalogs"; // only needs to be imported once
import  classes from"./ClientsTable.module.scss"
import StatusSelect from "../ui/StatusSelect/StatusSelect"
import SingleSelect from  "../ui/SingleSelect/SingleSelect"
import cn from "classnames"
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker, { DesktopDatePickerProps} from '@mui/lab/DesktopDatePicker';

import TextField from '@mui/material/TextField';
import PlusIconSvg from "../icons/PlusIconSvg";
import SplitPaneWrapper from "../SplitPaneWrapper/SplitPaneWrapper";
import LoadingSpinner from "../ui/LoadingSpinner/LoadingSpinner";
import Input from "../ui/Input/Input"
import {PickerOnChangeFn} from "@mui/lab/internal/pickers/hooks/useViews";
import ClearButton from "../ui/ClearButton/ClearButton";
import SortIcon from '@mui/icons-material/Sort';
import {useInterval} from "../../hooks/useInterval";
import {useModal} from "../../hooks/useModal";
import CreateClientModal from "../modals/CreateClientModal";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import { useMeasure } from 'react-use';


interface ClientsTableProps {
    height: number,
    onAddClick?: () => void,
    onEditClientClick?: (id: string, type: ClintTypesEnum) => void,
}


export default function ClientsTable (props: ClientsTableProps) {

    const {status, data, filters, sorts, allDataLoaded, scrollIndex, activeClientId, lastAddedClientDate, blockAutoFetching} = useAppSelector((state) => state.clientsTable);
    const { role_id } = useAppSelector((state) => state.user);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    const [activeMenuId, setActiveMenuId] = useState("");
    const [activeMenuType, setActiveMenuType] = useState(0);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string, type: ClientTypesEnum) => {
        setActiveMenuId(id);
        setActiveMenuType(type);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const formatOpf = (opf: string) => {
        if(opf === undefined) {
            return "Неизв."
        }
        opf = opf.replaceAll("(", " ")
        opf = opf.replaceAll(")", " ")
        opf = opf.replaceAll(",", " ")
        return opf.split(" ").filter(s => s.length > 2).map(s => s[0].toUpperCase()).join("");
    }

    const showAllHandler = () => {
        dispatch(setActiveId(""));
        dispatch(setFilterIOId(""));
        dispatch(setActiveClient(""));
        dispatch(clearFilters());
        dispatch(setData([]));
        dispatch(reInit());
        dispatch(resetPagination());
        // @ts-ignore
        dispatch(setScrollIndex(0));
        loadMoreRows({startIndex: 0, stopIndex: 40, from: "init"});
        //setActiveId, setData
        //clearFilters();
    }

    // @ts-ignore
    function rowRenderer({key, index, style}) {
        const items = data.data;

        if(items[index] == undefined) {
            if(filters.id.length) {
                return <div onClick={showAllHandler} className={classes.showAll}>Показать всё...</div>
            } else {
                return <div></div>
            }
        }

        let type = '';

        if(isClientLegalInterface(items[index])) {
            //@ts-ignore
            type = `${formatOpf(OpfTypeName[items[index].legal_client.opf_type_id])}`;
            //type = items[index].legal_client.opf_type_id;
        } else if (isClientPhysicalInterface(items[index])) {
            //@ts-ignore
            type = "Физ. Лицо"
        } else {
            // TODO: throw error
        }

        let date = null;

        if(lastAddedClientDate != null) {
            date = new Date(lastAddedClientDate);
            date.setSeconds(lastAddedClientDate.getSeconds() + 10);
        }


        let highlightNew = false;
        try {
            let date = new Date(Date.parse(items[index].created_at));
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
            date.setSeconds(date.getSeconds() + 10);
            highlightNew = (new Date()) < date;
        } catch (e) {}

        const highlightActive = items[index].id === activeClientId;


        let highlightNewInSSK = false;
        if(role_id === RoleIdsEnum.SSK) {
            let lastUpdateDate = (new Date(items[index].updated_at));
            lastUpdateDate.setSeconds(lastUpdateDate.getSeconds() + 10);
            highlightNewInSSK = (new Date()) < lastUpdateDate;
        }


        return (
            <div key={key} style={style}
                 className={cn(classes.row, { [classes.rowActive]: highlightActive, [classes.rowAdded]: ((highlightActive && highlightNew) || highlightNewInSSK) } )}
            >
                <div
                    className={classes.rowMenu}
                    // @ts-ignore
                    onClick={(event => handleClick(event, items[index].id, items[index].type_id))}>
                    <MoreVertIcon className={classes.menuIcon} />
                </div>
                <div className={classes.activeRow} onClick={() => dispatch(setActiveClient(items[index].id))}>
                    <div className={cn(classes.status, classes.rowItem, {[classes.inWork]: items[index].is_objects_in_process})}>
                        { items[index].is_objects_in_process ? 'В работе' : 'Не в работе' }
                    </div>

                    <div className={cn(classes.name, classes.rowItem)}>
                        { items[index].km_company_name }
                    </div>

                    <div className={cn(classes.name, classes.rowItem)}>
                        { items[index].name }
                    </div>

                    <div className={cn(classes.inn, classes.rowItem)}>
                        { items[index].identity }
                    </div>

                    <div className={cn(classes.type, classes.rowItem)}>
                        { type }
                    </div>
                    <div className={cn(classes.comment, classes.rowItem)}>
                        { items[index].comment }
                    </div>
                    <div className={cn(classes.date, classes.rowItem)}>
                        { formatDateForClient(items[index].created_at) }
                    </div>
                </div>
            </div>
        );
    }


    const dispatch = useAppDispatch();


    function loadClients({startIndex, stopIndex }: {startIndex: number, stopIndex: number }) {
        //console.log('status != RequestStatusEnum.Succeeded || allDataLoaded', status != RequestStatusEnum.Succeeded || allDataLoaded)
        if(status != RequestStatusEnum.Succeeded) {
            return;
        }
        return dispatch(fetchClients({skip: startIndex, take: stopIndex}));
    }

    const loadMoreRowsTmp = ({startIndex, stopIndex, from = "loadmore" }: {startIndex: number, stopIndex: number, from?: string }) => {
        //console.log('allDataLoaded', allDataLoaded)
        //if(allDataLoaded) { return; }
        loadClients({startIndex: startIndex, stopIndex: stopIndex });

    }

    const loadMoreRows = ({startIndex, stopIndex, from = "loadmore" }: {startIndex: number, stopIndex: number, from?: string }) => {
        return new Promise(  async () => {
            if(from == 'loadmore') {
                if(data.data.length < 40) {
                    return;
                }
            }
            try {
                loadMoreRowsTmp({startIndex: startIndex, stopIndex: stopIndex, from: from});
            } catch (e) {

            }
        })
    };


    const loadMoreRowsHandler = ({startIndex, stopIndex, from = "loadmore" }: {startIndex: number, stopIndex: number, from?: string }) => {
        return new Promise(  async () => {
            if(allDataLoaded) { return }
            setTimeout(
                () => loadMoreRows({startIndex: startIndex, stopIndex: stopIndex}),
            )
        })
    };



    useEffect(() => {
        setTimeout(() => {
            if(!filters.id) {
                loadMoreRows({startIndex: 0, stopIndex: 40, from: "init"});
            }
        }, 500)
        return () => {
            dispatch(clearData());
            dispatch(setActiveId(""));
            dispatch(setFilterIOId(""));
            dispatch(setActiveClient(""));
            dispatch(clearFilters());
            dispatch(setData([]));
            dispatch(reInit());
            dispatch(resetPagination());

        }
    }, []);




    function initTable() {
        dispatch(resetPagination());
        // @ts-ignore
        dispatch(setScrollIndex(0));
        loadMoreRows({startIndex: 0, stopIndex: 40, from: 'initTable'});
    }

    function handleChangeKMCompanyName(event: any) {
        dispatch(setFilterKMCompanyName(event.target.value));
        initTable();
    }

    function handleChangeName(event: any) {
        dispatch(setFilterName(event.target.value));
        initTable();
    }

    function handleChangeIdentity(event: any) {
        dispatch(setFilterIdentity(event.target.value));
        initTable();
    }

    function handleChangeOpfType(event: OptionInterface) {
        dispatch(setFilterOpfType(event != null ? event.value : 0));
        initTable();
    }

    function handleChangeStatus(event: any) {
        dispatch(setFilterStatus(event != null ? event.value : ""));
        initTable();
    }

    function handleChangeComment(event: any) {
        dispatch(setFilterComment(event.target.value));
        initTable();
    }


    function handleChangeCreatedDate(event: any | null) {
        if(event instanceof Date) {
            if(event.getDate().toString() === 'NaN') { return }
            dispatch(setFilterCreatedDate(formatDateForBackend(event)));
        }  else if(typeof event == "string") {
            try {
                const date = new Date(event);
                dispatch(setFilterCreatedDate(formatDateForBackend(date)));
            } catch (e) {
                dispatch(setFilterCreatedDate(""));
            }
        } else {
            dispatch(setFilterCreatedDate(""));
        }
        initTable();
    }

    const [tableScrollTop, setTableScrollTop] = useState(0);

    function scrollHandler(scrollParams: ScrollParams) {
        dispatch(setScrollIndex(undefined));
        setTableScrollTop(scrollParams.scrollTop);
    }

    useInterval(() => initTable(), 5000, () => tableScrollTop < 15 && !blockAutoFetching)

    const infiniteLoaderRef = useRef(null);

    function handleChangeSort(value: SortOptionInterface) {
        if(value == null) {
            dispatch(clearSort());
        } else {
            dispatch(setSort({ name: value.value.name, order: value.value.order }));
        }
        initTable();
    }

    function clearFiltersHandler() {
        if(filters.id.length !== 0) {
            showAllHandler();
        } else {
            dispatch(clearSort());
            dispatch(clearFilters());
            initTable();
        }

    }

    function formatSortOptionLabel(label: SortOptionInterface, data: any) {
        if(data.context === "menu") {
            return label.label;
        }

        if(ClientSortFieldToName.hasOwnProperty(label.value.name)) {
            // @ts-ignore
            const displayLabel = ClientSortFieldToName[label.value.name]
            return <div className={classes.sortValue}>
                { displayLabel }
                <SortIcon className={cn({[classes.sortMirrorVertical]: label.value.order == "asc"}) } />
            </div>
        }

        return  label.label;
    }

    const editClientHandler = () => {
        setAnchorEl(null);
        if(props.onEditClientClick !== undefined) {
            props.onEditClientClick(activeMenuId, activeMenuType)
        }
    }


    //const [ref, { width: rootWidth, left}] = useMeasure<HTMLDivElement>();

    //const ref = useRef(null);

    /*useEffect(() => {
        if(ref === null) { return }
        console.log('ref',);
    }, [rootWidth]);*/

    const ref = useRef(null);
    const [tableSize, tableSizeSet] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        function handleResize() {
            if(ref.current === null) { return }
            tableSizeSet({
                // @ts-ignore
                width: (ref.current.clientWidth === ref.current.scrollWidth || ref.current.clientWidth > 1100) ? ref.current.clientWidth : ref.current.scrollWidth,
                // @ts-ignore
                height: 0,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <SplitPaneWrapper tableName={"Клиенты"} onAddClick={ role_id === RoleIdsEnum.SSK ? undefined : props.onAddClick}>
            <div className={classes.root} ref={ref}>
                <div className={classes.header}
                    // @ts-ignore
                     style={{width: tableSize.width - 30 }}
                >
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={editClientHandler}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{ role_id === RoleIdsEnum.KM ? 'Редактировать' : 'Просмотреть' }</ListItemText>
                        </MenuItem>
                    </Menu>
                    <div className={classes.headerLeft}>
                        <StatusSelect
                            options={clientStatusOptions}
                            onChange={handleChangeStatus}
                            value={filters.is_objects_in_process}
                            placeholder={'Статус'}
                        />
                        <Input placeholder={"Заказчик"} width={165} onChange={handleChangeKMCompanyName} value={filters.km_company_name} />
                        <Input placeholder={"ФИО / Наименование"} width={165} onChange={handleChangeName} value={filters.name} />
                        <Input placeholder={"ИНН / Паспорт"} width={108} onChange={handleChangeIdentity} value={filters.identity} />
                        {/*<input onChange={handleChangeName} value={filters.name} className={classes.nameSearch} type="text"/>*/}
                        <SingleSelect
                            width={120}
                            placeholder={'Тип'}
                            options={OpfTypeNameOptions}
                            onChange={handleChangeOpfType}
                            value={filters.opf_type_id ? OpfTypeNameOptions.filter((o) => o.value == filters.opf_type_id)[0] : null}
                        />
                        <Input placeholder={"Комментарий"} width={140} onChange={handleChangeComment} value={filters.comment} />
                        <DesktopDatePicker
                            inputFormat="dd.MM.yyyy"
                            value={filters.created_at ? filters.created_at : null}
                            onChange={(event) => handleChangeCreatedDate(event != null ? event : null)}
                            renderInput={(params) => <TextField {...params} className={classes.dateInput} />
                            }
                        />
                    </div>
                    <div className={classes.headerRight}>
                        <SingleSelect
                            width={140}
                            formatOptionLabel={formatSortOptionLabel}
                            placeholder={'Сортировка'}
                            options={clientSortOptions}
                            onChange={handleChangeSort}
                            menuStyles={{width: '250px', left: "-110px"}}
                            value={ Object.keys(sorts).length ? clientSortOptions.filter((cs) => cs.value.name == Object.keys(sorts)[0] && cs.value.order == sorts[Object.keys(sorts)[0]])[0] : null}
                        />
                        <ClearButton width={25} onClick={clearFiltersHandler} />
                        {/*{JSON.stringify(sorts)}*/}
                    </div>
                </div>
                <div className={classes.clientTableBody}>
                    {/*{ (status == RequestStatusEnum.Loading) ? <div className={classes.spinnerContainer}>
                        <LoadingSpinner />
                    </div> : null }*/}

                    <InfiniteLoader
                        ref={infiniteLoaderRef}
                        minimumBatchSize={41}
                        isRowLoaded={({index}) => {
                            return status == RequestStatusEnum.Succeeded && !!data.data[index];
                        } }
                        loadMoreRows={loadMoreRowsHandler}
                        rowCount={data.data.length + 1}
                    >
                        {({ onRowsRendered, registerChild }) => (
                            <AutoSizer>
                                {({ width, height}) => (
                                    <List
                                        onScroll={scrollHandler}
                                        scrollToIndex={scrollIndex}
                                        className={classes.scrollTest}
                                        // @ts-ignore
                                        width={ tableSize.width > width ? tableSize.width : width}
                                        // - height info and - height table header ( - 65)
                                        height={props.height - 40 - 30 - 35}
                                        onRowsRendered={onRowsRendered}
                                        ref={registerChild}
                                        rowCount={filters.id.length === 0 ? data.data.length : (data.data.length + 1)}
                                        rowHeight={40}
                                        rowRenderer={rowRenderer}
                                    />
                                )}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                </div>
            </div>
        </SplitPaneWrapper>
    );
}