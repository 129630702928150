import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    ClientLegalInterface,
    ClientPhysicalInterface,
    ClientsResponse, MessageResponse,
    RequestStatusEnum,
} from "../../common/commonTypes";
import {getChatMessages} from "../../api/chatApi";
import _ from "lodash"
import moment from "moment";


interface ChatState {
    data: Array<MessageResponse>,
    total: number,
    has_next: boolean,
    has_prev: boolean,
    first_message_date: string,
    status: RequestStatusEnum,
    lastLoadedCount: number,
}

// Define the initial state using that type
const initialState: ChatState = {
    data: [],
    total: 0,
    has_next: false,
    has_prev: false,
    first_message_date: "",
    status: RequestStatusEnum.Succeeded,
    lastLoadedCount: 0,
};

export const limit = 40;

export const fetchMessages = createAsyncThunk('chat/initFetch', async ({chatId, successCallBack, force}: {chatId: string, force?: boolean, successCallBack: (data: any, fullData: any) => void}, { getState }) => {
    let queryString = `?limit=${limit}`;
    //queryString += `&timestamp=${encodeURIComponent('2020-02-11T18:58:28.951919+03:00')}`;
    //                                                 2022-02-12T21:17:57.1159113 04:00
    // @ts-ignore
    const state: ChatState = getState().chat;
    queryString += `&direction=older`;
    if(state.first_message_date !== "" && !force) {
        queryString += `&offset_date=${encodeURIComponent(state.first_message_date)}`
        queryString += `&offset=${state.data.length}`
    } else {
        queryString += `&offset_date=${moment().utc().toISOString()}`
    }


    return { data: await getChatMessages(chatId, queryString), successCallBack: successCallBack, force: force  }
})

export const fetchMessagesPolling = createAsyncThunk('chat/fetchMessagesPolling', async ({chatId, successCallBack, force}: {chatId: string, force?: boolean, successCallBack: (data: any, fullData: any) => void}, { getState }) => {
    let queryString = `?limit=${limit}`;
    const paginationDate = moment().utc().toISOString();
    //queryString += `&timestamp=${encodeURIComponent('2020-02-11T18:58:28.951919+03:00')}`;
    //                                                 2022-02-12T21:17:57.1159113 04:00
    // @ts-ignore
    const state: ChatState = getState().chat;
    queryString += `&direction=older`;
    queryString += `&offset_date=${paginationDate}`



    return { data: await getChatMessages(chatId, queryString), successCallBack: successCallBack, force: force, prevData: state.data, paginationDate: paginationDate  }
})


export const chatSlice = createSlice({
    name: 'chat',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        clearChatData: (state) => {
            for(let k in initialState) {
                // @ts-ignore
                state[k] = initialState[k];
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMessages.pending, (state, action) => {
                state.status = RequestStatusEnum.Loading;
            })
            .addCase(fetchMessages.fulfilled, (state, action) => {
                if(_.isEqual(state.data, action.payload.data.data.result)) { return }
                if(state.first_message_date === "" && action.payload.data.data.result.length) {
                    state.first_message_date = moment().utc().toISOString();
                    //state.first_message_date = action.payload.data.data.result[action.payload.data.data.result.length - 1].created_at;
                }
                //action.payload.successCallBack();
                state.total = action.payload.data.data.total;
                state.has_next = action.payload.data.data.has_next;

                if(action.payload.force) {
                    state.data = action.payload.data.data.result.reverse();
                    state.first_message_date = moment().utc().toISOString();
                } else {
                    state.data = [...action.payload.data.data.result.reverse(), ...state.data];
                }
                state.lastLoadedCount = action.payload.data.data.result.length;
                state.status = RequestStatusEnum.Succeeded;
                // @ts-ignore
                action.payload.successCallBack(action.payload.data.data.result, state.data);
            })
            .addCase(fetchMessages.rejected, (state, action) => {
                state.status = RequestStatusEnum.Failed;
            })
            .addCase(fetchMessagesPolling.fulfilled, (state, action) => {
                if(_.isEqual(state.data, action.payload.data.data.result)) {
                    //action.payload.successCallBack(action.payload.data.data.result, state.data);
                    return
                }

                const t1 = action.payload.prevData.slice(action.payload.prevData.length - limit, action.payload.prevData.length).map( t => t.id);
                const t2 = action.payload.data.data.result.map( t => t.id);

                //console.log('hello', t1, t2)
                if(t1.length === t2.length && _.difference( t1, t2).length === 0) { return; }

                state.first_message_date = action.payload.paginationDate;
                state.has_next = action.payload.data.data.has_next;
                state.total = action.payload.data.data.total;
                state.data = action.payload.data.data.result.reverse();
                action.payload.successCallBack(action.payload.data.data.result, state.data);
            })
    }

})

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}




export const {
    clearChatData
} = chatSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default chatSlice.reducer