import {TimeoutId} from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import {AxiosResponse} from "axios";

export function debounce(func: any, timeout = 300){
    let timer: TimeoutId;
    return (...args : any) => {
        clearTimeout(timer);
        // @ts-ignore
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
function saveInput(){
    console.log('Saving data');
}

export const nonEmptyString = (value: string) => typeof value === 'string' && value.length > 0;

export const generateId = (min = 0, max = 1000000000) => {
    const roundedMin = Math.ceil(min);
    const roundedMax = Math.floor(max);

    return Math.floor(Math.random() * (roundedMax - roundedMin + 1)) + roundedMin;
};

export function formatDateForBackend(date: Date | string) {
    if(typeof date === "string") {
        date = new Date(date);
    }

    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    if(day.length == 1) {
        day = `0${day}`
    }
    if(month.length == 1) {
        month = `0${month}`
    }


    return `${date.getFullYear()}-${month}-${day}`
}


export function formatDateForClient(date: Date | string) {
    if(typeof date === "string") {
        date = new Date(date);
    }

    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    if(day.length == 1) {
        day = `0${day}`
    }
    if(month.length == 1) {
        month = `0${month}`
    }


    return `${day}.${month}.${date.getFullYear()}`
}


const getFileNameFromContentDisposition = (disposition: string) => {
    if (disposition
        && (disposition.startsWith('attachment') || disposition.startsWith('inline'))
    ) {
        let filename = disposition.startsWith('attachment')
            ? disposition.replace("attachment;", "")
            : disposition.replace("inline;", ""); //replaces first match only
        filename = filename.trim();
        if (filename.includes("filename*=") && filename.includes("filename=")) {
            let filenames = filename.split(";"); //we can parse by ";" because all ";"s inside filename are escaped
            if (filenames.length > 1) { //"filename=" or "filename*=" not inside filename
                if (filenames[0].trim().startsWith("filename*=")) { //"filename*=" is preferred
                    filename = filenames[0].trim();
                } else {
                    filename = filenames[1].trim();
                }
            }
        }
        if (filename.startsWith("filename*=")) {
            filename = filename.replace("filename*=", "")
                .split("''").slice(1).join("''"); //remove encoding and ''
            filename = decodeURIComponent(filename);
        } else if (filename.startsWith("filename=")) {
            filename = filename.replace("filename=", "")
            if (filename.startsWith('"') && filename.endsWith('"')) {
                filename = filename.slice(1, filename.length - 1); //remove quotes
            }
        }
        return decodeURIComponent(filename);
    }
}

export async function downloadFile(response: AxiosResponse) {
    //const filename = response.headers['content-disposition'].split('filename=')[1];
    const filename = getFileNameFromContentDisposition(response.headers['content-disposition'])

    const data = response.data;
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
    // @ts-ignore
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        // @ts-ignore
        window.navigator.msSaveBlob(blob, filename);
        return;
    }
    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    // @ts-ignore
    tempLink.setAttribute('download', filename);
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}


export function separateThousands(numbers: number) {
    if ((numbers !== null) && (numbers !== undefined)) {
        let numbersToString = numbers.toString();
        let result = numbersToString.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
        return result;
    }
    return numbers;
}

export function sumOfFields(arr: any, field: any) {
    let sum = 0;
    arr.forEach((item: any) => {
        if(item && item[field]) {
            sum += Number.parseInt(item[field]);
        }
    })
    return separateThousands(sum);
}