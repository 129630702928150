import React, {useEffect, useState} from 'react';
import {MyForm} from "./forms/MyForm";
import Dashboard from "./screens/Dashboard/Dashboard"
import BasePageWrapper from "./features/BasePageWrapper/BasePageWrapper";
import ReduxCounter from "./features/ReduxCounter";
import LoginPage from "./features/login/login";

import {
    Route,
    Routes,
    useRoutes,
} from "react-router-dom";
import CreateClientModal from "./features/modals/CreateClientModal";
import CreatePhysicalClientModal from "./features/modals/CreatePhysicalClientModal";
import CreateInsuranceObjectModal from "./features/modals/CreateInsuranceObjectModal";
import { useAppSelector } from "./store/hooks";
import {useDispatch} from "react-redux";
import {setUser} from "./store/features/user";
import { Navigate,Outlet } from 'react-router-dom';
import _ from "lodash"
import DealsTable from "./features/DealsTable/DealsTable";
import Deals from "./screens/Deals/Deals";
import AdminPage from "./features/admin";
import {RoleIdsEnum} from "./common/commonTypes";

// @ts-ignore
String.prototype.rtrim = function (s: string) {
    if (s == undefined)
        s = '\\s';
    return this.replace(new RegExp("[" + s + "]*$"), '');
};
// @ts-ignore
String.prototype.ltrim = function (s: string) {
    if (s == undefined)
        s = '\\s';
    return this.replace(new RegExp("^[" + s + "]*"), '');
};



export default function App() {


    const user = useAppSelector((state) => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        //console.log('hello', localStorage.getItem("user"));
        if(localStorage.getItem("user")) {
            // @ts-ignore
            const loadedUser = JSON.parse(localStorage.getItem("user"));
            //console.log('hello1', user, loadedUser, _.isEqual(user, loadedUser))
            if(!_.isEqual(user, loadedUser)) {
                dispatch(setUser(loadedUser));
            }
        }
    }, [user])

    let loggedIn;
    if (user && user.email) {
        loggedIn = user.email.length !== 0;
    } else {
        loggedIn = false;
    }

    return (
        <Routes>
            <Route path={"/"} element={
                <RequireAuth redirectTo={"/login"}>
                    <BasePageWrapper><Dashboard /></BasePageWrapper>
                </RequireAuth>
            }/>
            <Route path={"/login"} element={<LoginPage/>}/>
            <Route path={"/deals"} element={
                <RequireAuth redirectTo={"/login"}>
                    <BasePageWrapper> <Deals /> </BasePageWrapper>
                </RequireAuth>
            }/>
            <Route path={"/admin"} element={
                <RequireAuth redirectTo={"/"} role_ids={[RoleIdsEnum.ADMIN]}>
                    <BasePageWrapper> <AdminPage/> </BasePageWrapper>
                </RequireAuth>
            }/>
        </Routes>
    );
}

export const RequireAuth = (props: any) => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useDispatch();

    if(localStorage.getItem("user")) {
        // @ts-ignore
        const loadedUser = JSON.parse(localStorage.getItem("user"));
        //console.log('hello1', user, loadedUser, _.isEqual(user, loadedUser))
        if(!_.isEqual(user, loadedUser)) {
            dispatch(setUser(loadedUser));
        }

        if (props.role_ids && !props.role_ids.includes(loadedUser.role_id)) {
            return <Navigate to={props.redirectTo}/>
        }
        return props.children
    }
    return <Navigate to={props.redirectTo}/>
};