import React, {useEffect, useRef, useState} from 'react';
import { setFilterStatus, setFilterName, setFilterTarif,
    setFilterAward, setOperatorAward, setOperatorTarif, setFilterComment, setFilterCreatedDate,
    clearFilters,  fetchQuotes, setSort, clearSort, setActiveClientQuote, setChatId,
    resetPagination, setScrollIndex, setRequestStatus, reInit, clearData } from '../../store/features/quotesTable'
import {setActiveId, setData, setFilterIOId} from '../../store/features/insuranceObjects'
import {clearChatData} from '../../store/features/chat'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import {
    RequestStatusEnum,
    OpfTypeName,
    OpfTypeNameOptions,
    OptionInterface,
    clientSortOptions,
    SortOptionInterface,
    ClientSortFieldToName,
    ClintTypesEnum,
    quotesStatusOptions,
    RoleIdsEnum,
    operatorsOptions,
    quotationSortOptions,
    QuotationSortFieldToName, QuotationStatusesEnum
} from "../../common/commonTypes";
import {debounce, formatDateForBackend, formatDateForClient, separateThousands} from "../../common/lib";

import {InfiniteLoader, List, AutoSizer, ScrollParams} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {ClientTypesEnum} from "../../common/catalogs"; // only needs to be imported once
import  classes from"./QuotesTable.module.scss"
import StatusSelect from "../ui/StatusSelect/StatusSelect"
import SingleSelect from  "../ui/SingleSelect/SingleSelect"
import cn from "classnames"
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker, { DesktopDatePickerProps} from '@mui/lab/DesktopDatePicker';

import TextField from '@mui/material/TextField';
import PlusIconSvg from "../icons/PlusIconSvg";
import SplitPaneWrapper from "../SplitPaneWrapper/SplitPaneWrapper";
import LoadingSpinner from "../ui/LoadingSpinner/LoadingSpinner";
import Input from "../ui/Input/Input"
import ClearButton from "../ui/ClearButton/ClearButton";
import SortIcon from '@mui/icons-material/Sort';
import {useInterval} from "../../hooks/useInterval";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from "@mui/material/CircularProgress";
import LaunchIcon from '@mui/icons-material/Launch';
import {useMeasure} from "react-use";
import {setActiveClient} from "../../store/features/clientsTable";


interface ClientsTableProps {
    height: number,
    onAddClick?: (id: string, offersCount?: number) => void,
    onAddOffers?: (id: string) => void,
    onOfferEdit?: (offerId: string, quoteId: string, quotationStatusId: string) => void,
    onOfferView?: (offerId: string, quotationStatusId: string) => void,
    onEditDeal?: (quotationId: string, isEdit: boolean, offerId:string) => void,
    gotBillHandler?: (offerId: string) => void,
}


export default function QuotesTable (props: ClientsTableProps) {

    const {status, data, filters, operators, sorts, allDataLoaded, scrollIndex, activeQuoteId, activeQuoteOfferId, blockAutoFetching} = useAppSelector((state) => state.quotesTable);

    const [anchorQuoteEl, setAnchorQuoteEl] = React.useState<null | HTMLElement>(null);
    const menuIsOpen = Boolean(anchorQuoteEl);


    const [activeQuotationId, setActiveQuotationId] = useState("");
    const [activeQuotationOfferId, setActiveQuotationOfferId] = useState("");
    const [activeQuotationStatusId, setActiveQuotationStatusId] = useState("");
    const [activeQuotationIsApplied, setActiveQuotationIsApplied] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, quotationId: string, quotationOfferId: string, quotationStatusId: string, isApplied: boolean) => {
        setActiveQuotationId(quotationId);
        setActiveQuotationOfferId(quotationOfferId);
        setActiveQuotationStatusId(quotationStatusId);
        setActiveQuotationIsApplied(isApplied)
        setAnchorQuoteEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorQuoteEl(null);
    };

    const selectQuote = (quotationId: string, quotationOfferId: string, chatId: string) => {
        dispatch(setActiveClientQuote({quoteId: quotationId, quoteOfferId: quotationOfferId, chatId: chatId}));
    }

    const showAllHandler = () => {
        dispatch(reInit());
        initTable();
    }

    // @ts-ignore
    function rowRenderer({key, index, style}) {
        const items = data.data;

        if(items[index] == undefined) {
            //console.log('filters.id.length', key);

            if(filters.quotation_id) {
                return <div onClick={showAllHandler} className={classes.showAll}>Показать всё...</div>
            } else {
                return <div></div>
            }
        }
        let quotationStatus = { label: '', value: '', color: '' };
        if(items[index].is_rejected || (items[index].quotation_status_id === QuotationStatusesEnum.APPLY && items[index].is_applied === false)) {
            quotationStatus = quotesStatusOptions.filter( q => q.value === '999' )[0];
        } else {
            quotationStatus = quotesStatusOptions.filter( q => q.value === items[index].quotation_status_id.toString() )[0];
        }


        if(items[index].quotation_status_id === 1) {
            return <div className={classes.rowNoOffers} onClick={() => {  props.onAddOffers && props.onAddOffers(items[index].quotation_id) }}>
                <div className={cn(classes.status, classes.rowItem, {[classes.inWork]: items[index].quotation_offer_status_id === 1})}
                     style={{color: 'white', paddingLeft: "50px"}}
                >
                    { quotationStatus.label }
                </div>
                <div className={cn(classes.name, classes.rowItem)} style={{paddingLeft: "10px", paddingRight: '500px'}}>
                    { items[index].insurance_company_name }
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div className={classes.addOfferRowCta}>
                    Добавить предложения
                    <LaunchIcon fontSize="small" style={{marginLeft: "5px"}} />
                </div>
            </div>
        }

        const highlightActive = items[index].quotation_id == activeQuoteId && items[index].quotation_offer_id == activeQuoteOfferId;

        let highlightNew = false;
        try {

            let date = new Date((new Date(items[index].created_at)).toUTCString());
            date.setSeconds(date.getSeconds() + 10);
            highlightNew = ((new Date()) < date);
        } catch (e) {}

        return (
            <div key={key} style={style}
                 className={cn(classes.row, classes.blink, {[classes.rowActive]: highlightActive, [classes.rowAdded]: highlightNew})}
            >
                <div
                    className={classes.rowMenu}
                    // @ts-ignore
                    onClick={(event) => handleClick(event, items[index].quotation_id, items[index].quotation_offer_id, items[index].quotation_status_id, items[index].is_applied)}>
                    <MoreVertIcon className={classes.menuIcon} />
                </div>
                <div className={classes.activeRow} onClick={() => selectQuote(items[index].quotation_id, items[index].quotation_offer_id, items[index].chat_id)}>
                    <div className={cn(classes.status, classes.rowItem, {[classes.inWork]: items[index].quotation_offer_status_id === 1})}
                        style={{color: quotationStatus.color}}
                    >
                        { quotationStatus.label }
                    </div>
                    <div className={cn(classes.name, classes.rowItem)}>
                        { items[index].insurance_company_name }
                    </div>

                    <div className={cn(classes.inn, classes.rowItem)}>
                        { items[index].tarif } %
                    </div>

                    <div className={cn(classes.type, classes.rowItem)}>
                        { separateThousands(items[index].insurance_award )}
                    </div>
                    <div className={cn(classes.comment, classes.rowItem)}>
                        { items[index].comment }
                    </div>
                    <div className={cn(classes.date, classes.rowItem)}>
                        { formatDateForClient(items[index].created_at) }
                    </div>
                </div>
            </div>
        );
    }


    const dispatch = useAppDispatch();


    function loadClients({startIndex, stopIndex }: {startIndex: number, stopIndex: number }) {
        //console.log('status != RequestStatusEnum.Succeeded || allDataLoaded', status != RequestStatusEnum.Succeeded || allDataLoaded)
        if(status != RequestStatusEnum.Succeeded) {
            return;
        }
        if(activeId === "") {
            return;
        }
        return dispatch(fetchQuotes({skip: startIndex, take: stopIndex, activeInsuranceId: activeId}));
    }

    const loadMoreRowsTmp = ({startIndex, stopIndex, from = "loadmore" }: {startIndex: number, stopIndex: number, from?: string }) => {
        //console.log('allDataLoaded', allDataLoaded)
        //if(allDataLoaded) { return; }
        loadClients({startIndex: startIndex, stopIndex: stopIndex });

    }

    const loadMoreRows = ({startIndex, stopIndex, from = "loadmore" }: {startIndex: number, stopIndex: number, from?: string }) => {
        return new Promise(  async () => {
            if(from == 'loadmore') {
                if(data.data.length < 40) {
                    return;
                }
            }
            try {
                loadMoreRowsTmp({startIndex: startIndex, stopIndex: stopIndex, from: from});
            } catch (e) {

            }
        })
    };


    const loadMoreRowsHandler = ({startIndex, stopIndex, from = "loadmore" }: {startIndex: number, stopIndex: number, from?: string }) => {
        return new Promise(  async () => {
            if(allDataLoaded) { return }
            setTimeout(
                () => loadMoreRows({startIndex: startIndex, stopIndex: stopIndex}),
            )
        })
    };



  /*  useEffect(() => {
        loadMoreRows({startIndex: 0, stopIndex: 40, from: "init"});
    }, []);*/


    const activeId = useAppSelector((state) => state.insuranceObjects.activeId);
    const insuranceObjectFilterId = useAppSelector((state) => state.insuranceObjects.filters.id);
    const activeClientId = useAppSelector((state) => state.clientsTable.activeClientId);


    useEffect(() => {
        if(!insuranceObjectFilterId) {
            dispatch(reInit());
            dispatch(setActiveId(""));
        }
    }, [activeClientId])

    const [ioIdChangedLoading, setIoIdChangedLoading] = useState(false);

    useEffect(() => {
        dispatch(setChatId({id: ""}));
        dispatch(clearChatData());
        if(activeId === "") { return; }
        setIoIdChangedLoading(true);
        //console.log('activeId', activeId);
        dispatch(setRequestStatus(RequestStatusEnum.Loading));
        initTable();
    }, [activeId]);

    useEffect( () => {
        if(status === RequestStatusEnum.Succeeded) {
            setIoIdChangedLoading(false);
        }
    }, [status])



    function initTable() {
        dispatch(resetPagination());
        // @ts-ignore
        dispatch(setScrollIndex(0));
        loadMoreRows({startIndex: 0, stopIndex: 40, from: 'initTable'});
    }

    function handleChangeName(event: any) {
        dispatch(setFilterName(event.target.value));
        initTable();
    }

    function handleChangeTarif(event: any) {
        dispatch(setFilterTarif(event.target.value));
        initTable();
    }

    function handleChangeTarifOperator(event: any) {
        dispatch(setOperatorTarif(event.value));
        if(filters.tarif.length !== 0) {
            initTable();
        }
    }


    function handleChangeAward(event: any) {
        dispatch(setFilterAward(event.target.value));
        initTable();
    }

    function handleChangeAwardOperator(event: any) {
        dispatch(setOperatorAward(event.value));
        if(filters.insurance_award.length !== 0) {
            initTable();
        }
    }

    /*function handleChangeOpfType(event: OptionInterface) {
        dispatch(setFilterOpfType(event != null ? event.value : 0));
        initTable();
    }*/

    function handleChangeStatus(event: any) {
        dispatch(setFilterStatus(event != null ? event.value : ""));
        initTable();
    }

    function handleChangeComment(event: any) {
        dispatch(setFilterComment(event.target.value));
        initTable();
    }


    function handleChangeCreatedDate(event: any | null) {
        if(event instanceof Date) {
            if(event.getDate().toString() === 'NaN') { return }
            dispatch(setFilterCreatedDate(formatDateForBackend(event)));
        }  else if(typeof event == "string") {
            try {
                const date = new Date(event);
                dispatch(setFilterCreatedDate(formatDateForBackend(date)));
            } catch (e) {
                dispatch(setFilterCreatedDate(""));
            }
        } else {
            dispatch(setFilterCreatedDate(""));
        }
        initTable();
    }

    const [tableScrollTop, setTableScrollTop] = useState(0);

    function scrollHandler(scrollParams: ScrollParams) {
        dispatch(setScrollIndex(undefined));
        setTableScrollTop(scrollParams.scrollTop);
    }

    useInterval(() => initTable(), 5000, () => tableScrollTop < 15 && !blockAutoFetching)

    const infiniteLoaderRef = useRef(null);

    function handleChangeSort(value: SortOptionInterface) {
        if(value == null) {
            dispatch(clearSort());
        } else {
            dispatch(setSort({ name: value.value.name, order: value.value.order }));
        }
        initTable();
    }

    function clearFiltersHandler() {

        if(filters.quotation_id.length !== 0) {
            showAllHandler();
        } else {
            dispatch(clearSort());
            dispatch(clearFilters());
            initTable();
        }
    }

    function formatSortOptionLabel(label: SortOptionInterface, data: any) {
        if(data.context === "menu") {
            return label.label;
        }

        if(QuotationSortFieldToName.hasOwnProperty(label.value.name)) {
            // @ts-ignore
            const displayLabel = QuotationSortFieldToName[label.value.name]
            return <div className={classes.sortValue}>
                { displayLabel }
                <SortIcon className={cn({[classes.sortMirrorVertical]: label.value.order == "asc"}) } />
            </div>
        }

        return  label.label;
    }

    const editOfferHandler = () => {
        setAnchorQuoteEl(null);
        //console.log('activeQuotationId', activeQuotationId, activeQuotationOfferId);
        if(props.onOfferEdit !== undefined) {
            props.onOfferEdit(activeQuotationOfferId, activeQuotationId, activeQuotationStatusId)
        }
    }

    const viewOfferHandler = () => {
        setAnchorQuoteEl(null);
        if(props.onOfferView !== undefined) {
            props.onOfferView(activeQuotationOfferId, activeQuotationStatusId)
        }
    }

    const createDealHandler = () => {
        setAnchorQuoteEl(null);
        if(props.onEditDeal !== undefined) {
            props.onEditDeal(activeQuotationId, false, activeQuotationOfferId)
        }
    }

    const gotBillHandler = () => {
        setAnchorQuoteEl(null);
        if(props.gotBillHandler !== undefined) {
            props.gotBillHandler(activeQuotationId)
        }
    }



    const { role_id } = useAppSelector((state) => state.user);

    const ref = useRef(null);

    const [tableSize, tableSizeSet] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        function handleResize() {
            if(ref.current === null) { return }
            tableSizeSet({
                // @ts-ignore
                width: (ref.current.clientWidth === ref.current.scrollWidth || ref.current.clientWidth > 1100) ? ref.current.clientWidth : ref.current.scrollWidth,
                // @ts-ignore
                height: 0,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    return (
        <SplitPaneWrapper tableName={"Котировки и предложения"}
                          onAddClick= {role_id === RoleIdsEnum.KM ? undefined : () => {
                              if(data.data[0] !== undefined) {
                                const offersCount = data.data.filter(d => d.quotation_offer_id !== null).length;
                                props.onAddClick && props.onAddClick(data.data[0].quotation_id, offersCount)
                              }
                          }}
                          showFooter={false}
                          addButtonActive={role_id === RoleIdsEnum.SSK && data.data.length !== 0
                            && data.data.length < 3 && !data.data.filter( q => q.is_applied).length
                          }
        >
            <div className={classes.root} ref={ref}>
                <div className={classes.header}
                    // @ts-ignore
                     style={{width: tableSize.width - 30 }}
                >
                    <Menu
                        id="quote-menu"
                        anchorEl={anchorQuoteEl}
                        open={menuIsOpen}
                        onClose={handleClose}
                    >
                        {
                            role_id == RoleIdsEnum.SSK && [QuotationStatusesEnum.NEW.toString(), QuotationStatusesEnum.OPEN.toString()].includes(activeQuotationStatusId.toString())
                            && <MenuItem onClick={editOfferHandler} >
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Редактировать</ListItemText>
                            </MenuItem>
                        }

                        {
                            (role_id == RoleIdsEnum.SSK && activeQuotationStatusId.toString() === QuotationStatusesEnum.APPLY.toString() && activeQuotationIsApplied)
                            && <MenuItem onClick={createDealHandler}>
                                <ListItemIcon>
                                    <LaunchIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Создать сделку</ListItemText>
                            </MenuItem>
                        }

                        {
                            (role_id == RoleIdsEnum.KM && activeQuotationStatusId.toString() === QuotationStatusesEnum.GOT_BILL.toString() && activeQuotationIsApplied)
                            && <MenuItem onClick={gotBillHandler}>
                                <ListItemIcon>
                                    <LaunchIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Оплатить счёт</ListItemText>
                            </MenuItem>
                        }

                        <MenuItem onClick={viewOfferHandler}>
                            <ListItemIcon>
                                <LaunchIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Просмотр</ListItemText>
                        </MenuItem>
                    </Menu>
                    <div className={classes.headerLeft}>
                        <StatusSelect
                            options={quotesStatusOptions}
                            onChange={handleChangeStatus}
                            value={filters.quotation_status_id}
                            placeholder={'Статус...'}
                        />
                        <Input placeholder={"Наименование компании..."} width={160} onChange={handleChangeName} value={filters.insurance_company_name} />
                        <div style={{display: "flex"}}>
                            <SingleSelect
                                isClearable={false}
                                width={45}
                                placeholder={'='}
                                options={operatorsOptions}
                                isOperatorSelect={true}
                                onChange={handleChangeTarifOperator}
                                value={operators.tarif ? operatorsOptions.filter((o) => o.value == operators.tarif)[0] : null}
                            />
                            <Input withSelect={true} placeholder={"Тариф..."} width={88} onChange={handleChangeTarif} value={filters.tarif} className={classes.inputWithSelect} />
                        </div>
                        <div style={{display: "flex"}}>
                            <SingleSelect
                                isClearable={false}
                                width={45}
                                placeholder={'='}
                                options={operatorsOptions}
                                isOperatorSelect={true}
                                onChange={handleChangeAwardOperator}
                                value={operators.insurance_award ? operatorsOptions.filter((o) => o.value == operators.insurance_award)[0] : null}
                            />
                            <Input withSelect={true} placeholder={"Премия..."} width={88} onChange={handleChangeAward} value={filters.insurance_award} className={classes.inputWithSelect} />
                        </div>
                        {/*<input onChange={handleChangeName} value={filters.name} className={classes.nameSearch} type="text"/>*/}
                        <Input placeholder={"Комментарий..."} width={140} onChange={handleChangeComment} value={filters.comment} />
                        <DesktopDatePicker
                            inputFormat="dd.MM.yyyy"
                            value={filters.created_at ? filters.created_at : null}
                            onChange={(event) => handleChangeCreatedDate(event != null ? event : null)}
                            renderInput={(params) => <TextField {...params} className={classes.dateInput} />
                            }
                        />
                    </div>
                    <div className={classes.headerRight}>
                        <SingleSelect
                            width={140}
                            formatOptionLabel={formatSortOptionLabel}
                            placeholder={'Сортировка'}
                            options={quotationSortOptions}
                            onChange={handleChangeSort}
                            menuStyles={{width: '250px', left: "-110px"}}
                            value={ Object.keys(sorts).length ? clientSortOptions.filter((cs) => cs.value.name == Object.keys(sorts)[0] && cs.value.order == sorts[Object.keys(sorts)[0]])[0] : null}
                        />
                        <ClearButton width={25} onClick={clearFiltersHandler} />
                        {/*{JSON.stringify(sorts)}*/}
                    </div>
                </div>

                <div className={classes.clientTableBody}>

                    {
                        ioIdChangedLoading &&
                        <div className={classes.loader}>
                            <CircularProgress size={50} style={{color: "white", marginRight: '5px'}} />
                        </div>
                    }
                    {/*{ (status == RequestStatusEnum.Loading) && <div className={classes.spinnerContainer}> <LoadingSpinner /> </div> }*/}

                    <InfiniteLoader
                        ref={infiniteLoaderRef}
                        minimumBatchSize={41}
                        isRowLoaded={({index}) => {
                            return status == RequestStatusEnum.Succeeded && !!data.data[index];
                        } }
                        loadMoreRows={loadMoreRowsHandler}
                        rowCount={data.data.length + 1}
                    >
                        {({ onRowsRendered, registerChild }) => (
                            <AutoSizer>
                                {({ width, height}) => (
                                    <List
                                        onScroll={scrollHandler}
                                        scrollToIndex={scrollIndex}
                                        className={classes.scrollTest}
                                        // @ts-ignore
                                        width={ tableSize.width > width ? tableSize.width : width}
                                        // - height info and - height table header ( - 65)
                                        height={props.height - 148}
                                        onRowsRendered={onRowsRendered}
                                        ref={registerChild}
                                        rowCount={filters.quotation_id.length === 0 ? data.data.length : (data.data.length + 1)}
                                        rowHeight={40}
                                        rowRenderer={rowRenderer}
                                    />
                                )}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                </div>
            </div>
        </SplitPaneWrapper>
    );
}