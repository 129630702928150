import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ClientsTable from "../../features/ClientsTable/ClientsTable";
import classes from "./Deals.module.scss";
import InsuranceObjectList from "../../features/Purchases/InsuranceObjectList";
import Chat from "../../features/Chat/Chat";
import {useModal} from "../../hooks/useModal";
import {
    setBlockAutoFetching,
} from '../../store/features/dealsTable'
import EditDealFormModal from "../../features/modals/EditDealFormModal/EditOfferFormModal";
import _ from 'lodash';
import {useAppDispatch} from "../../store/hooks";
import DealsTable from "../../features/DealsTable/DealsTable";
import {getDealsFile, getDealsPaymentSchedule} from "../../api/commonApi";
import ShowDealFilesFormModal from "../../features/modals/ShowDealFilesModal/ShowDealFilesModal";
import ShowDealPaymentScheduleFormModal from "../../features/modals/ShowDealPaymentScheduleModal/ShowDealPaymentScheduleModal";


export default function Deals() {


    const modalDealRef = useRef(null);
    const modalShowDealFilesRef = useRef(null);
    const modalShowDealPaymentScheduleRef = useRef(null);

    const dispatch = useAppDispatch();

    const { toggle: dealToggle, Modal: DealModal, visible: dealVisible } = useModal(modalDealRef);
    const { toggle: dealFilesToggle, Modal: ShowDealFilesModal, visible: showDealFilesVisible } = useModal(modalShowDealFilesRef);
    const { toggle: dealPaymentScheduleToggle, Modal: ShowDealPaymentScheduleModal, visible: showDealPaymentScheduleVisible } = useModal(modalShowDealPaymentScheduleRef);


    // Block pollings if exist active modals
    useEffect(() => {
            const haveVisible = dealVisible || showDealFilesVisible;

            if(haveVisible) {
                dispatch(setBlockAutoFetching(true));
            } else {
                dispatch(setBlockAutoFetching(false))
            }
        },
        [dealVisible, showDealFilesVisible]
    )


    const [currentDealID, setCurrentDealID] = useState("");
    const [currentDealAccountNumber, setCurrentDealAccountNumber] = useState("");
    const [currentDealFiles, setCurrentDealFiles] = useState([]);
    const [currentDealClientName, setCurrentDealClientName] = useState("");


    const onDealEdit = (dealId: string) => {
        setCurrentDealID(dealId);
        dealToggle()
    }

    const onDealShowPaymentSchedule = (dealId: string) => {
        setCurrentDealID(dealId);
        dealPaymentScheduleToggle()
    }


    const onDealFilesShow = (dealId: string, dealAccountNumber: string, dealFiles: [], dealClientName: string) => {
        setCurrentDealID(dealId);
        setCurrentDealAccountNumber(dealAccountNumber);
        setCurrentDealFiles(dealFiles);
        setCurrentDealClientName(dealClientName);
        dealFilesToggle()
    }

    return (
        <div className={classes.root}>


            <DealModal>
                <EditDealFormModal
                    ref={modalDealRef}
                    onClose={() => dealToggle()}
                    dealId={currentDealID}
                    isEdit={true}
                    quotationId={""}
                    offerId={""}
                />
            </DealModal>

            <ShowDealFilesModal>
                <ShowDealFilesFormModal ref={modalShowDealFilesRef}
                                        accountNumber={currentDealAccountNumber}
                                        files={currentDealFiles}
                                        clientName={currentDealClientName}
                                        onClose={() => dealFilesToggle()} />
            </ShowDealFilesModal>

            <ShowDealPaymentScheduleModal>
                <ShowDealPaymentScheduleFormModal ref={modalShowDealPaymentScheduleRef}
                                                  dealId={currentDealID}
                                                  onClose={() => dealPaymentScheduleToggle()} />
            </ShowDealPaymentScheduleModal>

            <DealsTable
                onDealEdit={onDealEdit}
                // @ts-ignore
                onDealFilesShow={onDealFilesShow}
                onDealShowPaymentSchedule={onDealShowPaymentSchedule} />

        </div>
    );
}