import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import classes from "./ShowDealFilesModal.module.scss";
import EditOfferForm from "../../../forms/EditOfferForm/EditOfferForm";
import {Button} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {downloadFileById, getDealTableRequest, updateDealStatus, updateOfferStatus} from "../../../api/commonApi";
import {DealStatusesEnum, FileResponse, QuotationStatusesEnum} from "../../../common/commonTypes";
import { ToastContainer, toast } from 'react-toastify';
import {fetchInsuranceObjects} from "../../../store/features/insuranceObjects";
import {fetchQuotes, resetPagination, setScrollIndex} from "../../../store/features/quotesTable";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../store/hooks";
import CloseIcon from "@mui/icons-material/Close";
import {FileIcon} from "react-file-icon";
import DownloadIcon from "@mui/icons-material/Download";



export default function ShowDealFilesFormModal (props: { onClose?: () => void, ref?: any, clientName: string,
    accountNumber: string, files: Array<FileResponse>, }) {

    const [loading, loadingSet] = useState(false);

    const dispatch = useDispatch();
    const { activeClientId } = useAppSelector((state) => state.clientsTable);
    const { activeId } = useAppSelector((state) => state.insuranceObjects);

    const [downloadFileActiveId, setDownloadFileActiveId] = useState("");

    const onDownloadFile = async (fileId: string) => {
        setDownloadFileActiveId(fileId);
        try {
            await downloadFileById(fileId);
        } finally {
            setDownloadFileActiveId("");
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <ToastContainer limit={10} />

                <div className={classes.header}>
                    <div>
                        Файлы сделки. Клиент: {props.clientName}. Договор №: {props.accountNumber}
                    </div>

                    <CloseIcon onClick={props.onClose} style={{cursor: "pointer"}}/>
                </div>

                <div>
                    {
                        (props.files && props.files.length !== 0) &&
                        props.files.map((f) =>
                            <div>
                                <div className={classes.fileItem}>
                                    <div className={classes.fileName}>
                                        <div style={{ width: '30px'}}>
                                            <FileIcon extension={f.name.split(".").pop()} />
                                        </div>
                                        { f.name }
                                    </div>
                                    {
                                        downloadFileActiveId === f.id
                                            ? <CircularProgress style={{color: "#3DD598",}} size={18}/>
                                            : <DownloadIcon style={{color: "#3DD598", cursor: "pointer"}} onClick={() => onDownloadFile(f.id)} />
                                    }

                                </div>

                            </div>)
                    }
                </div>
            </div>
        </div>
    );
}