import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    ClientLegalInterface,
    ClientPhysicalInterface,
    ClientsResponse, InsuranceObjectInterface, InsuranceObjectStatusesEnum,
    RequestStatusEnum,
} from "../../common/commonTypes";
import {getClientsTableRequest, getInsuranceObjects} from "../../api/commonApi";
import _ from "lodash";

interface InsuranceObjectsState {
    data: Array<InsuranceObjectInterface>,
    activeId: string,
    status: RequestStatusEnum,
    blockAutoFetching: boolean,
    lastAddedInsuranceObjectDate: Date | null,
    filters: {
        id: string
    }
}

// Define the initial state using that type
const initialState: InsuranceObjectsState = {
    data: [],
    activeId: "",
    status: RequestStatusEnum.Succeeded,
    blockAutoFetching: false,
    lastAddedInsuranceObjectDate: null,
    filters: {
        id: ""
    }
};

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export const fetchInsuranceObjects = createAsyncThunk('insuranceObjects/initFetchInsurance', async ({clientId}: {clientId: string}, { getState }) => {
    return await getInsuranceObjects(clientId);
})

export const fetchInsuranceObjectsAuto = createAsyncThunk('insuranceObjects/fetchInsuranceObjectsAuto', async ({clientId}: {clientId: string}, { getState }) => {
    return await getInsuranceObjects(clientId)
})



export const insuranceObjectsSlice = createSlice({
    name: 'insuranceObjects',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setData: (state, { payload }: {payload: Array<InsuranceObjectInterface>}) => {
            state.data = [];
        },
        setFilterIOId: (state, { payload }: {payload: string}) => {
            state.filters.id = payload;
        },
        updateItemStatus: (state, { payload }: {payload: { id: string, status: InsuranceObjectStatusesEnum }}) => {
            const idx = state.data.findIndex( (io) => io.id === payload.id );
            if(idx < 0) { return }
            else { state.data[idx].status_id = payload.status; }
        },
        updateQuotationCount: (state, { payload }: {payload: { id: string, quotations_count: number }}) => {
            const idx = state.data.findIndex( (io) => io.id === payload.id );
            if(idx < 0) { return }
            else { state.data[idx].quotations_count = payload.quotations_count; }
        },
        setActiveId: (state, { payload }: {payload: string}) => {
            state.activeId = payload;
        },
        setLastAddedDate: (state, { payload }: {payload: Date | null}) => {
            state.lastAddedInsuranceObjectDate = payload;
        },
        setBlockInsuranceAutoFetching: (state, { payload }: {payload: boolean}) => {
            state.blockAutoFetching = payload;
        },
        
    },
    extraReducers(builder) {
        builder
            .addCase(fetchInsuranceObjects.pending, (state, action) => {
                state.status = RequestStatusEnum.Loading;
            })
            .addCase(fetchInsuranceObjects.fulfilled, (state, action) => {
                //console.log('action.payload', action.payload)
                if(_.isEqual(state.data, action.payload.data.data)) {
                    state.status = RequestStatusEnum.Succeeded;
                    return;
                }
                state.data = action.payload.data.data;
                state.status = RequestStatusEnum.Succeeded;
            })
            .addCase(fetchInsuranceObjectsAuto.fulfilled, (state, action) => {
                if(_.isEqual(state.data, action.payload.data.data)) {
                    return;
                }
                state.data = action.payload.data.data;
            })
            .addCase(fetchInsuranceObjects.rejected, (state, action) => {
                state.status = RequestStatusEnum.Failed;
            })
    }

})



export const {
    setData,
    setActiveId,
    setFilterIOId,
    setBlockInsuranceAutoFetching,
    setLastAddedDate,
    updateItemStatus,
    updateQuotationCount,
} = insuranceObjectsSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default insuranceObjectsSlice.reducer