import Axios, {AxiosError} from "axios";
import {
    ClientsResponse,
    ContactLegalInterface,
    CreateLegalInterface,
    CreateOfferInterface,
    CreatePhysicalInterface,
    DealCreateInterface, DealsResponse,
    InsuranceObjectInterface,
    LoginInterface, MessagesListResponse,
    QuotesResponse,
    RequisitesInterface,
    SignInResponseInterface,
    UpdateOfferInterface,
    UserInterface
} from "../common/commonTypes";
import {downloadFile, formatDateForBackend} from "../common/lib";
import {chatMessageNotify} from "./commonApi";
import {toast} from "react-toastify";

const token = localStorage.getItem("access_token") || null;
if (token) Axios.defaults.headers.common.Authorization = `Bearer ${token}`;

export const baseUrl = process.env.NODE_ENV === "development"
    ? "http://localhost:18000/api"
    : "https://aima-chat.aima.biz/api";


export const chatApi = Axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
});


export const getChatMessages = async (chatId: string, queryString: string) => {
    return await chatApi.get<MessagesListResponse>(`chats/${chatId}/messages${queryString}`)
}

export const createMessage = async (chatId: string, text: string) => {
    chatApi.post(`messages`, {
        "chat_id": chatId,
        "text": text
    }).then(() => {
        chatMessageNotify(chatId, text).then(() => {
            toast.success("Сообщение успешно отправлено!")
        })
    })
}


/*api.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error: AxiosError) {
        const status = error.response?.status || 500;
        if(status == 401) {
            try {
                await refreshTokenRequest();
                const token = localStorage.getItem("access_token") || null;
                if(error.config.headers != undefined) {
                    error.config.headers.Authorization =  token ? `Bearer ${token}` : ''
                }
                return Axios.request(error.config)
            } catch (e) {
                localStorage.setItem("user", "");
                localStorage.setItem("access_token", "");
                localStorage.setItem("refresh_token", "");
                location.href = "/login" + window.location.search;
            }
        }

        return Promise.reject(error);
    }
);


api.interceptors.request.use(function (config) {
    const token = localStorage.getItem("access_token") || null;

    if(config.headers) {
        config.headers.Authorization =  token ? `Bearer ${token}` : '';
    }
    return config;
});*/
