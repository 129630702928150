import React, {useEffect, useState} from 'react';
import classes from "./Message.module.scss";
import cn from "classnames"
import ReadIconSvg from "../../icons/ReadedIconSvg";
import moment from "moment";

interface MessageProps {
    created_at?: string,
    from?: string,
    message: string,
    is_me?: boolean,
}

export default function Message(props: MessageProps) {

    // let moment = require("moment-timezone");
    // @ts-ignore
    let date = new Date(props.created_at);
    let formatted = date.toLocaleString('ru-RU', { timeZone: "Europe/Moscow" });
    const time = formatted.split(', ')[1]
    // @ts-ignore
    const newDate = formatted.split(', ')[0];
    return (
        <div className={cn(classes.root, {[classes.rootUser]: !props.is_me})}>
            <div className={cn(classes.avatar, {[classes.avatarUser]: !!props.is_me})}>
                { props.from }
            </div>
            <div className={cn(classes.message, { [classes.messageFrom]: !!props.is_me, [classes.userMessage]: !props.is_me})}>
                { props.message }
                <div className={classes.statusBar}>
                    <ReadIconSvg
                        stroke={!!props.is_me ? '#323C47' : 'white'}
                        className={cn({[classes.statusIconRead]: !!props.is_me})} width={16} height={16}/>
                    <div className={classes.statusText}>{time} {newDate}</div>
                </div>
            </div>
        </div>
    );
}