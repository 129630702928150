import React, {useEffect, useState} from 'react';
import classes from "./ViewOfferForm.module.scss"
import {
    InsuranceObjectInterface,
    OfferResponseInterface,
    QuotationStatusesEnum,
    RequestStatusEnum,
    RoleIdsEnum
} from "../../common/commonTypes";
import {downloadFileById, getInsuranceObject, getOffer, updateOfferStatus} from "../../api/commonApi";
import {useAppSelector} from "../../store/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchInsuranceObjects} from "../../store/features/insuranceObjects";
import {useDispatch} from "react-redux";
import {fetchQuotes, resetPagination, setScrollIndex} from "../../store/features/quotesTable";
import {FileIcon} from "react-file-icon";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import {Button} from "@mui/material";
import {formatDateForClient, separateThousands} from "../../common/lib";


interface InsuranceObjectFormProps {
    offerId: string,
    quotationStatusId: string,
    onClose?: () => void,
}


// @ts-ignore
const OfferViewForm = (props: InsuranceObjectFormProps) => {

    const dispatch = useDispatch();

    const { activeClientId } = useAppSelector((state) => state.clientsTable);
    const { activeId } = useAppSelector((state) => state.insuranceObjects);
    const insuranceObjectActiveId = useAppSelector((state) => state.insuranceObjects.activeId);

    const [io, setIO] = useState<InsuranceObjectInterface | null>(null);
    const [data, setInitVals] = useState<OfferResponseInterface | null>(null);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(  () => {
        setLoading(true);
        getInsuranceObject(insuranceObjectActiveId).then((io) => {
            setIO(io.data)
        })
        getOffer(props.offerId)
            .then((offer) => {
                setInitVals(offer.data);
                //console.log('offer', offer.data)
            })
            .finally(() => setLoading(false));
    }, [])

    const onSubmit = async () => {
        setSaving(true);

        await updateOfferStatus(props.offerId, { is_applied: true });

        await dispatch(fetchInsuranceObjects({clientId: activeClientId}));

        await dispatch(resetPagination());
        // @ts-ignore
        await dispatch(setScrollIndex(0));
        await dispatch(fetchQuotes({skip: 0, take: 40, activeInsuranceId: activeId}));

        setSaving(false);
        props.onClose && props.onClose();

    }

    const onRejectOffer = async () => {
        setSaving(true);

        await updateOfferStatus(props.offerId, { is_rejected: true });

        await dispatch(fetchInsuranceObjects({clientId: activeClientId}));

        await dispatch(resetPagination());
        // @ts-ignore
        await dispatch(setScrollIndex(0));
        await dispatch(fetchQuotes({skip: 0, take: 40, activeInsuranceId: activeId}));

        setSaving(false);
        props.onClose && props.onClose();
    }

    const [initValues, setInitValues] = useState({});

    //const [status, setStatus] = useState(props.quoteId === "" ? RequestStatusEnum.Idle : RequestStatusEnum.Loading);
    const [status, setStatus] = useState(RequestStatusEnum.Idle);



    const onDownloadFile = async (fileId: string) => {
        setDownloadFileActiveId(fileId);
        try {
            await downloadFileById(fileId);
        } finally {
            setDownloadFileActiveId("");
        }
    }


    const [downloadFileActiveId, setDownloadFileActiveId] = useState("");

    const { role_id } = useAppSelector((state) => state.user);

    if(loading) {
        return  <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size={100} />
        </div>
    } else if (data !== null) {
        return (
            /*
                comment: "213213"
                files: Array(1)
                0: {id: 'e98d1de6-f972-497a-9216-342de2f84b6f', name: 'test.pdf', content_type: 'image/jpeg', size: 100596, path: 'C:\\Users\\Xiaomi\\GolandProjects\\aima\\files\\20220128\\e98d1de6-f972-497a-9216-342de2f84b6f', …}
                length: 1
                [[Prototype]]: Array(0)
                id: "8d5ed268-8ffd-4d8f-ab52-0855f0c12ee6"
                insurance_award: 123213
                quotation_id: "1ab979b8-dfe4-4c34-b597-430745b1befb"
                tarif: 123213
                updated_at: "2022-01-28T10:36:34.087704Z"
             */
            <div className={classes.root}>
                <div className={classes.header}>
                    Просмотр предложения
                    <CloseIcon onClick={props.onClose} style={{cursor: "pointer"}}/>
                </div>

                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "85%"}}>
                    <div className={classes.content}>
                        {
                            data.date && <div className={classes.item}>
                                <div className={classes.itemName}>Дата:</div> { formatDateForClient(data.date)}
                            </div>
                        }
                        {
                            data.tarif && <div className={classes.item}>
                                <div className={classes.itemName}>Тариф:</div> { data.tarif } %
                            </div>
                        }
                        {
                            data.insurance_award && <div className={classes.item}>
                                <div className={classes.itemName}>Премия:</div> { separateThousands(data.insurance_award) }
                            </div>
                        }
                        {
                            insuranceObjectActiveId && io && io.insurance_sum &&
                            <div className={classes.item}>
                                <div className={classes.itemName}>Страховая сумма: </div> { separateThousands(io.insurance_sum) }
                            </div>
                        }
                        {
                            data.comment && <div className={classes.item}>
                                <div className={classes.itemName}>Комментарий:</div> { data.comment }
                            </div>
                        }
                        {
                            data.account_needs && <div className={classes.item}>
                                <div className={classes.itemName}>Для договора необходимо:</div> { data.account_needs }
                            </div>
                        }

                        {
                            data.is_franchise_exist && <div className={classes.item}>
                                <div className={classes.itemName}>Франшиза:</div> { data.is_franchise_exist ? "Да" : "Нет" }
                            </div>
                        }

                        {
                            data.is_franchise_exist && data.franchise_price && <div className={classes.item}>
                                <div className={classes.itemName}>Франшиза:</div> { separateThousands(data.franchise_price) }
                            </div>
                        }
                        {
                            data.is_franchise_exist && <div className={classes.item}>
                                <div className={classes.itemName}>Франшиза условная/безусловная:</div> { data.is_franchise_conditional ? "Условная" : "Безусловная" }
                            </div>
                        }

                        {
                            (data.files && data.files.length !== 0) &&
                            <div className={classes.fileHeader}>
                                Документы и файлы
                            </div>
                        }

                        {
                            (data.files && data.files.length !== 0) &&
                            data.files.map((f) =>
                                <div>
                                    <div className={classes.fileItem}>
                                        <div className={classes.fileName}>
                                            <div style={{ width: '30px'}}>
                                                <FileIcon extension={f.name.split(".").pop()} />
                                            </div>
                                            { f.name }
                                        </div>
                                        {
                                            downloadFileActiveId === f.id
                                                ? <CircularProgress style={{color: "#3DD598",}} size={18}/>
                                                : <DownloadIcon style={{color: "#3DD598", cursor: "pointer"}} onClick={() => onDownloadFile(f.id)} />
                                        }

                                    </div>

                                </div>)
                        }
                    </div>

                    <div className={classes.submitContainer}>
                        {/*{*/}
                        {/*    [QuotationStatusesEnum.NEW.toString(), QuotationStatusesEnum.OPEN.toString() ].includes(props.quotationStatusId.toString()) && role_id === RoleIdsEnum.KM &&*/}
                        {/*    <Button disabled={saving} style={{width: "250px"}} onClick={onRejectOffer} className={cn(classes.rejectBtn, classes.submit)} variant="contained"*/}
                        {/*            type="submit">*/}
                        {/*        {*/}
                        {/*            saving ? <CircularProgress style={{color: "white",}} size={30}/>*/}
                        {/*                : "Отклонить предложение"*/}
                        {/*        }*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {
                            [QuotationStatusesEnum.NEW.toString(), QuotationStatusesEnum.OPEN.toString() ].includes(props.quotationStatusId.toString()) && [RoleIdsEnum.KM, RoleIdsEnum.SUPERVISOR_KM].includes(role_id) &&
                            <Button disabled={saving} style={{width: "250px"}} onClick={onSubmit} className={classes.submit} variant="contained"
                                    type="submit">
                                {
                                    saving ? <CircularProgress style={{color: "white",}} size={30}/>
                                        //: "Принять предложение"
                                        : "Запросить договор"
                                }
                            </Button>
                        }

                        { !([QuotationStatusesEnum.NEW.toString(), QuotationStatusesEnum.OPEN.toString() ].includes(props.quotationStatusId.toString()) && role_id) && data.is_applied && <div className={classes.applied}>
                            Предложение принято
                        </div> }

                        { !([QuotationStatusesEnum.NEW.toString(), QuotationStatusesEnum.OPEN.toString() ].includes(props.quotationStatusId.toString())) && !data.is_applied && <div className={classes.notApplied}>
                            Предложение отклонено
                        </div> }
                    </div>
                </div>
            </div>

        )
    } else {
        return ( <div></div> )
    }

}

export default OfferViewForm;
