import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect, useState} from 'react';
import {
    ChooseFormData, DaDataData,
    LoginInterface,
    OpfTypeNameOptions,
    OptionInterface,
    RequestStatusEnum, RoleIdsEnum
} from "../../common/commonTypes";
import { useAppSelector, useAppDispatch } from '../../store/hooks'


import FormBuilder from "../FormBuilder/FormBuilder";
import {TabFormInterface} from "../FormBuilder/FormBuilder";
import AddButton from "../../features/ui/AddButton/AddButtonButton";
import { renderToStaticMarkup } from 'react-dom/server'
import classes from './ClientForm.module.scss'
import {createLegalClient, deleteClientContact, deleteClientRequisites, updateLegalClient} from "../../api/commonApi";
import { fetchClients, resetPagination, setScrollIndex, setLastAddedClient, setFilterId } from '../../store/features/clientsTable'
import { setFilterIOId } from '../../store/features/insuranceObjects'
import { setFilterQuotationId } from '../../store/features/quotesTable'
import {fetchLegalClient} from "../../store/features/currentClient";
import {prop} from "react-data-table-component/dist/src/DataTable/util";
import _ from 'lodash';
import cn from "classnames"
import {toast, ToastContainer} from "react-toastify";


const physicalData: TabFormInterface = {
    tabs: [
        {
            key: "root",
            class: classes.mainGroup,
            label: "Основная информация",
            fields: [
                {
                    type: "string",
                    key: "inn",
                    label: "ИНН",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "name",
                    label: "Наименование",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "select",
                    options: OpfTypeNameOptions,
                    key: "opf_type_id",
                    label: "Тип",
                    required: true,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "site",
                    label: "Сайт",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "kpp",
                    label: "КПП",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "ogrn",
                    label: "ОГРН",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "okato",
                    label: "ОКАТО",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "okpo",
                    label: "ОКПО",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    type: "string",
                    key: "oktmo",
                    label: "ОКТМО",
                    required: false,
                    validate: (val: string) => true,
                },
                {
                    namedGroup: {
                        name: "client",
                        fields: [
                            {
                                type: "hidden",
                                key: "id",
                                label: "",
                                required: false,
                                validate: (val: string) => true,
                            },
                            {
                                type: "string",
                                key: "comment",
                                label: "Комментарий",
                                required: false,
                                validate: (val: string) => true,
                            },
                        ]
                    }
                }
            ]

        },

        {
            key: "requisites",
            label: "Реквизиты",
            fields: [

                {
                    namedGroup: {
                        name: "client",
                        fields: [
                            {
                                group: {
                                    class: cn(classes.requisitesGroup),
                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить Реквизиты'} />),
                                    name: "requisites",
                                    onDelete: async (id, values) => {
                                        if(values.client.requisites[id] === undefined) { return; }

                                        if(!values.client.requisites[id].id) {
                                            values.client.requisites.splice(id, 1);
                                            return;
                                        }

                                        if(!values.client.id) { return; }

                                        await deleteClientRequisites(values.client.id, values.client.requisites[id].id);
                                        values.client.requisites.splice(id, 1);
                                    },
                                    fields: [
                                        [
                                            {
                                                type: "hidden",
                                                key: "id",
                                                label: "",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "bank_name",
                                                label: "Название банка",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "bik",
                                                label: "БИК",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "k_s",
                                                label: "к/с",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                options: OpfTypeNameOptions,
                                                key: "r_s",
                                                label: "р/с",
                                                required: false,
                                                validate: (val: string) => true,
                                            }
                                        ]
                                    ]
                                }
                            }
                        ]
                    }
                }
            ]

        },

        {
            key: "clients",
            label: "Контакты",
            fields: [
                {
                    namedGroup: {
                        name: "client",
                        fields: [
                            {
                                group: {
                                    class: classes.contactGroup,
                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить контакт'} />),
                                    onDelete: async (id, values) => {
                                        if(values.client.contacts[id] === undefined) { return; }

                                        if(!values.client.contacts[id].id) {
                                            values.client.contacts.splice(id, 1);
                                            return;
                                        }

                                        if(!values.client.id) { return; }

                                        await deleteClientContact(values.client.id, values.client.contacts[id].id);
                                        values.client.contacts.splice(id, 1);
                                    },
                                    name: 'contacts',
                                    fields: [
                                        [
                                            {
                                                type: "hidden",
                                                key: "id",
                                                label: "ID",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "email",
                                                label: "E-mail",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                key: "fio",
                                                label: "ФИО",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                type: "string",
                                                class: classes.position,
                                                options: OpfTypeNameOptions,
                                                key: "position",
                                                label: "Должность",
                                                required: false,
                                                validate: (val: string) => true,
                                            },
                                            {
                                                group: {
                                                    name: 'phone',
                                                    class: classes.phoneGroup,
                                                    addButtonComponent: renderToStaticMarkup(<AddButton title={'Добавить телефон'} />),
                                                    fields: [
                                                        [
                                                            {
                                                                type: "string",
                                                                key: "",
                                                                label: "Телефон",
                                                                required: false,
                                                                validate: (val: string) => true,
                                                            }
                                                        ]
                                                    ]
                                                }
                                            },
                                        ]
                                    ]
                                }
                            }
                        ]
                    }
                }
            ]

        }

    ],
}


const ClientForm = (props: { onClose?: () => void, clientId: string, chooseForm?: ChooseFormData, kontur?: DaDataData }) => {

    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    async function initTable() {
        await dispatch(resetPagination());
        // @ts-ignore
        await dispatch(setScrollIndex(0));
        await dispatch(fetchClients({skip: 0, take: 40}));
    }


    const { legalClient } = useAppSelector((state) => state.editClient)


    useEffect( () => {
        //console.log('test', props.kontur, props.chooseForm);
        if(props.clientId === "") {
            return
        } else {
            dispatch(fetchLegalClient({clientId: props.clientId}));
        }
    }, []);


    const [form, setForm] = useState(null);
    const [initValues, setInitValues] = useState({});

    useEffect(() => {
        //console.log('legalClient', legalClient)
        if(legalClient === null) {
            return
        } else {
            const client = JSON.parse(JSON.stringify(legalClient));
            const newForm: TabFormInterface = _.cloneDeep(physicalData);

            //@ts-ignore
            client.opf_type_id = OpfTypeNameOptions.filter(ot => ot.value == legalClient.opf_type_id)[0];

            //console.log('client', client)

            setInitValues(client);


            if(legalClient.client.requisites) {
                // @ts-ignore
                //console.log('legalClient.client.requisites.length', newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields)
                for(let i = 0; i < legalClient.client.requisites.length - 1; i++) {
                    // @ts-ignore
                    //console.log('newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields[0]', newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields[0])
                    // @ts-ignore
                    newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields.push(newForm.tabs[1].fields[0].namedGroup.fields[0].group.fields[0])
                }
            }

            //console.log('newForm', newForm)



            if(legalClient.client.contacts) {
                // @ts-ignore
                const contactField = newForm.tabs[2].fields[0].namedGroup.fields[0].group.fields[0];
                // @ts-ignore
                newForm.tabs[2].fields[0].namedGroup.fields[0].group.fields = [];
                for(let i = 0; i < legalClient.client.contacts.length; i++) {
                    const newContactField = _.cloneDeep(contactField);
                    //console.log('newContactField', newContactField[3].group.fields)
                    if(legalClient.client.contacts && legalClient.client.contacts[i].phone && legalClient.client.contacts[i].phone.length > 1) {
                        for (let x = 0; x < legalClient.client.contacts[i].phone.length - 1; x ++) {
                            newContactField[4].group.fields.push(newContactField[4].group.fields[0])
                        }
                    }
                    // @ts-ignore
                    newForm.tabs[2].fields[0].namedGroup.fields[0].group.fields.push(newContactField)
                }
            }


            //console.log('newForm', newForm)
            // @ts-ignore
            setForm(newForm);
        }
    }, [legalClient])

    const submitHandler = async (data: any) => {
        setLoading(true)
        try {
            const values = JSON.parse(JSON.stringify(data));
            if(values.hasOwnProperty('opf_type_id')) {
                values.opf_type_id = values.opf_type_id.value;
            }
            if(props.clientId) {
                updateLegalClient(props.clientId, values);
                dispatch(setLastAddedClient({ id: props.clientId, date: (new Date()) }));
            } else {
                if(props.kontur !== undefined) {
                    values.kontur = props.kontur;
                }
                const {id, err} = await createLegalClient(values);
                if (err != null) {
                    // @ts-ignore
                    if (err.code === "AIMA-00105") {
                        toast.error("Такой клиент уже существует");
                        return
                    }
                }
                dispatch(setLastAddedClient({ id: id, date: (new Date()) }))
                dispatch(setFilterId(""));
                dispatch(setFilterIOId(""));
                dispatch(setFilterQuotationId(""));
            }
            await initTable();
        } catch (e) {
            return;
        } finally {
            setLoading(false);
            //window.location.reload()
        }

        if(props.onClose) { props.onClose() }

    }


    const { role_id } = useAppSelector((state) => state.user);


    //if(OpfTypeNameOptions.filter(ot => ot.label == props.kontur?.UL.opf)[0)

    useEffect(() => {
        if(props.kontur?.data.opf.code && OpfTypeNameOptions.filter(ot => ot.value == props.kontur?.data.opf.code)[0] === undefined) {
            toast.error(`ОПФ тип '${props.kontur?.data.opf.full} ОКПФ: (${props.kontur?.data.opf.code})' не найден в системе`,  { autoClose: false,}  );
        }
    }, [])

    return (
        <div>
            <ToastContainer limit={10} />
            {
                (form !== null || props.clientId === "")
                    ? <FormBuilder
                        initialValues={ props.clientId !== "" ?  (initValues ?? {}) : {
                            inn: (props.chooseForm?.inn ?? props.kontur?.data.inn ),
                            ogrn: props.kontur?.data.ogrn,
                            kpp: (props.kontur && props.kontur.data) ? props.kontur.data.kpp : "",
                            oktmo: (props.kontur && props.kontur.data) ? props.kontur.data.oktmo : "",
                            okpo: (props.kontur && props.kontur.data) ? props.kontur.data.okpo : "",
                            okato: (props.kontur && props.kontur.data) ? props.kontur.data.okato : "",
                            opf_type_id: props.kontur?.data.opf.code ? OpfTypeNameOptions.filter(ot => ot.value == props.kontur?.data.opf.code)[0] : '',
                            name: (props.kontur?.data.name && props.kontur?.data.name.full.length !== 0) ? props.kontur?.data.name.full : ''
                        }}
                        onClose={props.onClose}
                        onSubmit={submitHandler}
                        data={form ?? physicalData}
                        useTab={true}
                        loading={loading}
                        disableAll={role_id === RoleIdsEnum.SSK}
                        title={props.clientId ? (
                            role_id === RoleIdsEnum.KM
                                ? 'Редактирование юридического лица'
                                : 'Просмотр юридического лица'
                        ) : 'Создание нового юридического лица'}
                    />
                    : null
            }
        </div>
    )
}

export default ClientForm;
