import React, {useCallback, useEffect, useRef, useState} from 'react';
import ClientsTable from "../../features/ClientsTable/ClientsTable";
import SplitPane from "react-split-pane";
import {ChooseFormData, ClintTypesEnum, DaDataData} from "../../common/commonTypes";
import 'react-virtualized/styles.css'; // only needs to be imported once
import "./ReactSplitPane.css"
import classes from "./Dashboard.module.scss";
import InsuranceObjectList from "../../features/Purchases/InsuranceObjectList";
import Chat from "../../features/Chat/Chat";
import {useModal} from "../../hooks/useModal";
// @ts-ignore
import CreateNewClientChoose from "../../features/modals/CreateNewClientChoose";
import CreateClientModal from "../../features/modals/CreateClientModal";
import CreatePhysicalClientModal from "../../features/modals/CreatePhysicalClientModal";
import {setActiveId as setIOActiveId, setBlockInsuranceAutoFetching, setFilterIOId} from "../../store/features/insuranceObjects";
import {clearChatData} from "../../store/features/chat";
import InsuranceObjectModal from "../../features/modals/InsuranceObjectModal/InsuranceObjectModal";
import {
    setBlockAutoFetching,
    setFilterId,
    fetchClients,
    setScrollIndex,
    setActiveClient,
} from '../../store/features/clientsTable'
import { setBlockQuotesAutoFetching, setFilterQuotationId } from '../../store/features/quotesTable'
import { useAppDispatch } from '../../store/hooks'
import QuotesTable from "../../features/QuotesTable/QuotesTable";
import OffersModal from "../../features/modals/OffersModal/OffersModal";
import EditOfferFormModal from "../../features/modals/EditOfferFormModal/EditOfferFormModal";
import OfferViewModal from "../../features/modals/OfferViewModal/EditOfferFormModal";
import EditDealFormModal from "../../features/modals/EditDealFormModal/EditOfferFormModal";
import _ from 'lodash';
import GotBillFormModal from "../../features/modals/GotBillFormModal/GotBillFormModal";
import ShowDealFilesModal from "../../features/modals/ShowDealFilesModal/ShowDealFilesModal";
import ShowDealFilesFormModal from "../../features/modals/ShowDealFilesModal/ShowDealFilesModal";


const WINDOW_HEIGHT = (window.innerHeight || document.documentElement.clientHeight ||
    document.body.clientHeight) - 40;


const useQueryParams = (query: string | null = null) => {
    const result: Record<string, string> = {};
    new URLSearchParams(query||window.location.search).forEach((value, key) => {
        result[key] = value;
    });
    return result;
}


// @ts-ignore
function removeParamInAddressBar(parameter) {
    let url = document.location.href;
    let urlparts = url.split('?');

    if (urlparts.length >= 2) {
        let urlBase = urlparts.shift();
        let queryString = urlparts.join("?");

        let prefix = encodeURIComponent(parameter) + '=';
        let pars = queryString.split(/[&;]/g);
        for (let i = pars.length; i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        if (pars.length == 0) {
            // @ts-ignore
            url = urlBase;
        } else {
            url = urlBase + '?' + pars.join('&');
        }

        window.history.pushState('', document.title, url); // push the new url in address bar
    }
    return url;
}

export default function Dashboard() {

    const dispatch = useAppDispatch();

    const { client_id, io_id, quotation_id } = useQueryParams();

    useEffect(() => {

        if(client_id && io_id && quotation_id) {
            //console.log('quotation_id ------- ', quotation_id)
            dispatch(setFilterQuotationId(quotation_id));
            //dispatch(setQuotesScrollIndex(0));
            //dispatch(fetchQuotes({ skip: 0, take: 40, activeInsuranceId: io_id }))
        }

        //console.log('client_id && io_id', client_id, io_id);
        if(client_id && io_id) {
            //console.log('hello', io_id)

            dispatch(setFilterIOId(io_id));
            dispatch(setIOActiveId(io_id));
            // @ts-ignore
            dispatch(setScrollIndex(0));
        }

        if(client_id) {
            dispatch(setFilterId(client_id));
            dispatch(setActiveClient(client_id));
            // @ts-ignore
            dispatch(setScrollIndex(0));
            dispatch(fetchClients({skip: 0, take: 40}));
        }

        removeParamInAddressBar("client_id");
        removeParamInAddressBar("io_id");
        removeParamInAddressBar("quotation_id");

    }, []);

    const [clientTableSize, setClientTableSize] = useState(WINDOW_HEIGHT / 2);
    const [quotesTableSize, setQuotesTableSize] = useState(WINDOW_HEIGHT / 2);

    const [objectTableSize, setObjectTableSize] = useState(WINDOW_HEIGHT / 2);
    const [chatSize, setChatSize] = useState(WINDOW_HEIGHT / 2);
    const [chatKey, setChatKey] = useState(0);


    const modalContentRef = useRef(null);
    const modalPhysicalContentRef = useRef(null);
    const modalClientChooseContentRef = useRef(null);
    const modalInsuranceObjectsContentRef = useRef(null);
    const modalOffersContentRef = useRef(null);
    const modalOfferEditContentRef = useRef(null);
    const modalOfferViewRef = useRef(null);
    const modalDealEditRef = useRef(null);
    const modalGotBillRef = useRef(null);
    const modalShowDealFilesRef = useRef(null);


    const { toggle: legalToggle, Modal, visible: legalVisible } = useModal(modalContentRef);
    const { toggle: physicalToggle, Modal: PhysicalModal, visible: physicalVisible  } = useModal(modalPhysicalContentRef);
    const { toggle: clientChooseToggle, Modal: ClientChooseModal, visible: clientChooseVisible } = useModal(modalPhysicalContentRef);
    const { toggle: insuranceObjectToggle, Modal: InsuranceModal, visible: insuranceVisible } = useModal(modalInsuranceObjectsContentRef);
    const { toggle: addOffersToggle, Modal: OfferModal, visible: offersVisible } = useModal(modalInsuranceObjectsContentRef);
    const { toggle: editOfferToggle, Modal: EditOfferModal, visible: offersEditVisible } = useModal(modalOfferEditContentRef);
    const { toggle: offerViewToggle, Modal: OfferViewsModal, visible: offerViewVisible } = useModal(modalOfferViewRef);
    const { toggle: dealEditToggle, Modal: DealEditModal, visible: dealEditVisible } = useModal(modalDealEditRef);
    const { toggle: gotBillToggle, Modal: GotBillModal, visible: gotBillVisible } = useModal(modalGotBillRef);
    const { toggle: showDealFilesToggle, Modal: ShowDealFilesModal, visible: showDealFilesModalVisible } = useModal(modalGotBillRef);


    // Block pollings if exist active modals
    useEffect(() => {
        const haveVisible = legalVisible || physicalVisible || clientChooseVisible
                            || insuranceVisible || offersVisible ||  offersEditVisible
                            || offerViewVisible || dealEditVisible || gotBillVisible;

        if(haveVisible) {
            dispatch(setBlockAutoFetching(true));
            dispatch(setBlockQuotesAutoFetching(true))
            dispatch(setBlockInsuranceAutoFetching(true))
        } else {
            dispatch(setBlockAutoFetching(false))
            dispatch(setBlockQuotesAutoFetching(false))
            dispatch(setBlockInsuranceAutoFetching(false))
        }
     },
        [legalVisible, physicalVisible, clientChooseVisible,
              insuranceVisible, offersVisible, offersEditVisible,
              offerViewVisible, dealEditVisible, gotBillVisible]
    )


    const addClientHandler = () => {
        setCurrentClientId("");
        clientChooseToggle();
    }

    const onAddOffersHandler = (id: string, offersCount?: number) => {
        if(offersCount === undefined) {
            setOffersCount(0);
        } else {
            setOffersCount(offersCount);
        }
        setCurrentQuoteId(id);
        addOffersToggle();
    }

    const clientModalCloseHandler = () => {
        legalToggle();
    }

    const insuranceModalCloseHandler = () => {
        insuranceObjectToggle();
    }

    const clientPhysicalModalCloseHandler = () => {
        physicalToggle();
    }

    const clientChooseModalCloseHandler = () => {
        clientChooseToggle();
    }

    const [konturData, setKonturData] = useState<DaDataData | undefined>(undefined);
    const [chooseFormData, setChooseFormData] = useState<ChooseFormData | undefined>(undefined);

    const createNewClientChooseSubmitHandler = (value: string, formData?: ChooseFormData, konturResp?: DaDataData) => {
        if(konturResp !== undefined) {
            setKonturData(konturResp);
        } else {
            setKonturData(undefined);
        }

        if(formData !== undefined) {
            setChooseFormData(formData);
        }

        setCurrentClientId("");
        clientChooseToggle();
        if(value == "legal") {
            legalToggle();
        } else {
            physicalToggle();
        }
    }


    const [currentClientId, setCurrentClientId] = useState("");
    const [currentInsuranceObjectId, setCurrentInsuranceObjectId] = useState("");
    const [currentQuoteId, setCurrentQuoteId] = useState("");
    const [currentOffersCount, setOffersCount] = useState(0);
    const [currentOfferId, setCurrentOfferId] = useState("");
    const [currentQuoteOfferId, setCurrentQuoteOfferId] = useState("");
    const [currentQuotationStatusId, setCurrentQuotationStatusId] = useState("");
    const [currentDealQuoteId, setCurrentDealQuoteId] = useState("");
    const [currentDealIsEdit, setCurrentDealIsEdit] = useState(false);

    const [currentDealAccountNumber, setCurrentDealAccountNumber] = useState("")
    const [currentDealFiles, setCurrentDealFiles] = useState([])
    const [currentDealClientName, setCurrentDealClientName] = useState("")

    const editClientHandler = (id: string, type: ClintTypesEnum) => {
        setCurrentClientId(id);
        if(type === ClintTypesEnum.LEGAL) {
            legalToggle();
        } else if (type === ClintTypesEnum.PHYSICAL) {
            physicalToggle()
        } else {
            alert('Неизвестный тип клиента, пожалуйста, обратитесь к администратору');
        }
    }

    const insuranceObjectsAddHandler = () => {
        setCurrentInsuranceObjectId("");
        insuranceObjectToggle();
    }

    const insuranceObjectEditHandler = (id: string) => {
        setCurrentInsuranceObjectId(id);
        insuranceObjectToggle();
        //console.log('insuranceObjectEditHandler', id)
    }


    const onDealEdit = (quotationId: string, isEdit: boolean, offerId: string) => {
        setCurrentDealQuoteId(quotationId);
        setCurrentDealIsEdit(isEdit);
        setCurrentOfferId(offerId);
        dealEditToggle();
    }

    const onGotBillHandler = (offerId: string, ) => {
        //console.log('offerId', offerId)
        setCurrentOfferId(offerId);
        gotBillToggle();
    }


    const onOfferEdit = (offerId: string, quoteId: string, quotationStatusId: string) => {
        //console.log('offerId', offerId, quoteId)
        setCurrentOfferId(offerId);
        setCurrentQuoteOfferId(quoteId);
        setCurrentQuotationStatusId(quotationStatusId);
        editOfferToggle();
    }

    const onOfferView = (offerId: string, quotationStatusId: string) => {
        //console.log('offerId', offerId)
        setCurrentOfferId(offerId);
        setCurrentQuotationStatusId(quotationStatusId);
        offerViewToggle();
    }

    //useEffect(() => dealEditToggle(), [])


    const changeHandler = async (newSize: any) => {
        //console.log('hello')
        await dispatch(clearChatData());
        setObjectTableSize(newSize)
        setChatSize(WINDOW_HEIGHT - newSize);
        setChatKey(Math.floor(Math.random() * 100));
    }

    const debounced = useRef(_.debounce((newSize) => changeHandler(newSize), 50))


    const debouncedChangeHandler = useCallback((newSize: any) => _.debounce(changeHandler, 5000)(newSize), [])


    const recalcChatSize = useCallback((newSize: any) => {
        debounced.current(newSize)
    }, [])
    return (
        <div className={classes.root}>

            <GotBillModal>
                <GotBillFormModal ref={modalGotBillRef} offerId={currentOfferId} onClose={() => gotBillToggle()} />
            </GotBillModal>

            <ShowDealFilesModal>
                <ShowDealFilesFormModal ref={modalShowDealFilesRef} accountNumber={currentDealAccountNumber}
                                        files={currentDealFiles} clientName={currentDealClientName}
                                        onClose={() => showDealFilesToggle()} />
            </ShowDealFilesModal>

            <DealEditModal>
                <EditDealFormModal
                    ref={modalDealEditRef}
                    onClose={() => dealEditToggle()}
                    dealId={""}
                    isEdit={currentDealIsEdit}
                    quotationId={currentDealQuoteId}
                    offerId={currentOfferId}
                />
            </DealEditModal>

            <OfferViewsModal>
                <OfferViewModal
                    ref={modalOfferViewRef}
                    onClose={() => offerViewToggle()}
                    offerId={currentOfferId}
                    quotationStatusId={currentQuotationStatusId}
                />
            </OfferViewsModal>
            <OfferModal>
                <OffersModal
                    ref={modalOffersContentRef}
                    onClose={addOffersToggle}
                    clientId={currentClientId}
                    quoteId={currentQuoteId}
                    offersCount={currentOffersCount}
                />
            </OfferModal>
            <EditOfferModal>
                <EditOfferFormModal
                    ref={modalOfferEditContentRef}
                    onClose={editOfferToggle}
                    offerId={currentOfferId}
                    quoteId={currentQuoteOfferId}
                    quotationStatusId={currentQuotationStatusId}
                />
            </EditOfferModal>
            <InsuranceModal>
                <InsuranceObjectModal
                    ref={modalInsuranceObjectsContentRef}
                    onClose={insuranceModalCloseHandler}
                    clientId={currentClientId}
                    insuranceObjectId={currentInsuranceObjectId}
                    header={"Объект страхования"}
                />
            </InsuranceModal>
            <Modal>
                <CreateClientModal
                    ref={modalContentRef}
                    onClose={clientModalCloseHandler}
                    clientId={currentClientId}
                    kontur={konturData}
                    chooseForm={chooseFormData}
                />
            </Modal>
            <PhysicalModal>
                <CreatePhysicalClientModal
                    ref={modalPhysicalContentRef}
                    onClose={clientPhysicalModalCloseHandler}
                    clientId={currentClientId}
                    chooseForm={chooseFormData}
                />
            </PhysicalModal>
            <ClientChooseModal>
                <CreateNewClientChoose ref={modalClientChooseContentRef}
                                       onSubmit={createNewClientChooseSubmitHandler}
                                       onClose={clientChooseModalCloseHandler}
                />
            </ClientChooseModal>
            <div className={classes.leftSplitPane}>

                <SplitPane
                    split="horizontal"
                    defaultSize={WINDOW_HEIGHT / 2}
                    minSize={200}
                    maxSize={WINDOW_HEIGHT - 200}
                    onChange={(newSize: number) => {
                        setClientTableSize(newSize)
                        setQuotesTableSize(WINDOW_HEIGHT - newSize)
                    }}
                >
                    <ClientsTable height={clientTableSize} onAddClick={addClientHandler} onEditClientClick={editClientHandler} />
                    <QuotesTable height={quotesTableSize}
                                 onAddOffers={onAddOffersHandler}
                                 onOfferEdit={onOfferEdit}
                                 onOfferView={onOfferView}
                                 onAddClick={onAddOffersHandler}
                                 onEditDeal={onDealEdit}
                                 gotBillHandler={onGotBillHandler}
                    />
                </SplitPane>
            </div>

            <div className={classes.rightSplitPane}>
                <SplitPane
                    split="horizontal"
                    defaultSize={WINDOW_HEIGHT / 2}
                    minSize={200}
                    maxSize={WINDOW_HEIGHT - 200}
                    onChange={(newSize: number) => { recalcChatSize(newSize) }}
                >
                    <InsuranceObjectList
                        height={objectTableSize}
                        addOnClick={insuranceObjectsAddHandler}
                        onEdit={insuranceObjectEditHandler}
                    />
                    <Chat height={chatSize} key={chatKey} />
                </SplitPane>
            </div>

        </div>
    );
}