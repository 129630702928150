import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    ClientLegalInterface,
    ClientPhysicalInterface,
    ClientsResponse, CreateLegalInterface, CreatePhysicalInterface,
    RequestStatusEnum,
} from "../../common/commonTypes";
import {getClientsTableRequest, getLegalClient, getPhysicalClient} from "../../api/commonApi";
import CreatePhysicalClientModal from "../../features/modals/CreatePhysicalClientModal";




interface InsuranceObjectsState {
    legalClient: CreateLegalInterface | null,
    physicalClient: CreatePhysicalInterface | null,
    status: RequestStatusEnum,
}

// Define the initial state using that type
const initialState: InsuranceObjectsState = {
    legalClient: null,
    physicalClient: null,
    status: RequestStatusEnum.Idle,
};

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export const fetchLegalClient = createAsyncThunk('editClient/getLegalClient', async ({clientId}: {clientId: string}, { getState }) => {
    return await getLegalClient(clientId);
});

export const fetchPhysicalClient = createAsyncThunk('editClient/getPhysicalClient', async ({clientId}: {clientId: string}, { getState }) => {
    return await getPhysicalClient(clientId);
})


export const editClientSlice = createSlice({
    name: 'editClient',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setData: (state, { payload }: {payload: string}) => {},
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLegalClient.pending, (state, action) => {
                state.status = RequestStatusEnum.Loading;
            })
            .addCase(fetchLegalClient.fulfilled, (state, action) => {
                //console.log('action.payload', action.payload)
                state.legalClient = action.payload;
                state.status = RequestStatusEnum.Succeeded;

                //state.items = action.payload.items;
            })
            .addCase(fetchLegalClient.rejected, (state, action) => {
                state.status = RequestStatusEnum.Failed;
            })

            .addCase(fetchPhysicalClient.pending, (state, action) => {
                state.status = RequestStatusEnum.Loading;
            })
            .addCase(fetchPhysicalClient.fulfilled, (state, action) => {
                //console.log('action.payload', action.payload)
                state.physicalClient = action.payload;
                state.status = RequestStatusEnum.Succeeded;

                //state.items = action.payload.items;
            })
            .addCase(fetchPhysicalClient.rejected, (state, action) => {
                state.status = RequestStatusEnum.Failed;
            })
    }

})



export const {
    setData,
} = editClientSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default editClientSlice.reducer