import React from 'react';
import chroma from 'chroma-js';

import { ColourOption } from './data';
import Select, { StylesConfig } from 'react-select';

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({ ...styles,
        backgroundColor: 'white',
        width: 150,
        height: 25,
        minHeight: 25,
        fontSize: 12
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: 24}),
    valueContainer: (styles) => ({ ...styles, top: '-4px', left: '-5px'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    input: (styles) => ({ ...styles, ...dot(),}),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color),}),
    dropdownIndicator: (styles) => ({ ...styles, width: 20, padding: "2px"}),
    clearIndicator: (styles) => ({ ...styles,
        padding: "0 1px",
        width: 13,
        height: 13,
        background: "#FC5A5A",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "6px",
        color: "white",
        cursor: "pointer"
    }),
};

interface StatusSelectProps {
    options: Array<ColourOption>,
    onChange?: any;
    value?: any,
    placeholder?: string,
}

export default (props: StatusSelectProps) => (
    <Select
        options={props.options}
        styles={colourStyles}
        onChange={props.onChange}
        isClearable
        placeholder={props.placeholder}
        value={props.options?.filter((o) => o.value == props.value)}
    />
);