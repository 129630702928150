import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import classes from "./InsuranceObjectModal.module.scss";
import InsuranceObjectForm from "../../../forms/InsuranceObjectForm/InsuranceObjectForm";




export default function InsuranceObjectModal (props: { onClose?: () => void, ref?: any, clientId: string, insuranceObjectId: string, header: string }) {


    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <InsuranceObjectForm clientId={props.clientId} insuranceObjectId={props.insuranceObjectId} onClose={props.onClose} header={props.header} />
            </div>
        </div>
    );
}