import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect, useState} from 'react';
import 'react-virtualized/styles.css';
import classes from "./ShowDealPaymentScheduleModal.module.scss";
import {ToastContainer} from 'react-toastify';
import {useDispatch} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {getDealsPaymentSchedule, updateIsPaidStatusDealsPaymentSchedule} from "../../../api/commonApi";
import {Button} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {formatDateForClient, separateThousands} from "../../../common/lib";


export default function ShowDealPaymentScheduleFormModal(props: { onClose?: () => void, ref?: any, dealId: string }) {
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const [loading, setLoading] = useState(true);


    const loadingFunc = async () => {
        getDealsPaymentSchedule(props.dealId).then((data) => {
            setState(data.data);
        })
    }

    useEffect(() => {
            setLoading(false);
            loadingFunc()
        },
        []
    )

    let paymentScheduleId = '';

    function renderDealsPaymentScheduleRows(obj: any) {
        return (
            <div className={classes.row} style={{display: "flex", justifyContent: "space-between"}}>
                <div className={classes.rowItem}>
                    {
                        obj.number
                    }
                </div>
                <div className={classes.rowItem} style={{width: "150px"}}>
                    {
                        separateThousands(obj.sum)
                    }
                </div>
                <div className={classes.rowItem}>
                    {
                        formatDateForClient(obj.date_to_pay)
                    }
                </div>
                <div className={classes.rowItem}>
                    {
                        obj.is_paid ? '✓' : <Button className={classes.payButton} onClick={(e) =>
                            //@ts-ignore
                            changeIsPaidStatusHandler(e, obj.id)}>
                            Оплатить
                        </Button>
                    }
                </div>
            </div>
        )
    }

    const changeIsPaidStatusHandler = async (e: any, paymentScheduleId: any) => {
        await updateIsPaidStatusDealsPaymentSchedule(props.dealId, paymentScheduleId, {is_paid: true});
        e.target.replaceWith('✓');
    }

    if (loading) {
        return <div
            style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size={100}/>
        </div>
    } else {

        return (
            <div className={classes.root}>
                <div className={classes.clientForm} style={{width: "40%"}}>
                    <ToastContainer limit={10}/>

                    <div className={classes.header}>
                        <div style={{fontWeight: 600}}>
                            График платежей
                        </div>

                        <CloseIcon onClick={props.onClose} style={{cursor: "pointer"}}/>
                    </div>

                    <div className={classes.header} style={{display: "flex", justifyContent: "space-between",  textAlign: 'center', marginBottom: "20px"}}>
                        <div style={{width: "100px"}}>
                            №
                        </div>
                        <div style={{width: "150px"}}>
                            Сумма платежа
                        </div>
                        <div style={{width: "100px"}}>
                            Дата
                        </div>
                        <div style={{width: "100px"}}>
                            Оплачен?
                        </div>
                    </div>

                    <div>
                        {
                            state.map((item: any) => {
                                return renderDealsPaymentScheduleRows(item);
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
