import React, {useEffect} from "react";
import classes from "./LoginPage.module.scss"
import LoginForm from "../../forms/LoginForm/LoginForm";
import {useAppSelector} from "../../store/hooks";
import {useNavigate} from "react-router-dom";

export default function LoginPage(props: any) {
    const navigate = useNavigate();

    const user = useAppSelector((state) => state.user);

    useEffect(() => {
        if (localStorage.getItem("user")) {
            navigate("/")
        }
    }, [user])

    return (
        <div className={classes.root}>
            <div className={classes.form}>
                <div className={classes.name}>Авторизация</div>
                <LoginForm/>
            </div>
        </div>
    )
}
