import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {useState} from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {CompanyInterface, UserInterface} from "../../../common/commonTypes";
import moment from "moment";
import {USER_ROLES} from "../AddUserModal";

interface UserCardProps {
    user: UserInterface;
    company: CompanyInterface;
    onEditUser: (userID: string, roleID: number) => void;
    onDeleteUser: () => void;
}

export default function UserCard(props: UserCardProps) {
    const [anchorUserCardMenu, setAnchorUserCardMenu] = useState<null | HTMLElement>(null);
    const handleOpenUserCardMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorUserCardMenu(event.currentTarget);
    };
    const handleCloseUserCardMenu = () => {
        setAnchorUserCardMenu(null);
    };

    const handleEditUser = () => {
        props.onEditUser(props.user.id, props.user.role_id);
    }
    const handleDeleteUser = () => {
        props.onDeleteUser();
    }

    return (
        <Card sx={{backgroundColor: moment(props.user.created_at).isAfter(moment().subtract(1, 'hours')) ? "aqua" : "", minWidth: 300}}>
            <Box component={"div"} sx={{height: 64, minHeight: 64}}>
                <CardHeader
                    subheader={USER_ROLES[props.user.role_id]}
                    action={
                        <IconButton aria-label="settings"
                                    onClick={handleOpenUserCardMenu}>
                            <MoreVertIcon/>
                        </IconButton>
                    }
                />
                <Menu
                    sx={{mt: '45px'}}
                    id="menu-appbar"
                    anchorEl={anchorUserCardMenu}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorUserCardMenu)}
                    onClose={handleCloseUserCardMenu}
                >
                    <MenuItem onClick={handleEditUser}>
                        <Typography textAlign="center">
                            Изменить
                        </Typography>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleDeleteUser}>
                        <Typography textAlign="center" color="error">
                            Удалить
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
            <CardContent>
                <Typography gutterBottom noWrap variant="h6" component="div"
                            textAlign={"center"}
                            textOverflow={'ellipsis'}>
                    {props.user.name}
                </Typography>
                <Typography gutterBottom noWrap variant="subtitle1" component="div"
                            textAlign={"center"}
                            maxWidth={250} textOverflow={'ellipsis'}>
                    {props.company.name}
                </Typography>
            </CardContent>
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>
                        {props.user.email}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}