import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import classes from "./EditOfferFormModal.module.scss";
import EditOfferForm from "../../../forms/EditOfferForm/EditOfferForm";


export default function EditOfferFormModal (props: { onClose?: () => void, ref?: any, offerId: string, quoteId: string, quotationStatusId: string}) {
    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <EditOfferForm offerId={props.offerId} quoteId={props.quoteId} onClose={props.onClose} quotationStatusId={props.quotationStatusId} />
            </div>
        </div>
    );
}