import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import classes from "./BasePageWrapper.module.scss"
import SearchIcon from '@mui/icons-material/Search';
import LogoIconSvg from "../icons/LogoIconSvg";
import HeaderLink from "../ui/HeaderLink/HeaderLink";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {UserInterface} from "../../common/commonTypes";
import {useAppSelector} from "../../store/hooks";
import {setUser} from "../../store/features/user";
import {useDispatch} from "react-redux";
import {HelpCenterRounded} from "@mui/icons-material";
import {useModal} from "../../hooks/useModal";
import {useRef} from "react";
import HelpFormModal from "../modals/HelpFormModal/HelpFormModal";


export default function BasePageWrapper(props: any) {

    const modalHelpRef = useRef(null);
    const { toggle: helpModalToggle, Modal: HelpModal, visible: showHelpModalVisible } = useModal(modalHelpRef);

    const logout = () => {
        localStorage.setItem("user", "");
        localStorage.setItem("access_token", "");
        localStorage.setItem("refresh_token", "");
        location.href = "/login" + window.location.search;
    }

    const { email, name, role_id } = useAppSelector((state) => state.user);
    const clientFilterId = useAppSelector((state) => state.clientsTable.filters.id );


    return (
        <div>
            <div className={classes.header}>
                <div className={classes.logo}>
                    <LogoIconSvg width={60} />
                </div>


                {
                    clientFilterId.length !== 0
                    && <div className={classes.editMode}>
                        Вы находитесь в режиме просмотра по ссылке
                    </div>
                }
                <div className={classes.headerItemsWrapper}>
                    <div className={classes.linkWrapper}>
                        <HeaderLink to={'/'} icon={ <PersonOutlineIcon />} >
                            Клиенты и котировки
                        </HeaderLink>

                        <HeaderLink to={'/deals'} icon={ <LegendToggleIcon />} >
                            Сделки
                        </HeaderLink>

                        <div className={classes.menuEntry} onClick={() => helpModalToggle()}>
                            <div className={classes.menuEntryIcon}>
                                <HelpCenterRounded />
                            </div>
                            <div>Помощь</div>
                        </div>
                    </div>

                    <div className={classes.accountInfo}>
                        <AccountCircleIcon className={classes.accountNonIcon} />
                        <div className={classes.accountData}>
                            <div className={classes.accountName}>
                                { name }
                            </div>
                            <div className={classes.accountEmail}>
                                { email }
                            </div>
                        </div>

                        <div className={classes.accountMenu}>
                            <div className={classes.menuItem} onClick={logout}>
                                Выйти
                                <LogoutIcon className={classes.menuIcon}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{padding: "20px"}}>
                { props.children }
            </div>

            <HelpModal>
                <HelpFormModal
                    ref={modalHelpRef}
                    onClose={() => helpModalToggle()}
                    userRole={role_id}
                />
            </HelpModal>
        </div>
    );
}