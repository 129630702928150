import { configureStore } from '@reduxjs/toolkit'
import counterReducer from "./features/counter"
import clientsTable from "./features/clientsTable"
import quotesTable from "./features/quotesTable"
import insuranceObjects from "./features/insuranceObjects"
import editClient from "./features/currentClient"
import userSlice from "./features/user"
import dealsTableSlice from "./features/dealsTable"
import chat from "./features/chat"

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        clientsTable: clientsTable,
        quotesTable: quotesTable,
        insuranceObjects: insuranceObjects,
        editClient: editClient,
        user: userSlice,
        dealsTable: dealsTableSlice,
        chat: chat,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
