import React, {InputHTMLAttributes, MouseEventHandler, useEffect, useState} from 'react';
import classes from "./ClearButton.module.scss";
import CancelIcon from '@mui/icons-material/Cancel';
import cn from "classnames"

interface ClearButtonProps {
    width: number;
    onClick?: MouseEventHandler<HTMLDivElement> ;
}

export default function ClearButton(props: ClearButtonProps) {

    return (
        <div className={classes.root}
             onClick={props.onClick}
             style={{
                 width: typeof props.width == "number" ? `${(props.width)}px` : "40px",
                 height: typeof props.width == "number" ? `${(props.width)}px` : "40px",
             }}
        >
            <CancelIcon style={{ height: '50%', color: '#FC5A5A' }} />
        </div>
    );
}