import React, {useState} from 'react';
import classes from "./PurchaseObject.module.scss";
import {
    InsuranceObjectInterface,
    InsuranceObjectStatusesEnum,
    InsuranceObjectTypesEnum,
    RoleIdsEnum
} from "../../common/commonTypes";
import EditIcon from '@mui/icons-material/Edit';
import cn from "classnames"
import {createInsuranceObject, getInsuranceObject, updateInsuranceObjectStatus} from "../../api/commonApi";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {setActiveId, updateItemStatus, updateQuotationCount} from "../../store/features/insuranceObjects";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {formatDateForClient, separateThousands} from "../../common/lib";
import {ContentCopy} from "@mui/icons-material";
import _, {isUndefined} from "lodash";
import {toast} from "react-toastify";


interface InsuranceObjectItemProps {
    insuranceObject: InsuranceObjectInterface,
    onEdit?: (id: string) => void,
    onCopy: (id: string) => void,
}

const dateTimeFormat = (date: string) => {
    const dateTime = date.split('T');
    const dateDay = formatDateForClient(date);
    return dateDay.replaceAll('-', '.') + ' ' + dateTime[1].split('.')[0].split(":").slice(0, 2).join(":");
}

const getStatusName = (status: InsuranceObjectStatusesEnum, quotations_count?: number) => {
    if (status === InsuranceObjectStatusesEnum.NEW) {
        return 'Новый'
    } else if (status === InsuranceObjectStatusesEnum.SEND_IN_INSURANCE_COMPANY) {
        return 'Отправлен в СК'
    } else if (status === InsuranceObjectStatusesEnum.WORKING_IN_INSURANCE_COMPANY) {
        if (!quotations_count) {
            return 'Отправлен в СК'
        } else {
            return 'В работе СК'
        }
    } else if (status === InsuranceObjectStatusesEnum.QUOTES_PROPOSED) {
        return 'Котировки предложены'
    } else if (status === InsuranceObjectStatusesEnum.DEAL_REQUESTED) {
        return 'Договор запрошен'
    } else if (status === InsuranceObjectStatusesEnum.INSURED) {
        return 'Застрахован'
    } else {
        return 'Неизвестный статус'
    }
}

const getStatusClass = (status: InsuranceObjectStatusesEnum, quotations_count?: number) => {
    if (status === InsuranceObjectStatusesEnum.NEW) {
        return classes.statusNew
    } else if (status === InsuranceObjectStatusesEnum.SEND_IN_INSURANCE_COMPANY) {
        return classes.statusSendInInsuranceCompany
    } else if (status === InsuranceObjectStatusesEnum.WORKING_IN_INSURANCE_COMPANY) {
        if (!quotations_count) {
            return classes.statusSendInInsuranceCompany
        } else {
            return classes.statusInWork
        }
    } else if (status === InsuranceObjectStatusesEnum.QUOTES_PROPOSED) {
        return classes.quotesProposed
    } else if (status === InsuranceObjectStatusesEnum.DEAL_REQUESTED) {
        return classes.statusUnknown
    } else if (status === InsuranceObjectStatusesEnum.INSURED) {
        return classes.statusUnknown
    } else {
        return classes.statusUnknown
    }
}


const getTypeName = (status: InsuranceObjectTypesEnum) => {
    if (status === InsuranceObjectTypesEnum.APARTMENT) {
        return 'Квартира'
    } else if (status === InsuranceObjectTypesEnum.HOUSE) {
        return 'Дом'
    } else if (status === InsuranceObjectTypesEnum.VEHICLE) {
        return 'Автотранспортные средства'
    } else if (status === InsuranceObjectTypesEnum.COMMERCIAL_TMC) {
        return 'Недвижимость, Оборудование, ТМЦ'
    } else if (status === InsuranceObjectTypesEnum.OTHER_TRANSPORT) {
        return 'Прочие транспортные средства'
    } else {
        return 'Неизвестный тип'
    }
}


export default function InsuranceObjectItem(props: InsuranceObjectItemProps) {

    //console.log('item', props.insuranceObject.id);

    const dispatch = useAppDispatch();
    const activeClientId = useAppSelector((state) => state.clientsTable.activeClientId);

    const [actionInProcess, setActionInProcess] = useState(false);
    const [actionDeleteInProcess, setActionDeleteInProcess] = useState(false);

    const sendToSk = async () => {
        setActionInProcess(true);
        try {
            await updateInsuranceObjectStatus(props.insuranceObject.id, {status_id: InsuranceObjectStatusesEnum.SEND_IN_INSURANCE_COMPANY});
            await dispatch(updateItemStatus({
                id: props.insuranceObject.id,
                status: InsuranceObjectStatusesEnum.SEND_IN_INSURANCE_COMPANY
            }));
            if (activeId === props.insuranceObject.id) {
                await dispatch(setActiveId(""))
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            await dispatch(setActiveId(props.insuranceObject.id));
        } finally {
            setActionInProcess(false);
        }
    }

    const deleteFromWork = async () => {
        setActionDeleteInProcess(true)
        try {
            await updateInsuranceObjectStatus(props.insuranceObject.id, {status_id: InsuranceObjectStatusesEnum.DECLINED_BY_INSURANCE_COMPANY});
            await dispatch(updateQuotationCount({id: props.insuranceObject.id, quotations_count: 0}));
            if (activeId === props.insuranceObject.id) {
                await dispatch(setActiveId(""));
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            await dispatch(setActiveId(props.insuranceObject.id));
        } finally {

        }
    }

    const takeInWork = async () => {
        setActionInProcess(true);
        try {
            await updateInsuranceObjectStatus(props.insuranceObject.id, {status_id: InsuranceObjectStatusesEnum.WORKING_IN_INSURANCE_COMPANY});
            await dispatch(updateQuotationCount({id: props.insuranceObject.id, quotations_count: 1}));
            if (activeId === props.insuranceObject.id) {
                await dispatch(setActiveId(""));
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            await dispatch(setActiveId(props.insuranceObject.id));
        } finally {
            setActionInProcess(false);
        }
    }


    const {role_id} = useAppSelector((state) => state.user);
    const {activeId} = useAppSelector((state) => state.insuranceObjects);

    //console.log('props.insuranceObject', props.insuranceObject)

    const AllowedActionButtons = () => {
        if ([RoleIdsEnum.KM].includes(role_id) && props.insuranceObject.status_id == InsuranceObjectStatusesEnum.NEW) {
            return <div className={cn(classes.btn, {[classes.actionInProcessBtn]: actionInProcess})} onClick={sendToSk}>
                {actionInProcess && <CircularProgress size={15} style={{color: "white", marginRight: '5px'}}/>}
                {actionInProcess ? 'Отправляем в СК' : 'Отправить в СК'}
            </div>
        } else if ([RoleIdsEnum.SSK].includes(role_id) && !props.insuranceObject.quotations_count) {
            return <div className={classes.btn} onClick={takeInWork}>
                {actionInProcess && <CircularProgress size={15} style={{color: "white", marginRight: '5px'}}/>}
                {actionInProcess ? 'Берем в работу' : 'Взять в работу'}
            </div>
        } else if ([RoleIdsEnum.SSK].includes(role_id) && props.insuranceObject.quotations_count && props.insuranceObject.status_id !== InsuranceObjectStatusesEnum.INSURED) {
            return <div className={classes.badBtn} onClick={deleteFromWork}>
                {actionDeleteInProcess && <CircularProgress size={15} style={{color: "white", marginRight: '5px'}}/>}
                {actionDeleteInProcess ? 'Удаляем из работы' : 'Удалить из работы'}
            </div>
        } else {
            return null;
        }
    }

    const handleInsuranceObjectClick = () => {
        dispatch(setActiveId(props.insuranceObject.id));
    }

    let highlightNew = false;
    try {
        let date = new Date((new Date(props.insuranceObject.created_at)).toUTCString());
        date.setSeconds(date.getSeconds() + 10);
        highlightNew = ((new Date()) < date) && props.insuranceObject.status_id === InsuranceObjectStatusesEnum.NEW;
    } catch (e) {
    }

    let highlightSentInSSK = false;
    try {
        let date = new Date((new Date(props.insuranceObject.updated_at)).toUTCString());
        date.setSeconds(date.getSeconds() + 10);
        highlightSentInSSK = ((new Date()) < date) && props.insuranceObject.status_id === InsuranceObjectStatusesEnum.SEND_IN_INSURANCE_COMPANY && role_id === RoleIdsEnum.SSK;
    } catch (e) {
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuIsOpen = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleEditClick = () => {
        props.onEdit && props.onEdit(props.insuranceObject.id);
        setAnchorEl(null);
    };

    const handleCopyClick = async () => {
        props.onCopy(props.insuranceObject.id);
        setAnchorEl(null);
    }


    return (
        <div className={cn(classes.root, {
            [classes.active]: props.insuranceObject.id === activeId,
            [classes.new]: (highlightNew || highlightSentInSSK)
        })}>
            <div className={classes.header}>
                <div
                    className={cn(classes.status, getStatusClass(props.insuranceObject.status_id, props.insuranceObject.quotations_count))}>
                    <div className={classes.statusIcon}></div>
                    {getStatusName(props.insuranceObject.status_id, props.insuranceObject.quotations_count)}
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div className={classes.date}>{dateTimeFormat(props.insuranceObject.updated_at)}</div>

                    <div
                        className={classes.rowMenu}
                        style={{cursor: "pointer"}}
                        // @ts-ignore
                        onClick={(event) => handleClick(event)}>
                        <MoreVertIcon className={classes.menuIcon}/>
                    </div>
                </div>

                <Menu
                    id="quote-menu"
                    anchorEl={anchorEl}
                    open={menuIsOpen}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleEditClick}>
                        <ListItemIcon>
                            <EditIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>{role_id === RoleIdsEnum.KM ? 'Редактировать' : 'Просмотреть'}</ListItemText>
                    </MenuItem>
                    {role_id === RoleIdsEnum.KM && <MenuItem onClick={handleCopyClick}>
                        <ListItemIcon>
                            <ContentCopy fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Сделать копию</ListItemText>
                    </MenuItem>}
                </Menu>

            </div>
            <div className={classes.info} onClick={handleInsuranceObjectClick}>
                <div className={classes.keyValue}>
                    <div className={classes.key}>Вид:</div>
                    <div className={classes.value}>{getTypeName(props.insuranceObject.type_id)}</div>
                </div>
                <div className={classes.keyValue}>
                    <div className={classes.key}>Страховая сумма:</div>
                    <div className={classes.value}>{separateThousands(props.insuranceObject.insurance_sum)} руб.</div>
                </div>
                {
                    props.insuranceObject.address && props.insuranceObject.address !== " " &&
                    <div className={classes.keyValue}>
                        <div className={classes.key}>Адрес:</div>
                        <div className={classes.value}>{props.insuranceObject.address}</div>
                    </div>
                }
            </div>

            <div className={classes.footer}>
                <div>
                </div>
                <div className={classes.footerRightButtons}>

                    <AllowedActionButtons/>

                </div>
            </div>

        </div>
    );
}