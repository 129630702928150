import * as React from "react";

const LogoIconSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 585.93 141.88"
         shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality"
         fillRule="evenodd" clipRule="evenodd" className="sc-bqGGPW iPexDg">
        <path fill="#323C47"
              d="M404.48 94.97l-.13 17.53 33.33-.05L498.45.54h17.33l70.15 141.11h-32.38l-47.21-94.97-51.41 94.68-79.75.15.35-46.65 28.95.11zM210.04 0l.67 141.51-79.71-.15-51.42-94.68-47.2 94.97H0L70.15.54h17.33l60.77 111.91 33.37.05L181.09.11 210.04 0z"></path>
        <path fill="#109CF1"
              d="M244.83 141.88V.06h29.06l54.16 55 26.04-25.97-25.99.04V.18l76.74-.12.11 75.59H376l-.04-27.45-47.97 47.85-54.1-54.27v100.1z"></path>
    </svg>
);

export default LogoIconSvg;
