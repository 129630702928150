import * as React from 'react';
import {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {createKM, createSSK, getAllCompanies} from "../../../api/commonApi";
import {CompanyInterface, RoleIdsEnum} from "../../../common/commonTypes";
import {Field, Form} from 'react-final-form'
import {LoadingButton} from "@mui/lab";

export const USER_ROLES: Record<number, string> = {
    2: "KM",
    3: "SSK",
    4: "SUPERVISOR_KM",
    5: "SUPERVISOR_SSK",
}

interface CreateUserDTO {
    email: string;
    name: string;
    role_id: number;
}

export interface CreateKMDTO {
    user: CreateUserDTO;
    company_id: string;
}

export interface CreateSSKDTO {
    user: CreateUserDTO;
    company_id: string;
}

interface AddUserModalProps {
    isOpen: boolean;
    onClose: () => void;
    companies: Record<string, CompanyInterface>;
}

export const AddUserModal = (props: AddUserModalProps) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: CreateKMDTO | CreateSSKDTO) => {
        if (data.company_id == "") {
            alert("выберите компанию")
            return
        }
        if (data.user.name == "") {
            alert("введите имя")
            return
        }
        if (data.user.email == "") {
            alert("введите почту")
            return
        }
        setLoading(true)
        if ([RoleIdsEnum.KM, RoleIdsEnum.SUPERVISOR_KM].includes(data.user.role_id)) {
            createKM(data).then(() => {
                alert("КМ создан")
                props.onClose()
            }).finally(() => {
                setLoading(false)
            })
        } else if ([RoleIdsEnum.SSK, RoleIdsEnum.SUPERVISOR_SSK].includes(data.user.role_id)) {
            createSSK(data).then(() => {
                alert("SSK создан")
                props.onClose()
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const getCompanyName = (key: string) => {
        if (!props.companies) return ""
        if (!props.companies[key]) return ""
        if (!props.companies[key].name) return ""
        return props.companies[key].name
    }

    const getCompanyId = (key: string) => {
        if (!props.companies) return ""
        if (!props.companies[key]) return ""
        if (!props.companies[key].id) return ""
        return props.companies[key].id
    }

        return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isOpen}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isOpen}>
                    <Box sx={{
                        position: "absolute",
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "30%",
                        height: "30%",
                        overflow: "scroll",
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Typography variant="h6" component="h2">
                            Добавить пользователя
                        </Typography>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                user: {
                                    email: "",
                                    name: "",
                                    password: "",
                                    role_id: RoleIdsEnum.SSK,
                                },
                                company_id: "",
                            }}
                            render={({handleSubmit, form, submitting, pristine, values}) => (
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <label>Электропочта</label>
                                        <Field
                                            name="user.email"
                                            component="input"
                                            type="text"
                                            placeholder="Электропочта"
                                        />
                                    </div>
                                    <div>
                                        <label>Пароль</label>
                                        <Field
                                            name="user.password"
                                            component="input"
                                            type="text"
                                            placeholder="Пароль"
                                        />
                                    </div>
                                    <div>
                                        <label>Имя</label>
                                        <Field
                                            name="user.name"
                                            component="input"
                                            type="text"
                                            placeholder="Имя"
                                        />
                                    </div>
                                    <div>
                                        <label>Роль</label>
                                        <Field name="user.role_id" component="select">
                                            {
                                                Object.keys(USER_ROLES).map(Number).map((ur) => (
                                                    <option value={ur}>{USER_ROLES[ur]}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <label>Компания</label>
                                        <Field name="company_id" component="select">
                                            <option/>
                                            {
                                                Object.keys(props.companies).map(String).map((ur) => (
                                                    <option value={getCompanyId(ur)}>
                                                        {
                                                            getCompanyName(ur)
                                                        }
                                                    </option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    <LoadingButton loading={loading} type={"submit"} variant="outlined" sx={{mt: 3}}>
                                        Сохранить
                                    </LoadingButton>
                                </form>
                            )}
                        />
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}