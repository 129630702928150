import React, {useEffect, useState} from "react";

export function useModal(ref) {
  const [visible, setVisible] = useState(false);
  const toggle = (isVisible) => {
    if (typeof isVisible === 'boolean') {
      setVisible(isVisible);
    } else {
      setVisible((prevState => (!prevState)));
    }
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggle(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  const Modal = ({children , overlay = false}) => (
    <>
      {visible && overlay && (
        <div
          css={`
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #171725;
            opacity: .4;
            z-index: 10000;
          `}
        >
          {visible && children}
        </div>
      )}

      {visible && children}
    </>
  );

  return { visible, toggle, Modal};
}
