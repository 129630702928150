import React, {useEffect} from 'react';

import 'react-virtualized/styles.css';
import classes from "./HelpFormModal.module.scss";
import {ToastContainer} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
// @ts-ignore
import QRious from "qrious"
import {RoleIdsEnum} from "../../../common/commonTypes";

interface HelpFormModalProps {
    onClose?: () => void;
    ref?: any;
    userRole: number;
}

export default function HelpFormModal(props: HelpFormModalProps) {

    useEffect(() => {
        new QRious({
            element: document.getElementById('qr'),
            value: 'https://wa.me/79851000864'
        });
    })

    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <ToastContainer limit={10}/>

                <div className={classes.header}>
                    <div>
                        Помощь
                    </div>

                    <CloseIcon onClick={props.onClose} style={{cursor: "pointer"}}/>
                </div>

                <div>
                    <div>
                        До обращения в техническую поддержку обязательно просмотрите материалы по ссылкам:
                        {
                            props.userRole == RoleIdsEnum.SSK &&
                            <ul>
                                <ol>
                                    <a href={"/help/AIMA_video_s.mp4"}>
                                        Посмотреть обучение (видео)
                                    </a>
                                </ol>
                                <ol>
                                    <a href={"/help/AIMA_manual_s.pdf"}>
                                        Скачать инструкцию (pdf)
                                    </a>
                                </ol>
                            </ul>
                        }
                        {
                            props.userRole == RoleIdsEnum.KM &&
                            <ul>
                                <ol>
                                    <a href={"/help/AIMA_video_k.mp4"}>
                                        Посмотреть обучение (видео)
                                    </a>
                                </ol>
                                <ol>
                                    <a href={"/help/AIMA_manual_k.pdf"}>
                                        Скачать инструкцию (pdf)
                                    </a>
                                </ol>
                            </ul>
                        }
                    </div>

                    <div>
                        Если вы не нашли ответа на свой вопрос, свяжитесь с нами:
                        <ul>
                            <ol>
                                <a href={"tel:89851000864"}>+7 (985) 100-08-64</a> (WhatsApp)
                            </ol>
                            <ol>
                                <a href={"mailto:support@aima.biz"}>support@aima.biz</a>
                            </ol>
                        </ul>
                    </div>

                    <canvas id="qr"></canvas>

                    <p>Служба технической поддержки работает в будние дни с 8 до 18 часов по московскому времени.</p>
                </div>
            </div>
        </div>
    );
}