import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    QuotationStatusesEnum,
    QuotesResponse,
    RequestStatusEnum,
} from "../../common/commonTypes";
import {getClientsTableRequest, getQuotesTableRequest} from "../../api/commonApi";



interface ClientTableFilterInterface {
    insurance_company_name: string;
    tarif: string;
    insurance_award:  string;
    created_at:  string;
    comment:  string;
    account_needs: string;
    quotation_status_id: string,
    quotation_id: string,
}

interface SortTableFilterInterface {
    [key: string]: string | null;
}


interface QuotesTableOperatorsInterface {
    tarif: string;
    insurance_award: string;
}

interface QuotesTableState {
    data: QuotesResponse,
    status: RequestStatusEnum,
    filters: ClientTableFilterInterface,
    operators: QuotesTableOperatorsInterface,
    sorts: SortTableFilterInterface,
    resetPagination: boolean,
    allDataLoaded: boolean,
    lastQuery: string,
    scrollIndex: number | undefined,
    activeQuoteId: string,
    activeQuoteOfferId: string,
    chatId: string,
    blockAutoFetching: boolean,
}

// Define the initial state using that type
const initialState: QuotesTableState = {
    data: {
        count_all: 0,
        limit: 0,
        ptoken: "",
        data: []
    },
    status: RequestStatusEnum.Succeeded,
    filters: {
        insurance_company_name: "",
        tarif: "",
        insurance_award: "",
        created_at: "",
        comment: "",
        account_needs: "",
        quotation_status_id: "",
        quotation_id: "",
    },
    operators: {
      tarif: "eq",
      insurance_award: "eq",
    },
    sorts: {
    },
    resetPagination: false,
    allDataLoaded: false,
    lastQuery: "",
    scrollIndex: undefined,
    activeQuoteId: "",
    activeQuoteOfferId: "",
    chatId: "",
    blockAutoFetching: false
};

export const fetchQuotes = createAsyncThunk('quotesTable/initFetch', async ({skip, take, activeInsuranceId}: {skip: number, take: number, activeInsuranceId: string}, { getState }) => {

    // @ts-ignore
    const state: QuotesTableState = getState().quotesTable;

    let queryString = `?limit=${40}`;

    Object.keys(state.filters).forEach((fk) => {
        // @ts-ignore
        const value = state.filters[fk];
        //console.log('fk', fk, value)

        if(value) {
            // @ts-ignore
            const operator = state.operators[fk];
            //console.log('operator', operator);
            if(operator) {
                queryString = `${queryString}&${fk}=${operator}:${value}`;
            } else {
                if(fk === "quotation_status_id") {
                    if(value === QuotationStatusesEnum.APPLY.toString()) {
                        queryString = `${queryString}&${fk}=${value}`;
                        queryString = `${queryString}&is_applied=true`;
                    } else if (value === QuotationStatusesEnum.REJECTED.toString()) {
                        queryString = `${queryString}&${fk}=${QuotationStatusesEnum.APPLY}`;
                        queryString = `${queryString}&is_applied=false`;
                    } else {
                        queryString = `${queryString}&${fk}=${value}`;
                    }
                } else {
                    queryString = `${queryString}&${fk}=${value}`;

                }
            }

        }
    });

    for (let fk in state.sorts) {
        // @ts-ignore
        const value = state.sorts[fk];

        if(value) {
            queryString = `${queryString}&sort_by=${fk}&sort_order=${value}`;
            break;
        }

    }

    const qString = queryString;

    if(!state.resetPagination && state.data.ptoken.length != 0) {
        queryString = `${queryString}&ptoken=${state.data.ptoken}`
    }

    const result = await getQuotesTableRequest(queryString, activeInsuranceId);

    return { skip: skip, data: result.data, lastQuery: queryString};
    
    //await timeout(2000);
    
    //console.log('skip, take', skip, take)
    //return { skip: skip, items: generateRows(take - skip, skip)};
})


export const quotesTableSlice = createSlice({
    name: 'quotesTable',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        reInit: (state) => {
            for(let k in initialState) {
                // @ts-ignore
                state[k] = initialState[k];
            }
        },
        clearData: (state) => {
            // @ts-ifnore
            state.data.data = [];
        },
        setRequestStatus: (state, { payload }: {payload: RequestStatusEnum}) => {
            state.status = payload;
        },
        setBlockQuotesAutoFetching: (state, { payload }: {payload: boolean}) => {
            state.blockAutoFetching = payload;
        },
        setFilterName: (state, { payload }: {payload: string}) => {
            state.filters.insurance_company_name = payload;
        },
        setFilterQuotationId: (state, { payload }: {payload: string}) => {
            state.filters.quotation_id = payload;
        },
        setScrollIndex: (state, { payload }: { payload: number | undefined}) => {
            state.scrollIndex = payload;
        },
        setOperatorTarif: (state, { payload }: {payload: string}) => {
            state.operators.tarif = payload;
        },
        setOperatorAward: (state, { payload }: {payload: string}) => {
            state.operators.insurance_award = payload;
        },
        setFilterTarif: (state, { payload }: {payload: string}) => {
            state.filters.tarif = payload;
        },
        setFilterAward: (state, { payload }: {payload: string}) => {
            state.filters.insurance_award = payload;
        },
        setFilterCreatedDate: (state, { payload }: {payload: string}) => {
            state.filters.created_at = payload;
        },

        setActiveClientQuote: (state, { payload }: { payload: { quoteId: string, quoteOfferId: string, chatId: string } }) => {
            state.activeQuoteId = payload.quoteId;
            state.activeQuoteOfferId = payload.quoteOfferId;
            state.chatId = payload.chatId;
        },

        setFilterComment: (state, { payload }: {payload: string}) => {
            state.filters.comment = payload;
        },

        setFilterStatus: (state, { payload }: {payload: string}) => {
            state.filters.quotation_status_id = payload;
        },

        clearFilters: (state) => {
            state.filters = {
                insurance_company_name: "",
                tarif: "",
                insurance_award: "",
                created_at: "",
                comment: "",
                account_needs: "",
                quotation_status_id: "",
                quotation_id: "",
            };

            state.operators = {
                tarif: "eq",
                insurance_award: "eq",
            };

            state.sorts = {};

            state.resetPagination = true;
            state.allDataLoaded = false;
        },
        resetPagination: (state) => {
            state.allDataLoaded = false;
            state.resetPagination = true;
            state.data.ptoken = "";
        },
        setChatId: (state, { payload }: {payload: { id: string,}}) => {
            state.chatId = payload.id;
        },
        setSort: (state, { payload }: {payload: { name: string, order: string }}) => {
            Object.keys(state.sorts).forEach((ks) => {
                state.sorts[ks] = null;
            })
            state.sorts[payload.name] = payload.order;
            state.resetPagination = true;
            state.allDataLoaded = false;
        },
        clearSort: (state) => {
            Object.keys(state.sorts).forEach((ks) => {
                state.sorts[ks] = null;
            })
            state.resetPagination = true;
            state.allDataLoaded = false;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchQuotes.pending, (state, action) => {
                //state.status = RequestStatusEnum.Loading;
            })
            .addCase(fetchQuotes.fulfilled, (state, action) => {
                if(action.payload.data.data.length < 40) {
                    //console.log('state.data.ptoken', state.data.ptoken)
                    state.allDataLoaded = true;
                    if(!state.data.ptoken) {
                        state.data = action.payload.data;
                    } else {
                        state.data = { ...action.payload.data, data: [...state.data.data, ...action.payload.data.data] };
                    }
                    state.status = RequestStatusEnum.Succeeded;
                    return;
                }
                if(state.resetPagination) {
                    state.data = action.payload.data;
                } else {
                    state.data = { ...action.payload.data, data: [...state.data.data, ...action.payload.data.data] };
                }
                state.resetPagination = false;
                state.allDataLoaded = false;
                state.status = RequestStatusEnum.Succeeded;

                //state.items = action.payload.items;
            })
            .addCase(fetchQuotes.rejected, (state, action) => {
                state.status = RequestStatusEnum.Failed;
            })
    }

})

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}




export const {
    setFilterStatus,
    setFilterQuotationId,
    setFilterName,
    setFilterTarif,
    setFilterAward,
    setOperatorAward,
    setOperatorTarif,
    setFilterComment,
    setFilterCreatedDate,
    clearFilters,
    setSort,
    clearSort,
    resetPagination,
    setChatId,
    setScrollIndex,
    setActiveClientQuote,
    setBlockQuotesAutoFetching,
    setRequestStatus,
    reInit,
    clearData,
} = quotesTableSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default quotesTableSlice.reducer