import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import classes from "./GotBillFormModal.module.scss";
import EditOfferForm from "../../../forms/EditOfferForm/EditOfferForm";
import {Button} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {getDealTableRequest, updateDealStatus, updateOfferStatus} from "../../../api/commonApi";
import {DealStatusesEnum, QuotationStatusesEnum} from "../../../common/commonTypes";
import { ToastContainer, toast } from 'react-toastify';
import {fetchInsuranceObjects} from "../../../store/features/insuranceObjects";
import {fetchQuotes, resetPagination, setScrollIndex} from "../../../store/features/quotesTable";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../store/hooks";
import CloseIcon from "@mui/icons-material/Close";



export default function GotBillFormModal (props: { onClose?: () => void, ref?: any, offerId: string, }) {

    const [loading, loadingSet] = useState(false);

    const dispatch = useDispatch();
    const { activeClientId } = useAppSelector((state) => state.clientsTable);
    const { activeId } = useAppSelector((state) => state.insuranceObjects);


    const payHandler = async () => {
        loadingSet(true);
        try {
            const resp = await getDealTableRequest(`?quotation_id=${props.offerId}&limit=1`);
            const deal = resp.data.data[0];
            await updateDealStatus( deal.id, { status_id: DealStatusesEnum.DONE });
          /*  await dispatch(fetchInsuranceObjects({clientId: activeClientId}));

            await dispatch(resetPagination());
            // @ts-ignore
            await dispatch(setScrollIndex(0));
            await dispatch(fetchQuotes({skip: 0, take: 40, activeInsuranceId: activeId}));

            props.onClose && props.onClose();*/

            toast.info(`Счет успешно оплачен`,   );
        } catch (e) {
            toast.error(`Оплата: что-то пошло не так, обратитесь к администратору`,   );
        } finally {
            loadingSet(false);
            props.onClose && props.onClose();
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <ToastContainer limit={10} />

                <div className={classes.header}>
                    <div>
                        Оплата счёта
                    </div>

                    <CloseIcon onClick={props.onClose} style={{cursor: "pointer"}}/>
                </div>

                <div>

                </div>

                <div className={classes.btnContainer}>
                    {
                        !loading && <Button disabled={loading} onClick={payHandler} className={classes.submit} variant="contained"
                                                     type="submit">
                            {
                                loading ? <CircularProgress style={{color: "white",}} size={30}/>
                                    : "Cчёт оплачен"
                            }
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
}