import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import classes from "./EditOfferFormModal.module.scss";
import EditOfferForm from "../../../forms/EditOfferForm/EditOfferForm";
import EditDealForm from "../../../forms/EditDealForm/EditDealForm";


export default function EditDealFormModal (props: { onClose?: () => void, ref?: any, dealId: string, isEdit: boolean, quotationId: string, offerId: string}) {
    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <EditDealForm dealId={props.dealId}
                              onClose={props.onClose}
                              quotationId={props.quotationId}
                              isEdit={props.isEdit}
                              offerId={props.offerId}
                />
            </div>
        </div>
    );
}