import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserInterface} from "../../common/commonTypes";

// Define the initial state using that type
const initialState: UserInterface = {
    "created_at": "",
    "email": "",
    "id": "",
    "name": "",
    "role_id": 0,
    "updated_at": ""
}


export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserInterface>) => {
            //console.log('hello')
            Object.keys(state).forEach(k => {
                // @ts-ignore
                state[k] = action.payload[k]
            });
        },
    },
})

export const { setUser } = userSlice.actions

/*// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter.value*/

export default userSlice.reducer