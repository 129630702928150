import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import ClientForm from "../../forms/ClientForm/ClientForm";
import classes from "./Modal.module.scss";
import ClientPhysicalForm from "../../forms/ClientForm/ClientPhysicalForm";
import {ChooseFormData} from "../../common/commonTypes";




export default function CreatePhysicalClientModal (props: { onClose?: () => void, ref?: any, clientId: string, chooseForm?: ChooseFormData}) {

    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <ClientPhysicalForm onClose={props.onClose} clientId={props.clientId} chooseForm={props.chooseForm} />
            </div>
        </div>
    );
}