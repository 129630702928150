import React, {useEffect, useRef, useState} from 'react';

import 'react-virtualized/styles.css';
import classes from "./OffersModal.module.scss";
import InsuranceObjectForm from "../../../forms/InsuranceObjectForm/InsuranceObjectForm";
import OffersFormForm from "../../../forms/OffersForm/OffersFormForm";




export default function OffersModal (props: { onClose?: () => void, ref?: any, clientId: string, quoteId: string, offersCount: number }) {

    return (
        <div className={classes.root}>
            <div className={classes.clientForm}>
                <OffersFormForm clientId={props.clientId} quoteId={props.quoteId} onClose={props.onClose} offersCount={props.offersCount}/>
            </div>
        </div>
    );
}